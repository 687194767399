<div class="thumb"
     [ngClass]="{'video-thumb': thumbItem.Type !== 1}"
     [ngStyle]="{ backgroundImage: 'url('+ thumbItem.ThumbnailUrl + ')'}">
</div>
<div class="show-all-layer" *ngIf="showAllLayer" (click)="$event.stopPropagation()">
  <a [routerLink]="['/news/', newsId]" class="flex flex-[1_0_auto] flex-center-center w-full">
    <div class="thumb-content">
      <div class="images-count typography-top-header">+{{moreCount}}</div>
      <p class="typography-label font-bold">
        Show all<span class="max-md:hidden"> photos</span>
      </p>
    </div>
  </a>
</div>
