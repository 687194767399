<layout-container>
  <div class="flex flex-col flex-start-stretch gap-32" *rxLet="state$ as state">
    <div class="flex max-md:flex-col flex-auto gap-32 user-profile-container">
      <personal-details class="flex flex-col flex-[0_1_100%]"
                        *ngIf="state.hasAccessToPersonalDetails"
                        [canEdit]="state.canEdit">
      </personal-details>
      <div class="flex flex-col flex-[0_1_100%] gap-32"
           *ngIf="state.hasAccessToBusinessDetails || state.hasAccessToPreferredWorkLocations">
        <business-details *ngIf="state.hasAccessToBusinessDetails"
                          [canEdit]="state.canEdit">
        </business-details>
        <preferred-work-locations *ngIf="state.hasAccessToPreferredWorkLocations"
                                  [canEdit]="state.canEdit">
        </preferred-work-locations>
      </div>
    </div>
    <terms-and-conditions *ngIf="state.hasAccessToTermsAndConditions"
                          [canEdit]="state.canEdit">
    </terms-and-conditions>
    <notification-settings *ngIf="state.hasAccessToNotificationSettings"
                           [canEdit]="state.canEdit">
    </notification-settings>
  </div>
</layout-container>
