import {Directive, ElementRef, Input, OnDestroy} from '@angular/core';
import {SharepointService} from '../services/sharepoint.service';
import {take} from 'rxjs/operators';


const enum LoadingState {
  'IN_PROGRESS' = 'in_progress',
  'LOADED' = 'loaded',
  'ERROR' = 'error'
}

@Directive({
  selector: '[galleryPhoto]'
})
export class GalleryPhotoDirective implements OnDestroy {

  blobUrl: string;

  @Input() set galleryPhoto(photoUrl: string) {
    this.loadPhoto(photoUrl);
  }

  constructor(private el: ElementRef,
              private sharepointService: SharepointService) {
  }

  clearLoadingStateClasses() {
    this.el.nativeElement.classList.remove(
      LoadingState.IN_PROGRESS,
      LoadingState.LOADED,
      LoadingState.ERROR
    );
  }

  updateLoadingState(loadingState: LoadingState) {
    this.clearLoadingStateClasses();
    this.el.nativeElement.classList.add(loadingState);
  }

  loadPhoto(photoURL: string) {
    this.updateLoadingState(LoadingState.IN_PROGRESS);
    this.sharepointService.getSharepointFile(photoURL)
      .pipe(
        take(1)
      )
      .subscribe((data) => {
        this.blobUrl = URL.createObjectURL(data);
        this.doPreload(this.blobUrl);
      });
  }

  doPreload(blobUrl: string) {
    const img = new Image();

    img.onload = () => {
      this.onImagePreload(blobUrl);
    };

    img.onerror = (error) => {
      this.updateLoadingState(LoadingState.ERROR);
      console.debug('[GalleryPhotoDirective] Failed to load image : ' + this.galleryPhoto, error);
    };

    img.src = blobUrl;
  }

  onImagePreload(blobUrl: string) {
    this.updateLoadingState(LoadingState.LOADED);
    if (this.el.nativeElement.tagName.toLowerCase() === 'img') {
      // if directive used on IMG then set it's src attribute
      this.el.nativeElement.src = blobUrl;
    } else {
      // else set background image
      this.el.nativeElement.style.backgroundImage = 'url(' + blobUrl + ')';
    }
  }

  ngOnDestroy() {
    console.debug('[GalleryPhotoDirective][ngOnDestroy] revoke Blob', this.blobUrl);
    URL.revokeObjectURL(this.blobUrl);
  }
}
