<ng-container *rxLet="state$ as state">
  <section
    class="location-details"
    data-test-id="user-profile-location-container">
    <h4 class="font-bold">Preferred work location(s)</h4>
    <ng-container *ngIf="!state.preferredWorkLocationsLoading; else loading">
      <ng-container *ngIf="state.preferredWorkLocations; else nodata">
        <p>
          Specify the locations where you would consider working in case of
          interesting job opportunities.
        </p>
        <location-edit
          [locations]="state.preferredWorkLocations"
          data-test-id="user-profile-locationEdit-container"
          [hint]="true"
          updateMethodName="updateLocationsProfile"
          (serverError)="onServerError()"
          (updateSuccess)="preferredWorkLocationsUpdated($event)"
          [canEdit]="state.canEdit">
        </location-edit>
      </ng-container>
    </ng-container>
  </section>
</ng-container>

<ng-template #nodata>
  <no-data></no-data>
</ng-template>

<ng-template #loading>
  <div class="flex flex-center-start w-full h-full">
    <loader color="primary"></loader>
  </div>
</ng-template>
