import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CmsSortingModes } from '../interfaces/cms';

@Injectable()
export class CmsCardListService {
  private _sortingMode$ = new BehaviorSubject<CmsSortingModes>(
    CmsSortingModes.NewestFirst
  );

  private _isFilterVisible$ = new BehaviorSubject<boolean>(false);

  get sortingMode$(): Observable<CmsSortingModes> {
    return this._sortingMode$.asObservable();
  }

  get isFilterVisible$(): Observable<boolean> {
    return this._isFilterVisible$.asObservable();
  }

  updateSortingMode(mode: CmsSortingModes) {
    this._sortingMode$.next(mode);
  }

  updateContentFilterVisibility(isVisible: boolean) {
    this._isFilterVisible$.next(isVisible);
  }

  switchContentFilterVisibility() {
    this._isFilterVisible$.next(!this._isFilterVisible$.value);
  }
}
