import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { forkJoin, Observable, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  map,
  mergeMap
} from 'rxjs/operators';
import { AppConfigService } from '../../services/app-config.service';
import * as GlobalAppConfigActions from '../actions/global-app-config.actions';
import * as fromGlobalAppConfig from '../reducers/global/global-app-config.reducer';
import * as fromGlobalAppConfigSelectors from '../reducers/global/global-app-config.selectors';
import {
  IAllowedFeaturesResponse,
  IAppConfig
} from '../../interfaces/app-config';
import { HttpClient } from '@angular/common/http';
import { IUserPreferences } from '../../interfaces/user-preferences';
import { ITermsAndConditions } from '../../interfaces/user-profile';
import { ICvLanguage, ICvLanguageResponse } from '../../interfaces/cv-language';
import { AuthorizationService } from '../../services/authorization.service';
import { ITimeKeysResponse } from '../../interfaces/time-keys';

@Injectable()
export class GlobalAppConfigEffects {
  constructor(
    private actions$: Actions,
    private authorizationService: AuthorizationService,
    private appConfigStore$: Store<fromGlobalAppConfig.GlobalAppConfigState>,
    private http: HttpClient
  ) {}


  private readonly commonPrivilegesList: Array<string> = [
    'v1/static-content/*'
  ];

  queryAppConfig$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(GlobalAppConfigActions.queryAppConfig),
      distinctUntilChanged(),
      concatLatestFrom(() => [
        this.appConfigStore$.select(
          fromGlobalAppConfigSelectors.selectAppConfigApiUrlsState
        )
      ]),
      mergeMap(([action, configApis]) => {
        const allConfigApis = Object.keys(configApis).map((key) => {
          return this.http.get(configApis[key]);
        });

        return forkJoin(allConfigApis).pipe(
          map(this.setConfigProperties),
          catchError((error) => {
            console.error(
              '[My7NAppConfig] Error occurred during requiring for configuration'
            );

            return of(GlobalAppConfigActions.queryAppConfigError({ error }));
          })
        );
      })
    );
  });

  private appendCommonPrivileges(refToListOfPrivileges: Array<string>) {
    Array.prototype.push.apply(refToListOfPrivileges, this.commonPrivilegesList);
  }

  private setConfigProperties = (response: Array<any>) => {
    const config: IAppConfig = {
      // should be set during init process
      TimeKeys: {},
  
      // should be set during init process
      User: undefined,
  
      // should be set during init process
      Languages: undefined
    };
  
    const privileges = [];
    let regexPrivileges;
  
    const timekeysResponse: ITimeKeysResponse = response[0];
    const userPreferencesResponse: IUserPreferences  = response[1];
    const languagesResponse: ICvLanguageResponse = response[2];
    const accessFeaturesResponse: IAllowedFeaturesResponse = response[3];
    const termsResponse: ITermsAndConditions = response[4];
  
    // Set Timekeys
    Object.keys(timekeysResponse).forEach((timekey) => {
      // Remove TimeKey from key
      config.TimeKeys[timekey.replace('TimeKey', '')] =
      timekeysResponse[timekey];
    });
  
    config.User = userPreferencesResponse;
    config.User.AcceptStoringAndSendingDataBy7n = termsResponse.AcceptStoringAndSendingDataBy7n;
    config.User.AcceptedMy7nEventInvitation = termsResponse.AcceptMy7nEventInvitation;
    config.User.AcceptedMy7nNewsletter = termsResponse.AcceptMy7nNewsletter;
    config.User.Privileges = config.User.Privileges || [];
  
    // Check [ADR 0005] to know why we are appending privileges on frontend
    this.appendCommonPrivileges(config.User.Privileges);
  
    // First add privileges ending with '/*' to strict privileges, but without '/*' ending
    // this will allow to match endpoints like api/cv and api/cv/2 but no api/cvaaaaaaaaa/s
    config.User.Privileges.forEach(function (privilege) {
      if (privilege.substring(-2) === '/*') {
        // Remove /* or * char at the end
        privileges.push(privilege.replace('/*', ''));
      }
    });
  
    // all feature privileges that we got from identity service are added also to privileges
    privileges.push(
      ...accessFeaturesResponse.AllowedFeatures
    );
  
    // append the original privileges also
    Array.prototype.push.apply(privileges, config.User.Privileges);
  
    // Define regex privileges. {id} strings are replaced with regex
    regexPrivileges = privileges
      .slice()
      .filter((privilege) => {
        return privilege.indexOf('{id}') > -1;
      })
      .map(function (privilege) {
        const appendEndChar = privilege[privilege.length - 1] !== '*',
          // Remove /* or * char at the end and replace {id} with regex for numbers between 0-9+
          cleanPrivilege = privilege
            .replace(/(\*|\/\*)$/, '')
            .replace(
              /{id}/g,
              '([0-9]+)|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})'
            );
  
        return new RegExp(
          '^' + cleanPrivilege + (appendEndChar ? '$' : ''),
          'i'
        );
      });
  
    config.User.Privileges = privileges;
    config.User.RegexPrivileges = regexPrivileges;
  
    config.Languages = languagesResponse.Languages.map(
      (language: ICvLanguage) => {
        language.FlagUrl =
        AppConfigService.imagesPath + `/flags/${language.LanguageCode}.svg`;
  
        return language;
      }
    );
  
    // Initialize auth service with new user config
    this.authorizationService.init(config.User);
  
    return GlobalAppConfigActions.queryAppConfigSuccess({ config });
  }
}
