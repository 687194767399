import { LegalEntityFeatures } from '@my7n/features';
import { BusinessUnit } from './business-unit';

export const LegalEntityFeaturesBusinessUnitMapping: {
  [key: string]: BusinessUnit;
} = {
  [LegalEntityFeatures._7n_AS]: BusinessUnit.Denmark,
  [LegalEntityFeatures._7n_sp_zoo]: BusinessUnit.Poland,
  [LegalEntityFeatures._7n_CIM]: BusinessUnit.Poland,
  [LegalEntityFeatures._7n_USA_Inc]: BusinessUnit.USA,
  [LegalEntityFeatures._7n_Sweiz_AG]: BusinessUnit.Switzerland,
  [LegalEntityFeatures._7n_Norge_AS]: BusinessUnit.Norway,
  [LegalEntityFeatures._7n_Finland_OY]: BusinessUnit.Finland,
  [LegalEntityFeatures._7n_IT_Consulting_AB]: BusinessUnit.Sweden,
  [LegalEntityFeatures._7n_Seven_N_Consulting_Pvt_Ltd]: BusinessUnit.India
};

export enum LegalEntityCountry {
  Poland = 'Poland',
  Denmark = 'Denmark',
  India = 'India',
  Finland = 'Finland',
  Norway = 'Norway',
  Sweden = 'Sweden',
  Switzerland = 'Switzerland',
  USA = 'USA'
}

export const LegalEntityFeaturesCountryNameMapping: { [key: string]: string } =
  {
    [LegalEntityFeatures.Poland]: LegalEntityCountry.Poland,
    [LegalEntityFeatures.Denmark]: LegalEntityCountry.Denmark,
    [LegalEntityFeatures.USA]: LegalEntityCountry.USA,
    [LegalEntityFeatures.Switzerland]: LegalEntityCountry.Switzerland,
    [LegalEntityFeatures.Norway]: LegalEntityCountry.Norway,
    [LegalEntityFeatures.Finland]: LegalEntityCountry.Finland,
    [LegalEntityFeatures.Sweden]: LegalEntityCountry.Sweden,
    [LegalEntityFeatures.India]: LegalEntityCountry.India
  };

export const NON_INTERNATIONAL_LEGAL_ENTITY_COUNTRIES = [
  LegalEntityCountry.Poland,
  LegalEntityCountry.Denmark,
  LegalEntityCountry.India
];
