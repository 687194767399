import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IMy7nEnvConfig, ServiceNames } from '../interfaces/my7n-env-config';
import { MY7N_ENV_CONFIG } from '../functions/my7n-env-config';
import { lastValueFrom } from 'rxjs';
import { AppConfigService } from './app-config.service';

@Injectable()
export class FeedbackService {

  constructor(private http: HttpClient,
              @Inject(MY7N_ENV_CONFIG) private envConfig: IMy7nEnvConfig,
              private appConfigService: AppConfigService) { }

  sendEmail(content: string): Promise<Object> {
    return lastValueFrom(this.http.put(this.appConfigService.serviceUrl(ServiceNames.Core) + 'feedback', {
      Content: content,
      Location: window.location.href,
      VersionHash: this.envConfig.VERSION_HASH
    }));
  }
}
