import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import { SnackBarService, SnackBarTypes } from '@my7n/ui';
import { RxState } from '@rx-angular/state';
import { IAddress } from '../../../interfaces/address';
import { IPhones } from '../../../interfaces/phones';
import {
  IConsultantsTenantDetails,
  IPersonalDetails,
  MAX_LENGTHS
} from '../../../interfaces/user-profile';
import { UserProfileFacadeService } from '../../../services/facades/user-profile-facade.service';

export interface IPersonalDetailsLocalState {
  personalDetails: IPersonalDetails;
  personalDetailsLoading: boolean;
  canEdit: boolean;
  consultantsTenantDetails: IConsultantsTenantDetails;
  consultantsTenantDetailsLoading: boolean;
}

export const initialState: IPersonalDetailsLocalState = {
  personalDetails: null,
  personalDetailsLoading: false,
  canEdit: false,
  consultantsTenantDetails: null,
  consultantsTenantDetailsLoading: false
};

@Component({
  selector: 'personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState]
})
export class PersonalDetailsComponent implements OnInit {
  MAX_LENGTHS = MAX_LENGTHS;

  @Input() set canEdit(can: boolean) {
    this.state.set({ canEdit: can });
  }

  readonly state$ = this.state.select();

  constructor(
    private state: RxState<IPersonalDetailsLocalState>,
    private userProfileFacade: UserProfileFacadeService,
    private snackBarService: SnackBarService
  ) {
    this.state.set({ ...initialState });
  }

  ngOnInit() {
    this.userProfileFacade.queryPersonalDetails();
    this.userProfileFacade.queryConsultantsTenantDetails();
    this.initLocalStateConnections();
  }

  initLocalStateConnections() {
    this.state.connect(
      'personalDetails',
      this.userProfileFacade.personalDetails$
    );
    this.state.connect(
      'personalDetailsLoading',
      this.userProfileFacade.personalDetailsLoading$
    );
    this.state.connect(
      'consultantsTenantDetails',
      this.userProfileFacade.consultantsTenantDetails$
    );

    this.state.connect(
      'consultantsTenantDetailsLoading',
      this.userProfileFacade.consultantsTenantDetailsLoading$
    );
  }

  personalDetailsEmailUpdated(email: string) {
    this.userProfileFacade.updatePersonalDetailsEmail(email);
  }

  personalDetailsPhonesUpdated(phones: IPhones) {
    this.userProfileFacade.updatePersonalDetailsPhones(phones);
  }

  personalDetailsAddressUpdated(address: IAddress) {
    this.userProfileFacade.updatePersonalDetailsAddress(address);
  }

  onServerError() {
    this.snackBarService.open({
      message: 'A server error occured',
      type: SnackBarTypes.ErrorAlt
    });
  }
}
