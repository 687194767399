import { Injectable } from '@angular/core';
import { CmsContentTypes } from 'projects/my7n-app/src/interfaces/cms';

const URL_PREFIXES = {
  [CmsContentTypes.AcademyItem]: '/learning-and-dev/academy/library',
  [CmsContentTypes.AcademyVideo]: '/learning-and-dev/academy/videos',
  [CmsContentTypes.AcademyBookItem]: '/learning-and-dev/academy/library/book',
  [CmsContentTypes.News]: 'content/news/' // @TODO: set to simple news/ when yammer news won't be supported
};

// services

@Injectable()
export class CmsCardService {
  getLinkPrefix(type: CmsContentTypes) {
    return URL_PREFIXES[type] ? URL_PREFIXES[type] : '';
  }
}
