<div class="news-container news-event" #newsContainer>
  <!-- photo -->
  <div class="news-photo"
       *ngIf="event.Attachments[0].ThumbnailUrl"
       [ngStyle]="{'width': imageSize.width + 'px', 'height': imageSize.height + 'px', 'background-image': 'url(' + event.Attachments[0].ThumbnailUrl + ')'}">
  </div>

  <div class="news-content flex flex-col">

    <!-- news title -->
    <h3 class="news-title" *ngIf="event.Title">
      <a [routerLink]="detailsUrl">{{event.Title | truncate: titleLimit:true}}</a>
    </h3>

    <!-- news description with more link -->
    <div class="news-description">
      <p class="news-description-text" *ngIf="event.Description" [innerHTML]="event.Description | truncate: descriptionLimit:true"></p>
      <a [routerLink]="detailsUrl" 
         type="button" 
         mat-button 
         color="primary"
         data-test-id="news-description-read-more">Read more</a>
    </div>
    <hr/>
    <!-- news footer -->
    <div class="news-footer flex flex-row flex-between-center">
      <div class="footer-author-container flex max-sm:flex-col sm:gap-24 max-sm:flex-start-start sm:max-md:flex-between-center md:flex-start-center">
        <!-- news author info -->
        <div class="news-author-info flex flex-start-center md:gap-12">
          <div class="news-author-info-avatar max-md:hidden">
            <secure-avatar [avatarUrl]="event.AuthorAvatar" [title]="event.AuthorName"></secure-avatar>
          </div>
          <div class="news-author-info-name-posted flex flex-col flex-[1_1_0%]">
            <span class="news-author-info-name">{{event.AuthorName}}</span>
            <span class="news-author-info-job-title">{{event.AuthorJobTitle}}</span>
          </div>
        </div>

        <!-- news dates -->
        <div class="news-dates flex start-center gap-12">
          <mat-icon class="max-md:hidden" svgIcon="calendar_big"></mat-icon>
          <span class="news-author-info-posted">{{event.Published | defaultDate: 'minute': true}}</span>
        </div>
        </div>
      <reactions [contentId]="event.Id" [contentType]="contentType" data-test-id="reactions"></reactions>
    </div>
    </div>
</div>
