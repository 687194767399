<div class="mark-as-read-container">
  <a mat-button title="Mark all as read" color="primary" (click)="markAllAsRead()">Mark all as read</a>
  <button mat-button isIconButton="true" class="close-btn" (click)="popoverInstance.closePopover($event)">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</div>
<div class="notifications-container">
  <cdk-virtual-scroll-viewport class="notifications-virtual-scroll-container" itemSize="150" [minBufferPx]="450" [maxBufferPx]="1000" *ngIf="notifications && notifications.length > 0; else noNotifications">
    <ng-container *cdkVirtualFor="let notification of notifications">
      <notifications-list-element [notification]="notification" (checkDetailsClicked)="popoverInstance.closePopover($event)"></notifications-list-element>
    </ng-container>
  </cdk-virtual-scroll-viewport>
  <ng-template #noNotifications>
    <div class="notifications-zero-screen">
      <h5>Sorry, we couldn't find new notifications</h5>
      <button mat-flat-button color="accent" (click)="refreshNotifications()" *ngIf="!(loading$ | async); else loading">
        Refresh
      </button>
      <ng-template #loading>
        <loader color="primary"></loader>
      </ng-template>
    </div>
  </ng-template>
</div>
