import { ChangeDetectionStrategy, Component, Injector, Input, OnInit } from '@angular/core';
import { NewsListElementComponent } from '../news-list-element/news-list-element.component';
import { INewsList, NewsTypes } from '../../../../../interfaces/news';
import { NewsListEventElementComponent } from '../news-list-event-element/news-list-event-element.component';
import { NEWS_INJECTION_TOKEN } from '../../../../../common/custom-tokens';
import { NewsListCmsElementComponent } from '../news-list-cms-element/news-list-cms-element.component';

@Component({
  selector: 'news-list-element-outlet',
  template: `<ng-container
    *ngComponentOutlet="targetComponent; injector: dataInjector"
  ></ng-container>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewsListElementOutletComponent implements OnInit {
  private _news: INewsList;

  @Input()
  set news(value: INewsList) {
    this._news = value;

    switch (value.Type) {
      case NewsTypes.EVENT:
        this.targetComponent = NewsListEventElementComponent;
        break;
      case NewsTypes.NEWS:
        this.targetComponent = NewsListElementComponent;
        break;
      case NewsTypes.CMS_NEWS:
        this.targetComponent = NewsListCmsElementComponent;
        break;
      default:
        console.error(
          '[NewsListElementOutletComponent] Wrong type of news element',
          value
        );
        break;
    }
  }

  /**
   * Component that will be rendered in outlet.
   */
  targetComponent;

  /**
   * Injects data to resolved component.
   */
  dataInjector: Injector;

  constructor(private injector: Injector) {}

  ngOnInit() {
    this.dataInjector = Injector.create({
      providers: [{ provide: NEWS_INJECTION_TOKEN, useValue: this._news }],
      parent: this.injector
    });
  }
}
