import { Injectable, ApplicationRef } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject
} from 'rxjs';
import { first, take } from 'rxjs/operators';
import * as _packageInfo from '../../../../package.json';
import { SwMessages } from '../sw/sw-messages';
import { GlobalCommonFacadeService } from './facades/global-common-facade.service';

const packageInfo = _packageInfo;

export enum UpdateReadyType {
  LazyUpdate,
  ForceUpdate
}

@Injectable({
  providedIn: 'root'
})
export class PwaService {
  static readonly SW_FILE_NAME = 'ngsw-worker.js';
  static isStandaloneApp = false;
  static appVersion: string;

  private _isSwActive = new BehaviorSubject<boolean>(false);
  private _swStatus = new BehaviorSubject<string>('unknown');
  private _isUpdateReady$ = new ReplaySubject<UpdateReadyType>();
  private _newSwActivated$ = new ReplaySubject<boolean>();
  private _isStable$ = new ReplaySubject<boolean>();
  private _reloadDialogDisabled$ = new BehaviorSubject<boolean>(false);

  get isUpdateReady$(): Observable<UpdateReadyType> {
    return this._isUpdateReady$.asObservable();
  }

  get newSwActivated$(): Observable<boolean> {
    return this._newSwActivated$.asObservable();
  }

  get isSwActive$(): Observable<boolean> {
    return this._isSwActive.asObservable();
  }

  get swStatus$(): Observable<string> {
    return this._swStatus.asObservable();
  }

  get isStable$(): Observable<boolean> {
    return this._isStable$.asObservable();
  }

  get reloadDialogDisabled$(): Observable<boolean> {
    return this._reloadDialogDisabled$.asObservable();
  }

  constructor(
    private appRef: ApplicationRef,
    private globalFacade: GlobalCommonFacadeService
  ) {
    this.appRef.isStable
      .pipe(first((isStable) => isStable === true))
      .pipe(take(1))
      .subscribe(() => {
        this._isStable$.next(true);
        this.refreshSwInfo();
      });

    this.globalFacade.updateAppVersion(packageInfo.version);

    PwaService.appVersion = packageInfo.version;
  }

  initSwMessages() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.onmessage = (event) => {
        if (event.data && event.data.type === SwMessages.AppVersionRequest) {
          navigator.serviceWorker.controller.postMessage({
            type: SwMessages.AppVersionResponse,
            version: packageInfo.version
          });
        }

        if (event.data && event.data.type === SwMessages.AppReloadRequest) {
          this._isUpdateReady$.next(UpdateReadyType.ForceUpdate);
        } else if (
          event.data &&
          event.data.type === SwMessages.AppReloadSuggestion
        ) {
          this._isUpdateReady$.next(UpdateReadyType.LazyUpdate);
        }

        if (event.data && event.data.type === SwMessages.SwActivated) {
          // a new SW activated!!!
          this._newSwActivated$.next(true);
        }
      };
    }
  }

  standaloneAppParamUpdate() {
    // Thanks SO: https://stackoverflow.com/a/40932301
    PwaService.isStandaloneApp =
      window.navigator['standalone'] === true ||
      window.matchMedia('(display-mode: standalone)').matches;
  }

  updateReloadDialogDisabled(isDisabled: boolean) {
    this._reloadDialogDisabled$.next(isDisabled);
  }

  private getSwRegistration(): Promise<ServiceWorkerRegistration> {
    if (navigator && navigator.serviceWorker) {
      return navigator.serviceWorker.getRegistration();
    } else {
      return Promise.reject();
    }
  }

  private getSwRegistrations(): Promise<
    ReadonlyArray<ServiceWorkerRegistration>
  > {
    if (navigator && navigator.serviceWorker) {
      return navigator.serviceWorker.getRegistrations();
    } else {
      return Promise.reject();
    }
  }

  private refreshSwInfo(): void {
    this.getSwRegistration().then((registration: ServiceWorkerRegistration) => {
      if (registration && registration.active) {
        this._isSwActive.next(true);
        this._swStatus.next(registration.active.state);
      }
    });
  }

  private async clearCache(): Promise<boolean> {
    try {
      const names = await caches.keys();
      for (const name of names) {
        caches.delete(name);
      }
      return Promise.resolve(true);
    } catch (error) {
      console.error('[PwaService] error during cache clear: ', error);
      return Promise.reject();
    }
  }
}
