<h1 mat-dialog-title>Print settings
  <button mat-button
          type="button"
          isIconButton="true"
          (click)="cancel();"
          class="close-btn"
          data-test-id="print-settings-dialog-closeButton">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</h1>
<div mat-dialog-content class="flex flex-col">
  <span class="desc">Select layout options for the CV to be printed.</span>

  <div class="templates-section">

      <label class="mat-radio-group-label">Choose template</label>
      <mat-radio-group [(ngModel)]="printSettings.PrintCvTemplateId" color="primary"
                       class="flex flex-col flex-center-start gap-12" aria-label="Select an option">
        <mat-radio-button class="flex"
                          *ngFor="let template of Templates"
                          name="type"
                          [value]="template.Id"
                          [checked]="printSettings.PrintCvTemplateId == template.Id"
                          data-test-id="print-settings-dialog-{{template.Id}}">{{template.Name}}
          <tooltip-popover position="bottom" #tooltip>
            <mat-icon-with-hover #tooltipIcon iconName="tooltip_default" iconNameHover="tooltip_hover" iconNameActive="tooltip_active"></mat-icon-with-hover>
            <ng-template cdkPortal #tooltipPopoverContent="cdkPortal" let-tooltipPopover>
              <mat-icon svgIcon="close_tiny" (click)="tooltip.closePopover();" class="close-icon"></mat-icon>
              <div class="dialog-content multiline-content">
                <div class="title">{{template.Name}}</div>
                <span>{{template.Description}}</span>
              </div>
            </ng-template>
          </tooltip-popover>
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="presenter-section ng-select-flex-content-wrapper">
      <ng-select [(ngModel)]="presenters.choosenPresenter"
                 [items]="Presenters"
                 [multiple]="false"
                 [clearable]="false"
                 [closeOnSelect]="true"
                 bindLabel="Name"
                 placeholder="Select Presenter"
                 [disabled]="printSettings.PrintCvWithoutBranding"
                 (change)="printSettings.PrintCvPresenterId = presenters.choosenPresenter.CrmId"
                 [appendTo]="'.cdk-overlay-pane'"
                 data-test-id="print-settings-dialog-chosenPresenter">
      </ng-select>
      <label class="ng-select-label">Presented by</label>
    </div>
    <div class="flex flex-col flex-start-start">
      <div class="mat-checkbox-container">
        <mat-checkbox color="primary"
                      [(ngModel)]="printSettings.PrintCvWithoutBranding"
                      data-test-id="print-settings-dialog-PrintCvWithoutBranding">Without 7N branding</mat-checkbox>
      </div>
      <div class="mat-checkbox-container">
        <mat-checkbox color="primary"
                      [(ngModel)]="printSettings.PrintCvWithoutPhoto"
                      data-test-id="print-settings-dialog-PrintCvWithoutPhoto">Without photo of consultant</mat-checkbox>
      </div>
      <div class="mat-checkbox-container">
        <mat-checkbox color="primary"
                      [(ngModel)]="printSettings.PrintCvAnonymized"
                      data-test-id="print-settings-dialog-PrintCvAnonymized">Anonymized</mat-checkbox>
      </div>
    </div>
</div>
<div mat-dialog-actions>
  <button type="submit"
          mat-stroked-button
          color="primary"
          class="small-button"
          (click)="save()"
          [loading]="loading"
          data-test-id="print-settings-dialog-saveButton">Save settings</button>
  <button type="button"
          mat-button
          color="primary"
          class="small-button"
          (click)="cancel()"
          data-test-id="print-settings-dialog-cancelButton">Cancel</button>
</div>
