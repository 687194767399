import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import { tap } from 'rxjs/operators';

import {
  ROUTER_NAVIGATION,
  ROUTER_NAVIGATED,
  RouterNavigationAction,
  SerializedRouterStateSnapshot,
  ROUTER_ERROR,
  ROUTER_CANCEL,
} from '@ngrx/router-store';
import { ApplicationInsightsService } from '../../services/application-insights.service';

@Injectable()
export class RouterEffects {
  constructor(
    private actions$: Actions,
    private applicationInsightsService: ApplicationInsightsService
  ) {}

  routerNavigation$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      tap((a: RouterNavigationAction<SerializedRouterStateSnapshot>) => {
        this.applicationInsightsService.startPageTrack(a.payload.event.url);
      }));
  }, { dispatch: false });

  routerNavigated$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      tap((a: RouterNavigationAction<SerializedRouterStateSnapshot>) => {
        this.applicationInsightsService.stopPageTrack(a.payload.event.url);
      }));
  }, { dispatch: false });

  routerError$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_ERROR),
      tap((a: RouterNavigationAction<SerializedRouterStateSnapshot>) => {
        this.applicationInsightsService.stopPageTrack(a.payload.event.url);
      }));
  }, { dispatch: false });

  routerCancel$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_CANCEL),
      tap((a: RouterNavigationAction<SerializedRouterStateSnapshot>) => {
        this.applicationInsightsService.stopPageTrack(a.payload.event.url);
      }));
  }, { dispatch: false });
}
