import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Observable } from 'rxjs';
import { IEvent, IEventType } from '../interfaces/event';
import { IEventsResponse } from '../interfaces/events-response';
import { IEventBanner } from '../interfaces/event-banner';
import { ServiceNames } from '../interfaces/my7n-env-config';
import { IEventOwner } from '../interfaces/event-owner';
import { IEventGroup } from '../interfaces/event-group';
import { EventTypeString } from '../interfaces/event';
import { AppConfigService } from './app-config.service';

@Injectable()
export class EventsService {
  /**
   * Event API prefix.
   * @type {string}
   */
  readonly API_EVENTS: string;

  constructor(private http: HttpClient,
              private appConfigService: AppConfigService) {

    this.API_EVENTS = this.appConfigService.serviceUrl(ServiceNames.Events, 'v1') + 'events/';
  }

  /**
   * Returns event with specified id.
   * @param {number} eventId
   * @returns {Promise<IEvent>}
   */
  getEvent(eventId: number, forceRefresh= false): Observable<IEvent> {
    if (isNaN(eventId)) {
      throw new Error('Event Id is not a valid number');
    }

    let url = this.API_EVENTS + eventId;

    if (forceRefresh) {
      const salt = (new Date()).getTime();
      url += `?t=${salt}`;
    }

    return this.http.get<IEvent>(url);
  }

  /**
   * Returns events list.
   * @param {string} filter
   * @param {number} offset
   * @param {number} size
   * @returns {Observable<IEventsResponse>}
   */
  getEvents(
    filter = 'all',
    offset = 0,
    size?: number,
    includeUnannounced?: boolean,
    type?: EventTypeString
  ): Observable<IEventsResponse> {
    let apiResource = this.API_EVENTS + filter + '?offset=';

    apiResource += offset;

    if (size !== undefined) {
      apiResource += '&size=' + size;
    }

    if (includeUnannounced !== undefined && includeUnannounced !== false) {
      apiResource += '&includeUnannounced=true';
    }

    if (type) {
      apiResource += '&eventTypes=' + type;
    }

    return this.http.get<IEventsResponse>(apiResource);
  }

  /**
   * Returns event banners.
   * @returns {Promise<IEventBanner>}
   */
  getBanners(): Promise<Array<IEventBanner>> {
    return lastValueFrom(this.http
      .get<Array<IEventBanner>>(this.API_EVENTS + 'banners'))
  }

  /**
   * Returns event owners.
   * @returns {Observable<Array<IEventOwner>>}
   */
  getOwners(): Observable<Array<IEventOwner>> {
    return this.http.get<Array<IEventOwner>>(this.API_EVENTS + 'owners');
  }

  /**
   * Returns event groups.
   * @returns {Observable<Array<IEventOwner>>}
   */
  getGroups(): Observable<Array<IEventGroup>> {
    return this.http.get<Array<IEventGroup>>(this.API_EVENTS + 'groups');
  }

  /**
   * Activates event with specified id.
   * @param {number} eventId
   * @param {boolean} [notifyUsers=true]
   * @returns {Observable<void>}
   */
  activate(eventId: number, notifyUsers = true): Observable<void> {
    if (isNaN(eventId)) {
      throw new Error('eventId is not a valid number');
    }

    return this.http
      .patch<void>(this.API_EVENTS + eventId + '/activate?notifyUsers=' + notifyUsers, '');
  }

  /**
   * Deactivates event with specified id.
   * @param {number} eventId
   * @param {boolean} [notifyUsers=true]
   * @returns {Observable<void>}
   */
  deactivate(eventId: number, notifyUsers = true): Observable<void> {
    if (isNaN(eventId)) {
      throw new Error('eventId is not a valid number');
    }

    return this.http
      .patch<void>(this.API_EVENTS + eventId + '/deactivate?notifyUsers=' + notifyUsers, '');
  }

  /**
   * Published event with specified id to timeline.
   * @param {number} eventId
   * @returns {Observable<boolean>}
   */
  publishToTimeline(eventId: number): Observable<boolean> {
    if (isNaN(eventId)) {
      throw new Error('eventId is not a valid number');
    }

    return this.http
      .patch<boolean>(this.API_EVENTS + eventId + '/publish', '');
  }

  /**
   * Unpublish event from specified id to timeline.
   * @param {number} eventId
   * @returns {Observable<void>}
   */
  unpublishFromTimeline(eventId: number): Observable<void> {
    if (isNaN(eventId)) {
      throw new Error('eventId is not a valid number');
    }

    return this.http
      .patch<void>(this.API_EVENTS + eventId + '/unpublish', '');
  }

  /**
   * Creates new event.
   * @param eventObj
   * @returns {Promise<Object>}
   */
  createEvent(eventObj): Promise<IEvent> {
    return lastValueFrom(this.http
      .post<IEvent>(this.API_EVENTS + 'create', eventObj))
  }

  /**
   * Updates event data.
   * @param eventObj
   * @param {number} eventId
   * @returns {Promise<Object>}
   */
  updateEvent(eventObj, eventId: number, notifyUsers = true): Promise<IEvent> {
    return lastValueFrom(this.http
      .put<IEvent>(this.API_EVENTS + eventId + '/update?notifyUsers=' + notifyUsers, eventObj))
  }

  /**
   * Deletes event with specified id.
   * @param {number} eventId
   * @returns {Observable<void>}
   */
  deleteEvent(eventId: number, notifyUsers = true): Observable<void> {
   return this.http.delete<void>(this.API_EVENTS + eventId + '?notifyUsers=' + notifyUsers);
  }

  /**
   * Returns event types.
   */
  getEventTypes(): Observable<IEventType[]> {
    return this.http
      .get<IEventType[]>(this.API_EVENTS + 'types');
  }
}
