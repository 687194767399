import { RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';
import { ComponentRef, Injectable } from '@angular/core';

@Injectable()
export class CacheRouteReuseStrategy implements RouteReuseStrategy {
  private storedRouteHandles = new Map<string, DetachedRouteHandle>();

  // Structure corresponds to routing:
  // [empty] is the same as { path: "" ... } in routing module
  // e.g. home page is [root]/[empty]/[empty]
  //
  // This defined which page (defined as object keys) can be stored for later while moving to (defined as array in key-value) specific routes
  // Eg. /agent/search can only be stored when moving towards consultants (agent/consultant/:id) or their cvs (agent/consultant/cv/:id)
  //
  private readonly allowDetachingWhenMovingFrom = {
    '[root]/[empty]/agent/search': {
      to: [
        '[root]/[empty]/agent/consultant/:id',
        '[root]/[empty]/agent/consultant/cv/:id',
      ]
    }
  };

  // This holds the rules which paths (defined as object keys) can be restored while moving from specific pages
  // eg. search may be restored only from consultant details or consultant CV but not from timeline
  //
  private readonly allowAttachingWhenMovingTo = {
    '[root]/[empty]/agent/search': {
      from: [
        '[root]/[empty]/agent/consultant/:id',
        '[root]/[empty]/agent/consultant/cv/:id',
      ]
    }
  };

  private allowDetachPathsTransformed;
  private lastPath: string;

  shouldReuseRoute(before: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    const currPath = this.getPath(curr);
    const beforePath = this.getPath(before);

    // Clear the allowed detach paths list.
    // This method is called for source and target route one by one starting from root
    // eg: while switching from /agent/consultant/:id to /agent/search
    // [root] --> [root]                                                     (same route part)
    // [root]/[empty] --> [root]/[empty]                                     (same route part)
    // [root]/[empty] --> [root]/[empty]                                     (same route part)
    // [root]/[empty]/agent --> [root]/[empty]/agent                         (same route part)
    // [root]/[empty]/agent --> [root]/[empty]/agent                         (same route part)
    // [root]/[empty]/agent/consultant/:id --> [root]/[empty]/agent/search   (different route part)
    //
    // but for jumping from long path to one that is 'lower' without clearing routes may be marked as detachable (if they are kept in allowDetachingWhenMovingFrom)
    // consider following example (from: /agent/search to / )
    // [root] --> [root]                                                     (same route part)
    // [root]/[empty] --> [root]/[empty]                                     (same route part)
    // [root]/[empty] --> [root]/[empty]                                     (same route part)
    // [root]/[empty]/agent --> [root]/[empty]/[empty]                       (different route part)
    //
    // both [root]/[empty]/agent and [root]/[empty]/agent/search are marked for checking for 'shouldDetach'
    this.allowDetachPathsTransformed = {};

    // Should reuse route is called multiple times, it builds up the allowed detach paths from root to current activated route
    this.allowDetachPathsTransformed[beforePath] = (this.allowDetachingWhenMovingFrom[beforePath] && this.allowDetachingWhenMovingFrom[beforePath].to.indexOf(currPath) > -1) || false;
    this.lastPath = beforePath;

    return before.routeConfig === curr.routeConfig;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return this.storedRouteHandles.get(this.getPath(route)) as DetachedRouteHandle;
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const path = this.getPath(route);

    if (this.storedRouteHandles.has(path)) {
      // Checks if component is allowed to be attached based on previous route
      if (this.allowAttachingWhenMovingTo[path] && this.allowAttachingWhenMovingTo[path].from.indexOf(this.lastPath) > -1) {
        return true;
      } else {
        const storedDetachedRoute = <any>this.storedRouteHandles.get(path);

        // Destroys detached routes which are stored but invalid
        // Eg. search is valid from it's result but should be reset when we move from any other page
        // .destroy() called to let components clean up after themselves
        (<ComponentRef<any>>(storedDetachedRoute).componentRef).destroy();

        this.storedRouteHandles.delete(path);
      }
    }

    return false;
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    const path = this.getPath(route);
    return !!this.allowDetachPathsTransformed[path];
  }

  store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
    const path = this.getPath(route);

    if (detachedTree !== null) {
      this.storedRouteHandles.set(path, detachedTree);
    } else {
      this.storedRouteHandles.delete(path);
    }
  }

  private getPath(route: ActivatedRouteSnapshot): string {
    const pathFromRoot = route.pathFromRoot;
    const fullPath = pathFromRoot
      .map((routePart: ActivatedRouteSnapshot) => {
        if (routePart.routeConfig === null) {
          return '[root]';
        } else if (routePart.routeConfig.path === '') {
          return '[empty]';
        }

        return routePart.routeConfig.path;
      })
      .join('/');

    return fullPath || '';
  }
}
