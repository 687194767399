export interface ISharepointAccessToken {
  access_token: string;
  expires_in: number;
  expires_on: number
}

export interface ISharepointFile {
  Name: string;
  FileUrl: string;
  ThumbnailUrl: string;
  ETag: string;
  Id: string;
  Size: number;
  MimeType: string;
}

export interface ISharepointFiles {
  FilesData: Array<ISharepointFile>;
  Count: number;
}

export interface ISharepointGalleryFilesRequestParams {
  thumbSizeX?: number;
  thumbSizeY?: number;
  sizeX?: number;
  sizeY?: number;
}

export interface ISharepointGallery extends ISharepointFiles {
  basePath: string;
  loading: boolean;
  error: boolean;
  lastRequestTimestamp?: number;
}

export interface IQueryGalleryDataActionPayload {
  path: string;
  galleryParams: ISharepointGalleryFilesRequestParams;
  forceRefresh: boolean;
}

export enum SharepointImageSize {
  MaxWidth = 1920,
  MaxHeight = 1080,
  DefaultThumbWidth = 200,
  DefaultThumbHeight = 200,
  NewsThumbWidth = 250,
  NewsThumbHeight = 250
}
