import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input
} from '@angular/core';
import { NEWS_INJECTION_TOKEN } from '../../../../../common/custom-tokens';
import { ICmsNewsList } from '../../../../../interfaces/news';
import { TimelineService } from '../../../../../services/timeline.service';

@Component({
  selector: 'news-list-cms-element',
  templateUrl: './news-list-cms-element.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewsListCmsElementComponent {
  @Input() news: ICmsNewsList;

  get titleLimit(): number {
    return this.timelineService.titleTruncateLimit;
  }

  get descriptionLimit(): number {
    return this.timelineService.descriptionTruncateLimit;
  }

  constructor(
    @Inject(NEWS_INJECTION_TOKEN) newsInjected: ICmsNewsList,
    private timelineService: TimelineService
  ) {
    this.news = this.news || newsInjected;
  }
}
