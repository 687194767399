import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MediaQueryService } from '@my7n/ui';
// Interfaces
import { INgSelectOption } from '../../../interfaces/ng-select-option';
import { NotificationFrequency } from '../../../interfaces/notification-settings';
import { COMMON_BUTTONS_TEXTS } from '../../../interfaces/common-texts';

@Component({
  selector: 'edit-notification-frequency',
  templateUrl: './edit-notification-frequency.component.html',
  styleUrls: ['./edit-notification-frequency.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditNotificationFrequencyComponent implements OnInit {
  @Input() frequency: NotificationFrequency;
  @Input() saving = false;
  @Output() editResult = new EventEmitter<{
    result: 'updated' | 'canceled';
    frequency?: NotificationFrequency;
  }>();

  COMMON_BUTTONS_TEXTS = COMMON_BUTTONS_TEXTS;

  editForm: UntypedFormGroup;

  frequencyOptions: Array<INgSelectOption>;

  constructor(public mediaQueryService: MediaQueryService) {}

  ngOnInit() {
    this.frequencyOptions = [
      { long: 'Instantly', short: 'Instant', value: NotificationFrequency.INSTANT },
      { long: 'Daily', short: 'Daily', value: NotificationFrequency.DAILY },
      { long: 'Weekly', short: 'Weekly', value: NotificationFrequency.WEEKLY },
      { long: 'Monthly', short: 'Monthly', value: NotificationFrequency.MONTHLY }
    ];

    this.editForm = new UntypedFormGroup({
      frequency: new UntypedFormControl(this.frequency)
    });
  }

  update(form: UntypedFormGroup) {
    if (this.saving) {
      console.warn(
        '[EditNotificationFrequency] Cannot update while saving changes is in progress (education.update)'
      );
      return;
    }

    if (form.invalid) {
      console.debug(
        '[EditNotificationFrequency] Form is invalid, value:',
        form.value
      );
      return false;
    }

    form.markAllAsTouched();

    this.editResult.emit({
      result: 'updated',
      frequency: form.controls['frequency'].value
    });
  }

  cancel() {
    if (this.saving) {
      console.warn(
        '[EditNotificationFrequency] Cannot cancel while update operation is in progress.'
      );
      return;
    }

    this.editResult.emit({
      result: 'canceled'
    });
  }
}
