// MODULES
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { My7nCommonModule } from './my7n-common.module';
import { RouterModule } from '@angular/router';
import { My7nCmsModule } from './my7n-cms.module';
import { My7nCvCommonModule } from './my7n-cv-common.module';

// COMPONENTS
import { TimelineComponent } from '../components/timeline/timeline.component';
import { NewsListComponent } from '../components/timeline/news/news-list/news-list.component';
import { NewsListElementComponent } from '../components/timeline/news/news-list/news-list-element/news-list-element.component';
import { NewsListCmsElementComponent } from '../components/timeline/news/news-list/news-list-cms-element/news-list-cms-element.component';
import { NewsListElementOutletComponent } from '../components/timeline/news/news-list/news-list-element-outlet/news-list-element-outlet.component';
import { NewsListEventElementComponent } from '../components/timeline/news/news-list/news-list-event-element/news-list-event-element.component';
import { NewsViewComponent } from '../components/timeline/news/news-page/news-view/news-view.component';
import { CmsNewsViewComponent } from '../components/timeline/news/news-page/cms-news-view/cms-news-view.component';
import { CmsNewsContentComponent } from '../components/timeline/news/news-page/cms-news-view/cms-news-content/cms-news-content.component';
import { GalleryComponent } from '../components/common/gallery/gallery.component';
import { GalleryThumbComponent } from '../components/common/gallery/gallery-thumb/gallery-thumb.component';
import { GalleryLightboxComponent } from '../components/common/gallery/gallery-lightbox/gallery-lightbox.component';
import { GalleryThumbItemComponent } from '../components/common/gallery/gallery-thumb/gallery-thumb-item/gallery-thumb-item.component';
import { GalleryLightboxItemComponent } from '../components/common/gallery/gallery-lightbox/gallery-lightbox-item/gallery-lightbox-item.component';
import { CvBoxComponent } from '../components/timeline/cv-box/cv-box.component';
import { CvBoxPrimaryCvComponent } from '../components/timeline/cv-box/cv-box-primary-cv/cv-box-primary-cv.component';
import { NewsViewSubmenuComponent } from '../components/timeline/news/news-page/news-view/news-view-submenu/news-view-submenu.component';
import { CmsNewsViewSubmenuComponent } from '../components/timeline/news/news-page/cms-news-view/cms-news-view-submenu/cms-news-view-submenu.component';
import { RequestTransferComponent } from '../components/timeline/request-transfer/request-transfer.component';
import { VideoUpdateBoxComponent } from '../components/timeline/video-update-box/video-update-box.component';
import {
  GalleryLightboxSecureItemComponent
} from '../components/common/gallery/gallery-lightbox/gallery-lightbox-secure-item/gallery-lightbox-secure-item.component';
import { ReactionsComponent } from '../components/common/reactions/reactions.component';

// SERVICES
import { TimelineService } from '../services/timeline.service';
import { GalleryLightboxOverlayService } from '../components/common/gallery/gallery-lightbox/gallery-lightbox-overlay.service';
import { NewsContentfulService } from '../services/news-contentful.service';
import { VideoUpdateService } from '../services/video-update.service';
import { SharepointService } from '../services/sharepoint.service';

// FACADES
import { TimelineNewsFacadeService } from '../services/facades/timeline-news-facade.service';
import { VideoUpdateFacadeService } from '../services/facades/video-update-facade.service';

// RESOLVERS
import { NewsContentResolver } from '../resolvers/news-content.resolver';
import { CmsNewsContentResolver } from '../resolvers/cms-news-content.resolver';

// TOKENS
import { NEWS_INJECTION_TOKEN } from '../common/custom-tokens';

// NgRx
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromTimeline from '../store/reducers/timeline/index';
import * as fromGalleries from '../store/reducers/galleries/index';
import { TimelineNewsEffects } from '../store/effects/timeline-news.effects';
import { GalleriesEffects } from '../store/effects/galleries.effects';

@NgModule({
  imports: [
    CommonModule,
    My7nCommonModule,
    RouterModule,
    My7nCmsModule,
    My7nCvCommonModule,
    StoreModule.forFeature(fromTimeline.timelineFeatureKey, fromTimeline.reducers),
    EffectsModule.forFeature([TimelineNewsEffects]),
    StoreModule.forFeature(fromGalleries.galleriesFeatureKey, fromGalleries.reducers),
    EffectsModule.forFeature([GalleriesEffects]),
    ReactionsComponent
  ],
  exports: [
    TimelineComponent,
    NewsListComponent,
    NewsListElementComponent,
    NewsListCmsElementComponent,
    NewsListEventElementComponent,
    NewsListElementOutletComponent,
    NewsViewComponent,
    CmsNewsViewComponent,
    CmsNewsContentComponent,
    GalleryComponent,
    GalleryThumbComponent,
    GalleryThumbItemComponent,
    CvBoxComponent,
    CvBoxPrimaryCvComponent,
    RequestTransferComponent,
    VideoUpdateBoxComponent
  ],
  declarations: [
    TimelineComponent,
    NewsListComponent,
    NewsListElementComponent,
    NewsListCmsElementComponent,
    NewsListEventElementComponent,
    NewsListElementOutletComponent,
    NewsViewComponent,
    CmsNewsViewComponent,
    CmsNewsContentComponent,
    GalleryComponent,
    GalleryThumbComponent,
    GalleryLightboxComponent,
    GalleryThumbItemComponent,
    GalleryLightboxItemComponent,
    GalleryLightboxSecureItemComponent,
    CvBoxComponent,
    CvBoxPrimaryCvComponent,
    NewsViewSubmenuComponent,
    CmsNewsViewSubmenuComponent,
    RequestTransferComponent,
    VideoUpdateBoxComponent
  ],
  providers: [
    TimelineService,
    GalleryLightboxOverlayService,
    NewsContentfulService,
    { provide: NEWS_INJECTION_TOKEN, useValue: '' },
    NewsContentResolver,
    CmsNewsContentResolver,
    VideoUpdateService,
    SharepointService,
    // Facades
    TimelineNewsFacadeService,
    VideoUpdateFacadeService
  ]
})
export class My7nTimelineModule {}
