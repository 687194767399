<div class="notifications-header">
  <a mat-icon-button class="full-opacity icon-notification flex flex-center-center" [ngClass]="{ active: editMode }"
     (click)="openNotifications();"
     (mouseenter)="iconHovered=true"
     (mouseleave)="iconHovered=false"
     #popoverActivation>
    <mat-icon color="accent" *ngIf="editMode || iconHovered">notifications</mat-icon>
    <mat-icon color="light" *ngIf="!editMode && !iconHovered">notifications_none</mat-icon>
  </a>
  <span *ngIf="unseenNotificationsCounter" class="notifications-header-counter">{{unseenNotificationsCounter}}</span>
</div>
