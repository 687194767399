import { Injectable } from '@angular/core';
import { PageScrollOptions, PageScrollService } from 'ngx-page-scroll-core';
import { IScrollToElementOptions } from '../interfaces/scroll-to-element-options';

@Injectable()
export class ScrollToElementService {
  constructor(private pageScrollService: PageScrollService) {}

  scrollTo(options: IScrollToElementOptions) {
    const scrollOptions: PageScrollOptions = {
      document: document,
      scrollTarget: options.selector,
      scrollOffset: options.offset || this.getScrollOffset(),
      duration: options.duration || 500
    };

    if (options.scrollFinishListener) {
      Object.assign(scrollOptions, {
        scrollFinishListener: options.scrollFinishListener
      });
    }

    // we use setTimeout just to ensure that it will work also for newly added elements
    // also, it is important when scrolling is triggered in dialog just before it is closed.
    setTimeout(() => {
      this.pageScrollService.scroll(scrollOptions);
    }, 100);
  }

  private getScrollOffset() {
    const subNavbar: HTMLElement = document.querySelector('sub-navbar > nav');
    const menu = document.querySelector('navigation') as HTMLElement;
    const menuHeight = menu.offsetHeight;
    let scrollOffset = 1.5 * menuHeight;

    if (subNavbar && scrollOffset !== null) {
      // include 2nd level navbar height
      scrollOffset += subNavbar.offsetHeight;
    }

    return scrollOffset;
  }
}
