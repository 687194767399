import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, switchMap, map } from 'rxjs';
// Services
import { GlobalAppConfigFacadeService } from './facades/global-app-config-facade.service';
import { AppConfigService } from './app-config.service';
// Interfaces
import { ICvLanguage } from '../interfaces/cv-language';

interface LanguageDictResponse {
  Languages: Array<ICvLanguage>;
}

@Injectable()
export class CvLanguageService {
  constructor(
    private http: HttpClient,
    private appConfigService: AppConfigService,
    private globalAppConfigFacadeService: GlobalAppConfigFacadeService
  ) {}

  get(languageCode: string): Observable<ICvLanguage> {
    return this.globalAppConfigFacadeService.languages$.pipe(
      map((languages) => {
        const lang = languages.find((el) => el.LanguageCode === languageCode);
        return lang;
      })
    );
  }

  getAll(): Observable<Array<ICvLanguage>> {
    return this.globalAppConfigFacadeService.appConfigApiUrls$.pipe(
      switchMap((configApiUrls) => {
        return this.http
          .get<LanguageDictResponse>(configApiUrls.languages)
          .pipe(
            switchMap((response) => {
              const languages = response.Languages;

              languages.map((lang) => {
                lang.FlagUrl = lang.FlagUrl =
                  AppConfigService.imagesPath +
                  `/flags/${lang.LanguageCode}.svg`;
                return lang;
              });

              return of(languages);
            })
          );
      })
    );
  }
}
