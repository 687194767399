import {ISharepointFile} from '../../interfaces/sharepoint';
import {INewsAttachment, NewsAttachmentTypes} from '../../interfaces/news-attachment';

export class SharepointHelper {

  static extractGalleryDir(galleryUrl: string): string {
    const dirMatch = galleryUrl.match(/my7N%20Galleries\/([^?]+)/);
    if (dirMatch && dirMatch[1]) {
      return dirMatch[1];
    } else {
      return '';
    }
  }

  static convertSharePointItemsToNewsAttachments(sharepointFile: ISharepointFile[]): INewsAttachment[] {
    return sharepointFile.map((item) => {
      return {
        Id: item.Id,
        ThumbnailUrl: item.ThumbnailUrl,
        Url: item.FileUrl,
        Type: NewsAttachmentTypes.IMG_SECURED
      }
    });
  }
}
