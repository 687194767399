import * as fromMy7n from '../index';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { INotificationItem } from '../../../interfaces/notification-item';
import * as NotificationsActions from '../../actions/notifications.actions';
import { createReducer, on, Action } from '@ngrx/store';

export const adapter = createEntityAdapter<INotificationItem>({
  // we need to provide custom selectId function because standard one uses "id" instead of "Id" (like in our interface)
  selectId: instance => instance.Id
});

export interface FeatureState extends fromMy7n.State {
  notifications: State;
}

export interface State extends EntityState<INotificationItem> {
  totalUnseenNotifications: number;
  notificationsRequestTimestamp: Date;
  loadingNotifications: boolean;
}

export const initialState: State = adapter.getInitialState({
  totalUnseenNotifications: 0,
  notificationsRequestTimestamp: undefined,
  loadingNotifications: false
});

const reducer = createReducer(
  initialState,
  on(NotificationsActions.queryNotifications, state => ({
    ...state,
    loadingNotifications: true
  })),
  on(
    NotificationsActions.notificationsLoaded,
    (state, { notifications, totalUnseenNotifications, notificationsRequestTimestamp }) => ({
      ...adapter.setAll(notifications, state),
      totalUnseenNotifications: totalUnseenNotifications,
      notificationsRequestTimestamp: notificationsRequestTimestamp,
      loadingNotifications: false
    })
  ),
  on(NotificationsActions.queryNotificationsError, state => ({
    ...state,
    loadingNotifications: false
  })),
  on(NotificationsActions.updateNotificationsTimestamp, state => ({
    ...state,
    totalUnseenNotifications: 0,
    notificationsRequestTimestamp: undefined
  })),
  on(NotificationsActions.updateNotificationsTimestampSuccess, state => ({
    ...state
  })),
  on(NotificationsActions.updateNotificationsTimestampError, state => ({
    ...state
  })),
  on(
    NotificationsActions.notificationItemRead,
    (state, { notificationItemId }) => ({
      ...adapter.updateOne(
        {
          id: notificationItemId,
          changes: { IsVisited: true }
        },
        state
      )
    })
  ),
  on(NotificationsActions.notificationItemReadSuccess, state => ({
    ...state
  })),
  on(NotificationsActions.notificationItemReadError, state => ({
    ...state
  })),
  on(NotificationsActions.markAllNotificationsAsRead, state => {
    return {
      ...adapter.map((item: INotificationItem) => {
        return { ...item, IsVisited: true };
      }, state)
    };
  }),
  on(NotificationsActions.markAllNotificationsAsReadSuccess, state => ({
    ...state
  })),
  on(NotificationsActions.markAllNotificationsAsReadError, state => ({
    ...state
  }))
);

export function notificationsReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}

// configure entity store selectors
export const {
  // select the array of notifications
  selectIds: selectNoitificationsIds,

  // Select the dictionary of notifications entities
  selectEntities: selectNotificationsEntities,

  // SelectAll
  selectAll: selectAllNotifications,

  // Select total notifications count
  selectTotal: selectNotificationsTotal
} = adapter.getSelectors();

export const getTotalUnseenNotifications = (state: State) => {
  return state.totalUnseenNotifications;
};

export const getNotificationsRequestTimestamp = (state: State) => {
  return state.notificationsRequestTimestamp;
};

export const getLoadingNotifications = (state: State) => {
  return state.loadingNotifications;
};
