<div class="cms-news-view-content" data-test-id="cms-news-view-container" #content>
    <div class="cms-news-view-video"
         *ngIf="details?.videoUrl"
         data-test-id="cms-news-view-container-news-video">
         <my7n-video-player *ngIf="details?.videoUrl"
                            [videoUrl]="details?.videoUrl"
                            [module]="LogVideoModules.News"
                            [title]="details?.title"
                            [articleId]="details?.id" 
                            [config]="videoPlayerConfig"
                            data-test-id="cms-news-view-container-news-video-player"></my7n-video-player>
    </div>
    <cms-rich-text *ngIf="details.content"
                   class="cms-news-view-details-content"
                   [content]="details.content"
                   data-test-id="cms-news-view-container-news-details-content"></cms-rich-text>
    <div class="gallery"
         data-test-id="news-content-gallery"
         *ngIf="galleryPhotos$ | async as gallery">
      <sharepoint-gallery [items]="gallery.FilesData"
                          [newsId]="details.id"
                          [config]="galleryConfig"></sharepoint-gallery>
    </div>
    <div class="cms-news-view-footer flex flex-row flex-between-center"
           data-test-id="cms-news-view-container-news-footer">
      <div class="footer-author-container flex max-sm:flex-col sm:gap-24 max-sm:flex-start-start sm:max-md:flex-between-center md:flex-start-center">
        <div class="cms-news-view-footer-author flex flex-start-center md:gap-12">
          <div class="cms-news-view-container-footer-author-avatar max-md:hidden"
               data-test-id="cms-news-view-container-news-footer-author-avatar">
            <avatar [title]="details.author.fullName" [avatarUrl]="details.author.avatar"></avatar>
          </div>
          <div class="cms-news-view-footer-author-name-container flex flex-col flex-[1_1_0%]">
            <span class="cms-news-view-footer-author-name" data-test-id="cms-news-view-container-news-footer-author-name">{{ details.author.fullName }}</span>
          </div>
        </div>

        <div class="cms-news-view-footer-dates flex flex-start-center">
          <mat-icon class="max-md:hidden" svgIcon="calendar_big"></mat-icon>
          <span *ngIf="details.creationDate"
                class="cms-news-view-footer-creation-date"
                data-test-id="cms-news-view-container-news-footer-creation-date">
                {{ details.creationDate | defaultDate: 'minute':true }}
          </span>
        </div>
      </div>
      <reactions [contentId]="details.id" [contentType]="contentType" data-test-id="reactions"></reactions>
    </div>  
</div>
