import * as fromMy7n from '../index';
import { createReducer, on, Action } from '@ngrx/store';
import * as DictionariesActions from '../../actions/cms/dictionaries.actions';
import {
  CmsDictionaryTypes,
  ICmsDictionaryItem
} from '../../../interfaces/cms';
import { cloneDeep } from 'lodash-es';

export interface FeatureState extends fromMy7n.State {
  cmsDictionaries: State;
}

export interface State {
  dictionaries: Array<ICmsDictionaryItem>;
  dictionariesLoading: boolean;
}

export const initialState: State = {
  dictionaries: [],
  dictionariesLoading: false
};

const reducer = createReducer(
  initialState,
  on(DictionariesActions.queryDictionariesByTypes, (state) => ({
    ...state,
    dictionariesLoading: true
  })),
  on(
    DictionariesActions.queryDictionariesByTypesSuccess,
    (state, { dictionary, types }) => ({
      ...state,
      dictionaries: putDictionary(state, dictionary, types),
      dictionariesLoading: false
    })
  ),
  on(DictionariesActions.dictionariesLoadedFromCache, (state) => ({
    ...state,
    dictionariesLoading: false
  })),
  on(DictionariesActions.queryDictionariesByTypesError, (state) => ({
    ...state,
    dictionariesLoading: false
  }))
);

export function putDictionary(
  state: State,
  dictionary: Array<ICmsDictionaryItem>,
  types: Array<CmsDictionaryTypes>
): Array<ICmsDictionaryItem> {
  if (state.dictionaries?.length === 0) {
    // if empty, just add it
    return dictionary;
  }

  let dictionariesUpdatedState = cloneDeep(state.dictionaries);

  // remove existing values
  dictionariesUpdatedState = dictionariesUpdatedState.filter(
    (item: ICmsDictionaryItem) => types.includes(item.type)
  );

  // add new values
  dictionariesUpdatedState.push(...dictionary);
  return dictionariesUpdatedState;
}

export function dictionariesReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}

export const getDictionaries = (state: State) => {
  return state.dictionaries;
};

export const getDictionariesLoading = (state: State) => {
  return state.dictionariesLoading;
};
