import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'navigation-social-links',
  templateUrl: './navigation-social-links.component.html',
  styleUrls: ['./navigation-social-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationSocialLinksComponent {
  constructor() {}
}
