import { Action, createReducer, on } from '@ngrx/store';
import * as CvGlobalActions from '../../actions/cv-global.actions';
import { CV_MANDATORY_SECTIONS, CvMandatorySectionsFilled } from '../../../interfaces/cv-mandatory-sections';

export interface CvGlobalState {
  sendToAgentAttempt: boolean;
  cvMandatorySectionsFilled: CvMandatorySectionsFilled;
}

export const initialState: CvGlobalState = {
  sendToAgentAttempt: false,
  cvMandatorySectionsFilled: {
    ProfileSummary: false,
    ProfileSummaryJobTitle: false,
    ProfileSummaryAvailableFrom: false,
    ProfileSummaryExperienceSince: false,
    ProfileSummarySummary: false,
    WorkRoles: false,
    MainCompetenceAreas: false,
    CareerAndProjects: false,
    Skills: false,
    Languages: false,
    IndustryKnowledge: false,
    Education: false
  }
};

const reducer = createReducer(
  initialState,
  on(CvGlobalActions.updateSendToAgentAttempt, (state, { sendToAgentAttempt }) => ({
    ...state,
    sendToAgentAttempt
  })),
  on(CvGlobalActions.updateCvMandatorySectionFilled, (state, { section, filled }) => ({
    ...state,
    cvMandatorySectionsFilled: {
      ...state.cvMandatorySectionsFilled,
      [section]: filled,
      ProfileSummary: calculateProfileSummaryFilled(state, section, filled)
    }
  }))
);

export function cvGlobalReducer(
  state: CvGlobalState | undefined,
  action: Action
) {
  return reducer(state, action);
}

export const getSendToAgentAttempt = (state: CvGlobalState) => {
  return state.sendToAgentAttempt;
};

export const getCvMandatorySectionsFilled = (state: CvGlobalState) => {
  return state.cvMandatorySectionsFilled;
};

export function calculateProfileSummaryFilled(state: CvGlobalState, section: CV_MANDATORY_SECTIONS, filled: boolean) {
  let values = {
    ProfileSummaryJobTitle: state.cvMandatorySectionsFilled.ProfileSummaryJobTitle,
    ProfileSummaryAvailableFrom: state.cvMandatorySectionsFilled.ProfileSummaryAvailableFrom,
    ProfileSummaryExperienceSince: state.cvMandatorySectionsFilled.ProfileSummaryExperienceSince,
    ProfileSummarySummary: state.cvMandatorySectionsFilled.ProfileSummarySummary
  };

  values[section] = filled;

  return (
    values.ProfileSummaryJobTitle &&
    values.ProfileSummaryAvailableFrom &&
    values.ProfileSummaryExperienceSince &&
    values.ProfileSummarySummary
  );
}
