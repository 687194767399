export enum ContentReactionTypes {
  LIKE = 'like',
  INSIGHTFUL = 'insightful',
  FUNNY = 'funny',
  CELEBRATE = 'celebrate'
}

export enum ContentTypes {
  MY7N_NEWS = 'MY7N_NEWS',
  MY7N_EVENT = 'MY7N_EVENT',
  MY7N_ACADEMY_BOOK = 'MY7N_ACADEMY_BOOK',
  MY7N_ACADEMY_VIDEO = 'MY7N_ACADEMY_VIDEO',
  MY7N_BENEFIT = 'MY7N_BENEFIT',
  MY7N_LEARNING_AND_DEV = 'MY7N_LEARNING_AND_DEV'
}

export interface IContentReactionsItem {
  CrmContactId: string;
  ReactionType: ContentReactionTypes;
  ReactionDate: Date;
}

export interface IContentReactionsResponse {
  ContentId: string;
  ContentType: ContentTypes;
  Reactions: IContentReactionsItem[];
}

export interface IContentReactionsItemRequest {
  ContentId: string;
  ContentType: ContentTypes;
  Reaction: IContentReactionsItem;
}
