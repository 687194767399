import { Component, OnInit, Input, ElementRef, ViewChild, Inject, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { INewsList } from '../../../../../interfaces/news';
import { NewsAttachmentTypes } from '../../../../../interfaces/news-attachment';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { NEWS_INJECTION_TOKEN } from '../../../../../common/custom-tokens';
import { INewsMediaSize } from '../../../../../interfaces/news-media-size';
import { TimelineService } from '../../../../../services/timeline.service';
import { IVideoPlayerConfig, LogVideoModules, videoPlayerDefaultConfig } from '../../../../../interfaces/video-player';
import { ContentTypes } from '../../../../../interfaces/reactions';

@Component({
  selector: 'news-list-element',
  templateUrl: './news-list-element.component.html',
  styleUrls: ['./news-list-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewsListElementComponent implements OnInit, OnDestroy {
  @Input() news: INewsList;
  @ViewChild('newsContainer', { static: true }) newsContainer: ElementRef;

  readonly newsSubtypes = {
    TEXT_ONLY: 1,
    IMG_ONLY: 2,
    VIDEO_ONLY: 3,
    GALLERY: 4
  };

  get ATTACHMENT_TYPES() {
    return NewsAttachmentTypes;
  }

  templateTimeout = null;

  newsSubtype: number;

  detailsUrl: string;

  imageSize: INewsMediaSize;

  showVideo: boolean;

  trustedResourceUrl: SafeUrl;

  videoPlayerConfig: IVideoPlayerConfig = { ...videoPlayerDefaultConfig };

  ratio = 425 / 755;

  LogVideoModules = LogVideoModules;

  contentType = ContentTypes.MY7N_NEWS;

  /**
   * window.resize event listener function.
   */
  windowResizeListener = () => this.resizeContent();

  get titleLimit(): number {
    return this.timelineService.titleTruncateLimit;
  }

  get descriptionLimit(): number {
    return this.timelineService.descriptionTruncateLimit;
  }

  constructor(@Inject(NEWS_INJECTION_TOKEN) newsInjected: INewsList,
              private sanitizer: DomSanitizer,
              private timelineService: TimelineService,
              private cdr: ChangeDetectorRef) {
    this.news = this.news || newsInjected;
  }

  ngOnInit() {
    // detecting news subtype
    this.newsSubtype = this.getNewsSubType();
    // set details url
    this.detailsUrl = '/news/' + this.news.Id;

    this.resizeContent();
    window.addEventListener('resize', this.windowResizeListener);


    if (this.newsSubtype === this.newsSubtypes.IMG_ONLY) {
      this.imageSize = {
        width: 755,
        height: 250,
        ratio: 250 / 755
      };
    }

    if (this.newsSubtype === this.newsSubtypes.VIDEO_ONLY) {
      this.videoPlayerConfig.containerHeight = 425;
      this.videoPlayerConfig.containerWidth = 755;
      this.showVideo = false;

      switch (this.news.Attachments[0].Type) {
        case NewsAttachmentTypes.OTHER:
          this.trustedResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.news.Attachments[0].Url);
          break;
        case NewsAttachmentTypes.YT:
        case NewsAttachmentTypes.VIMEO:
          this.trustedResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.news.Attachments[0].Url.replace('autoplay=0', 'autoplay=1'));
          break;
        default:
          console.error('[NewsListElementComponent] Unrecognized video type: ' + this.news.Attachments[0].Type);
      }
    }
  }

  /**
   * Returns news subtype.
   * @returns {number}
   */
  getNewsSubType(): number {
      if (this.news.Attachments.length === 0) {
        return this.newsSubtypes.TEXT_ONLY;
      } else if (this.news.Attachments.length === 1 && this.news.Attachments[0].Type === NewsAttachmentTypes.IMG) {
        return this.newsSubtypes.IMG_ONLY;
      } else if (this.news.Attachments.length === 1 && this.news.Attachments[0].Type !== NewsAttachmentTypes.IMG) {
        return this.newsSubtypes.VIDEO_ONLY;
      } else {
        return this.newsSubtypes.GALLERY;
      }
  }

  /**
   * Resize video iframe to match all responsive widths.
   */
  resizeContent() {
    window.setTimeout(() => {
      const width = this.newsContainer.nativeElement.offsetWidth;
      if (this.newsSubtype === this.newsSubtypes.IMG_ONLY) {
        this.imageSize.width = width;
        this.imageSize.height = Math.round(width * this.imageSize.ratio);
        this.cdr.markForCheck();
      }

      if (this.newsSubtype === this.newsSubtypes.VIDEO_ONLY) {
        this.videoPlayerConfig.containerWidth = width;
        this.videoPlayerConfig.containerHeight = Math.round(width * this.ratio);
        this.cdr.markForCheck();
      }

    }, 0);
  }

  /**
   * Shows video.
   */
  show() {
    this.showVideo = true;
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.windowResizeListener);
    if (this.templateTimeout) {
      window.clearTimeout(this.templateTimeout);
    }
  }
}
