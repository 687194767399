import {
  Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, ElementRef, OnDestroy, ChangeDetectorRef,
  ViewEncapsulation
} from '@angular/core';
import { ICmsNewsViewItem } from '../../../../../../interfaces/news';
import { IVideoPlayerConfig, LogVideoModules, videoPlayerDefaultConfig } from '../../../../../../interfaces/video-player';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ISharepointFiles, SharepointImageSize } from '../../../../../../interfaces/sharepoint';
import { SharepointHelper } from '../../../../../../utils/helpers/sharepoint.helper';
import { IGalleryConfig } from '../../../../../../interfaces/gallery-config';
import { GalleryFacadeService } from '../../../../../../services/facades/gallery-facade.service';
import { IMediaQueriesBreakpoints, MediaQueryService } from '@my7n/ui';
import { ContentTypes } from '../../../../../../interfaces/reactions';

@Component({
  selector: 'cms-news-content',
  templateUrl: './cms-news-content.component.html',
  styleUrls: ['./cms-news-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class CmsNewsContentComponent implements OnInit, OnDestroy {
  @Input() details: ICmsNewsViewItem;
  @ViewChild('content', { static: true }) contentContainer: ElementRef;

  videoPlayerConfig: IVideoPlayerConfig = { ...videoPlayerDefaultConfig };
  windowResizeListener: any;
  LogVideoModules = LogVideoModules;
  contentType = ContentTypes.MY7N_NEWS;

  galleryPhotos$: Observable<ISharepointFiles>;
  galleryItemsInRowNumber: number;
  get galleryConfig(): IGalleryConfig {
    return {
      itemHeight: 200,
      rowItems: this.galleryItemsInRowNumber,
      limitToOneRow: false
    }
  }
  private _destroySubject$: Subject<void> = new Subject();

  readonly galleryItemsInRow = {
    MOBILE: 3,
    DESKTOP: 5
  };

  constructor(
    private cdr: ChangeDetectorRef,
    private mediaQueryService: MediaQueryService,
    private galleryFacade: GalleryFacadeService
  ) {}

  ngOnInit(): void {
    if (this.details.videoUrl) {
      this.videoPlayerConfig = {
        ...this.videoPlayerConfig,
        containerWidth: 750,
        containerHeight: 421,
        autoplay: false,
        showLoader: false
      }
      this.videoPlayerConfig.containerWidth = 750;
      this.videoPlayerConfig.containerHeight = 421;
      this.windowResizeListener = () => this.resizeVideo();
      this.resizeVideo();
      window.addEventListener('resize', this.windowResizeListener);
    }

    if (this.details.galleryUrl) {
      this.initNewsGallery(this.details.galleryUrl);
    }
  }

  initNewsGallery(galleryUrl: string) {
    const galleryDir = SharepointHelper.extractGalleryDir(galleryUrl);

    if (!galleryDir) {
      console.warn('Did not extract gallery dir for: ', galleryUrl);
      return;
    }
    this.galleryItemsInRowNumber = this.galleryItemsInRow.DESKTOP;

    this.galleryPhotos$ = this.galleryFacade.getGallery$(galleryDir);
    this.galleryFacade.queryGalleryData(galleryDir, {
      thumbSizeX: SharepointImageSize.NewsThumbWidth,
      thumbSizeY: SharepointImageSize.NewsThumbHeight
    });

    this.registerGalleryRowItemsHandler();
  }

  registerGalleryRowItemsHandler() {
    this.mediaQueryService.breakpoints$
      .pipe(takeUntil(this._destroySubject$))
      .subscribe((breakpoints: IMediaQueriesBreakpoints) => {
        const isXsMobile = breakpoints.ltSm;
        this.galleryItemsInRowNumber = isXsMobile ? this.galleryItemsInRow.MOBILE : this.galleryItemsInRow.DESKTOP;
      });
  }

  /**
   * Resize video iframe to match all responsive widths.
   */
  resizeVideo() {
    window.setTimeout(() => {
      const width = this.contentContainer.nativeElement.offsetWidth;
      const ratio = 421 / 750;
      this.videoPlayerConfig = {
        ...this.videoPlayerConfig,
        containerWidth: width,
        containerHeight: Math.round(width * ratio)
      }
      this.cdr.markForCheck();
    });
  }

  ngOnDestroy() {
    if (this.windowResizeListener) {
      window.removeEventListener('resize', this.windowResizeListener);
    }
  }
}
