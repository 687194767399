import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { PAGINATOR_SETTINGS_DEFAULT } from '../../../../interfaces/search';
import { IConsultantSearchedSelectable } from '../../../../interfaces/consultant-searched';
import * as AgentBookmarksActions from '../../../actions/agent-bookmarks.actions';
import * as AgentSearchActions from '../../../actions/agent-search.actions';
import * as fromMy7n from '../../index';
import { Params } from '@angular/router';


export const adapter = createEntityAdapter<IConsultantSearchedSelectable>({
  // we need to provide custom selectId function because standard one uses "id" instead of "Id" (like in our interface)
  selectId: (instance) => instance.Id
});

export interface FeatureState extends fromMy7n.State {
  bookmarks: AgentBookmarksState;
}

export interface AgentBookmarksState extends EntityState<IConsultantSearchedSelectable> {
  pageIndex: number;
  pageSize: number;
  loading: boolean;
  error: boolean;
  restore: { shouldRestore: boolean, scrollY: number, queryParams: Params };
  bookmarkedCount: { count: number, initialized: boolean};
  unbookmarkedCount: number;
}

export const initialState: AgentBookmarksState = adapter.getInitialState({
  pageIndex: 0,
  loading: false,
  error: false,
  pageSize: PAGINATOR_SETTINGS_DEFAULT.BOOKMARKS.PAGE_SIZE,
  restore: {
    shouldRestore: false,
    scrollY: 0,
    queryParams: null
  },
  bookmarkedCount: {
    count: 0,
    initialized: false
  },
  unbookmarkedCount: 0
});

const reducer = createReducer(
  initialState,
  on(AgentBookmarksActions.queryResults, (state) => ({
    ...state,
    loading: true,
    error: false
  })),
  on(AgentBookmarksActions.queryResultsSuccess, (state, { consultants, queryPageOptions }) => ({
    ...adapter.setMany(consultants, state),
    loading: false,
    pageIndex: queryPageOptions.pageIndex,
    pageSize: queryPageOptions.pageSize
  })),
  on(AgentBookmarksActions.queryResultsError, (state) => ({
    ...state,
    loading: false,
    error: true
  })),
  on(AgentBookmarksActions.clearResults, (state) => ({
    ...adapter.removeAll(state),
    pageIndex: 0,
    unbookmarkedCount: 0
  })),
  on(AgentBookmarksActions.queryBookmarkedCountSuccess, (state, { count }) => ({
    ...state,
    bookmarkedCount: {
      ...state.bookmarkedCount,
      count,
      initialized: true
    }
  })),
  on(AgentBookmarksActions.updatePaginator, (state, { paginatorEvent }) => ({
    ...state,
    pageIndex: paginatorEvent.pageIndex,
    pageSize: paginatorEvent.pageSize
  })),
  on(AgentBookmarksActions.toggleConsultantSelection, (state, { consultantId, selected }) => ({
    ...adapter.updateOne({ id: consultantId, changes: { selected } }, state)
  })),
  on(AgentBookmarksActions.clearSelectedConsultats, (state) => ({
    ...adapter.map((entity) =>  {
      return {...entity, selected: false };
    }, state)
  })),
  on(AgentBookmarksActions.setRestore, (state, { shouldRestore, scrollY }) => ({
    ...state,
    restore: {
      ...state.restore,
      shouldRestore,
      scrollY
    }
  })),
  on(AgentBookmarksActions.setRestoreQueryParams, (state, { queryParams }) => ({
    ...state,
    restore: {
      ...state.restore,
      queryParams
    }
  })),
  // global action

  // on search global action update bookmarked count
  on(AgentSearchActions.bookmarkSelectedConsultantsSuccess, (state, { consultants }) => ({
    ...state,
    bookmarkedCount: {
      ...state.bookmarkedCount,
      count: state.bookmarkedCount.count + consultants.length
    }
  })),

  // single action
  on(AgentBookmarksActions.bookmarkConsultant, (state, { consultant }) => ({
    ...adapter.updateOne({ id: consultant.Id, changes: { IsBookmarked: true } }, state)
  })),
  on(AgentBookmarksActions.bookmarkConsultantSuccess, (state) => ({
    ...state,
    bookmarkedCount: {
      ...state.bookmarkedCount,
      count: state.bookmarkedCount.count + 1
    },
    unbookmarkedCount: state.unbookmarkedCount - 1
  })),
  on(AgentBookmarksActions.bookmarkConsultantError, (state, { consultant }) => ({
    ...adapter.updateOne({ id: consultant.Id, changes: { IsBookmarked: false } }, state)
  })),
  on(AgentBookmarksActions.unbookmarkConsultant, (state, { consultantId }) => ({
    ...adapter.updateOne({ id: consultantId, changes: { IsBookmarked: false } }, state)
  })),
  on(AgentBookmarksActions.unbookmarkConsultantSuccess, (state) => ({
    ...state,
    bookmarkedCount: {
      ...state.bookmarkedCount,
      count: state.bookmarkedCount.count - 1
    },
    unbookmarkedCount: state.unbookmarkedCount + 1
  })),
  on(AgentBookmarksActions.unbookmarkConsultantError, (state, { consultantId }) => ({
    ...adapter.updateOne({ id: consultantId, changes: { IsBookmarked: true } }, state)
  })),
   // updating on search view should also update bookmarks count
   on(AgentSearchActions.bookmarkConsultantSuccess, (state) => ({
    ...state,
    bookmarkedCount: {
      ...state.bookmarkedCount,
      count: state.bookmarkedCount.count + 1
    },

  })),
  on(AgentSearchActions.unbookmarkConsultantSuccess, (state) => ({
    ...state,
    bookmarkedCount: {
      ...state.bookmarkedCount,
      count: state.bookmarkedCount.count - 1
    },
  }))
);

export function agentBookmarksReducer(state: AgentBookmarksState | undefined, action: Action) {
  return reducer(state, action);
}

// configure entity store selectors
export const {
  // select the array of contracts
  selectIds: selectConsultantsId,

  // select the dictionary of contract entities
  selectEntities: selectConsultantsEntities,

  // selectAll
  selectAll: selectAllConsultants,

  // select total consultants count
  selectTotal: selectTotalLoadedConsultantsCount
} = adapter.getSelectors();

export const getBookmarks = (state: AgentBookmarksState) => {
  return state;
};

export const getPageIndex = (state: AgentBookmarksState) => {
  return state.pageIndex;
};

export const getPageSize = (state: AgentBookmarksState) => {
  return state.pageSize;
};

export const getUnbookmarkedCount = (state: AgentBookmarksState) => {
  return state.unbookmarkedCount;
};

export const getBookmarkedCount = (state: AgentBookmarksState) => {
  return state.bookmarkedCount;
};

export const getLoading = (state: AgentBookmarksState) => {
  return state.loading;
};

export const getError = (state: AgentBookmarksState) => {
  return state.error;
};

export const getRestore = (state: AgentBookmarksState) => {
  return state.restore;
};

