<ng-container [formGroup]="parentForm">
  <div class="checkbox-container avoid-column-break" [formArrayName]="index"
    [ngClass]="{ 'column-break-before': isColumnBreakBefore }">
    <div class="item-padding flex flex-start-center">
      <div *ngIf="isLeaf()">
        <button type="button" mat-icon-button matTreeNodeToggle [disableRipple]="true" (click)="toggleSubcategories()"
          class="tree-toggle-button">
          <mat-icon color="primary" class="mat-icon-rtl-mirror" svgIcon="{{ icon }}"
            [ngClass]="{ 'tree-btn-icon-closed': !showSubcategories }">
          </mat-icon>
        </button>
      </div>
      <div [ngClass]="{ leaf: !isLeaf(), flat: isFlat }">
        <mat-checkbox color="primary" [id]="idAttributeGenerate(data)" formControlName="checkbox"
          (change)="onCheckboxValueChange()">{{ data.Name }}</mat-checkbox>
      </div>
    </div>

    <div class="subcategory" *ngIf="showSubcategories && branch.subcategories">
      <checkbox-category *ngFor="let subControl of branch.subcategories.controls; index as j" [instance]="instance"
        [branch]="subControl['controls']" [data]="data.Subcategories[j]" [name]="name"
        [parentForm]="branch.subcategories" [index]="j"></checkbox-category>
    </div>
  </div>
</ng-container>
