import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromAgent from '../../store/reducers/agent/index';
import * as fromAgentSearch from '../../store/reducers/agent/search';
import * as AgentSearchActions from '../../store/actions/agent-search.actions';
import { IQueryPageOptions, IQuerySearchParams } from '../../interfaces/search';
import { IConsultantSearchedSelectable } from '../../interfaces/consultant-searched';
import { PageEvent } from '@angular/material/paginator';
import { IConsultantDetails } from '../../interfaces/consultant-details';
import { Params } from '@angular/router';
import { IFavouriteFiltersSelected, IFavouriteFiltersStatus, ISearchFavouriteFilter } from '../../interfaces/serach-filters-data';

@Injectable()
export class AgentSearchFacadeService {
  constructor(private store: Store<fromAgent.AgentModuleState>) {}

  queryResults(searchParams: IQuerySearchParams, queryPageOptions: IQueryPageOptions) {
    this.store.dispatch(AgentSearchActions.queryResults({ searchParams, queryPageOptions }));
  }

  clearResults() {
    this.store.dispatch(AgentSearchActions.clearResults());
  }

  toggleSelectionConsultant(consultantId: string, selected: boolean) {
    this.store.dispatch(AgentSearchActions.toggleSelectionConsultant({ consultantId, selected }));
  }

  clearSelectedConsultats() {
    this.store.dispatch(AgentSearchActions.clearSelectedConsultats());
  }

  updatePaginator( paginatorEvent: PageEvent) {
    this.store.dispatch(AgentSearchActions.updatePaginator({ paginatorEvent }));
  }

  get pageIndex$(): Observable<number> {
    return this.store.pipe(select(fromAgentSearch.selectPageIndex));
  }

  get pageSize$(): Observable<number> {
    return this.store.pipe(select(fromAgentSearch.selectPageSize));
  }

  get total$(): Observable<number> {
    return this.store.pipe(select(fromAgentSearch.selectTotal));
  }

  get loading$(): Observable<boolean> {
    return this.store.pipe(select(fromAgentSearch.selectLoading));
  }

  updateLoadingState(loading: boolean) {
    this.store.dispatch(AgentSearchActions.updateLoadingState({ loading }));
  }

  get error$(): Observable<boolean> {
    return this.store.pipe(select(fromAgentSearch.selectError));
  }

  get allConsultants$(): Observable<Array<IConsultantSearchedSelectable>> {
    return this.store.pipe(select(fromAgentSearch.selectAllConsultants));
  }

  getSelectedConsultants$(): Observable<Array<IConsultantSearchedSelectable>> {
    return this.store.pipe(select(fromAgentSearch.selectSelectedConsultants));
  }

  get totalLoadedConsultantsCount$(): Observable<number> {
    return this.store.pipe(select(fromAgentSearch.selectTotalLoadedConsultantsCount));
  }

  updateExtendedViewState(checked: boolean) {
    this.store.dispatch(AgentSearchActions.updateExtendedViewState({ checked }));
  }

  get extendedView$(): Observable<boolean> {
    return this.store.pipe(select(fromAgentSearch.selectExtendedView));
  }

  setRestore(shouldRestore: boolean, scrollY: number) {
    this.store.dispatch(AgentSearchActions.setRestore({ shouldRestore, scrollY }));
  }

  setRestoreQueryParams(queryParams: Params) {
    this.store.dispatch(AgentSearchActions.setRestoreQueryParams({ queryParams }));
  }

  get restore$(): Observable<{ shouldRestore: boolean, scrollY: number, queryParams: Params }> {
    return this.store.pipe(select(fromAgentSearch.selectRestore));
  }

  clearRestoreObj() {
    this.setRestore(false, 0);
    this.setRestoreQueryParams(null);
    this.updateExtendedViewState(false);
  }

  // favourites

  queryFavouriteFilters() {
    this.store.dispatch(AgentSearchActions.queryFavouriteFilters());
  }

  get favouriteFiltersInitialized$(): Observable<boolean> {
    return this.store.pipe(select(fromAgentSearch.selectFavouriteFiltersInitialized));
  }

  get favouriteFilters$(): Observable<Array<ISearchFavouriteFilter>> {
    return this.store.pipe(select(fromAgentSearch.selectFavouriteFilters));
  }

  get favouriteFiltersStatus$(): Observable<IFavouriteFiltersStatus> {
    return this.store.pipe(select(fromAgentSearch.selectFavouriteFiltersStatus));
  }

  get favouriteFiltersSelected$(): Observable<ISearchFavouriteFilter> {
    return this.store.pipe(select(fromAgentSearch.selectFavouriteFiltersSelected));
  }

  get favouriteFiltersSelectedDraft$(): Observable<ISearchFavouriteFilter> {
    return this.store.pipe(select(fromAgentSearch.selectFavouriteFiltersSelectedDraft));
  }

  addFavouriteFilters(addedFilter: ISearchFavouriteFilter) {
    this.store.dispatch(AgentSearchActions.addFavouriteFilter({ addedFilter }));
  }

  removeFavouriteFilter(removedFilterIndex: number) {
    this.store.dispatch(AgentSearchActions.removeFavouriteFilter({ removedFilterIndex }));
  }

  selectFavouriteFilter(selected: ISearchFavouriteFilter) {
    this.store.dispatch(AgentSearchActions.selectFavouriteFilter({ selected }));
  }

  selectFavouriteFilterDraft(draft: ISearchFavouriteFilter) {
    this.store.dispatch(AgentSearchActions.selectFavouriteFilterDraft({ draft }));
  }

  setDraftAsSelectedFavouriteFilter() {
    this.store.dispatch(AgentSearchActions.setDraftAsSelectedFavouriteFilter());
  }

  setSelectedAsDraftFavouriteFilter() {
    this.store.dispatch(AgentSearchActions.setSelectedAsDraftFavouriteFilter());
  }

  // global actions

  bookmarkSelectedConsultants(consultants: IConsultantSearchedSelectable[]) {
    this.store.dispatch(AgentSearchActions.bookmarkSelectedConsultants({ consultants }));
  }

  // single actions

  bookmarkConsultant(consultant: IConsultantSearchedSelectable | IConsultantDetails) {
    this.store.dispatch(AgentSearchActions.bookmarkConsultant({ consultant }));
  }

  unbookmarkConsultant(consultantId: string) {
    this.store.dispatch(AgentSearchActions.unbookmarkConsultant({ consultantId }));
  }

  // other
  updateConsultantPortraitUrl(consultantId: string, portraitUrl: string) {
    this.store.dispatch(AgentSearchActions.updatePortraitUrl({ consultantId, portraitUrl }));
  }
}
