import {Component, Input, OnInit, ElementRef} from '@angular/core';

@Component({
  selector: 'image-preloader',
  templateUrl: './image-preloader.component.html',
  styleUrls: ['./image-preloader.component.scss']
})
export class ImagePreloaderComponent implements OnInit {
  @Input() imageSrc: string;
  @Input() cropped: boolean;

  /**
   * Holds information about current loading state of image.
   * Available values are: in_progress / loaded / error
   */
  public loadingState = 'in_progress';

  constructor() { }

  ngOnInit() {
    this.doPreload();
  }

  private doPreload() {
    const img = new Image();

    img.onload = () => {
      this.loadingState = 'loaded';
    };

    img.onerror = (error) => {
      this.loadingState = 'error';
      console.debug('[ImagePreloaderComponent] Failed to load image : ' + this.imageSrc, error);
    };

    img.src = this.imageSrc;
  }

}
