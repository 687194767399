import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class ProfileStrengthService implements OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private _activateSection: Subject<string> = new Subject<string>();

  activateSection$: Observable<string> = this._activateSection.asObservable();

  activateSection(section: string) {
    this._activateSection.next(section);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
  }
}
