import { AuthenticationService } from './services/authentication.service';
import { ApplicationInsightsService } from './services/application-insights.service';

export function initAll(
  authenticationService: AuthenticationService,
  applicationInsightsService: ApplicationInsightsService
) {
  authenticationService.init();
  applicationInsightsService.init();

  return () => Promise.resolve();
}
