import {NgModule} from '@angular/core';
import {CvService} from '../services/cv/cv.service';
import {CvListService} from '../services/cv/cv-list.service';
import {CvCommonService} from '../services/cv/cv-common.service';
import {CvParseService} from '../services/cv/cv-parse.service';
import {AgentSearchFacadeService} from '../services/facades/agent-search-facade.service';
import {CvLanguageService} from '../services/cv-language.service';
// NgRx
import { StoreModule } from '@ngrx/store';
import * as fromCv from '../store/reducers/cv/index';

@NgModule({
  imports: [
    StoreModule.forFeature(fromCv.cvFeatureKey, fromCv.reducers)
  ],
  providers: [
    CvService,
    CvListService,
    CvCommonService,
    CvParseService,
    CvLanguageService,
    // Facades
    AgentSearchFacadeService
  ]
})
export class My7nCvCommonModule {}
