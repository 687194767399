import { Injectable, Injector, ComponentRef } from '@angular/core';
import { Overlay, OverlayRef, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { take } from 'rxjs';
// Components
import { GalleryLightboxComponent } from './gallery-lightbox.component';
import { GalleryLightboxOverlayRef } from './gallery-lightbox-overlay-ref';
// Injection tokens
import { GALLERY_LIGHTBOX_DATA } from './galery-lightbox-overlay.tokens';
// Interfaces
import { IGalleryLightboxData } from '../../../../interfaces/gallery-lightbox-data';

export interface GalleryPreviewDialogConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  galleryData?: IGalleryLightboxData;
}

const DEFAULT_CONFIG: GalleryPreviewDialogConfig = {
  hasBackdrop: true,
  panelClass: 'gallery-dialog-panel',
  backdropClass: 'cdk-overlay-transparent-backdrop',
  galleryData: null
};

@Injectable()
export class GalleryLightboxOverlayService {
  constructor(private injector: Injector, private overlay: Overlay) {}

  open(config: GalleryPreviewDialogConfig = {}): GalleryLightboxOverlayRef {
    // Override default configuration
    const dialogConfig = { ...DEFAULT_CONFIG, ...config };

    // Returns an OverlayRef which is a PortalHost
    const overlayRef = this.createOverlay(dialogConfig);

    // Instantiate remote control
    const dialogRef = new GalleryLightboxOverlayRef(overlayRef);

    this.attachDialogContainer(
      overlayRef,
      dialogConfig,
      dialogRef
    );

    overlayRef.backdropClick()
      .pipe(take(1))
      .subscribe(_ => dialogRef.close());

    return dialogRef;
  }

  private createOverlay(config: GalleryPreviewDialogConfig) {
    const overlayConfig = this.getOverlayConfig(config);
    return this.overlay.create(overlayConfig);
  }

  private attachDialogContainer(
    overlayRef: OverlayRef,
    config: GalleryPreviewDialogConfig,
    dialogRef: GalleryLightboxOverlayRef
  ) {
    const injector = this.createInjector(config, dialogRef);

    const containerPortal = new ComponentPortal(
      GalleryLightboxComponent,
      null,
      injector
    );
    const containerRef: ComponentRef<GalleryLightboxComponent> = overlayRef.attach(
      containerPortal
    );

    return containerRef.instance;
  }

  private createInjector(
    config: GalleryPreviewDialogConfig,
    dialogRef: GalleryLightboxOverlayRef
  ): PortalInjector {
    const injectionTokens = new WeakMap();

    injectionTokens.set(GalleryLightboxOverlayRef, dialogRef);
    injectionTokens.set(GALLERY_LIGHTBOX_DATA, config.galleryData);

    return new PortalInjector(this.injector, injectionTokens);
  }

  private getOverlayConfig(config: GalleryPreviewDialogConfig): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      positionStrategy
    });

    return overlayConfig;
  }
}
