<div class="overlay-origin" cdkOverlayOrigin #trigger="cdkOverlayOrigin" class="flex flex-row flex-start-center">
  <ng-container *rxLet="liked$ as likedState">
    <button mat-button
            type="button"   
            class="reactions-button"
            [ngClass]="{liked: likedState}" 
            color="accent"
            (click)="like()"
            data-test-id="reactions-button"
            >
      <div class="flex flex-row flex-center-center">
        <img class="button-img-icon like-inactive" 
             *ngIf="!likedState" 
             src="assets/images/reactions/like-inactive.svg"
             data-test-id="reactions-button-img-icon-inactive"
             >
        <img class="button-img-icon {{likedState?.toLowerCase()}}" 
             *ngIf="likedState" 
             src="assets/images/reactions/{{likedState?.toLowerCase()}}.svg"
             data-test-id="reactions-button-img-icon-active">
        <span class="reactions-like-btn-text {{likedState?.toLowerCase()}}"
              data-test-id="reactions-button-text">{{ likedState ? likedState : 'Like' }}</span>
      </div>
    </button>
  </ng-container>
</div>

<ng-template cdkConnectedOverlay #reactionsPanel>
  <div class="reaction-menu-container" *rxLet="ractionsPanelVisibility$ as panelVisibilityState">
    <div class="reaction-menu" 
         [ngClass]="{hidden: !panelVisibilityState}"
         data-test-id="reactions-menu">
      <ul class="flex flex-row flex-start-center">
        <li *ngFor="let reaction of reactions" class="flex flex-full">
          <div class="flex flex-col flex-between-center gap-12 reaction-item">
            <button class="reaction-button flex flex-row flex-start-center" 
                    attr.data-test-id="reaction-button-{{reaction}}"
                    (click)="react(reaction, true)"> 
              <img src="assets/images/reactions/{{reaction}}.svg" alt="{{reaction}}" class="reaction {{reaction}}">
            </button>
            <div class="reaction-title-container">
              <div class="reaction-title">{{reaction}}</div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    </div>
</ng-template>
