<ng-container *ngIf="(mediaQueryService.breakpoints$ | async) as breakpoints">
  <custom-upload [parentForm]="formElement" [imagePicker]="selfInstance" [uploadedImage]="uploadedImage"></custom-upload>
  
  <div class="gallery-container flex">
    <button type="button" mat-mini-fab class="slider-nav-btn prev" (click)="prevSlide()" aria-label="Previous"
      [ngClass]="{ mobile: breakpoints.ltMd }" [ngStyle]="{ visibility: currentSlide == 0 ? 'hidden' : 'visible' }"
      [disableRipple]="true"
      data-test-id="imagePicker-prevSlide-button">
      <mat-icon svgIcon="arrow_down" class="rotate-90"></mat-icon>
    </button>
    <div #pickerSlider class="image-picker-slider-container" [ngClass]="{ disabled: disableSlider }">
      <div class="gallery-container element-list clearfix flex flex-col flex-start-start gap-6" #presetList>
        <div class="gallery-row flex flex-start-stretch gap-6" *ngFor="let preset of banners; let i = index">
          <div *ngFor="let item of preset; let j = index" class="gallery-item flex flex-start-stretch"
            [ngClass]=" {'item-active': getEventBannerActive(item)}" (mouseover)="onEventBannerOver(item)"
            (mouseleave)="onEventBannerLeave()" (click)="selectImage(item)" #presetEl
            [ngStyle]="item.PresetUrl ? {'background-image':  'url('+item.PresetUrl+')'} : {}"
            [style.width.px]="itemWidth" role="button"></div>
        </div>
      </div>
    </div>
    <button type="button" mat-mini-fab class="slider-nav-btn next" (click)="nextSlide()" aria-label="Next"
      [ngClass]="{ mobile: breakpoints.ltMd }" [ngStyle]="{
        visibility: currentSlide === slidesNumber - 1 ? 'hidden' : 'visible'
      }"
      data-test-id="imagePicker-nextSlide-button">
      <mat-icon svgIcon="arrow_down" class="rotate-270"></mat-icon>
    </button>
  </div>
  <validation-messages *ngIf="!selectedImage && uploadGroup.get('fileName').touched"
    [field]="uploadGroup.get('fileName')" messages="['required']"
    [texts]="{ required: 'Please, select or add an image' }"></validation-messages>
</ng-container>
