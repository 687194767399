
export interface INotificationSettings {
  ContactId: string;
  Email: string;
  WantToReceive: boolean;
  SummaryNotificationFrequency: NotificationFrequency;
}

export enum NotificationFrequency {
  INSTANT = 'Instant',
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly'
}
