import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer, SafeStyle } from '@angular/platform-browser';
import {ICompanyEvent} from '../../../../interfaces/company-event';
import { Router } from '@angular/router';

@Component({
  selector: 'event-box-element',
  templateUrl: './event-box-element.component.html',
  styleUrls: ['./event-box-element.component.scss']
})
export class EventBoxElementComponent implements OnInit {
  @Input() public event: ICompanyEvent;

  banner: SafeStyle = '';

  constructor(private sanitizer: DomSanitizer, private router: Router) { }

  ngOnInit() {
    this.banner = this.sanitizer.bypassSecurityTrustStyle(`url(${this.event.Banner})`);
  }

  showEvent() {
    this.router.navigate(['/events/' + this.event.Id]);
  }
}
