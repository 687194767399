import { createAction, props } from '@ngrx/store';
import { ISharepointGallery, ISharepointGalleryFilesRequestParams } from '../../interfaces/sharepoint';

export const queryGalleryData = createAction(
  '[Galleries] Query Gallery data',
  props<{
    path: string,
    galleryParams: ISharepointGalleryFilesRequestParams,
    forceRefresh: boolean
  }>()
);

export const queryGalleryDataError = createAction(
  '[Galleries] Query Gallery data error',
  props<{
    path: string
  }>()
);

export const updateGalleryData = createAction(
  '[Galleries] Update Gallery data',
  props<{
    gallery: ISharepointGallery
  }>()
);

export const galleryLoadedFromCache = createAction(
  '[Galleries] Gallery Loaded from cache'
);
