import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IConsultantSearchedSelectable } from '../../../../interfaces/consultant-searched';
import { getTimelineModuleState } from '../index';
import * as fromTimelineNews from './timeline-news.reducer';

export const timelineNewsStateKey = 'news';

export const getTimelineNewsState =
  createFeatureSelector<fromTimelineNews.TimelineNewsState>(timelineNewsStateKey);

export const selectTimelineNewsState = createSelector(
  getTimelineModuleState,
  getTimelineNewsState
);

export const selectNews = createSelector(
  selectTimelineNewsState,
  fromTimelineNews.getNews
);

export const selectPageSize = createSelector(
  selectTimelineNewsState,
  fromTimelineNews.getPageSize
);

export const selectScrollY = createSelector(
  selectTimelineNewsState,
  fromTimelineNews.getScrollY
);

export const selectLoadedInfo = createSelector(
  selectTimelineNewsState,
  fromTimelineNews.getLoadedInfo
);

export const selectLoading = createSelector(
  selectTimelineNewsState,
  fromTimelineNews.getLoading
);

export const selectLoadingMore = createSelector(
  selectTimelineNewsState,
  fromTimelineNews.getLoadingMore
);

export const selectLoadingError = createSelector(
  selectTimelineNewsState,
  fromTimelineNews.getLoadingError
);

export const selectLoadingMoreError = createSelector(
  selectTimelineNewsState,
  fromTimelineNews.getLoadingMoreError
);

export const selectInitialized = createSelector(
  selectTimelineNewsState,
  fromTimelineNews.getInitialized
);

// selector used by entity adapter
export const selectNewsEntities = createSelector(
  selectTimelineNewsState,
  fromTimelineNews.selectNewsEntities
);
// selector used by entity adapter
export const selectAllNews = createSelector(
  selectTimelineNewsState,
  fromTimelineNews.selectAllNews
);
// selector used by entity adapter
export const selectTotalLoadedNewsCount = createSelector(
  selectTimelineNewsState,
  fromTimelineNews.selectTotalLoadedNewsCount
);