import { createSelector } from '@ngrx/store';
import { getCvProfileStrengthState, getCvModuleState } from './index';
import * as fromCvProfileStrength from './cv-profile-strength.reducer';


export const selectCvProfileStrengthState = createSelector(
  getCvModuleState,
  getCvProfileStrengthState
);

export const selectProfileStrength = createSelector(
  selectCvProfileStrengthState,
  fromCvProfileStrength.getProfileStrength
);

export const selectMinimumRequirementMet = createSelector(
  selectCvProfileStrengthState,
  fromCvProfileStrength.getMinimumRequirementMet
);
