import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'print-preview-dialog',
  templateUrl: './print-preview-dialog.component.html',
  styleUrls: ['./print-preview-dialog.component.scss']
})
export class PrintPreviewDialogComponent implements OnInit {

  pdfBlob: Blob;
  downloadFileName: string;

  @ViewChild('pdfViewer') pdfViewer: ElementRef;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public sanitizer: DomSanitizer,
              public dialogRef: MatDialogRef<PrintPreviewDialogComponent>) { }

  ngOnInit() {
    this.pdfBlob = this.data.pdfData;
    this.downloadFileName = this.data.fileName;
  }

  close(): void {
    this.dialogRef.close(false);
  }
}
