import { FrontendFeature, FrontendFeaturesModule } from '../feature';

@FrontendFeaturesModule('UserProfile')
export class UserProfileFeatures {
  @FrontendFeature({
    summary: 'User profile page'
  })
  static readonly Default = 'user-profile';

  @FrontendFeature({
    summary: 'User profile edit'
  })
  static readonly Edit = 'user-profile/edit';

  @FrontendFeature({
    summary: 'User profile personal details section'
  })
  static readonly PersonalDetails = 'user-profile/personal-details';

  @FrontendFeature({
    summary: 'User profile business details section'
  })
  static readonly BusinessDetails = 'user-profile/business-details';

  @FrontendFeature({
    summary: 'User profile work locations section'
  })
  static readonly WorkLocations = 'user-profile/work-locations';

  @FrontendFeature({
    summary: 'User profile terms and conditions section'
  })
  static readonly Terms = 'user-profile/terms';

  @FrontendFeature({
    summary: 'User profile personal notifications section'
  })
  static readonly Notifications = 'user-profile/notifications';
}
