export interface INotificationItem {
  Id: string;
  ExternalId: number | string;
  Title: string;
  Headline: string;
  PostDate: string;
  IsVisited: boolean;
  Type: string;
  Payload?: string;
  Reason: string;
}

export enum NotificationItemType {
  EVENT = 'event',
  NEWS = 'news',
  REFERRAL = 'referral',
  OTHER = 'other'
}
