import { Inject, Injectable } from '@angular/core';
import { IUserPreferences } from '../interfaces/user-preferences';
import { MY7N_ENV_CONFIG } from '../functions/my7n-env-config';
import { IMy7nEnvConfig } from '../interfaces/my7n-env-config';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import {
  UserProfileFeatures,
  BenefitsFeatures,
  ReferralsFeatures,
  CvFeatures,
  Feature,
  EventsFeatures,
  TimelineFeatures,
  NotificationsFeatures,
  EvaluationsFeatures,
  ConsultantsFeatures
} from '@my7n/features';

@Injectable()
export class AuthorizationService {

  /**
   * User preferences
   */
  user: IUserPreferences;

  private _initialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  get initialized$(): Observable<boolean> {
    return this._initialized$.asObservable();
  }

  private _wasInitialized$: Observable<boolean>;
  get wasInitialized$(): Observable<boolean> {
    return this._wasInitialized$;
  }

  // Prefixes for features that are managed by Permission Manager
  // @TODO Hybrid privileges side effect - remove after moving all system parts to features
  private _PMManagedFeaturesPrefixes = [
    // Important!!! If you're updating it, probably you also need to edit whitelistApi in UnauthorizedHttpInterceptorService
    // BenefitsFeatures.Default,
    ReferralsFeatures.Default,
    CvFeatures.Default,
    UserProfileFeatures.Default,
    EventsFeatures.Default,
    TimelineFeatures.Default,
    NotificationsFeatures.Default,
    EvaluationsFeatures.Default,
    ConsultantsFeatures.Default
  ];

  get PMManagedFeaturesPrefixes(): Array<Feature> {
    return this._PMManagedFeaturesPrefixes;
  }

  /**
   * Sets user preferences using config data.
   */
  constructor(@Inject(MY7N_ENV_CONFIG) private envConfig: IMy7nEnvConfig) {

    this._wasInitialized$ = this.initialized$.pipe(
      // emit only when init is true, else wait
      filter((wasInitialized: boolean) => {
        return wasInitialized === true;
      })
    );
  }

  init(user: IUserPreferences) {
    this.user = user;
    this._initialized$.next(true);
  }

  /**
   * Checks if user has specified privileges.
   *
   * @param {string[]} privileges Required privileges.
   * @param {boolean} mustContainAtLeastOne If true one privilege is sufficient.
   * @returns {Observable<boolean>}
   */
  canMany(privileges: string[], mustContainAtLeastOne: boolean): Observable<boolean> {
    return this.wasInitialized$.pipe(
      take(1),
      map(() => {
        const userPrivileges = this.user.Privileges;

        let isAuthorized = !mustContainAtLeastOne; // set to TRUE for AND and to FALSE for OR logic
        let matchingPrivileges: boolean;

        if (!userPrivileges || (userPrivileges && userPrivileges.length === 0)) {
          console.warn('[AuthService] Current user (id:' + this.user.Id + ') has no defined privileges');
          // deferred.reject('Failed to load privileges for current user');
          return;
        }

        // Every empty set is a part of full sets
        if (privileges.length === 0) {
          return true;
        }

        privileges.forEach((privilege) => {
          matchingPrivileges = userPrivileges.some((userPrivilege) => {
            // SuperAdmin's GRANT ALL * sign not works for new features
            if (userPrivilege === '*' && !this.canGrantAllBeUsed(privilege)) {
              return false;
            }

            // if user privilege ends with * sign
            // @TODO Hybrid privileges side effect - remove after moving all system parts to features
            if (userPrivilege && userPrivilege.endsWith('*')) {
              // cut off '*' or '/*' ending before matching
              return privilege.indexOf( userPrivilege.replace(/\/?\*$/, '')) === 0;
            }

            return privilege === userPrivilege;
          });

          if (mustContainAtLeastOne) {
            // Must contain at least one matching privilege
            isAuthorized = isAuthorized || matchingPrivileges;
          } else {
            // Must contain all matching privileges
            isAuthorized = isAuthorized && matchingPrivileges;
          }
        });

        return isAuthorized;
      }),
    );
  }

  /**
   * Checks if user has specified privilege.
   * If 'useRegex' is set to true RegexPrivileges are also analyzed.
   *
   * @param {string} privilege
   * @param {boolean} useRegex
   * @returns {Observable<boolean>}
   */
  can(privilege: string, useRegex= false): Observable<boolean> {
    return this.wasInitialized$.pipe(
      take(1),
      map(() => {
        const userPrivileges = this.user.Privileges,
          regexUserPrivileges = this.user.RegexPrivileges;

        let matchingPrivileges;

        if (!userPrivileges || (userPrivileges && userPrivileges.length === 0)) {
          console.warn('[AuthService] Current user has no defined privileges');
          // deferred.reject('Failed to load privileges for current user');
          return false;
        }

        matchingPrivileges = userPrivileges.some((userPrivilege) => {
          // SuperAdmin's GRANT ALL * sign not works for new features
          // @TODO Hybrid privileges side effect - remove after moving all system parts to features
          if (userPrivilege === '*' && !this.canGrantAllBeUsed(privilege)) {
            return false;
          }

          if (userPrivilege && userPrivilege.endsWith('*')) {
            // Match privilege without ending '/' if present
            return privilege.indexOf(userPrivilege.slice(0, -1)) === 0;
          }

          return privilege === userPrivilege;
        });

        if (useRegex) {
          // check regex privileges
          matchingPrivileges = matchingPrivileges || regexUserPrivileges.some(regex => regex.test(privilege));
        }

        return matchingPrivileges;
      })
    );
  }

  /**
   * Checks if user has all specified privileges.
   *
   * @param {string[]} privileges
   * @returns {Observable<boolean>}
   */
  canAll(privileges: string[]): Observable<boolean> {
    return this.canMany(privileges, false);
  }

  /**
   * Checks if user has at least one of specified privileges.
   *
   * @param {string[]} privileges
   * @returns {Observable<boolean>}
   */
  canSome(privileges: string[]): Observable<boolean> {
    return this.canMany(privileges, true);
  }

  /**
   * Grant all privilege can be used only for features non-managable with Permission Manager
   * @TODO Hybrid privileges side effect - remove after moving all system parts to features
   */
  canGrantAllBeUsed(privilege: string): boolean {
    return this.PMManagedFeaturesPrefixes.some((prefix) => privilege?.startsWith(prefix)) === false;
  }

}
