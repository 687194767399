import 'reflect-metadata';
import { FrontendFeature, FrontendFeaturesModule } from '../feature';
import { Feature } from '../interfaces/feature';

@FrontendFeaturesModule('ConsultantGuide')
export class ConsultantGuideFeatures {
  @FrontendFeature({
    summary: 'Default consultant guide page'
  })
  static readonly Default: Feature = 'consultant-guide';

  @FrontendFeature({
    summary: 'Consultant guide home page'
  })
  static readonly Home: Feature = 'consultant-guide/home';

  @FrontendFeature({
    summary: 'Consultant guide finance page'
  })
  static readonly Finance: Feature = 'consultant-guide/finance';

  @FrontendFeature({
    summary: 'Consultant guide contact points page'
  })
  static readonly ContactPoints: Feature = 'consultant-guide/contact-points';

  @FrontendFeature({
    summary: 'Consultant guide benefits page'
  })
  static readonly Benefits: Feature = 'consultant-guide/benefits';

  @FrontendFeature({
    summary: 'Consultant guide development page'
  })
  static readonly Development: Feature = 'consultant-guide/development';

  @FrontendFeature({
    summary: 'Consultant guide events page'
  })
  static readonly Events: Feature = 'consultant-guide/events';

  @FrontendFeature({
    summary: 'Consultant guide events page'
  })
  static readonly EventsUpcomingEventsBtn: Feature = 'consultant-guide/events/upcoming-events-btn';

  @FrontendFeature({
    summary: 'Consultant guide communication page'
  })
  static readonly Communication: Feature = 'consultant-guide/communication';
}
