import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  Input
} from '@angular/core';
import { VideoUpdateFacadeService } from '../../../services/facades/video-update-facade.service';
import { fromEvent, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  ICmsVideoUpdateItem,
  ICmsViewMediaSize
} from '../../../interfaces/cms';
import { RxState } from '@rx-angular/state';
import {
  IVideoPlayerConfig,
  LogVideoModules,
  videoPlayerDefaultConfig
} from '../../../interfaces/video-player';

export interface IVideoUpdateBoxState {
  video: ICmsVideoUpdateItem;
  videoPlayerConfig: IVideoPlayerConfig;
}

const initialState: IVideoUpdateBoxState = {
  video: null,
  videoPlayerConfig: {
    ...videoPlayerDefaultConfig,
    autoplay: false,
    showLoader: false
  }
};
@Component({
  selector: 'video-update-box',
  templateUrl: './video-update-box.component.html',
  styleUrls: ['./video-update-box.component.scss'],
  providers: [RxState],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoUpdateBoxComponent implements OnInit {
  @Input() preview: boolean;
  @Input() id: string;
  readonly DEFAULT_RATIO = 421 / 750;
  readonly state$ = this.state.select();
  readonly videoSize$ = fromEvent(window, 'resize').pipe(
    switchMap(() => {
      return of(this.getVideoSize());
    })
  );

  @ViewChild('videoContainer', { static: true }) videoContainer: ElementRef;

  LogVideoModules = LogVideoModules;

  constructor(
    private videoUpdateFacade: VideoUpdateFacadeService,
    private state: RxState<IVideoUpdateBoxState>
  ) {
    this.state.set({ ...initialState });
  }

  ngOnInit(): void {
    if (this.preview && this.id) {
      this.videoUpdateFacade.queryVideoUpdatePreviewById(this.id);
    } else {
      this.videoUpdateFacade.queryLatestVideoUpdateByUserBu();
    }

    this.initLocalStateConnections();
    setTimeout(() => {
      const size = this.getVideoSize();
      return this.state.set((oldState) => ({
        ...oldState,
        videoPlayerConfig: {
          ...oldState.videoPlayerConfig,
          containerWidth: size.width,
          containerHeight: size.height
        }
      }));
    }, 0); // to get the right size, we have to schedule the call
  }

  initLocalStateConnections() {
    this.state.connect('video', this.videoUpdateFacade.latestVideoUpdate$);
    this.state.connect(this.videoSize$, (s, e) => {
      const videoSize = this.getVideoSize();
      return {
        ...s,
        videoPlayerConfig: {
          ...s.videoPlayerConfig,
          containerWidth: videoSize.width,
          containerHeight: videoSize.height
        }
      };
    });
  }

  getVideoContainerWidth(): number {
    return this.videoContainer.nativeElement.offsetWidth;
  }

  private getVideoSize(): ICmsViewMediaSize {
    return {
      width: this.getVideoContainerWidth(),
      height: Math.round(this.getVideoContainerWidth() * this.DEFAULT_RATIO),
      ratio: this.DEFAULT_RATIO
    };
  }
}
