import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { RouterModule } from '@angular/router';
// Services
import { ContentfulService } from '../services/contentful.service';
import { CmsCardService } from '../components/cms/cms-card/cms-card.service';
import { CmsCardListService } from '../services/cms-card-list.service';
import { VideoUpdateContentfulService } from '../services/video-update-contentful.service';
import { ContentfulGetterService } from '../services/contentful-getter.service';
import { ContentfulSettingsService } from '../services/contentful-settings.service';
import { DictionariesContentfulService } from '../services/dictionaries-contentful.service';

// Modules
import { My7nCommonModule } from './my7n-common.module';

// Components
import { CmsCardComponent } from '../components/cms/cms-card/cms-card.component';
import { CmsCardListComponent } from '../components/cms/cms-card-list/cms-card-list.component';
import { CmsViewComponent } from '../components/cms/cms-item-view/cms-item-view.component';
import { CmsNewsCardComponent } from '../components/cms/cms-news-card/cms-news-card.component';
import { CmsRichTextComponent } from '../components/cms/cms-rich-text/cms-rich-text.component';
import { ReactionsComponent } from '../components/common/reactions/reactions.component';

// NgRx
import * as fromCms from '../store/reducers/cms/index';
import { VideoUpdateEffects } from '../store/effects/cms/video-update.effects';
import { DictionariesEffects } from '../store/effects/cms/dictionaries.effects';

@NgModule({
  declarations: [
    CmsCardComponent,
    CmsCardListComponent,
    CmsViewComponent,
    CmsNewsCardComponent,
    CmsRichTextComponent
  ],
  imports: [
    CommonModule,
    My7nCommonModule,
    RouterModule,
    StoreModule.forFeature(fromCms.cmsFeatureKey, fromCms.reducers),
    EffectsModule.forFeature([VideoUpdateEffects, DictionariesEffects]),
    ReactionsComponent
  ],
  exports: [
    CmsCardComponent,
    CmsCardListComponent,
    CmsViewComponent,
    CmsNewsCardComponent,
    CmsRichTextComponent
  ],
  providers: [
    CmsCardService, 
    CmsCardListService,
    VideoUpdateContentfulService,
    ContentfulGetterService,
    ContentfulSettingsService,
    DictionariesContentfulService,
    ContentfulService
  ]
})
export class My7nCmsModule {}
