import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FeaturesEnabler, QUERY_PARAM_FEATURE_ENABLED_KEY } from '../interfaces/feature-enabler';

@Injectable({
  providedIn: 'root'
})
export class QueryParamsFeatureEnablerService {
  constructor(private route: ActivatedRoute) { }

  get enabledFeatures(): FeaturesEnabler[] {
    return this.route.snapshot.queryParamMap.get(QUERY_PARAM_FEATURE_ENABLED_KEY) ? this.route.snapshot.queryParamMap.get(QUERY_PARAM_FEATURE_ENABLED_KEY).split(',') as FeaturesEnabler[] : [];
  }

  isEnabled(feature: FeaturesEnabler) {
    return this.enabledFeatures.includes(feature);
  }
}
