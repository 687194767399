import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as fromCv from '../../store/reducers/cv/index';
import * as CvProfileStrengthSelectors from '../../store/reducers/cv/cv-profile-strength.selectors';
import * as CvProfileStrengthActions from '../../store/actions/cv-profile-strength.actions';

import { IProfileStrength } from '../../interfaces/profile-strength';

@Injectable()
export class CvProfileStrengthFacadeService {
  constructor(
    private store: Store<fromCv.CvModuleState>
  ) {}

  get profileStrength$(): Observable<IProfileStrength> {
    return this.store.pipe(select(CvProfileStrengthSelectors.selectProfileStrength));
  }

  get minimumRequrementMet$(): Observable<boolean> {
    return this.store.pipe(select(CvProfileStrengthSelectors.selectMinimumRequirementMet));
  }

  queryProfileStrength(cvId: number) {
    this.store.dispatch(CvProfileStrengthActions.queryProfileStrength({cvId}));
  }

  clearProfileStrength() {
    this.store.dispatch(CvProfileStrengthActions.clearProfileStrength());
  }
}
