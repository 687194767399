import { Action, createReducer, on } from '@ngrx/store';
import { IAppConfig } from '../../../interfaces/app-config';
import * as AppConfigActions from '../../actions/global-app-config.actions';
import { HttpErrorResponse } from '@angular/common/http';

export interface IConfigApiUrls {
  timekeys: string;
  userPreferences: string;
  languages: string;
  accessFeatures: string;
  terms: string;
}

export interface GlobalAppConfigState {
  dirty: boolean;
  loading: boolean;
  error: HttpErrorResponse;
  initialized: boolean;
  config: IAppConfig;
  configApiUrls: IConfigApiUrls;
}

export const initialState: GlobalAppConfigState = {
  dirty: false,
  loading: false,
  error: null,
  initialized: false,
  config: null,
  configApiUrls: null
};

const reducer = createReducer<GlobalAppConfigState>(
  initialState,
  on(AppConfigActions.queryAppConfig, (state) => ({
    ...state,
    loading: true,
    error: null
  })),
  on(AppConfigActions.queryAppConfigSuccess, (state, { config }) => ({
    ...state,
    loading: false,
    initialized: true,
    dirty: false,
    config
  })),
  on(AppConfigActions.queryAppConfigError, (state, { error }) => ({
    ...state,
    loading: false,
    error
  })),
  on(AppConfigActions.updatePrintPreferences, (state, { settings }) => ({
    ...state,
    config: {
      ...state.config,
      User: { ...state.config.User, PrintPreferences: settings }
    }
  })),
  on(AppConfigActions.updateConfigDirtyState, (state, { dirty }) => ({
    ...state,
    dirty
  })),
  on(AppConfigActions.updateConfigApiUrls, (state, { configApiUrls }) => ({
    ...state,
    configApiUrls
  })),
  on(AppConfigActions.updateUser, (state, { user }) => ({
    ...state,
    config: {
      ...state.config,
      User: { ...state.config.User, ...user }
    }
  }))
);

export function globalAppConfigReducer(
  state: GlobalAppConfigState | undefined,
  action: Action
) {
  return reducer(state, action);
}

export const getAppConfigConfigState = (state: GlobalAppConfigState) => {
  return state.config;
};

export const getAppConfigUserState = (state: GlobalAppConfigState) => {
  return state.config?.User;
};

export const getPrintPreferencesState = (state: GlobalAppConfigState) => {
  return state.config?.User?.PrintPreferences;
};

export const getAppConfigLanguagesState = (state: GlobalAppConfigState) => {
  return state.config?.Languages;
};

export const getAppConfigTimeKeysState = (state: GlobalAppConfigState) => {
  return state.config?.TimeKeys;
};

export const getConfigDirtyState = (state: GlobalAppConfigState) =>
  state.dirty;

export const getConfigLoadingState = (state: GlobalAppConfigState) =>
  state.loading;

export const getConfigErrorState = (state: GlobalAppConfigState) =>
  state.error;

export const getConfigInitializedState = (state: GlobalAppConfigState) =>
  state.initialized;

export const getConfigApiUrlsState = (state: GlobalAppConfigState) =>
  state.configApiUrls;
