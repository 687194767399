import { createSelector } from '@ngrx/store';
import { getGalleriesModuleState, getGalleriesState } from './index';
import * as fromGalleries from './galleries.reducer';
import { ISharepointGallery } from '../../../interfaces/sharepoint';

export const selectGalleriesState = createSelector(
  getGalleriesModuleState,
  getGalleriesState
);


export const selectGalleriesIds = createSelector(
  selectGalleriesState,
  fromGalleries.selectGalleriesIds
);

export const selectGalleriesEntities = createSelector(
  selectGalleriesState,
  fromGalleries.selectGalleriesEntities
);

export const selectAllGalleries = createSelector(
  selectGalleriesState,
  fromGalleries.selectAllGalleries
);

export const selectGalleriesTotalLoaded = createSelector(
  selectGalleriesState,
  fromGalleries.selectGalleriesTotalLoaded
);

export const selectGallery = createSelector(
  selectAllGalleries,
  (galleries:ISharepointGallery[], props: { path: string }): ISharepointGallery => (galleries.find((item) => item.basePath === props.path))
);

export const selectGalleryLastRequest = createSelector(
  selectAllGalleries,
  (galleries: ISharepointGallery[], props: { path: string }) => {
    const gallery = galleries.find((galleryItem) => galleryItem.basePath === props.path);
    return gallery.lastRequestTimestamp;
  }
);

export const selectGalleryLoadingState = createSelector(
  selectAllGalleries,
  (galleries: ISharepointGallery[], props: { path: string }) => {
    const gallery = galleries.find((galleryItem) => galleryItem.basePath === props.path);
    return gallery.loading;
  }
);

export const selectGalleryErrorState = createSelector(
  selectAllGalleries,
  (galleries: ISharepointGallery[], props: { path: string }) => {
     const gallery = galleries.find((galleryItem) => galleryItem.basePath === props.path);
     return gallery.error;
  }
);

export const selectGalleryFileCount = createSelector(
  selectAllGalleries,
  (galleries: ISharepointGallery[], props: { path: string }) => {
    const gallery = galleries.find((galleryItem) => galleryItem.basePath === props.path);
    return gallery.Count;
  }
);
