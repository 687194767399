import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FeedbackService } from '../../services/feedback.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { RequiredTrimmedValidator } from '../../validators/required-trimmed.validator';
import { SnackBarService, SnackBarTypes } from '@my7n/ui';

@Component({
  selector: 'feedback-modal',
  templateUrl: './feedback-modal.component.html',
  styleUrls: ['./feedback-modal.component.scss']
})
export class FeedbackModalComponent implements OnInit {
  /**
   * Feedback form reference (based on reactive form)
   */
  feedbackForm: UntypedFormGroup;

  constructor(public dialogRef: MatDialogRef<FeedbackModalComponent>,
              private feedbackService: FeedbackService,
              private snackBarService: SnackBarService,
              private formBuilder: UntypedFormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.createForm();
  }

  /**
   * Registers reactive form for editing event.
   */
  private createForm() {
    this.feedbackForm = this.formBuilder.group({
        userFeedback: ['', RequiredTrimmedValidator], // validator is added dynamically
    });
  }

  onSubmit(): boolean {
    if (!this.feedbackForm.valid) {
      console.debug('[FeedbackModalComponent][onSubmit] Form is invalid', this.feedbackForm.value);
      return false;
    }

    this.dialogRef.close();
    this.snackBarService.openPending({
      message: 'Sending feedback',
      type: SnackBarTypes.Notification
    });

    this.feedbackService.sendEmail(this.feedbackForm.value.userFeedback.trim()).then(() => {
      this.snackBarService.open({
        message: 'Your feedback has been sent, thank you!',
        type: SnackBarTypes.SuccessAlt
      });
    }, (err) => {
      this.snackBarService.open({
        message: 'There was a problem while sending your feedback',
        type: SnackBarTypes.ErrorAlt
      });
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

