import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { ApplicationInsightsService } from './application-insights.service';

@Injectable()
export class SecureImageService {
  constructor(private domSanitizer: DomSanitizer,
              private httpClient: HttpClient,
              private applicationInsights: ApplicationInsightsService) { }

  get(url: string): Observable<null | SafeUrl> {
    return this.httpClient.get(url, { responseType: 'blob' }).pipe(
      catchError(error => {
        this.applicationInsights.trackEvent(`[SecureImageService] Image '${url}' could not be loaded.`);

        return of(null);
      }),
      map(blob => {
        if (blob === null) {
          return null;
        }
        return this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
      })
    );
  }
}
