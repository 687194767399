<div class="cv-primary-dialog-container">
  <h1 mat-dialog-title>
    This is primary CV
    <button mat-button isIconButton="true" class="close-btn" (click)="cancelDialog();">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </h1>
  <div mat-dialog-content class="flex flex-col">
    <section>
      <p>Primary CV is used for matching you with new interesting job opportunities in the future.<br>
        If you want to delete it, please contact your agent.</p>
    </section>
  </div>
  <div mat-dialog-actions>
    <button mat-stroked-button color="primary" class="small-button" (click)="cancelDialog()" cdkFocusInitial>OK</button>
  </div>
</div>
