import { FrontendFeature, FrontendFeaturesModule } from '../feature';

@FrontendFeaturesModule('Timeline')
export class TimelineFeatures {
  @FrontendFeature({
    summary: 'Main page for news / timeline'
  })
  static readonly Default = 'news';

  @FrontendFeature({
    summary: 'CV box on timeline view'
  })
  static readonly CvBox = 'news/cv-box';

  @FrontendFeature({
    summary: 'Events on timeline view'
  })
  static readonly EventBox = 'news/event-box';

  @FrontendFeature({
    summary: 'News item view on timeline view'
  })
  static readonly ItemView = 'news/item/view';

  @FrontendFeature({
    summary: 'News item preview on timeline view and list'
  })
  static readonly ItemPreview = 'news/item/preview';

  @FrontendFeature({
    summary: 'Request transfer on timeline view'
  })
  static readonly RequestTransfer = 'news/request-transfer';

  @FrontendFeature({
    summary: 'Video Update box on timeline view'
  })
  static readonly VideoUpdateBox = 'news/video-update-box';

  @FrontendFeature({
    summary: 'Video Update Preview box on timeline view'
  })
  static readonly VideoUpdatePreviewBox = 'news/video-update-box/preview';
}
