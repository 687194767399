import * as fromApp from '../index';
import * as fromVideoUpdate from './video-update.reducer';
import * as fromDictionaries from './dictionaries.reducer';
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

export const cmsFeatureKey = 'cmsModule';
export const videoUpdateStateKey = 'videoUpdate';
export const dictionariesStateKey = 'dictionaries';

export interface FeatureState extends fromApp.State {
  videoUpdate: State;
  dictionaries: State;
}

export interface State {
  videoUpdate: fromVideoUpdate.State;
  dictionaries: fromDictionaries.State;
}

export const reducers: ActionReducerMap<State> = {
  videoUpdate: fromVideoUpdate.videoUpdateReducer,
  dictionaries: fromDictionaries.dictionariesReducer
};

export const getCmsModuleState = createFeatureSelector<State>(cmsFeatureKey);

export const getVideoUpdateState =
  createFeatureSelector<fromVideoUpdate.State>(videoUpdateStateKey);

export const getDictionariesState =
  createFeatureSelector<fromDictionaries.State>(dictionariesStateKey);
