import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'cv-primary-dialog',
  templateUrl: './cv-primary-dialog.component.html',
  styleUrls: ['./cv-primary-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CvPrimaryDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<CvPrimaryDialogComponent>
  ) { }

  cancelDialog(): void {
    this.dialogRef.close();
  }
}
