import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder} from '@angular/forms';
// Services
import { PhonesEditService } from '../../../services/phones-edit.service';
import { MediaQueryService } from '@my7n/ui';
import { AgentService } from '../../../services/agent.service';
// Interfaces
import { IPhones } from '../../../interfaces/phones';
import { COMMON_BUTTONS_TEXTS } from '../../../interfaces/common-texts';

@Component({
  selector: 'phones-edit',
  templateUrl: './phones-edit.component.html',
  styleUrls: ['./phones-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AgentService, PhonesEditService] // AgentService is dependency for PhonesEditService
})
export class PhonesEditComponent implements OnInit {
  phonesEditForm: UntypedFormGroup;

  @Input() consultantId: string;
  @Input() phones: IPhones;
  @Input() updateMethodName: string;
  @Input() readonly = false;
  @Input() sidePanel = false;
  @Input() canEdit = true;

  @Output() serverError = new EventEmitter<boolean>();
  @Output() updateSuccess = new EventEmitter<IPhones>();

  COMMON_BUTTONS_TEXTS = COMMON_BUTTONS_TEXTS;

  editMode = false;
  error = false;
  loading = false;

  _copiedPhones: IPhones;

  constructor(
    public mediaQueryService: MediaQueryService,
    private fb: UntypedFormBuilder,
    private phonesEditService: PhonesEditService,
    private changeDetRef: ChangeDetectorRef
    ) { }

  ngOnInit() {
    this.phonesEditForm = this.fb.group({
      mobileNumber: this.fb.control(this.phones.MobilePhone),
      businessNumber: this.fb.control(this.phones.BusinessPhone)
    });
  }

  activate() {
    if (!this.editMode && !this.readonly) {
      this.editMode = true;
      this.error = false;
      this._copiedPhones = {
        MobilePhone: this.phonesEditForm.value.mobileNumber,
        BusinessPhone: this.phonesEditForm.value.businessNumber,
      };
    }
  }

  keyListener($event) {
    switch ($event.which) {
      // Bind ESC key
      case 27:
        this.reset();
        break;
    }
  }

  reset() {
    this.phonesEditForm.reset(
      {
        mobileNumber: this._copiedPhones.MobilePhone,
        businessNumber: this._copiedPhones.BusinessPhone,
      }

    );

    this.phones = {...this._copiedPhones};

    this.editMode = false;
    this.loading = false;
  }

  update() {
    if (this.loading || this.readonly) {
      console.debug('[PhonesEditComponent] Cannot save during loading or while in readonly mode');
      return false;
    }

    if (!this.canEdit) {
      console.debug('[PhonesEditComponent] User doesn\'t have permission to edit');
      return false;
    }

    if (this.phonesEditForm.invalid) {
      console.debug('[PhonesEditComponent] Form is invalid, value:', this.phonesEditForm.value);
      return false;
    }

    this.error = false;
    this.loading = true;

    this.phonesEditForm.controls.mobileNumber.setValue(this.getTrimmed(this.phonesEditForm.controls.mobileNumber.value));
    this.phonesEditForm.controls.businessNumber.setValue(this.getTrimmed(this.phonesEditForm.controls.businessNumber.value));

    const phones: IPhones = {
      MobilePhone: this.phonesEditForm.controls.mobileNumber.value,
      BusinessPhone: this.phonesEditForm.controls.businessNumber.value
    };

    this.phonesEditService.phonesEditSave(phones, this.updateMethodName, this.consultantId).then(() => {
      this.error = false;
      this.editMode = false;
      this.loading = false;
      this.phones = {...phones};
      this.updateSuccess.emit(this.phones);
    }, () => {
      this.reset();
      this.error = true;
      this.serverError.emit(true);
    }).finally(() => {
      this.changeDetRef.markForCheck();
    });
  }

  private getTrimmed(value: string): string | null {
    return value ? value.trim() : null;
  }
}
