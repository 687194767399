import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { SnackBarTypes, SnackBarService, Icons, MediaQueryService } from '@my7n/ui';
import { ImageCroppedEvent } from 'ngx-image-cropper';

// Pipes
import { FilesizePipe } from '../../../../pipes/filesize.pipe';
// Components
import { ImagePickerComponent } from '../image-picker.component';
// Validators
import { Validators } from '@angular/forms';
import { RequiredTrimmedValidator } from '../../../../validators/required-trimmed.validator';
import * as ValidatorProperties from '../../../../common/validator-properties';
import { FileTypeValidator } from '../../../../validators/file-type.validator';

@Component({
  selector: 'custom-upload',
  templateUrl: './custom-upload.component.html',
  styleUrls: ['./custom-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomUploadComponent implements OnInit, OnDestroy {
  readonly validatorProperties: object;
  /**
   * Reference to the parent form element
   */
  @Input() parentForm: UntypedFormGroup;

  /**
   * Reference to the parent ImagePickerComponent object.
   */
  @Input() imagePicker: ImagePickerComponent;

  /**
   * Information about currently uploaded image.
   */
  private _uploadedImage;

  get uploadedImage() {
    return this._uploadedImage;
  }

  @Input()
  set uploadedImage(dataUrl) {
    if (dataUrl !== this._uploadedImage) {
      this._uploadedImage = dataUrl;

      if (dataUrl) {
            this.imagePicker.customImageSelected.emit(dataUrl);
            this.thumbnailImageSource = dataUrl;
            this.changeDetecionRef.markForCheck();
      } else {
        this.thumbnailImageSource = null;
      }
    }
  }

  /**
   * Formats accepted by custom upload element
   */
  @Input() acceptedMimeTypes = 'image/jpeg,image/png';

  /**
   * Reference to HTML input[type=file] element used to upload custom images.
   */
  @ViewChild('customUploadFileInput') fileInput;

  /**
   * FormGroup for custom banner to better separate validation from parent form
   */
  form: UntypedFormGroup;

  uploadError: string;

  thumbnailImageSource: string;
  get safeThumbnailImageSource(): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(
      `url(${this.thumbnailImageSource})`
    );
  }

  imageChangedEvent: FileList;

  cropper = {
    active: false,
    ready: false,
    image: null
  };

  isMobile = false;
  subscriptions = new Subscription();

  constructor(
    public sanitizer: DomSanitizer,
    private filesizePipe: FilesizePipe,
    private snackBarService: SnackBarService,
    private changeDetecionRef: ChangeDetectorRef,
    private mediaQueryService: MediaQueryService
  ) {
    this.validatorProperties = ValidatorProperties;

    this.subscriptions.add(
      this.mediaQueryService.breakpoints$.subscribe(({ ltMd }) => {
        this.isMobile = ltMd;
      })
    );
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      fileName: new UntypedFormControl(''),
      size: new UntypedFormControl(0),
      type: new UntypedFormControl('')
    });

    this.parentForm.addControl('upload', this.form);

    if (!this.uploadedImage) {
      // @TODO not sure if this is the best approach
      // maybe some custom validation should be in place when we have already an uploaded image
      this.addUploadValidation();
    }

    this.subscriptions.add(
      this.imagePicker.presetSelected
      .pipe(
        filter((selected) => selected !== undefined && selected !== null)
      )
      .subscribe((selected) => {
        this.thumbnailImageSource = this.imagePicker.bannerList.find(
          (banner) => banner.PresetId === selected
        ).PresetUrl;
        this.removeUploadValidation();
        this.fileInput.nativeElement.value = '';
        this.resetCropper();
        this.uploadFormGroup.markAllAsTouched();
        this.changeDetecionRef.markForCheck();
      })
    );
  }

  ngOnDestroy(): void {
    this.parentForm.removeControl('upload');
    this.parentForm.updateValueAndValidity();

    this.subscriptions.unsubscribe();
  }

  /**
   * Reads content of specified file.
   * @param {File} file
   */
  getFileDetailsAndContent(file: File) {
    this.setUploadFormGroupValues(file.name, file.size, file.type);

    this.imagePicker.onCustomImageUpload(null);

    // Verify if file should be processed and read it

    if (this.uploadFormGroup.get('type').valid) {
      if (this.uploadFormGroup.get('size').valid) {
        this.setCropperImage(file);
        this.uploadError = null;
      } else {
        this.uploadError =
          'File size must be less than ' +
          this.filesizePipe.transform(ValidatorProperties.event.maxFileSize, 0);
      }
    } else {
      this.uploadError =
        'Please choose a file with the right format: ' +
        this.acceptedMimeTypes.split(',').join(', ');
    }

    if (this.uploadError) {
      this.openSnackBar(this.uploadError);
    }
  }

  private openSnackBar(message: string) {
    this.snackBarService.open({
      message: message,
      type: SnackBarTypes.ErrorAlt,
      actionIcon: Icons.CLOSE
    });
  }

  get uploadFormGroup(): UntypedFormGroup {
    return <UntypedFormGroup>this.parentForm.controls['upload'];
  }

  isUploadFormInvalid(): boolean {
    return this.uploadFormGroup.invalid && this.uploadFormGroup.touched;
  }

  private setUploadFormGroupValues(
    fileName: string,
    fileSize: number,
    fileType: string
  ) {
    this.uploadFormGroup.get('fileName').setValue(fileName);
    this.uploadFormGroup.get('size').setValue(fileSize);
    this.uploadFormGroup.get('type').setValue(fileType);
    this.updateUploadFormGroupValueAndValidity();
  }

  removePhoto() {
    this.thumbnailImageSource = null;
    this.imagePicker.resetSelectedImage();
    this.fileInput.nativeElement.value = '';

    this.setUploadFormGroupValues(null, null, null);

    this.uploadError = null;
    this.resetCropper();

    this.addUploadValidation();
    this.uploadFormGroup.markAllAsTouched();
    this.changeDetecionRef.markForCheck();
  }

  /**
   * Handles uploading new custom image file.
   */
  uploadFileChanged(fileList?: FileList) {
    const uploadedFiles: FileList = fileList
      ? fileList
      : this.fileInput.nativeElement.files;

    if (uploadedFiles.length > 0) {
      this.getFileDetailsAndContent(uploadedFiles[0]);
    }

    this.imagePicker.resetSelectedImage();
    this.uploadFormGroup.markAllAsTouched();
    this.changeDetecionRef.markForCheck();
  }

  private addUploadValidation() {
    this.uploadFormGroup
      .get('fileName')
      .setValidators(RequiredTrimmedValidator);
    this.uploadFormGroup
      .get('size')
      .setValidators(Validators.max(ValidatorProperties.event.maxFileSize)); // @TODO connecting this with event does not ensure portability
    this.uploadFormGroup
      .get('type')
      .setValidators(FileTypeValidator(this.acceptedMimeTypes.split(',')));

    this.updateUploadFormGroupValueAndValidity();
  }

  removeUploadValidation() {
    this.uploadFormGroup.get('fileName').clearValidators();
    this.uploadFormGroup.get('size').clearValidators();
    this.uploadFormGroup.get('type').clearValidators();
    this.updateUploadFormGroupValueAndValidity();
  }

  private updateUploadFormGroupValueAndValidity() {
    this.uploadFormGroup
      .get('fileName')
      .updateValueAndValidity({ onlySelf: true }); // only update own validity, parent will be refreshed later, no need to recalculate in between
    this.uploadFormGroup.get('size').updateValueAndValidity({ onlySelf: true }); // same as above
    this.uploadFormGroup.get('type').updateValueAndValidity(); // now update also parents
  }

  imageCropped(event: ImageCroppedEvent) {
    this.imagePicker.onCustomImageUpload(event.base64);
    this.changeDetecionRef.markForCheck();
  }

  cropperReady() {
    this.cropper.ready = true;
  }

  loadImageFailed() {
    this.openSnackBar('Failed to load image');
    this.resetCropper();
  }

  private resetCropper() {
    this.cropper.active = false;
    this.cropper.ready = false;
    this.cropper.image = null;
  }

  private setCropperImage(image: File) {
    this.cropper.image = image;
    this.cropper.active = true;
  }
}

