import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import {
  filter,
  map,
  mergeMap,
  Observable,
  of,
  switchMap,
  withLatestFrom
} from 'rxjs';
import * as fromGlobal from '../../store/reducers/global/index';
import * as AppConfigSelectors from '../../store/reducers/global/global-app-config.selectors';
import * as AppConfigActions from '../../store/actions/global-app-config.actions';
import { IUserPreferences } from '../../interfaces/user-preferences';
import { IPrintPreferences } from '../../interfaces/print-preferences';
import {
  LEGAL_ENTITY_COUNTRY_FEATURE_PREFIX,
  LegalEntityFeatures
} from '@my7n/features';
import { IConfigApiUrls } from '../../store/reducers/global/global-app-config.reducer';
import { IAppConfig, TimeKeysHashMap } from '../../interfaces/app-config';
import { HttpErrorResponse } from '@angular/common/http';
import { ICvLanguage } from '../../interfaces/cv-language';

@Injectable()
export class GlobalAppConfigFacadeService {
  constructor(private store: Store<fromGlobal.GlobalModuleState>) {}

  get config$(): Observable<IAppConfig> {
    return this.store.pipe(
      select(AppConfigSelectors.selectAppConfig),
      withLatestFrom(this.loading$),
      filter(([config, loading]) => !!config && !loading),
      map(([config]) => config)
    );
  }

  get user$(): Observable<IUserPreferences> {
    return this.store.pipe(
      select(AppConfigSelectors.selectAppConfigUser),
      withLatestFrom(this.loading$),
      filter(([user, loading]) => !!user && !loading),
      map(([user]) => user)
    );
  }

  get printPreferences$(): Observable<IPrintPreferences> {
    return this.store
      .pipe(
        select(AppConfigSelectors.selectAppConfigPrintPreferences),
        withLatestFrom(this.loading$),
        filter(([printPreferences, loading]) => !!printPreferences && !loading),
        map(([printPreferences]) => printPreferences)
      );
  }

  get userPrivileges$(): Observable<string[]> {
    return this.user$.pipe(
      withLatestFrom(this.loading$),
      filter(([user, loading]) => !!user && !loading),
      map(([user]) => user),
      switchMap((user) => {
        return of(user.Privileges);
      })
    );
  }

  get legalEntityCountryFeature$(): Observable<LegalEntityFeatures> {
    return this.user$.pipe(
      withLatestFrom(this.loading$),
      filter(([user, loading]) => !!user && !loading),
      mergeMap(([user]) => {
        const legalEntityFeatures: Array<LegalEntityFeatures> =
          user.Privileges?.filter((p) =>
            p.startsWith(LEGAL_ENTITY_COUNTRY_FEATURE_PREFIX)
          );
        if (!legalEntityFeatures || legalEntityFeatures.length === 0) {
          // this case should be visible in logs - it means that the user account is not configured properly
          console.error(
            '[GlobalAppConfigFacadeService] Legal entity country not provided in user privileges'
          );
        }
        if (legalEntityFeatures.length > 1) {
          // this case should be visible in logs - it means that the user account is not configured properly
          console.error(
            '[GlobalAppConfigFacadeService] Multiple legal entity countries provided in user privileges'
          );
        }
        return of(legalEntityFeatures[0]); // return first one
      })
    );
  }

  get languages$(): Observable<ICvLanguage[]> {
    return this.store
      .pipe(
        select(AppConfigSelectors.selectAppConfigLanguages),
        withLatestFrom(this.loading$),
        filter(([languages, loading]) => !!languages && !loading),
        map(([languages]) => languages)
      );
  }

  get timeKeys$(): Observable<TimeKeysHashMap> {
    return this.store
      .pipe(
        select(AppConfigSelectors.selectAppConfigTimekeys),
        withLatestFrom(this.loading$),
        filter(([timeKeys, loading]) => !!timeKeys && !loading),
        map(([timeKeys]) => timeKeys)
      );
  }

  get dirty$(): Observable<boolean> {
    return this.store.pipe(
      select(AppConfigSelectors.selectAppConfigDirtyState)
    );
  }

  get loading$(): Observable<boolean> {
    return this.store.pipe(
      select(AppConfigSelectors.selectAppConfigLoadingState)
    );
  }

  get error$(): Observable<HttpErrorResponse> {
    return this.store.pipe(
      select(AppConfigSelectors.selectAppConfigErrorState)
    );
  }

  get initialized$(): Observable<boolean> {
    return this.store.pipe(
      select(AppConfigSelectors.selectAppConfigInitializedState)
    );
  }

  get appConfigApiUrls$(): Observable<IConfigApiUrls> {
    return this.store.pipe(
      select(AppConfigSelectors.selectAppConfigApiUrlsState)
    );
  }

  queryAppConfig() {
    this.store.dispatch(AppConfigActions.queryAppConfig());
  }

  updatePrintPreferences(settings: IPrintPreferences) {
    this.store.dispatch(
      AppConfigActions.updatePrintPreferences({
        settings
      })
    );
  }

  updateConfigDirtyState(dirty: boolean) {
    this.store.dispatch(
      AppConfigActions.updateConfigDirtyState({
        dirty
      })
    );
  }

  updateConfigApiUrls(configApiUrls: IConfigApiUrls) {
    this.store.dispatch(
      AppConfigActions.updateConfigApiUrls({
        configApiUrls
      })
    );
  }

  updateUser(user: Partial<IUserPreferences>) {
    this.store.dispatch(
      AppConfigActions.updateUser({
        user
      })
    );
  }
}
