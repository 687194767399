<div class="frequency-details" *ngIf="!editMode && !saving">
  <label class="standalone-label">Email notification frequency</label>
  <div class="flex flex-between-center">
    <p>{{frequency}}</p>
    <button mat-button 
            type="button" 
            color="primary" 
            class="small-button flex-[0_0_auto]" 
            title="Edit notification frequency preference" 
            (click)="edit()"
            data-test-id="notification-frequency-edit-button"
            *ngIf="canEdit">Edit</button>
  </div>
</div>
<edit-notification-frequency *ngIf="editMode || saving" 
                             [frequency]="frequency" 
                             (editResult)="onEditResult($event)"
                             [saving]="saving"
                             data-test-id="edit-notification-frequency-container"></edit-notification-frequency>
