import { PageEvent } from '@angular/material/paginator';
import { Params } from '@angular/router';
import { createAction, props } from '@ngrx/store';
import { IConsultantDetails } from '../../interfaces/consultant-details';
import { IConsultantSearchedSelectable } from '../../interfaces/consultant-searched';
import { IQueryPageOptions } from '../../interfaces/search';

export const queryResults = createAction(
  '[Agent][Bookmarks] query results',
  props<{ searchParams: any, queryPageOptions: IQueryPageOptions }>()
);

export const queryResultsSuccess = createAction(
  '[Agent][Bookmarks] query results Success',
  props<{ consultants: IConsultantSearchedSelectable[], queryPageOptions: IQueryPageOptions }>()
);

export const queryResultsError = createAction(
  '[Agent][Bookmarks] query results Error'
);

export const clearResults = createAction(
  '[Agent][Bookmarks] clear results'
);

export const queryBookmarkedCount = createAction(
  '[Agent][Bookmarks] query bookmarked count'
);

export const queryBookmarkedCountSuccess = createAction(
  '[Agent][Bookmarks] query bookmarked count Success',
  props<{ count: number }>()
);

export const queryBookmarkedCountError = createAction(
  '[Agent][Bookmarks] query bookmarked count Error'
);

export const toggleConsultantSelection = createAction(
  '[Agent][Bookmarks] toggle consultant selection',
  props<{ consultantId: string, selected: boolean }>()
);

export const clearSelectedConsultats = createAction(
  '[Agent][Bookmarks] clear selected consultants'
);

export const updatePaginator = createAction(
  '[Agent][Bookmarks] update paginator',
  props<{ paginatorEvent: PageEvent }>()
);

export const setRestore = createAction(
  '[Agent][Bookmarks] set restore',
  props<{ shouldRestore: boolean, scrollY: number }>()
);

export const setRestoreQueryParams = createAction(
  '[Agent][Bookmarks] set restore query params',
  props<{ queryParams: Params }>()
);

export const resetUnbookmarkedCount = createAction(
  '[Agent][Bookmarks] reset unbookmarked count'
);

// single actions

export const bookmarkConsultant = createAction(
  '[Agent][Bookmarks] bookmark consultant',
  props<{ consultant: IConsultantSearchedSelectable | IConsultantDetails }>()
);

export const bookmarkConsultantSuccess = createAction(
  '[Agent][Bookmarks] bookmark consultant Success',
  props<{ consultant: IConsultantSearchedSelectable | IConsultantDetails }>()
);

export const bookmarkConsultantError = createAction(
  '[Agent][Bookmarks] bookmark consultant Error',
  props<{ consultant: IConsultantSearchedSelectable | IConsultantDetails }>()
);

export const unbookmarkConsultant = createAction(
  '[Agent][Bookmarks] unbookmark consultant',
  props<{ consultantId: string }>()
);

export const unbookmarkConsultantSuccess = createAction(
  '[Agent][Bookmarks] unbookmark consultant Success',
  props<{ consultantId: string }>()
);

export const unbookmarkConsultantError = createAction(
  '[Agent][Bookmarks] unbookmark consultant Error',
  props<{ consultantId: string }>()
);
