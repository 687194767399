<div class="notification-container" [ngClass]="{'hovered': isHovered}">
  <a *ngIf="!isOtherType" [routerLink]="[urlBase]" [queryParams]="urlParams" (click)="handleClick($event)" title="Check details" class="notification-check-details">
    <span class="notification-title typography-paragraph font-bold">{{notification.Title | truncate: 40}}</span>
    <span class="notification-headline typography-paragraph">{{notification.Headline | truncate: 70:true}}</span>
    <span class="notification-post-date">
      <span title="{{notification.PostDate | amTimeAgo}}">{{notification.PostDate | amTimeAgo}}</span>
      <span *ngIf="notification.Reason && notification.Reason != 'Published'"> - {{notification.Reason}}</span>
    </span>
  </a>

  <a *ngIf="isOtherType" [attr.href]="notification.Payload" target="_blank" (click)="handleClick($event)" title="Check details" class="notification-check-details">
    <span class="notification-title typography-paragraph font-bold">{{notification.Title | truncate: 40:true}}</span>
    <span class="notification-headline typography-paragraph">{{notification.Headline | truncate: 70}}</span>
    <span class="notification-post-date">
      <span title="{{notification.PostDate | amTimeAgo}}">{{notification.PostDate | amTimeAgo}}</span>
    </span>
  </a>
</div>
