import { Component, ChangeDetectionStrategy } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { MediaQueryService } from '@my7n/ui';
import { INavigationItem } from '../../../interfaces/navigation-item';
import { NavigationService } from '../../../services/navigation.service';

@Component({
  selector: 'navigation-content',
  templateUrl: './navigation-content.component.html',
  styleUrls: ['./navigation-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationContentComponent {
  navContent$: Observable<[Array<INavigationItem>, string]> = combineLatest([
    this.navService.primaryNavigation$.pipe(
      // only items that are not hidden
      filter((navItems: Array<INavigationItem>) => navItems !== null),
      map((navItems: Array<INavigationItem>) => {
        return navItems.filter(
          (navItem: INavigationItem) => navItem.hidden !== true
        );
      })
    ),
    this.navService.activeNavigationItem$.pipe(
      map((item: INavigationItem) => item?.link)
    )
  ]).pipe(distinctUntilChanged());

  isVertical$ = this.mediaQuery.breakpoints$.pipe(map(({ gtMd }) => gtMd));

  constructor(
    private navService: NavigationService,
    private mediaQuery: MediaQueryService
  ) {}

  navSelected() {
    this.navService.updateNavigationDropdownVisibility(false);
  }
}
