<ng-container *ngIf="(mediaQueryService.breakpoints$ | async) as breakpoints">
  <div class="nav-container mat-toolbar-row-padding flex flex-between-center w-full h-full">
    <div>
      <button class="max-md:hidden"
              mat-button
              color="primary"
              routerLink="/"
              [queryParams]="queryParams || null"
              [ngClass]="{ 'small-button': breakpoints.ltMd }">
        Go to home
      </button>
      <button class="md:hidden"
              mat-icon-button
              color="primary"
              routerLink="/"
              [queryParams]="queryParams || null"
              [ngClass]="{'small-button': breakpoints.ltMd }">
              <mat-icon svgIcon="arrow_back" color="primary"></mat-icon>
      </button>
    </div>
  </div>
</ng-container>
