import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of } from 'rxjs';
import { AuthorizationService } from './authorization.service';
import { IBookmarkedConsultantsCount } from '../interfaces/bookmarked-consultants-count';
import { INavigationItem } from '../interfaces/navigation-item';
import { distinctUntilChanged, filter, map, share, shareReplay, switchMap } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import {
  BenefitsFeatures,
  ReferralsFeatures,
  BillingFeatures,
  CvFeatures,
  EventsFeatures,
  TimelineFeatures,
  EvaluationsFeatures,
  ConsultantsFeatures,
  ConsultantGuideFeatures,
  LearningAndDevFeatures
} from '@my7n/features';
import { AppConfigService } from '../services/app-config.service';

@Injectable()
export class NavigationService {

  readonly DISABLED_MODULES_CFG_KEY = 'disabledModules';

  bookmarkedConsultantsCount: BehaviorSubject<IBookmarkedConsultantsCount> = new BehaviorSubject({
    initialized: false,
    count: 0
  });

  subscribeBookmarked: any;

  private readonly _basePrimaryNavigation: Array<INavigationItem> = [
    {
      title: 'Home',
      description: 'Check out upcoming events and 7N news.',
      link: '/',
      restrictedTo: {
        features: [TimelineFeatures.Default]
      },
      activeForRoutePrefix: ['/news']
    },
    {
      title: 'CVs',
      description: 'Create or update your 7N CV to ensure best matching with open projects.',
      link: '/cv',
      restrictedTo: {
        features: [CvFeatures.Default]
      }
    },
    {
      title: 'CV Search',
      description: 'Find candidates best-suited to our customers requests.',
      link: '/agent',
      restrictedTo: {
        features: [ConsultantsFeatures.Default]
      }
    },
    {
      title: 'Go to Matching',
      description: 'Match job requests to consultants, consultants to job requests or consultants to consultants.',
      link: 'https://agenthub.7n.com/matching',
      restrictedTo: {
        features: [ConsultantsFeatures.Default]
      },
      external: true // external link, open in a new tab
    },
    {
      title: 'Evaluations',
      description: 'Deliver feedback to the consultants.',
      link: '/evaluations',
      restrictedTo: {
        features: [EvaluationsFeatures.Default]
      }
    },
    {
      title: 'Consultant Guide',
      description:
        'Check everything you need to know as a 7N Consultant.',
      link: '/consultant-guide',
      restrictedTo: {
        features: [ConsultantGuideFeatures.Default]
      }
    },
    {
      title: 'Time Reporting',
      description: 'Report your time and expenses.',
      link: '/time-reporting',
      restrictedTo: {
        features: [BillingFeatures.Default]
      }
    },
    {
      title: 'Learning & Development',
      description: 'Upgrade your skills and develop your career - all in one place.',
      link: '/learning-and-dev',
      restrictedTo: {
        features: [LearningAndDevFeatures.Default]
      }
    },
    {
      title: 'Events',
      description: 'Register for upcoming meetups, workshops and social events.',
      link: '/events',
      restrictedTo: {
        features: [EventsFeatures.Default]
      }
    },
    {
      title: 'Benefits',
      description: 'Sign up for life insurance, healthcare, Multisport and others.',
      link: '/benefits',
      restrictedTo: {
        features: [BenefitsFeatures.Default]
      }
    },
    {
      title: 'Referrals',
      description: 'Get a bonus by recommending to us your top 3% colleagues.',
      link: '/referrals',
      restrictedTo: {
        features: [ReferralsFeatures.Default]
      }
    },
    {
      title: 'Profile Details',
      link: '/profile/details',
      hidden: true // should not be visible inside navigation items (only as a title inside the navbar)
    },
    {
      title: 'Welcome to my7N',
      link: '/welcome',
      hidden: true
    },
    {
      title: 'Terms and Conditions',
      link: '/agreement',
      hidden: true
    }
  ];

  get basePrimaryNavigation(): Array<INavigationItem> {
    return this._basePrimaryNavigation;
  }

  primaryNavigation$: Observable<Array<INavigationItem>>;

  //
  // @TODO secondaryNavigation, this requires some logic addition or change because feedback dialog is show with (click)
  // but we use it only once so benefit of moving it here gets a little bit lost
  //
  // readonly secondaryNavigation: Array<INavigationItem> = [
  //
  // ];
  //

  activeNavigationItem$: Observable<INavigationItem>;

  // can be used to show/hide the navigation dropdown
  private _navDropdownVisible$ = new BehaviorSubject<boolean>(false);

  constructor(private authorizationService: AuthorizationService,
              private router: Router) {

    this.primaryNavigation$ = of(this.basePrimaryNavigation).pipe(
      switchMap((navItemList: Array<INavigationItem>) => {
        // Wait for all checks to complete
        // map all navItems to 'canSome' checks
        return forkJoin(navItemList.filter(NavigationService.noNullValuesFilter).map((navItem) => {
          if (navItem.restrictedTo && navItem.restrictedTo.features) {
            return this.authorizationService.canSome(navItem.restrictedTo.features).pipe(
              // forkJoin needs to get at least one value from inner observables to emit value at the end
              // so instead of filtering here, null will be provided and later filtered out
              map(accessGranted => accessGranted ? navItem : null)
            );
          }

          return of(navItem);
        }));
      }),
      // Filter out null items to remove navigation items that should not be visible
      map(items => items.filter(NavigationService.noNullValuesFilter)),
      // Filter out navigation items for disabled modules specified in app-config disabledModules property
      map(items => items.filter(this.disabledModuleFilter, this)),
      // No need to repeat this all the time
      share()
    );

    this.activeNavigationItem$ = combineLatest([
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd)
      ),
      this.primaryNavigation$,
    ]).pipe(
      map(([event, navItemsList]) => {
        const navigationEndEvent: NavigationEnd = (event as NavigationEnd);
        const startsWithFn = String.prototype.startsWith.bind(navigationEndEvent.urlAfterRedirects);

        // First find the root navigation index if it exists in array
        const rootPathIndex: number = navItemsList.findIndex((item) => {
          return item.link === '/';
        });

        const rootPathItem: INavigationItem = rootPathIndex > -1 ? navItemsList[rootPathIndex] : null;

        // First we check if after redirects the url is pointing to root
        // saves some checks later on
        if (navigationEndEvent.urlAfterRedirects === '/' && rootPathIndex > -1) {
          return navItemsList[rootPathIndex];
        }

        // Find index of matching element if it exists in
        const matchingPathIndex = navItemsList.findIndex((item, index) => {
          // We skip this for now because it will be always the root of path
          if (rootPathIndex > -1 && index === rootPathIndex) {
            return false;
          }

          // Check if the current url starts with the link from navigation...
          return navigationEndEvent.urlAfterRedirects.startsWith(item.link)
            // otherwise checks if it's among prefixes
            || (item.activeForRoutePrefix && item.activeForRoutePrefix.some(startsWithFn));
        });

        // If we found a match return it, otherwise check the root if it's matching
        if (matchingPathIndex > -1) {
          return navItemsList[matchingPathIndex];
        }

        // If nothing was found we are going back to root element to check if something matches there
        return !rootPathItem ? null : (
          // If current route is among active prefixes for root navigation item
          rootPathItem.activeForRoutePrefix && rootPathItem.activeForRoutePrefix.some(startsWithFn) ? rootPathItem : null
        );
      }
    ),
    distinctUntilChanged(),
    shareReplay(1)
    );
  }


  get bookmarkedConsultantsCount$(): Observable<IBookmarkedConsultantsCount> {
    return this.bookmarkedConsultantsCount.asObservable();
  }

  static noNullValuesFilter(item: any) {
    return item !== null;
  }

  disabledModuleFilter(item: INavigationItem) {
    return !(AppConfigService[this.DISABLED_MODULES_CFG_KEY].some((disabledModule) => disabledModule === item.link.replace(/^\//, '')));
  }

  get navDropdownVisible$(): Observable<boolean> {
    return this._navDropdownVisible$.asObservable();
  }

  updateNavigationDropdownVisibility(isVisible: boolean) {
    this._navDropdownVisible$.next(isVisible);
  }
}
