import { Action, createReducer, on } from '@ngrx/store';
import * as CvProfileStrengthActions from '../../actions/cv-profile-strength.actions';
import { IProfileStrength } from '../../../interfaces/profile-strength';

export interface CvProfileStrengthState {
  profileStrength: IProfileStrength;
  minimumRequirementMet: boolean;
}

export const initialState: CvProfileStrengthState = {
  profileStrength: null,
  minimumRequirementMet: null
};

const reducer = createReducer(
  initialState,
  on(CvProfileStrengthActions.queryProfileStrengthSuccess, (state, { profileStrength }) => ({
    ...state,
    profileStrength,
    minimumRequirementMet: minimumRequirementMet(profileStrength)
  })),
  on(CvProfileStrengthActions.clearProfileStrength, (state) => ({
    ...state,
    profileStrength: null,
    minimumRequirementMet: null
  }))
);

export function cvProfileStrenghtReducer(
  state: CvProfileStrengthState | undefined,
  action: Action
) {
  return reducer(state, action);
}

export const getProfileStrength = (state: CvProfileStrengthState) => {
  return state.profileStrength;
};

export const getMinimumRequirementMet = (state: CvProfileStrengthState) => {
  return state.minimumRequirementMet;
};

function minimumRequirementMet(profileStrength: IProfileStrength): boolean {
  return !Object.keys(profileStrength).some(key => {
    const value = profileStrength[key];
    return value.IsDone === false && value.IsMandatory === true;
  });
}
