import * as fromTimelineNews from './news/timeline-news.reducer';
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';


export const timelineFeatureKey = 'timelineModule';

export interface TimelineModuleState {
  news: fromTimelineNews.TimelineNewsState;
}

export const reducers: ActionReducerMap<TimelineModuleState> = {
  news: fromTimelineNews.timelineNewsReducer
};

export const getTimelineModuleState = createFeatureSelector<TimelineModuleState>(
  timelineFeatureKey
);
