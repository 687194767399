import { IProfileStrength } from './profile-strength';
import { IIndustryKnowledge } from './industry-knowledge';
import { IWorkRole } from './work-role';
import { ICompetenceAreas } from './competence-areas';
import { ICvSkill } from './cv-skill';
import { ICareerPosition } from './career-position';
import { IEducation } from './education';
import { IAdditionalInformation } from './additional-information';

export interface ICv {
  Id?: number;
  CrmContactId?: string;
  CvName: string;
  ConsultantId: string;
  FirstName?: string;
  MiddleName?: string | null;
  LastName?: string;
  ProfileSummary?: string;
  /**
   * @property Title Consultant's job title
   */
  Title?: string;
  Portrait: string | null;
  PortraitRefreshToken?: string;
  Language?: string;
  Birthday?: string | null;
  AvailableFrom?: string | null;
  ExperienceSince?: string | null;
  IndustryFocus?: Array<IIndustryKnowledge>;
  Education: Array<IEducation>;
  WorkRoles?: Array<IWorkRole>;
  ApplicationTypesBusinessKnowledges: Array<IAdditionalInformation>;
  NetworksAndCommunities: Array<IAdditionalInformation>;
  Awards?: Array<IAdditionalInformation>;
  CompetenceAreas?: Array<ICompetenceAreas>;
  Career?: Array<ICareerPosition>;
  Skills?: Array<ICvSkill>;
  LanguageSkills?: Array<ICvSkill>;
  CreateDate?: string;
  Progress?: number;
  IsSent?: boolean;
  IsPrimary?: boolean;
  IsParsed?: boolean;
  Status?: string;
  Strength?: IProfileStrength;
  LastModifiedDate?: string;
  CrmLink?: string;
}

export const ICV_MAX_LENGTHS = {
  CvName: 50
};

export const CV_DUPLICATE_SUFFIX = ' (copy)';

export interface ICvParseIdResponse {
  CvId: number;
}

