import { Component, ElementRef, OnDestroy, OnInit, ViewChild, Inject } from '@angular/core';
import { GalleryLightboxOverlayRef } from './gallery-lightbox-overlay-ref';
// Interfaces
import { GALLERY_LIGHTBOX_DATA } from './galery-lightbox-overlay.tokens';
import { IGalleryLightboxData } from '../../../../interfaces/gallery-lightbox-data';
import { NewsAttachmentTypes } from '../../../../interfaces/news-attachment';
import { MediaQueryService } from '@my7n/ui';

@Component({
  selector: 'gallery-lightbox',
  templateUrl: './gallery-lightbox.component.html',
  styleUrls: ['./gallery-lightbox.component.scss']
})
export class GalleryLightboxComponent implements OnInit, OnDestroy {
  @ViewChild('galleryLightbox', { static: false })
  set galleryLightboxRef(element: ElementRef) {
    if (element) {
      this.galleryLightbox = element;
      this.addSwipeListener();
    }
  }

  galleryLightbox: ElementRef;

  limitTo: number;
  hideNext: boolean;

  private _currentIndex: number;
  get currentIndex(): number {
    return this._currentIndex;
  }
  set currentIndex(indexNo: number) {
    if (this._currentIndex !== indexNo) {
      this._currentIndex = indexNo;
      this.setHideNext();
    }
  }

  get ATTACHMENT_TYPES() {
    return NewsAttachmentTypes;
  }

  private keys = {
    escape: 'Escape',
    arrowLeft: 'ArrowLeft',
    arrowRight: 'ArrowRight'
  };

  /**
   * window.keydown listener function.
   */
  keydownListener = (event) => this.galleryKeyboard(event);

  listenerOptions;
  private swipeCoord?: [number, number];
  private swipeTime?: number;

  constructor(
    public dialogRef: GalleryLightboxOverlayRef,
    @Inject(GALLERY_LIGHTBOX_DATA) public galleryData: IGalleryLightboxData,
    public mediaQueryService: MediaQueryService
  ) { }

  ngOnInit() {
    this.currentIndex = this.galleryData.index || 0;
    this.setLimitTo();

    // Safely detecting option support
    // @see https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener
    this.listenerOptions = Object.defineProperty({}, 'passive', {
      get: function() {
        return true;
      }
    });
    window.addEventListener('keydown', this.keydownListener, this.listenerOptions);
  }

  addSwipeListener() {
    this.galleryLightbox.nativeElement.addEventListener('touchstart', (event) => {
      this.swipe(event, 'start');
    }, this.listenerOptions);

    this.galleryLightbox.nativeElement.addEventListener('touchend', (event) => {
      this.swipe(event, 'end');
    }, this.listenerOptions);
  }

  private setLimitTo() {
    let limit: number;

    if (this.galleryData.galleryConfig.limitToOneRow) {
      if (this.galleryData.attachments.length > this.galleryData.galleryConfig.rowItems) {
        limit = this.galleryData.galleryConfig.rowItems;
      } else {
        limit = this.galleryData.attachments.length;
      }
    } else {
      limit = Infinity;
    }

    this.limitTo = limit;
  }

  private setHideNext() {
    this.hideNext = !this.canDisplayNext();
  }

  galleryKeyboard(event) {
    switch (event.key) {
      case this.keys.arrowLeft:
        if (this.currentIndex === 0) {
          return false;
        }
        this.currentIndex--;
        break;

      case this.keys.arrowRight:
        if (!this.canDisplayNext()) {
          return;
        }
        this.currentIndex++;
        break;

      case this.keys.escape:
        this.dialogRef.close();
        break;
    }
  }

  canDisplayNext(): boolean {
    if (this.galleryData.galleryConfig.limitToOneRow) {
      const lastIndex = Math.min(this.galleryData.attachments.length, this.galleryData.galleryConfig.rowItems) - 1;
      if (this.currentIndex === lastIndex) {
        return false;
      }
    } else if (this.currentIndex === (this.galleryData.attachments.length - 1)) {
      return false;
    }
    return true;
  }

  next() {
    if (!this.canDisplayNext()) {
      return;
    }
    this.currentIndex++;
  }

  prev() {
    if (this.currentIndex === 0) {
      return false;
    }
    this.currentIndex--;
  }

  swipe(e: TouchEvent, when: string): void {
    const coord: [number, number] = [e.changedTouches[0].pageX, e.changedTouches[0].pageY];
    const time = new Date().getTime();

    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else if (when === 'end') {
      const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
      const duration = time - this.swipeTime;

      if (duration < 1000
        && Math.abs(direction[0]) > 30
        && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) { // Horizontal enough
        if (direction[0] < 0) {
          this.next();
        } else {
          this.prev();
        }
      }
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    window.removeEventListener('keydown', this.keydownListener, this.listenerOptions);
  }
}
