import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ICheckboxCategoryTreeItem } from '../../../interfaces/checkbox-category-tree-item';
import { UntypedFormGroup, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { Icons } from '@my7n/ui';

interface HashOfFormArrays {
  checkbox: UntypedFormControl;
  subcategories: UntypedFormArray;
}

@Component({
  selector: 'checkbox-category',
  templateUrl: './checkbox-category.component.html',
  styleUrls: ['./checkbox-category.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxCategoryComponent implements OnInit, OnDestroy {
  @Input() branch: HashOfFormArrays;
  @Input() data: ICheckboxCategoryTreeItem;

  @Input() name: string;
  @Input() instance: string;

  @Input() parentForm: UntypedFormGroup;
  @Input() index: number;

  @Input() isFlat: boolean;
  @Input() isColumnBreakBefore: boolean;

  showSubcategories = false;

  icon = Icons.ARROW_DOWN;

  private changeSubscription = null;

  constructor() {}

  ngOnInit() {
    if (!this.instance) {
      this.instance = '';
    }

    if (!this.changeSubscription) {
      this.onChangeSubcategories();
    }
  }

  ngOnDestroy() {
    if (this.changeSubscription) {
      this.changeSubscription.unsubscribe();
      this.changeSubscription = null;
    }
  }

  /**
   * Opens / closes subcategories after click
   */
  toggleSubcategories() {
    if (this.branch.subcategories) {
      this.showSubcategories = !this.showSubcategories;
    }
  }

  /**
   * Triggered when value of checkbox with subcategies is changed
   */
  onCheckboxValueChange() {
    this.toggleAllSubcategories();
  }

  /**
   * Forces all subcategories to be the same value as the parent one
   */
  private toggleAllSubcategories() {
    const valueToPropagate = this.branch.checkbox.value,
      subcategories = this.branch.subcategories;

    if (subcategories && subcategories.controls.length > 0) {
      const subcatControls = subcategories.controls;

      subcatControls.forEach(control => {
        control.patchValue(
          { checkbox: valueToPropagate },
          {
            onlySelf: false,
            emitEvent: false
          }
        );
        // @TODO not sure if this will work for more than 1 level deep
      });

      subcategories.updateValueAndValidity();
    }

    // Open branch if value is set to true
    if (valueToPropagate) {
      this.showSubcategories = true;
    }
  }

  /**
   * Marks parent checkbox as checked when any of subcategories is set to true
   * Unchecks if all are false
   */
  private onChangeSubcategories() {
    const subcategories = this.branch.subcategories;
    const branchParentFormGroup: UntypedFormGroup = <UntypedFormGroup>(
      this.branch.checkbox.parent
    );

    if (subcategories && subcategories.controls.length > 0) {
      this.changeSubscription = subcategories.valueChanges.subscribe(value => {
        // If there is any true value among checkboxes, dont set parent checkbox to false
        let parentValue = false;

        value.forEach(field => {
          parentValue = parentValue || field.checkbox;
        });

        branchParentFormGroup.patchValue(
          { checkbox: parentValue },
          {
            onlySelf: true,
            emitEvent: false
          }
        );

        branchParentFormGroup.updateValueAndValidity();
      });
    }
  }

  idAttributeGenerate(checkbox) {
    return [this.name, checkbox.Name, checkbox.Id, 'instance', this.instance]
      .map(part => {
        return (part + '' || '').replace(/[\s|,|\.\\\/|-]/g, '').toLowerCase();
      })
      .join('-');
  }

  isLeaf(): boolean {
    return !!(
      this.branch &&
      this.branch.subcategories &&
      this.branch.subcategories.controls.length > 0
    );
  }
}
