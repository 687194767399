import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, take, switchMap, filter } from 'rxjs';
import { GlobalCommonFacadeService } from '../services/facades/global-common-facade.service';

const PORTRAITS_CONSULTANTS_URL_REGEXP = /\/(consultants|consultants-backup)\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\/portrait/;
const PORTRAITS_STAFF_URL_REGEXP = /\/(staff|staff-backup|staff-members)\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\/portrait/;

@Injectable()
export class SasPortraitsHttpInterceptor implements HttpInterceptor {

  constructor(private globalCommonFacadeService: GlobalCommonFacadeService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let baseUrl = '';
    let modifiedUrl = '';
    const isConsultantPortraitUrl = req.url.match(PORTRAITS_CONSULTANTS_URL_REGEXP);
    const isStaffPortraitUrl = req.url.match(PORTRAITS_STAFF_URL_REGEXP);

    if (isConsultantPortraitUrl || isStaffPortraitUrl) {
      baseUrl = req.url.split('?')[0];

      return this.globalCommonFacadeService.blobSasTokens$.pipe(filter(token => !!token), take(1), switchMap( token => {
        modifiedUrl = `${baseUrl}?${isConsultantPortraitUrl ? token.ConsultantContainerSas : token.StaffContainerSas}`;
        req = req.clone({ url: modifiedUrl });

        return next.handle(req);
      }));
    }

    return next.handle(req);
  }
}
