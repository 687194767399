import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'login-required-error',
  templateUrl: './login-required-error.component.html',
  styleUrls: ['./login-required-error.component.scss']
})
export class LoginRequiredErrorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
