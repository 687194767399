import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { GalleryComponent } from '../gallery.component';
// Components
import { GalleryLightboxOverlayRef } from '../gallery-lightbox/gallery-lightbox-overlay-ref';
// Services
import { GalleryLightboxOverlayService } from '../gallery-lightbox/gallery-lightbox-overlay.service';
@Component({
  selector: 'gallery-thumb',
  templateUrl: './gallery-thumb.component.html',
  styleUrls: ['./gallery-thumb.component.scss']
})
export class GalleryThumbComponent implements OnInit, AfterViewInit {
  @Input() gallery: GalleryComponent;
  @ViewChild('thumbContainer', { static: true }) thumbContainer: ElementRef;

  limitTo: number;

  thumbHeight: number;

  constructor(
    private galleryLightboxOverlayService: GalleryLightboxOverlayService
  ) {}

  ngOnInit() {
    this.setLimitTo();
  }

  ngAfterViewInit() {
    // timeout is necessary because we need to display square and element width is responsive
    setTimeout(() => this.resizeContent(), 0);
    window.addEventListener('resize', () => this.resizeContent());
  }

  showPreview(index) {
    const dialogRef: GalleryLightboxOverlayRef = this.galleryLightboxOverlayService.open(
      {
        galleryData: {
          galleryConfig: this.gallery.config,
          attachments: this.gallery.items,
          index: index
        }
      }
    );
  }

  private setLimitTo() {
    let limit: number;

    if (this.gallery.config.limitToOneRow) {
      if (this.gallery.items.length > this.gallery.config.rowItems) {
        limit = this.gallery.config.rowItems;
      } else {
        limit = this.gallery.items.length;
      }
    } else {
      limit = Infinity;
    }

    this.limitTo = limit;
  }

  resizeContent() {
    // we want thumb to be a square so if width is changing height is also updated
    this.thumbHeight = this.thumbContainer.nativeElement.children[0].offsetWidth;
  }
}
