<ng-container *ngIf="(mediaQueryService.breakpoints$ | async) as breakpoints">
  <div class="gallery-lightbox-container flex flex-around-stretch">
    <button type="button"
            class="show-prev-btn"
            (click)="prev()"
            aria-label="Previous"
            [ngClass]="{ invisible: currentIndex === 0, mobile: breakpoints.ltMd }">
      <mat-icon svgIcon="arrow_down" class="rotate-90"></mat-icon>
    </button>
    <div #galleryLightbox class="gallery-lightbox flex-full">
      <ng-container *ngFor="let item of galleryData.attachments | slice:0:limitTo; index as i; trackById">
        <ng-container *ngIf="item.Type !== ATTACHMENT_TYPES.IMG_SECURED">
          <gallery-lightbox-item [lightboxItem]="item" *ngIf="currentIndex === i"></gallery-lightbox-item>
        </ng-container>
        <ng-container *ngIf="item.Type === ATTACHMENT_TYPES.IMG_SECURED">
          <gallery-lightbox-secure-item [lightboxItem]="item" *ngIf="currentIndex === i"></gallery-lightbox-secure-item>
        </ng-container>
      </ng-container>
    </div>
    <button type="button"
            class="show-next-btn"
            (click)="next()"
            aria-label="Next"
            [ngClass]="{ invisible: hideNext, mobile: breakpoints.ltMd }">
      <mat-icon svgIcon="arrow_down" class="rotate-270"></mat-icon>
    </button>
  </div>
  <button mat-button
          type="button"
          isIconButton="true"
          (click)="closeDialog()"
          class="close-btn">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</ng-container>
