import { Component } from '@angular/core';
import { Params } from '@angular/router';
import { MediaQueryService } from '@my7n/ui';

@Component({
  selector: 'news-view-submenu',
  templateUrl: './news-view-submenu.component.html'
})
export class NewsViewSubmenuComponent {
  constructor(public mediaQueryService: MediaQueryService) {}

  get queryParams(): Params {
    return null;
  }
}
