import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'removeByTags'
})
export class RemoveByTagsPipe implements PipeTransform {

  transform(text: string, tagsToRemove: Array<string>): string {
    let node;

    if (text && tagsToRemove.length > 0) {
      node = document.createElement('div');
      node.innerHTML = text;

      tagsToRemove.forEach(tag => {
        const elements = node.getElementsByTagName(tag);

        for (let index = elements.length - 1; index >= 0; index--) {
          elements[index].parentNode.removeChild(elements[index]);
        }
      });
    }

    return node.innerHTML;
  }
}
