<div #subNavbarContainer>
  <sub-navbar></sub-navbar>
</div>
<div class="cms-item-view flex flex-col flex-start-stretch flex-full"
     [ngClass]="{'with-banner': details.headerImageUrl }">
  <layout-container [mode]="LayoutContainerModes.fullWidth">
    <div class="cms-item-view-container flex flex-col flex-full">
      <div class="cms-item-view-header flex flex-start-stretch flex-auto"
           *ngIf="details.headerImageUrl"
           [ngStyle]="{'background-image': 'url(' + details.headerImageUrl + ')'}">
      </div>
      <div class="cms-item-view-content flex flex-col flex-[0_1_100%]">
        <mat-card appearance="outlined" class="gradient-box-shadow flex flex-col flex-initial">
          <mat-card-title>
            <div class="cms-item-view-tags-container"
                 *ngIf="hasTags"
                 data-test-id="cms-item-view-tags-container">
              <div class="tag pill"
                   *ngFor="let tag of details.tags; index as i"
                    [attr.color]="'accent'"
                    [attr.data-test-id]="'cms-item-view-tag-' + i">
                    {{ tag }}
              </div>
            </div>
            <ng-container *ngIf="details.title">
              <h2 class="news-title max-md:hidden">{{details.title}}</h2>
              <h3 class="news-title md:hidden">{{details.title}}</h3>
            </ng-container>
          </mat-card-title>
          <mat-card-content>
            <ng-content></ng-content>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </layout-container>
</div>
