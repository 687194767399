import { Inject, Injectable } from '@angular/core';
import { MY7N_ENV_CONFIG } from '../functions/my7n-env-config';
import { IMy7nEnvConfig, ServiceNames } from '../interfaces/my7n-env-config';
import { OpenIdConfiguration } from 'angular-auth-oidc-client';
import { GlobalAppConfigFacadeService } from './facades/global-app-config-facade.service';
import { IConfigApiUrls } from '../store/reducers/global/global-app-config.reducer';

export const OAUTH_CONFIG_ID = 'my7n_ouath_config';

@Injectable()
export class AppConfigService {
  constructor(
    @Inject(MY7N_ENV_CONFIG) private envConfig: IMy7nEnvConfig,
    private globalAppConfigFacadeService: GlobalAppConfigFacadeService
  ) {
    let configUrls: IConfigApiUrls = {
      'timekeys': this.serviceUrl(ServiceNames.Cv, 'v1') + 'cv/dictionaries/timekeys',
      'userPreferences': this.serviceUrl(ServiceNames.User, 'v1') + 'user',
      'languages': this.serviceUrl(ServiceNames.Cv, 'v1') + 'cv/dictionaries/languages',
      'accessFeatures': this.serviceUrl(ServiceNames.Identity, 'v1') + 'identity/allowed-features',
      'terms': this.serviceUrl(ServiceNames.Gdpr, 'v1') + 'gdpr/consent/terms',
    }

    this.globalAppConfigFacadeService.updateConfigApiUrls(configUrls);
  }

  public static readonly DEFAULT_DATE_OUTPUT_FORMATS = {
    'second': 'DD MMM YYYY, HH:mm:ss',
    'minute': 'DD MMM YYYY, HH:mm',
    'day': 'DD MMM YYYY',
    'month': 'MMM YYYY',
    'year': 'YYYY',
    'time': 'HH:mm',
    'short_month': 'MMM',
    'short_day_of_week': 'ddd',
    'two_digits_day': 'DD'
  };

  public static readonly imagesPath: string = 'assets/images';

  public static readonly disabledModules: string[] = [];

  serviceUrl(serviceName: ServiceNames = ServiceNames.Core, version: string = 'v2'): string {
    if (!this.envConfig.services[serviceName]) {
      console.error(`[AppConfig] service ${serviceName} is missing in configuration, empty string returned`);
      return '';
    }

    // Add trailing slash if it's missing
    let serviceUrl =  `${this.envConfig.services[serviceName].replace(/\/?$/, '/')}`;

    // optional API version
    if (version) {
      serviceUrl += version + '/';
    }

    return serviceUrl;
  }

  get oauthConfig(): OpenIdConfiguration {
    const config: OpenIdConfiguration = {
      ...this.envConfig.auth, // from my7n-env-config file
      configId: OAUTH_CONFIG_ID,
      responseType: 'code',
      silentRenew: true,
      useRefreshToken: true,
      ignoreNonceAfterRefresh: true,
      historyCleanupOff: false,
      renewTimeBeforeTokenExpiresInSeconds: 100,
      maxIdTokenIatOffsetAllowedInSeconds: 600,
      issValidationOff: false,
      autoUserInfo: false,
      customParamsAuthRequest: {
        prompt: 'select_account' // login, consent
      },
      // routes where accessToken will be added
      secureRoutes: [
        this.envConfig.services[ServiceNames.Benefits],
        this.envConfig.services[ServiceNames.Billing],
        this.envConfig.services[ServiceNames.Cms],
        this.envConfig.services[ServiceNames.Consultant],
        this.envConfig.services[ServiceNames.Core],
        this.envConfig.services[ServiceNames.Cv],
        this.envConfig.services[ServiceNames.Evaluation],
        this.envConfig.services[ServiceNames.Events],
        this.envConfig.services[ServiceNames.File],
        this.envConfig.services[ServiceNames.Notification],
        this.envConfig.services[ServiceNames.InstantNotification],
        this.envConfig.services[ServiceNames.Referral],
        this.envConfig.services[ServiceNames.Identity],
        this.envConfig.services[ServiceNames.StaticContent],
        this.envConfig.services[ServiceNames.KeyValueStorage],
        this.envConfig.services[ServiceNames.User]
      ]
    };
    return config;
  }
}
