<form [formGroup]="editForm" 
      [ngClass]="{ loading: saving }" 
      (ngSubmit)="update(editForm)"
      novalidate
      data-test-id="edit-notification-frequency-form">
  <div class="header">
    <h4 class="font-bold">Notification preferences</h4>
  </div>
  <div class="form-group">
      <mat-label>Show me notifications</mat-label>
      <ng-select class="frequency-select"
                 placeholder="Frequency"
                 formControlName="frequency"
                 bindLabel="long"
                 bindValue="value"
                 [clearable]="false"
                 [items]="frequencyOptions"
                 data-test-id="edit-notification-frequency-select">
      </ng-select>
      <validation-messages [form]="editForm" 
                           [field]="editForm.get('frequency')" 
                           [messages]="['required']" 
                           [texts]="{'required':'Please select a frequency'}"
                           data-test-id="edit-notification-frequency-select-validationMessage"></validation-messages>
      <validation-messages [form]="editForm" 
                           [field]="editForm" 
                           [messages]="['serverError']"
                           data-test-id="edit-notification-frequency-select-serverError-validationMessage"></validation-messages>
  </div>
  <div class="form-actions">
    <button mat-stroked-button 
            color="primary" 
            type="submit" 
            class="save-button"
            [loading]="saving"
            data-test-id="edit-notification-frequency-save-button">
      {{(mediaQueryService.breakpoints$ | async).ltMd ? COMMON_BUTTONS_TEXTS.SAVE_CHANGES_MOBILE : COMMON_BUTTONS_TEXTS.SAVE_CHANGES_DESKTOP}}
    </button>
    <button mat-button 
            color="primary" 
            type="button" 
            class="cancel-button" 
            (click)="cancel()"
            data-test-id="edit-notification-frequency-discard-button">
      {{(mediaQueryService.breakpoints$ | async).ltMd ? COMMON_BUTTONS_TEXTS.DISCARD_CHANGES_MOBILE: COMMON_BUTTONS_TEXTS.DISCARD_CHANGES_DESKTOP}}
    </button>
  </div>
</form>
