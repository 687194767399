import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  IVideoPlayerConfig,
  LogVideoEvents,
  LogVideoModules,
  videoPlayerDefaultConfig,
  VideoProviders
} from '../../../interfaces/video-player';
import VimeoPlayer from '@vimeo/player';
import { VideoPlayerService } from '../../../services/video-player.service';
import { EmbedVideo } from '../../../utils/helpers/embed-video.helper';
import { generateInt } from '../../../utils/generate-utils';
import { RxState } from '@rx-angular/state';
import { select, selectSlice } from '@rx-angular/state/selections';
import { debounceTime, delay, distinctUntilChanged, filter } from 'rxjs';
import { concatLatestFrom } from '@ngrx/effects';
import { MY7N_ENV_CONFIG } from '../../../functions/my7n-env-config';
import { IMy7nEnvConfig } from '../../../interfaces/my7n-env-config';

export interface IVideoPlayerState {
  videoId: string;
  videoProvider: VideoProviders;
  videoPlayed: boolean;
  loading: boolean;
  module: LogVideoModules;
  videoUrl: string;
  config: IVideoPlayerConfig;
  title: string;
  articleId: string;
  embeddedHeaderVideoUrl: string;
  videoStateChanged: LogVideoEvents;
  iframeLoaded: boolean;
  ytPlayerApiReady: boolean;
}

export const initialState: IVideoPlayerState = {
  videoId: null,
  videoProvider: null,
  videoPlayed: false,
  loading: true,
  module: null,
  videoUrl: null,
  config: { ...videoPlayerDefaultConfig, autoplay: false },
  title: null,
  articleId: null,
  embeddedHeaderVideoUrl: null,
  videoStateChanged: null,
  iframeLoaded: false,
  ytPlayerApiReady: false
};

@Component({
  selector: 'my7n-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState]
})
export class VideoPlayerComponent implements OnInit, OnDestroy {
  state$ = this.state.select();
  playerState$ = this.state
    .select()
    .pipe(
      debounceTime(100),
      selectSlice([
        'videoId',
        'config',
        'videoUrl',
        'loading',
        'iframeLoaded',
        'embeddedHeaderVideoUrl',
        'ytPlayerApiReady',
        'videoProvider'
      ]),
      distinctUntilChanged()
    );

  @Output() videoEvent = this.state$.pipe(select('videoStateChanged'));

  @Input() set module(module: LogVideoModules) {
    this.state.set({ module });
  }

  @Input() set videoUrl(url: string) {
    this.state.set({ videoUrl: url });
  }

  @Input() set config(config: IVideoPlayerConfig) {
    this.state.set({ config });
  }

  @Input() set title(title: string) {
    this.state.set({ title });
  }

  private _articleId: string;

  @Input() set articleId(id: string | number) {
    this._articleId = id.toString();
  }

  get articleId(): string {
    return this._articleId;
  }

  get appUrl(): string {
    let url = this.envConfig.APPLICATION_URL;
    // yt player doesn't like trailing slash
    if (url.endsWith('/')) {
      url = url.slice(0, -1);
    }

    return url;
  }

  vimeoPlayer: VimeoPlayer;
  youtubePlayer: YT.Player;

  readonly playerId = `my7n-player-${Date.now()}-${generateInt(
    10000000,
    99999999
  )}`;

  constructor(
    private videoPlayerService: VideoPlayerService,
    private state: RxState<IVideoPlayerState>,
    @Inject(MY7N_ENV_CONFIG) private envConfig: IMy7nEnvConfig
  ) {
    this.state.set(initialState);
  }

  isPlayerReady(state: Partial<IVideoPlayerState>): boolean {
    if (state.videoProvider === VideoProviders.Youtube) {
      return (
        // for yt check if the player api is initialized
        state.ytPlayerApiReady && state.embeddedHeaderVideoUrl !== null
      );
    } else if (state.videoProvider === VideoProviders.Vimeo) {
      return state.embeddedHeaderVideoUrl !== null;
    }
    // we only support youtube and vimeo for now
    return false;
  }

  ngOnInit() {
    this.initLocalStateConnections();
    this.initLocalStateEffects();
  }

  initLocalStateConnections() {
    this.state.connect(
      'ytPlayerApiReady',
      this.videoPlayerService.ytApiLoaded$
    );
    const videoUrlState$ = this.state
      .select('videoUrl')
      .pipe(filter((videoUrl) => videoUrl !== null));
    this.state.connect(videoUrlState$, (state, videoUrl) => ({
      ...state,
      iframeLoaded: false,
      videoPlayed: false,
      videoStateChanged: null,
      videoProvider: EmbedVideo.extractVideoProvider(videoUrl),
      videoId: EmbedVideo.extractVideoId(
        videoUrl,
        EmbedVideo.extractVideoProvider(videoUrl)
      ),
      embeddedHeaderVideoUrl: this.getEmbeddedHeaderVideoUrl(videoUrl),
      loading: true
    }));
  }

  initLocalStateEffects() {
    const canCreatePlayer$ = this.state.select('iframeLoaded').pipe(
      concatLatestFrom(() => [this.state.select('videoProvider')]),
      filter(
        ([iframeLoaded, videoProvider]) =>
          iframeLoaded && videoProvider !== null
      ),
      delay(100)
    );

    this.state.hold(canCreatePlayer$, ([iframeLoaded, videoProvider]) => {
      if (videoProvider === VideoProviders.Youtube) {
        this.createYoutubePlayer();
      } else if (videoProvider === VideoProviders.Vimeo) {
        this.createVimeoPlayer();
      }
    });
  }

  ngOnDestroy() {
    if (this.youtubePlayer) {
      this.youtubePlayer.destroy();
    }

    if (this.vimeoPlayer) {
      this.vimeoPlayer.destroy();
    }
  }

  iframeLoaded() {
    this.state.set({ iframeLoaded: true });
  }

  private getEmbeddedHeaderVideoUrl(videoUrl: string): string {
    return (
      EmbedVideo.getEmbededUrl(videoUrl) +
      (EmbedVideo.extractVideoProvider(videoUrl) === VideoProviders.Youtube
        ? `&enablejsapi=1&origin=${this.appUrl}&playerapiid=ytplayer`
        : '')
    );
  }

  onVimeoPlayerPlay() {
    this.state.set({ loading: false });
    if (!this.state.get('videoPlayed')) {
      this.state.set({ videoPlayed: true });
      this.logVideoPlayedEvent();
      this.state.set({ videoStateChanged: LogVideoEvents.Played });
    }
  }

  private logVideoPlayedEvent() {
    this.videoPlayerService.logVideoEvent({
      module: this.module,
      event: LogVideoEvents.Played,
      videoId: this.state.get('videoId'),
      videoProvider: this.state.get('videoProvider'),
      title: this.title,
      articleId: this.articleId
    });
  }

  private createVimeoPlayer() {
    const iframe = document.getElementById(this.playerId);
    this.vimeoPlayer = new VimeoPlayer(iframe);

    this.vimeoPlayer.on('play', (event) => {
      if (!this.state.get('config', 'autoplay')) {
        this.onVimeoPlayerPlay();
      }
    });
    this.vimeoPlayer.on('ended', (event) => {
      this.state.set({ videoStateChanged: LogVideoEvents.Ended });
    });

    if (this.state.get('config', 'autoplay')) {
      this.vimeoPlayer.play(); // if autoplay, we need to play the video using API
      this.onVimeoPlayerPlay();
    } else {
      this.state.set({ loading: false });
    }
  }

  private createYoutubePlayer() {
    const iframe = document.getElementById(this.playerId);
    this.youtubePlayer = new YT.Player(iframe, {
      playerVars: {
        origin: this.appUrl,
        enablejsapi: 1
      },
      events: {
        onStateChange: this.onYtPlayerStateChange.bind(this),
        onReady: this.onPlayerReady.bind(this)
      }
    });
  }

  private onPlayerReady(event: YT.PlayerEvent) {
    if (this.state.get('config', 'autoplay')) {
      event.target.playVideo();
      this.ytVideoPlayed();
    } else {
      // if not autoplay, we need to hide the loading spinner
      this.state.set({ loading: false });
    }
  }

  private onYtPlayerStateChange(event: YT.OnStateChangeEvent) {
    if (event.data === YT.PlayerState.PLAYING) {
      if (!this.state.get('config', 'autoplay')) {
        this.ytVideoPlayed();
      }
    } else if (event.data === YT.PlayerState.ENDED) {
      this.state.set({ videoStateChanged: LogVideoEvents.Ended });
    }
  }

  private ytVideoPlayed() {
    this.state.set({ loading: false });
    if (!this.state.get('videoPlayed')) {
      this.state.set({ videoPlayed: true });
      this.logVideoPlayedEvent();
      this.state.set({ videoStateChanged: LogVideoEvents.Played });
    }
  }
}
