import { createAction, props } from '@ngrx/store';
import { IAddress } from '../../interfaces/address';
import { ILocation } from '../../interfaces/location';
import { INotificationSettings } from '../../interfaces/notification-settings';
import { IPhones } from '../../interfaces/phones';
import {
  IBusinessDetails, IConsultantsTenantDetails,
  IPersonalDetails,
  ITermsAndConditions
} from '../../interfaces/user-profile';

export const queryPersonalDetails = createAction(
  '[User Profile] query Personal Details'
);

export const personalDetailsLoaded = createAction(
  '[User Profile] Personal Details loaded',
  props<{ personalDetails: IPersonalDetails }>()
);

export const personalDetailsLoadedFromCache = createAction(
  '[User Profile] Personal Details loaded from cache'
);

export const queryPersonalDetailsError = createAction(
  '[User Profile] query Personal Details error'
);

export const queryBusinessDetails = createAction(
  '[User Profile] query Business Details'
);

export const businessDetailsLoaded = createAction(
  '[User Profile] Business Details loaded',
  props<{ businessDetails: IBusinessDetails }>()
);

export const businessDetailsLoadedFromCache = createAction(
  '[User Profile] Business Details loaded from cache'
);

export const queryBusinessDetailsError = createAction(
  '[User Profile] query Business Details error'
);

export const queryPreferredWorkLocations = createAction(
  '[User Profile] query Preferred Work Locations'
);

export const preferredWorkLocationsLoaded = createAction(
  '[User Profile] Preferred Work Locations loaded',
  props<{ preferredWorkLocations: Array<ILocation> }>()
);

export const preferredWorkLocationsLoadedFromCache = createAction(
  '[User Profile] Preferred Work Locations loaded from cache'
);

export const queryPreferredWorkLocationsError = createAction(
  '[User Profile] query Preferred Work Locations error'
);

export const queryTermsAndConditions = createAction(
  '[User Profile] query Terms And Conditions'
);

export const termsAndConditionsLoaded = createAction(
  '[User Profile] Terms And Conditions loaded',
  props<{ termsAndConditions: ITermsAndConditions }>()
);

export const termsAndConditionsLoadedFromCache = createAction(
  '[User Profile] Terms And Conditions loaded from cache'
);

export const queryTermsAndConditionsError = createAction(
  '[User Profile] query Terms And Conditions error'
);

export const queryNotificationSettings = createAction(
  '[User Profile] query Notification Settings'
);

export const notificationSettingsLoaded = createAction(
  '[User Profile] Notification Settings loaded',
  props<{ notificationSettings: INotificationSettings }>()
);

export const notificationSettingsLoadedFromCache = createAction(
  '[User Profile] Notification Settings loaded from cache'
);

export const queryNotificationSettingsError = createAction(
  '[User Profile] query Notification Settings error'
);

export const updatePersonalDetailsEmail = createAction(
  '[User Profile] update Personal Details Email',
  props<{ email: string }>()
);

export const updatePersonalDetailsPhones = createAction(
  '[User Profile] update Personal Details Phones',
  props<{ phones: IPhones }>()
);

export const updatePersonalDetailsAddress = createAction(
  '[User Profile] update Personal Details Address',
  props<{ address: IAddress }>()
);

export const updateBusinessDetailsCompanyName = createAction(
  '[User Profile] update Business Details Company Name',
  props<{ companyName: string }>()
);

export const updateBusinessDetailsCompanyWebsite = createAction(
  '[User Profile] update Business Details Company Website',
  props<{ companyWebsite: string }>()
);

export const updatePreferredWorkLocations = createAction(
  '[User Profile] update Preferred Locations',
  props<{ locations: Array<ILocation> }>()
);

export const updateNotificationSettingsSubscription = createAction(
  '[User Profile] update Notification Settings Subscription',
  props<{ wantToReceive: boolean }>()
);

export const updateNotificationSettingsSubscriptionSuccess = createAction(
  '[User Profile] update Notification Settings Subscription success'
);

export const updateNotificationSettingsSubscriptionError = createAction(
  '[User Profile] update Notification Settings Subscription error'
);

export const updateTermsEventInvitationSubscription = createAction(
  '[User Profile] update Terms Event Invitation Subscription',
  props<{ accepted: boolean }>()
);

export const updateTermsEventInvitationSubscriptionSuccess = createAction(
  '[User Profile] update Terms Event Invitation Subscription success'
);

export const updateTermsEventInvitationSubscriptionError = createAction(
  '[User Profile] update Terms Event Invitation Subscription error'
);

export const updateTermsNewsletterSubscription = createAction(
  '[User Profile] update Terms Newsletter Subscription',
  props<{ accepted: boolean }>()
);

export const updateTermsNewsletterSubscriptionSuccess = createAction(
  '[User Profile] update Terms Newsletter Subscription success'
);

export const updateTermsNewsletterSubscriptionError = createAction(
  '[User Profile] update Terms Newsletter Subscription error'
);

export const updateTermsStoringDataSubscription = createAction(
  '[User Profile] update Terms Storing Data Subscription',
  props<{ accepted: boolean }>()
);

export const updateTermsStoringDataSubscriptionSuccess = createAction(
  '[User Profile] update Terms Storing Data Subscription success'
);

export const updateTermsStoringDataSubscriptionError = createAction(
  '[User Profile] update Terms Storing Data Subscription error'
);

export const updateNotificationSettingsFrequency = createAction(
  '[User Profile] update Notification Settings Frequency',
  props<{ settings: INotificationSettings }>()
);

export const updateNotificationSettingsFrequencySuccess = createAction(
  '[User Profile] update Notification Settings Frequency success',
  props<{ settings: INotificationSettings }>()
);

export const updateNotificationSettingsFrequencyError = createAction(
  '[User Profile] update Notification Settings Frequency error'
);

export const queryConsultantsTenantDetails = createAction(
  '[User Profile] query Consultants tenant details'
);

export const queryConsultantsTenantDetailsError = createAction(
  '[User Profile] query Consultants tenant details error'
);

export const consultantsTenantDetailsLoaded = createAction(
  '[User Profile] Consultants tenant details loaded',
  props<{ details: IConsultantsTenantDetails }>()
);

export const consultantsTenantDetailsLoadedFromCache = createAction(
  '[User Profile] Consultants tenant details loaded from cache'
);
