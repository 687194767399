import * as fromGlobalCommon from './global-common.reducer';
import * as fromGlobalAppConfig from './global-app-config.reducer';
import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';

export const globalFeatureKey = 'globalModule';
export const globalCommonStateKey = 'common';
export const globalAppConfigStateKey = 'appConfig';

export interface GlobalModuleState {
  common: fromGlobalCommon.GlobalCommonState;
  appConfig: fromGlobalAppConfig.GlobalAppConfigState;
}


export const reducers: ActionReducerMap<GlobalModuleState> = {
  common: fromGlobalCommon.globalReducer,
  appConfig: fromGlobalAppConfig.globalAppConfigReducer
};

// Global module selectors
export const getGlobalModuleState =
  createFeatureSelector<GlobalModuleState>(globalFeatureKey);

export const getGlobalCommonState =
  createFeatureSelector<fromGlobalCommon.GlobalCommonState>(globalCommonStateKey);

export const getGlobalAppConfigState =
  createFeatureSelector<fromGlobalAppConfig.GlobalAppConfigState>(globalAppConfigStateKey);

export const selectGlobalCommonState = createSelector(
  getGlobalModuleState,
  getGlobalCommonState
);

export const selectGlobalAppConfigState = createSelector(
  getGlobalModuleState,
  getGlobalAppConfigState
);