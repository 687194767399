import { Injectable } from '@angular/core';
import { ICommonEditSave } from '../interfaces/common-edit-save';

// services
import { AgentService } from './agent.service';
import { UserProfileService } from './user-profile.service';

@Injectable()
export class LabeledEditService {

  servicesMethods: object = {
    // consultant view
    updateEmail: (email: string, consultantId: string) => {
      return this.agentService.updateEmail(email, consultantId);
    },
    updateSkypeId: (skypeId: string, consultantId: string) => {
      return this.agentService.updateSkypeId(skypeId, consultantId);
    },
    updateCompanyName: (name: string, consultantId: string) => {
      return this.agentService.updateCompanyName(name, consultantId);
    },
    updateCompanyWebsite: (website: string, consultantId: string) => {
      return this.agentService.updateCompanyWebsite(website, consultantId);
    },

    // profile view
    updateEmailProfile: (email: string) => {
      return this.userProfileService.updateEmail(email);
    },
    updateCompanyNameProfile: (name: string) => {
      return this.userProfileService.updateCompanyName(name);
    },
    updateCompanyWebsiteProfile: (website: string) => {
      return this.userProfileService.updateCompanyWebsite(website);
    }
  };

  constructor(private agentService: AgentService,
              private userProfileService: UserProfileService) { }

  labeledEditSave(fieldValue: any, methodName: string, consultantId?: string, cvId?: string) {
    return this.servicesMethods[methodName](fieldValue, consultantId);
  }
}
