import * as fromAgentSearch from './search/agent-search.reducer';
import * as fromAgentBookmarks from './bookmarks/agent-bookmarks.reducer';
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';


export const agentFeatureKey = 'agentModule';

export interface AgentModuleState {
  search: fromAgentSearch.AgentSearchState;
  bookmarks: fromAgentBookmarks.AgentBookmarksState;
}

export const reducers: ActionReducerMap<AgentModuleState> = {
  search: fromAgentSearch.agentSearchReducer,
  bookmarks: fromAgentBookmarks.agentBookmarksReducer
};

export const getAgentModuleState = createFeatureSelector<AgentModuleState>(
  agentFeatureKey
);
