import * as fromGalleries from './galleries.reducer';
import * as fromApp from '../index';
import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

export const galleriesFeatureKey = 'galleriesModule';
export const galleriesStateKey = 'galleries';

export interface State {
  galleries: fromGalleries.GalleriesState;
}

export interface FeatureState extends fromApp.State {
  galleries: State;
}

export const reducers: ActionReducerMap<State> = {
  galleries: fromGalleries.galleriesReducer
};

// galleries module selectors
export const getGalleriesModuleState = createFeatureSelector<State>(
  galleriesFeatureKey
);

export const getGalleriesState = createFeatureSelector<fromGalleries.GalleriesState>(
  galleriesStateKey
);

export const selectGalleriesState = createSelector(
  getGalleriesModuleState,
  getGalleriesState
);
