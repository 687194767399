import { Route } from '@angular/router';

export const CareerDevProgramRedirects: Route = {
  path: 'career-dev-program',
  children: [
    {
      path: 'pl',
      children: [
        {
          path: '',
          redirectTo: '/learning-and-dev/career-dev-program/pl/about',
          pathMatch: 'full'
        },
        {
          path: 'about',
          redirectTo: '/learning-and-dev/career-dev-program/pl/about'
        }
      ]
    },
    {
      path: 'dk',
      children: [
        {
          path: '',
          redirectTo: '/learning-and-dev/career-dev-program/dk/about',
          pathMatch: 'full'
        },
        {
          path: 'about',
          redirectTo: '/learning-and-dev/career-dev-program/dk/about'
        }
      ]
    }
  ]
};

export const AcademyRedirects: Route = {
  path: 'academy',
  children: [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: '/learning-and-dev/academy/about',
    },
    {
      path: 'about',
      redirectTo: '/learning-and-dev/academy/about'
    },
    {
      path: 'library',
      redirectTo: '/learning-and-dev/academy/library'
    },
    {
      path: 'videos',
      redirectTo: '/learning-and-dev/academy/videos'
    },
    {
      path: 'videos/:videoId',
      redirectTo: '/learning-and-dev/academy/videos/:videoId'
    },
    {
      path: 'videos/:videoId/preview',
      redirectTo: '/learning-and-dev/academy/videos/:videoId/preview'
    },
    {
      path: 'library/book/:bookId',
      redirectTo: '/learning-and-dev/academy/library/book/:bookId'
    },
    {
      path: 'library/book/:bookId/preview',
      redirectTo: '/learning-and-dev/academy/library/book/:bookId/preview'
    },
    {
      path: 'offers',
      redirectTo: '/learning-and-dev/academy/offers'
    },
    {
      path: 'offers/:offerId',
      redirectTo: '/learning-and-dev/academy/offers/:offerId'
    },
    {
      path: 'offers/:offerId/preview',
      redirectTo: '/learning-and-dev/academy/offers/:offerId/preview'
    }
  ]
}
