import 'reflect-metadata';
import { FrontendFeature, FrontendFeaturesModule } from '../feature';
import { Feature } from '../interfaces/feature';

@FrontendFeaturesModule('Benefits')
export class BenefitsFeatures {
  @FrontendFeature({
    summary: 'Default benefits page'
  })
  static readonly Default: Feature = 'benefits';

  @FrontendFeature({
    summary: 'Go to benefits button'
  })
  static readonly GoTo: Feature = 'benefits/go-to';

  @FrontendFeature({
    summary: 'Benefits offers page'
  })
  static readonly Offers: Feature = 'benefits/offers';

  @FrontendFeature({
    summary: 'Benefits featured offers list'
  })
  static readonly OffersFeatured: Feature = 'benefits/offers/featured';

  @FrontendFeature({
    summary: 'Benefits offers list'
  })
  static readonly OffersList: Feature = 'benefits/offers/list';

  @FrontendFeature({
    summary: 'Benefits Offer detail view page'
  })
  static readonly OffersItemView: Feature = 'benefits/offers/item/view';

  @FrontendFeature({
    summary: 'Benefits Offer detail preview page'
  })
  static readonly OffersItemPreview: Feature = 'benefits/offers/item/preview';
}
