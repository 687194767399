import { Inject, Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

// Services
import { MatDialogService, SnackBarService, SnackBarTypes } from '@my7n/ui';
import { GlobalAppConfigFacadeService } from './facades/global-app-config-facade.service';
// Components
import { PrintPreviewDialogComponent } from '../components/common/print-preview-dialog/print-preview-dialog.component';
// Interfaces
import { ICv } from '../interfaces/cv';
import { IPrintPreferences } from '../interfaces/print-preferences';
// Configuration
import { IMy7nEnvConfig, ServiceNames } from '../interfaces/my7n-env-config';
import { MY7N_ENV_CONFIG } from '../functions/my7n-env-config';
import { AppConfigService } from './app-config.service';


type CvExcerptForPrint = {
  Id: number;
  FirstName: string;
  LastName: string;
  CreateDate: string;
};

@Injectable()
export class PreviewService implements OnDestroy {
  readonly API_CVS: string;
  readonly API_EVALUATIONS: string;

  /**
   * Flag that indicates if PDF generation is in progress.
   */
  pdfProcessing = false;

  private unsubscribe$ = new Subject<void>();
  printSettings: IPrintPreferences;

  constructor(public dialog: MatDialogService,
              @Inject(MY7N_ENV_CONFIG) private envConfig: IMy7nEnvConfig,
              private appConfigService: AppConfigService,
              private snackBarService: SnackBarService,
              private http: HttpClient,
              private globalAppConfigFacadeService: GlobalAppConfigFacadeService) {
    this.API_CVS = appConfigService.serviceUrl(ServiceNames.Core) + 'cvs/';
    this.API_EVALUATIONS = appConfigService.serviceUrl(ServiceNames.Evaluation, 'v1') + 'evaluations/';

    this.globalAppConfigFacadeService.printPreferences$
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe(
        (settings: IPrintPreferences) => {
          this.printSettings = { ...settings };
        }
      );
  }

  private prepareQueryString(preferences: IPrintPreferences) {
    let preferencesKeys;

    if (typeof (preferences) !== 'object') {
      preferences = {};
    }

    preferencesKeys = Object.keys(preferences);

    if (preferencesKeys.length > 0) {
      preferencesKeys = preferencesKeys.map(function (key) {
        return key + '=' + preferences[key];
      });

      return '?' + preferencesKeys.join('&');
    }

    return '';
  }

  openPreviewDialog(pdfData: Uint8Array, fileName: string) {
    this.dialog.openFullScreenDialog(PrintPreviewDialogComponent, {
      data: {
        pdfData: pdfData,
        fileName: fileName
      }
    });
  }

  printPreview(cv: ICv | CvExcerptForPrint) {
    let userName,
      createDate,
      fileName,
      fileUrl,
      printSettingsQueryString,
      fullQueryString;

    if (!cv || !cv.Id) {
      console.error('[PreviewService] CV with CV Id must be provided to prepare print');
      return;
    }

    printSettingsQueryString = this.prepareQueryString(this.printSettings);
    createDate = moment().format('DD.MM.YYYY');
    userName = (cv.FirstName + '_' + cv.LastName).replace(/\s+/, '_');
    fileName = 'CV_' + userName + '_7N_' + createDate + '.pdf';
    fullQueryString = printSettingsQueryString ? `${printSettingsQueryString}&filename=${fileName}` : `?filename=${fileName}`;

    fileUrl = `${this.API_CVS + cv.Id}/printout/preview${fullQueryString}`;

    this.processPdf(fileUrl, fileName);
  }

  advancedPrintPreview(cv: ICv | CvExcerptForPrint, preferences?: IPrintPreferences) {
    let userName,
      createDate,
      fileName,
      fileQueryString,
      fileUrl;

    if (!cv || !cv.Id) {
      console.error('[PreviewService] CV with CV Id must be provided to prepare print');
      return;
    }

    preferences = preferences || this.printSettings;

    createDate = moment().format('DD.MM.YYYY');
    userName = (cv.FirstName + '_' + cv.LastName).replace(/\s+/, '_');
    fileName = 'CV_' + userName + '_7N_' + createDate + '.pdf';
    if (preferences) {
      fileQueryString = this.prepareQueryString(preferences);
      fileQueryString += (fileQueryString.length > 0) ? '&' : '?';
    } else {
      fileQueryString = '?';
    }
    // must be as last querystring element because pdf.js expects that file will and with .pdf
    fileQueryString += 'filename=' + fileName;

    // VERSION FOR NEW LIB
    fileUrl = this.API_CVS + cv.Id + '/printout/pdf/' + fileQueryString;
    this.processPdf(fileUrl, fileName);
  }

  evaluationPrintPreview(evaluationId: string, consultantFullname: string) {
    let fileUrl: string;
    const fileName = 'Evaluation_' + consultantFullname.replace(/\s+/g, '_') + '.pdf';

    if (!evaluationId) {
      console.error('[PreviewService] Evaluation with Evaluation Id must be provided to prepare preview');
      return;
    }

    fileUrl = `${this.API_EVALUATIONS}${evaluationId}/printout/preview?filename=${encodeURIComponent(fileName)}`;
    this.processPdf(fileUrl, fileName);
  }

  processPdf(fileUrl: string, fileName: string) {
    if (this.pdfProcessing) {
      return;
    }

    this.snackBarService.openPending({
      message: 'Loading print preview...',
      type: SnackBarTypes.NotificationAlt
    });

    this.pdfProcessing = true;
    this.getPdf(fileUrl)
      .subscribe(blob => {
        this.pdfProcessing = false;
        this.snackBarService.close();

        const bufferBlob = new Uint8Array(blob);
        this.openPreviewDialog(bufferBlob, fileName);
      }, () => {
        this.pdfProcessing = false;
        this.snackBarService.open({
          message: 'Error while loading print preview...',
          type: SnackBarTypes.ErrorAlt
        });
      });
  }

  getPdf(url): Observable<any> {
    return this.http.get(url, {responseType: 'arraybuffer'});
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
  }
}
