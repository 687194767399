export class ImageProcessing {
  // If width not provided it will draw image in it's natural size
  static shrinkImageToWidth(fileContentOrUrl: string, width?: number): Promise<string> {
    const canvas: HTMLCanvasElement =  document.createElement('canvas');
    const img: HTMLImageElement =  document.createElement('img');
    const ctx = canvas.getContext('2d');

    img.crossOrigin = 'anonymous';

    return new Promise((resolve, reject) => {
      img.onload = () => {
        const ratio = (img.naturalWidth / img.naturalHeight),
          smallestWidth = width ? Math.min(width, img.naturalWidth) : img.naturalWidth;

        canvas.width = smallestWidth;
        canvas.height = smallestWidth / ratio;

        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        resolve(canvas.toDataURL('image/png'));
      };

      img.onerror = () => {
        reject('There was a problem while loading images to resize');
      };

      // As for 2020-12-02 service worker configuration caches requests for images on blob storage
      // for Chrome based browsers everything is fine, but in Firefox CORS error appears
      // Workbox authors explain that it's due to opaque requests https://developers.google.com/web/tools/workbox/guides/handle-third-party-requests
      // (this can be confirmed by browsing the cache in chrome for example, first request made for this image contains type "opaque")
      // To work around this we could either adjust the configuration of Service Workers by changing the strategy of
      // caching for specific images or we could introduce another entry in the cache which type is "cors".
      // This is done here by using .crossOrigin=anonymous (above) and adding 'cors=true' string to the end of the request
      // (naming doesn't really matter, could be an random string). This prevents cache hit for this image (because it's
      // fetched earlier as background in CSS etc.)
      if (fileContentOrUrl.startsWith('http')) {
        fileContentOrUrl += fileContentOrUrl.includes('?') ? '&cors=true' : '?cors=true';
      }

      img.src = fileContentOrUrl;
    });
  }

  static imageToDataUrl(image: HTMLImageElement) {
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    let dataURL;

    canvas.height = image.naturalHeight;
    canvas.width = image.naturalWidth;
    ctx.drawImage(image, 0, 0);
    dataURL = canvas.toDataURL();

    return dataURL;
  }
}
