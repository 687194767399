import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MediaQueryService } from '@my7n/ui';

@Component({
  selector: 'consultant-cv-primary-dialog',
  templateUrl: './consultant-cv-primary-dialog.component.html',
  styleUrls: ['./consultant-cv-primary-dialog.component.scss']
})
export class ConsultantCvPrimaryDialogComponent implements OnInit {
  cvsCount: number;

  constructor(
    public dialogRef: MatDialogRef<ConsultantCvPrimaryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public mediaQueryService: MediaQueryService
  ) { }

  ngOnInit() {
    this.cvsCount = this.data.cvsCount;
  }

  cancelDialog(): void {
    this.dialogRef.close(false);
  }
}
