import { ICmsItem } from './cms';
import { LegalEntityCountry } from './legal-entity';
import {INewsAttachment} from './news-attachment';
export interface INewsBase {
  Id: number | string;
  Title: string;
  Description: string;
  AuthorJobTitle: string;
  AuthorName: string;
  AuthorAvatar: string;
  Published: string;
  Type: NewsTypes;
}


export interface INewsList extends INewsBase {
  Attachments: INewsAttachment[];
  StartDate: string;
  EndDate: string;
  IsOngoing: boolean;
}
export interface ICmsNewsList extends INewsBase {
  Image: string;
  VideoUrl: string;
  VideoThumbnail: string;
  Countries: Array<LegalEntityCountry>;
  GalleryUrl?: string;
}

export interface INewsView extends INewsBase {
  Attachments: INewsAttachment[];
}

export interface ICmsNewsView extends INewsBase {
  Image: string;
  VideoUrl: string;
  VideoThumbnail: string;
  Country: LegalEntityCountry;
  GalleryUrl?: string;
}

// this template fits CmsItemView component
export interface ICmsNewsViewItem extends ICmsItem {
  videoUrl: string;
  videoThumbnail: string;
  galleryUrl?: string;
  countries: Array<LegalEntityCountry>;
}

export enum NewsTypes {
  NEWS = 1,
  EVENT = 2,
  CMS_NEWS = 3
}

export const NEWS_DETAIL_URLS = [
  '/news',
  '/content/news',
  '/events/' // with slash at the end to prevent restoring when from events list view
];

export const NEWS_PER_PAGE = 5;

