import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'cms-rich-text',
  templateUrl: './cms-rich-text.component.html',
  styleUrls: ['./cms-rich-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CmsRichTextComponent {

  @Input() content: string;

}
