import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
// Services
import { CvListService } from '../../../services/cv/cv-list.service';
// Interfaces
import { ICv } from '../../../interfaces/cv';

@Component({
  selector: 'cv-box',
  templateUrl: './cv-box.component.html',
  styleUrls: ['./cv-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CvBoxComponent implements OnInit {
  cv: ICv = null;

  constructor(
    private cvListService: CvListService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.cvListService.getPrimaryCv().then((result) => {
      this.cv = result;
      this.cdr.detectChanges();
    }, error => {
      console.error('[CvBoxComponent] Failed to load primary CV');
      return null;
    });
  }
}
