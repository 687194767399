import { FrontendFeature, FrontendFeaturesModule } from '../feature';

@FrontendFeaturesModule('TimeReporting')
export class BillingFeatures {
  @FrontendFeature({
    summary: 'Main time reporting page'
  })
  static readonly Default = 'time-reporting';

  @FrontendFeature({
    summary: 'About time reporting page'
  })
  static readonly About = 'time-reporting/about';

  @FrontendFeature({
    summary: 'FAQ time reporting page'
  })
  static readonly Faq = 'time-reporting/faq';

  @FrontendFeature({
    summary: 'Time reporting hours submit'
  })
  static readonly SubmitHours = 'time-reporting/item/submit-hours';

  @FrontendFeature({
    summary: 'Time reporting submitted invoice review'
  })
  static readonly ItemView = 'time-reporting/item/view';

  @FrontendFeature({
    summary: 'Time reporting Voice of the Consultant promo'
  })
  static readonly VotcPromo = 'time-reporting/votc-promo';
}
