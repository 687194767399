<div class="reactions-list-container flex flex-row flex-end-center">
  <div class="reactions-list flex flex-row flex-start-center" 
       cdkOverlayOrigin 
       #originOverlay="cdkOverlayOrigin"
       data-test-id="reactions-list">
    <div *ngFor="let reaction of getTopThreeReactions()" 
         class="reactions-list-item">
      <img src="assets/images/reactions/{{ reaction?.toLowerCase() }}-bg.svg" 
           alt="{{ reaction }}"
           class="reactions-list-item-icon {{ reaction?.toLowerCase() }}" 
           attr.data-test-id="reactions-list-item-img-{{ reaction?.toLowerCase() }}"/>
    </div>
    <div *ngIf="reactions?.length>0" class="reactions-list-item reactions-list-counter">
      {{ reactions?.length }}
    </div>
  </div>
</div>

<ng-template cdkConnectedOverlay 
             #reactionsListPanel 
             [cdkConnectedOverlayOrigin]="originOverlay"
             [cdkConnectedOverlayOpen]="isPanelVisible" 
             [cdkConnectedOverlayPositions]="cdkOverlayPositions"
             [cdkConnectedOverlayOffsetY]="-10">
  <div class="reactions-list-menu-container">
    <div class="reactions-list-menu" data-test-id="reactions-list-menu">
      <ul class="flex flex-row flex-start-center gap-12">
        <li *ngFor="let reaction of getReactionsCounter()" class="flex flex-row flex-start-center gap-6">
          <div class="flex flex-col flex-between-center reaction-item">
            <img src="assets/images/reactions/{{ reaction.type?.toLowerCase() }}-bg.svg" 
                 alt="{{ reaction.type }}"
                 class="reaction {{ reaction.type?.toLowerCase() }}" 
                 attr.data-test-id="reactions-list-menu-item-img-{{ reaction.type?.toLowerCase() }}"/>
          </div>
          <div class="reaction-item-counter">
            {{reaction.count}}
          </div>
        </li>
      </ul>
    </div>
  </div>
</ng-template>