<ng-container *rxLet="state$ as state">
  <section
    id="notifications"
    class="notifications-section"
    data-test-id="user-profile-notifications-container">
    <h4 class="font-bold">my7N Notifications</h4>
    <ng-container *ngIf="!state.notificationSettingsLoading; else loading">
      <ng-container *ngIf="state.notificationSettings && notificationsForm; else nodata">
        <p>
          These are email notifications to remind you about updates within the 7N
          community you may have missed in my7N. You may change this setting at
          any time by turning off this option.
        </p>
        <div class="sliders-container">
          <form [formGroup]="notificationsForm">
            <mat-slide-toggle
              color="primary"
              formControlName="subscription"
              disableRipple="true"
              class="slider"
              data-test-id="user-profile-notifications-slider">
              I want to receive 7N email notifications about updates in my7N.
            </mat-slide-toggle>
          </form>
        </div>
        <ng-container *ngIf="notificationsForm?.controls['subscription']?.value">
          <div class="frequency-section">
            <label class="standalone-label">Where you receive notifications</label>
            <p>{{ state.notificationSettings?.Email }}</p>
          </div>
          <notification-frequency
            [frequency]="state.notificationSettings.SummaryNotificationFrequency"
            [saving]="state.notificationSettingsSaving"
            (frequencyUpdated)="notificationSettingsFrequencyUpdated($event)"
            data-test-id="user-profile-notificationsFrequency-container"
            [canEdit]="state.canEdit">
          </notification-frequency>
        </ng-container>
      </ng-container>
    </ng-container>
  </section>
</ng-container>

<ng-template #nodata>
  <no-data></no-data>
</ng-template>

<ng-template #loading>
  <div class="flex flex-center-start w-full h-full">
    <loader color="primary"></loader>
  </div>
</ng-template>
