import { PageEvent } from '@angular/material/paginator';
import { Params } from '@angular/router';
import { createAction, props } from '@ngrx/store';
import { IConsultantDetails } from '../../interfaces/consultant-details';
import { IConsultantSearchedSelectable } from '../../interfaces/consultant-searched';
import { IQueryPageOptions, IQuerySearchParams } from '../../interfaces/search';
import { ISearchFavouriteFilter } from '../../interfaces/serach-filters-data';

export const queryResults = createAction(
  '[Agent][Search] query results',
  props<{ searchParams: IQuerySearchParams, queryPageOptions: IQueryPageOptions }>()
);

export const queryResultsSuccess = createAction(
  '[Agent][Search] query results Success',
  props<{ consultants: IConsultantSearchedSelectable[], total: number, queryPageOptions: IQueryPageOptions }>()
);

export const queryResultsError = createAction(
  '[Agent][Search] query results Error'
);

export const clearResults = createAction(
  '[Agent][Search] clear results'
);

export const toggleSelectionConsultant = createAction(
  '[Agent][Search] toggle consultant selection',
  props<{ consultantId: string, selected: boolean }>()
);

export const clearSelectedConsultats = createAction(
  '[Agent][Search] clear selected consultants'
);

export const updatePaginator = createAction(
  '[Agent][Search] update paginator',
  props<{ paginatorEvent: PageEvent }>()
);

export const updateExtendedViewState = createAction(
  '[Agent][Search] update ExtendedView state',
  props<{ checked: boolean }>()
);

export const updateLoadingState = createAction(
  '[Agent][Search] update loading state',
  props<{ loading: boolean }>()
);

export const setRestore = createAction(
  '[Agent][Search] set restore',
  props<{ shouldRestore: boolean, scrollY: number }>()
);

export const setRestoreQueryParams = createAction(
  '[Agent][Search] set restore query params',
  props<{ queryParams: Params }>()
);

// fav filters
export const queryFavouriteFilters = createAction(
  '[Agent][Search] query favourite filters'
);

export const queryFavouriteFiltersSuccess = createAction(
  '[Agent][Search] query favourite filters Success',
  props<{ filters: Array<ISearchFavouriteFilter> }>()
);

export const queryFavouriteFiltersError = createAction(
  '[Agent][Search] query favourite filters Error'
);

export const saveFavouriteFilters = createAction(
  '[Agent][Search] save favourite filters',
  props<{ updatedFilters: Array<ISearchFavouriteFilter> }>()
);

export const saveFavouriteFiltersSuccess = createAction(
  '[Agent][Search] save favourite filters Success',
  props<{ updatedFilters: Array<ISearchFavouriteFilter> }>()
);

export const saveFavouriteFiltersError = createAction(
  '[Agent][Search] save favourite filters Error'
);

export const addFavouriteFilter = createAction(
  '[Agent][Search] add favourite filter',
  props<{ addedFilter: ISearchFavouriteFilter }>()
);

export const removeFavouriteFilter = createAction(
  '[Agent][Search] remove favourite filter',
  props<{ removedFilterIndex: number }>()
);

export const selectFavouriteFilter = createAction(
  '[Agent][Search] select favourite filter',
  props<{ selected: ISearchFavouriteFilter }>()
);

export const selectFavouriteFilterDraft = createAction(
  '[Agent][Search] select favourite filter draft',
  props<{ draft: ISearchFavouriteFilter }>()
);

export const setDraftAsSelectedFavouriteFilter = createAction(
  '[Agent][Search] set draft as selected favourite filter'
);

export const setSelectedAsDraftFavouriteFilter = createAction(
  '[Agent][Search] set selected as draft favourite filter'
);

// global actions

export const bookmarkSelectedConsultants = createAction(
  '[Agent][Search] bookmark selected consultants',
  props<{ consultants: IConsultantSearchedSelectable[] }>()
);

export const bookmarkSelectedConsultantsSuccess = createAction(
  '[Agent][Search] bookmark selected consultants Success',
  props<{ consultants: IConsultantSearchedSelectable[] }>()
);

export const bookmarkSelectedConsultantsError = createAction(
  '[Agent][Search] bookmark selected consultants Error'
);

// single actions

export const bookmarkConsultant = createAction(
  '[Agent][Search] bookmark consultant',
  props<{ consultant: IConsultantSearchedSelectable | IConsultantDetails }>()
);

export const bookmarkConsultantSuccess = createAction(
  '[Agent][Search] bookmark consultant Success'
);

export const bookmarkConsultantError = createAction(
  '[Agent][Search] bookmark consultant Error',
  props<{ consultant: IConsultantSearchedSelectable | IConsultantDetails }>()
);

export const unbookmarkConsultant = createAction(
  '[Agent][Search] unbookmark consultant',
  props<{ consultantId: string }>()
);

export const unbookmarkConsultantSuccess = createAction(
  '[Agent][Search] unbookmark consultant Success'
);

export const unbookmarkConsultantError = createAction(
  '[Agent][Search] unbookmark consultant Error',
  props<{ consultantId: string }>()
);

export const updatePortraitUrl = createAction(
  '[Agent][Search] update consultant portrait URL',
  props<{ consultantId: string; portraitUrl: string }>()
);
