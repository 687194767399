import {
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Overlay, OverlayPositionBuilder } from '@angular/cdk/overlay';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { Router, RoutesRecognized } from '@angular/router';
// Components
import { PopoverComponent } from '../popover/popover.component';
// Facades
import { NotificationsFacade } from '../../../services/facades/notifications-facade.service';
// Interfaces
import { Breakpoints } from '../../../interfaces/breakpoints';

@Component({
  selector: 'popover-notifications',
  templateUrl: './popover-notifications.component.html',
  styleUrls: ['./popover-notifications.component.scss']
})
export class PopoverNotificationsComponent extends PopoverComponent implements OnInit, OnDestroy {
  @Input() unseenNotificationsCounter: number;
  @Input() lastReadTimestamp: Date;

  position = 'bottom';
  breakpointSubscription: Subscription;
  openNotificationsSubscription: Subscription;

  iconHovered: boolean;

  constructor(overlay: Overlay,
              overlayPositionBuilder: OverlayPositionBuilder,
              private notificationsFacade: NotificationsFacade,
              private breakpointObserver: BreakpointObserver,
              private router: Router) {
                super(overlay, overlayPositionBuilder);
                this.panelClass = ['popover', 'popover-notifications'];
  }

  ngOnInit() {
    this.preapareOverlayClasses();
    this.breakpointSubscription = this.breakpointObserver.observe([
      `(max-width: ${Breakpoints.SM}px)`
    ]).subscribe(result => {
      this.lockBody = result.matches;
    });
    this.openNotificationsSubscription = this.router.events.pipe(
      filter(val => val instanceof RoutesRecognized),
      take(1),
      filter((val: RoutesRecognized) => val.state.root.queryParams['openNotifications'] !== undefined))
    .subscribe((val: RoutesRecognized) => {
      this.openNotifications();
    });
  }

  openNotifications() {
    if (this.unseenNotificationsCounter > 0) {
      this.notificationsFacade.updateNotificationsTimestamp(this.lastReadTimestamp);
    }
    this.activate();
  }

  ngOnDestroy(){
    this.breakpointSubscription.unsubscribe();
    this.openNotificationsSubscription.unsubscribe();
  }
}


