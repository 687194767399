import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as GalleriesActions from '../../store/actions/galleries.actions';
import { Observable } from 'rxjs';
import * as fromGalleries from '../../store/reducers/galleries/galleries.reducer';
import * as GalleriesSelectors from '../../store/reducers/galleries/galleries.selectors';
import { ISharepointGallery, ISharepointGalleryFilesRequestParams } from '../../interfaces/sharepoint';

@Injectable()
export class GalleryFacadeService {
  constructor(private store: Store<fromGalleries.GalleriesState>) {}

  queryGalleryData(path: string, galleryParams: ISharepointGalleryFilesRequestParams = {}, forceRefresh= false) {
    this.store.dispatch(GalleriesActions.queryGalleryData({
      path,
      galleryParams,
      forceRefresh
    }));
  }

  get galleries$(): Observable<Array<ISharepointGallery>> {
    return this.store.pipe(select(GalleriesSelectors.selectAllGalleries));
  }

  getGallery$(path: string): Observable<ISharepointGallery> {
    return this.store.pipe(select(GalleriesSelectors.selectGallery, { path }));
  }

  getGalleryErrorState$(path: string): Observable<boolean> {
    return this.store.pipe(select(GalleriesSelectors.selectGalleryErrorState, { path }));
  }

  getGalleryLoadingState$(path: string): Observable<boolean> {
    return this.store.pipe(select(GalleriesSelectors.selectGalleryLoadingState, { path }));
  }

  getGalleryLastRequestTimestamp$(path: string): Observable<number> {
    return this.store.pipe(select(GalleriesSelectors.selectGalleryLastRequest, { path }));
  }
}
