<div #thumbContainer class="gallery-thumbnails-container flex-row" [ngClass]="{ 'flex-wrap': !gallery.config.limitToOneRow }">
  <gallery-thumb-item
    class="flex-auto"
    *ngFor="let thumb of gallery.items | slice:0:limitTo; index as i; trackById"
    [thumbItem]="thumb"
    [moreCount]="gallery.items.length - limitTo"
    [showAllLayer]="gallery.config.limitToOneRow && gallery.items.length > gallery.config.rowItems && i === gallery.config.rowItems - 1"
    [newsId]="gallery.newsId"
    [ngClass]="{
        'firstItem': i === 0,
        'lastItem': gallery.config.limitToOneRow && (i + 1 === gallery.config.rowItems),
        'flex-grow-0': !gallery.config.limitToOneRow
    }"
    [ngStyle]="{
        'height': thumbHeight + 'px',
        'width': gallery.config.limitToOneRow ? 'auto': 100/gallery.config.rowItems + '%'
    }"
    (click)="showPreview(i)">
  </gallery-thumb-item>
</div>
