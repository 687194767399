<ng-container *ngIf="
    {
        newsLoading: newsLoading$ | async,
        newsLoadingMore: newsLoadingMore$ | async,
        loadingError: loadingError$ | async,
        news: news$ | async
    } as newsStreamsObj">
  <h4 class="font-bold">News</h4>
  <ng-container *ngIf="!newsStreamsObj.newsLoading; else loader">
    <ng-container *ngIf="!newsStreamsObj.loadingError; else nodata">
        <news-list-element-outlet *ngFor="let news of newsStreamsObj.news; trackBy: trackById"
                                  [news]="news"
                                  [ngClass]="{ 'preview-news': news.Id === previewNewsId }"></news-list-element-outlet>
        <div class="more-loader flex flex-center-start w-full h-full"
             [hidden]="!newsStreamsObj.newsLoadingMore"
             data-test-id="news-list-more-loader">
          <loader color="primary"></loader>
        </div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #nodata>
    <div class="flex flex-center-start w-full h-full">
        <no-data data-test-id="news-list-no-data"></no-data>
    </div>
</ng-template>
 
<ng-template #loader>
    <div class="flex flex-center-start w-full h-full">
      <loader color="primary" class="init-loader" data-test-id="news-list-init-loader"></loader>
    </div>
</ng-template>
