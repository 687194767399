<div #thumbContainer
     class="gallery-thumbnails-container flex flex-row"
     data-test-id="gallery-thumbnails-container"
     [ngClass]="{'no-row-limit-gallery flex-grid': !config.limitToOneRow }">
  <sharepoint-gallery-thumb
    class="gallery-thumb flex flex-center-center"
    *ngFor="let thumb of visibleItems | slice:0:limitTo; index as i; trackById"
    [thumbItem]="thumb"
    [config]="config"
    [ngClass]="{
        'no-row-limit-gallery-item': !config.limitToOneRow,
        'firstItem': i === 0,
        'lastItem': config.limitToOneRow && (i + 1 === config.rowItems)
    }"
    [ngStyle]="{
      'height': getThumbHeight(),
      'width': getThumbWidth()
     }"
    (click)="showPreview(i)">
  </sharepoint-gallery-thumb>
   <div class="show-all-layer"
       data-test-id="show-all-layer"
       *ngIf="config.limitToOneRow && items.length > config.rowItems"
       [ngStyle]="{
        'height': getThumbHeight(),
        'width': getThumbWidth()
       }"
       (click)="$event.stopPropagation()">
    <a [routerLink]="['/content/news/', newsId]"
       class="flex flex-[1_0_auto] flex-center-center w-full h-full">
      <div class="thumb-content">
        <div class="images-count typography-top-header"
             data-test-id="images-count">+{{items.length - limitTo}}</div>
        <p class="typography-label font-bold text-12 leading-17">
          Show all<span class="max-md:hidden"> photos</span>
        </p>
      </div>
    </a>
  </div> 
</div>
<div *ngIf="!config?.limitToOneRow">
  <div id="visible-gallery-end"
       isElementVisible 
       (elementVisible)="loadMore$.next($event)"
       [isTargetElement]="true">
  </div>
</div>
