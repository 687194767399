import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ICachableDict} from '../interfaces/cachable-dict';
import { lastValueFrom } from 'rxjs';

const STORAGE_KEY_PREFIX = 'StorageCache_';

@Injectable()
export class StorageCacheService {

  private promisesForApi = {};
  private readonly localStorageSupport: boolean = true;

  constructor(private http: HttpClient) {
    this.localStorageSupport = StorageCacheService.storageAvailable('localStorage');

    if (!this.localStorageSupport) {
      console.warn('[StorageCacheService] localStorage is not supported on this browser');
    }
  }

  // thanks: https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
  static storageAvailable(type: string) {
    let storage = window[type];

    try {
      let x = '__storage_test__';
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    }
    catch(e) {
      return e instanceof DOMException && (
          // everything except Firefox
        e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
        // acknowledge QuotaExceededError only if there's something already stored
        storage.length !== 0;
    }
  }

  private getResolveWithHttp(api) {
    // One promise for URL at a time
    if (this.promisesForApi[api]) {
      return this.promisesForApi[api];
    }

    this.promisesForApi[api] = lastValueFrom(this.http.get<ICachableDict>(api)).then((response) => {
      console.debug('[StorageCacheService] Getting results for ' + api + ' using HttpClient');

      if (this.localStorageSupport) {
        try {
          localStorage[STORAGE_KEY_PREFIX + api] = JSON.stringify({
            uniqueKey: response.TimeKey,
            data: response
          });
        } catch (e) {
          console.error('[StorageCacheService] Error while saving to localStorage', e);

          // Thank you: https://stackoverflow.com/a/15720835
          // This code part is to debug, how is it possible that the problem showed up in the first place
          let totalSize = 0,
            entrySize,
            entry,
            sizes = [];

          for (entry in localStorage) {
            entrySize = ((localStorage[entry].length + entry.length) * 2);
            totalSize += entrySize;
            sizes.push(entry.substr(0, 50) + ' = ' + (entrySize / 1024).toFixed(2) + ' KB')
          }

          sizes.push('Total = ' + (totalSize / 1024).toFixed(2) + ' KB');
          console.debug('[StorageCacheService] ' + sizes.join(';\n'));
        }
      } else {
        console.warn('[StorageCacheService] localStorage is not supported on this browser');
      }

      this.promisesForApi[api] = null;

      return response;
    }, (error) => {
      console.debug('[StorageCacheService] Failed to get results for ' + api + ' using HttpClient');

      this.promisesForApi[api] = null;

      return Promise.reject(error);
    });

    return this.promisesForApi[api];
  }

  get<T>(api: string, timekey: string | Promise<string>) {
    const localStorage = window.localStorage;

    let localStorageEntry = this.localStorageSupport ? localStorage[STORAGE_KEY_PREFIX + api] : undefined;

    if (typeof timekey == 'string') {
      timekey = new Promise((resolve) => { resolve(timekey) });
    }

    return timekey.then<T>((timekeyValue) => {
      if (localStorageEntry !== undefined) {
        let parseEntry;

        try {
          parseEntry = JSON.parse(localStorageEntry);

          if (parseEntry && parseEntry.uniqueKey == timekeyValue) {
            return Promise.resolve(parseEntry.data);
          }
        } catch (e) {
          console.error(`[StorageCacheService] Storage entry for ${api} is has bad syntax, failing.`);
        }
      }

      return this.getResolveWithHttp(api);
    }, this.getResolveWithHttp.bind(null, api));
  }

  /**
   * Remove all keys prefixed with STORAGE_KEY_PREFIX
   */
  clear () {
    Object.keys(localStorage).filter(function (key) {
      return key.indexOf(STORAGE_KEY_PREFIX) === 0;
    }).forEach(function (key) {
      localStorage.removeItem(key);
    });
  }
}
