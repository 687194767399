import 'reflect-metadata';
import {FrontendFeature, FrontendFeaturesModule} from '../feature';
import {Feature} from '../interfaces/feature';

@FrontendFeaturesModule('LearningAndDev')
export class LearningAndDevFeatures {
  @FrontendFeature({
    summary: 'Default learning and development page'
  })
  static readonly Default: Feature = 'learning-and-dev';

  @FrontendFeature({
    summary: 'L&D home page'
  })
  static readonly Home: Feature = 'learning-and-dev/home';

  @FrontendFeature({
    summary: 'L&D platforms and courses page'
  })
  static readonly PlatformsAndCourses: Feature = 'learning-and-dev/platforms-and-courses';

  @FrontendFeature({
    summary: 'L&D platforms and courses detail view page'
  })
  static readonly PlatformsAndCoursesItemView: Feature = 'learning-and-dev/platforms-and-courses/item/view';

  @FrontendFeature({
    summary: 'L&D platforms and courses detail preview page'
  })
  static readonly PlatformsAndCoursesItemPreview: Feature = 'learning-and-dev/platforms-and-courses/item/preview';

  @FrontendFeature({
    summary: 'L&D inspiration team page'
  })
  static readonly InspirationTeam: Feature = 'learning-and-dev/inspiration-team';
}
