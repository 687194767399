import { Injectable } from '@angular/core';
import { IPhones } from './../interfaces/phones';

// services
import { AgentService } from './agent.service';
import { UserProfileService } from './user-profile.service';

@Injectable()
export class PhonesEditService {

  servicesMethods: object = {
    // consultant view
    updatePhones: (phones: IPhones, consultantId: string) => {
      return this.agentService.updatePhones(phones, consultantId);
    },

    // profile view
    updatePhonesProfile: (phones: IPhones) => {
      return this.userProfileService.updatePhones(phones);
    },
  };

  constructor(public agentService: AgentService, private userProfileService: UserProfileService) { }

  phonesEditSave(fieldValue: any, methodName: string, consultantId?: string) {
    return this.servicesMethods[methodName](fieldValue, consultantId);
  }
}
