import * as fromCvGlobal from './cv-global.reducer';
import * as fromCvProfileStrength from './cv-profile-strength.reducer';
import {
  ActionReducerMap,
  createFeatureSelector
} from '@ngrx/store';

export const cvFeatureKey = 'cvModule';
export const cvGlobalKey = 'global';
export const cvProfileStrengthKey = 'profileStrength';

export interface CvModuleState {
  global: fromCvGlobal.CvGlobalState;
  profileStrength: fromCvProfileStrength.CvProfileStrengthState;
}

export const reducers: ActionReducerMap<CvModuleState> = {
  global: fromCvGlobal.cvGlobalReducer,
  profileStrength: fromCvProfileStrength.cvProfileStrenghtReducer
};

// global cv module selectors
export const getCvModuleState =
  createFeatureSelector<CvModuleState>(cvFeatureKey);

export const getCvGlobalState =
  createFeatureSelector<fromCvGlobal.CvGlobalState>(
    cvGlobalKey
);

export const getCvProfileStrengthState =
  createFeatureSelector<fromCvProfileStrength.CvProfileStrengthState>(
    cvProfileStrengthKey
);
