import { createAction, props } from '@ngrx/store';
import { IProfileStrength } from '../../interfaces/profile-strength';

export const queryProfileStrength = createAction(
  '[CV][Profile Strength] query',
  props<{ cvId: number }>()
);

export const queryProfileStrengthError = createAction(
  '[CV][Profile Strength] query error'
);

export const queryProfileStrengthSuccess = createAction(
  '[CV][Profile Strength] query success',
  props<{ profileStrength: IProfileStrength }>()
);

export const clearProfileStrength = createAction(
  '[CV][Profile Strength] clear'
);
