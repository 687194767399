import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ISharepointFile } from '../../../../interfaces/sharepoint';
import { IGalleryConfig } from '../../../../interfaces/gallery-config';

@Component({
  selector: 'sharepoint-gallery-thumb',
  templateUrl: './sharepoint-gallery-thumb.component.html',
  styleUrls: ['./sharepoint-gallery-thumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SharepointGalleryThumbComponent {
  @Input() thumbItem: ISharepointFile;
  @Input() config: IGalleryConfig;

  getImgHeight(): string {
    return this.config?.itemHeight + 'px';
  }
}
