export interface INewsAttachment {
  Id: number | string;
  ThumbnailUrl: string;
  Type: NewsAttachmentTypes;
  Url: string;
}

export enum NewsAttachmentTypes {
  IMG = 1,
  OTHER = 2,
  YT = 3,
  VIMEO = 4,
  IMG_SECURED = 5
};
