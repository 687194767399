import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';

export const RequiredTrimmedValidator = (control: AbstractControl): ValidationErrors | null => {
  const value = control.value;

  // If value is a string trim it first and verify if value is not an empty string
  // else check validity with default angular required validator
  if ((typeof value === 'string' && value.trim() === '') || Validators.required(control)) {
    return {
      required: true
    };
  }

  return null;
};
