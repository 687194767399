import * as fromApp from '../index';
import * as fromNotifications from './notifications.reducer';
import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';

export const notificationsFeatureKey = 'notificationsModule';
export const notificationsStateKey = 'notifications';

export interface FeatureState extends fromApp.State {
  notifications: State;
}

export interface State {
  notifications: fromNotifications.State;
}

export const reducers: ActionReducerMap<State> = {
  notifications: fromNotifications.notificationsReducer
};

// events module selectors
export const getNotificationsModuleState = createFeatureSelector<State>(
  notificationsFeatureKey
);

export const getNotificationsState = createFeatureSelector<
  fromNotifications.State
>(notificationsStateKey);

export const selectNotificationsState = createSelector(
  getNotificationsModuleState,
  getNotificationsState
);
export const selectNotificationsIds = createSelector(
  selectNotificationsState,
  fromNotifications.selectNoitificationsIds
);
export const selectNotifications = createSelector(
  selectNotificationsState,
  fromNotifications.selectNotificationsEntities
);
export const selectAllNotifications = createSelector(
  selectNotificationsState,
  fromNotifications.selectAllNotifications
);
export const selectNotificationsTotal = createSelector(
  selectNotificationsState,
  fromNotifications.selectNotificationsTotal
);
export const selectTotalUnseenNotifications = createSelector(
  selectNotificationsState,
  fromNotifications.getTotalUnseenNotifications
);
export const selectNotificationsRequestTimestamp = createSelector(
  selectNotificationsState,
  fromNotifications.getNotificationsRequestTimestamp
);
export const selectLoadingNotifications = createSelector(
  selectNotificationsState,
  fromNotifications.getLoadingNotifications
);
