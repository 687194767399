<h1 mat-dialog-title class="preview-header">
  Preview
  <button mat-button isIconButton="true" (click)="close()" class="close-btn">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</h1>
<div mat-dialog-content class="preview-content flex flex-col">
  <section class="flex flex-col flex-start-stretch gap-12">

            <ng2-pdfjs-viewer *ngIf="pdfBlob"
                              class="print-preview-dialog-iframe"
                              [pdfSrc]="pdfBlob"
                              [downloadFileName]="downloadFileName"
                              [openFile]="false"
                              [viewBookmark]="false"></ng2-pdfjs-viewer>
  </section>
</div>
