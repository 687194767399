import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ServiceNames} from '../interfaces/my7n-env-config';
import {Observable, of} from 'rxjs';
import {
  ISharepointAccessToken,
  ISharepointFiles,
  ISharepointGalleryFilesRequestParams,
  SharepointImageSize
} from '../interfaces/sharepoint';
import {tap} from 'rxjs/operators';
import * as moment from 'moment';
import {AppConfigService} from './app-config.service';

@Injectable()
export class SharepointService {
  API_SHAREPOINT: string;
  API_FILE: string;
  readonly LS_SHAREPOINT_TOKEN_KEY = 'gallery_token';

  readonly MAX_IMAGE_WIDTH = SharepointImageSize.MaxWidth;
  readonly MAX_IMAGE_HEIGHT = SharepointImageSize.MaxHeight;

  readonly THUMB_IMAGE_WIDTH = SharepointImageSize.DefaultThumbWidth;
  readonly THUMB_IMAGE_HEIGHT = SharepointImageSize.DefaultThumbHeight;

  get sharepointToken(): ISharepointAccessToken {
    try {
      return JSON.parse(localStorage.getItem(this.LS_SHAREPOINT_TOKEN_KEY)) as ISharepointAccessToken;
    } catch(e) {
      return;
    }
  };

  set sharepointToken(token: ISharepointAccessToken) {
    try {
      localStorage.setItem(this.LS_SHAREPOINT_TOKEN_KEY, JSON.stringify(token));
    } catch (e) {
      console.error('Sharepoint token save error');
    }
  }

  constructor(private http: HttpClient,
              private appConfigService: AppConfigService) {
    const fileApiPrefix = this.appConfigService.serviceUrl(ServiceNames.File, 'v1');
    const sharepointApiPrefix = this.appConfigService.serviceUrl(ServiceNames.Sharepoint, null);
    this.API_SHAREPOINT =  sharepointApiPrefix + '';
    this.API_FILE =  fileApiPrefix + 'file/';
  }

  isSharepointUrl(url: string): boolean {
    return url.startsWith(this.API_SHAREPOINT);
  }

  isTokenValid(): boolean {
    let isValid = false;
    const token = this.sharepointToken;
    if (token) {
      isValid = token.expires_on - moment().unix() > 0;
    }
    return isValid;
  }

  getAccessToken(): Observable<ISharepointAccessToken> {
    if (this.isTokenValid()) {
      return of(this.sharepointToken);
    }

    return this.http.get<ISharepointAccessToken>(this.API_FILE + 'gallery/getaccesstoken')
      .pipe(
        tap((token) => {
          this.sharepointToken = token;
        })
      );
  }

  getGalleryFiles(path: string, params: ISharepointGalleryFilesRequestParams = {}): Observable<ISharepointFiles> {
    params = {
      thumbSizeX:
        (params.thumbSizeX && params.thumbSizeX < this.MAX_IMAGE_WIDTH) ? params.thumbSizeX : this.THUMB_IMAGE_WIDTH,
      thumbSizeY:
        (params.thumbSizeY && params.thumbSizeY < this.MAX_IMAGE_HEIGHT) ? params.thumbSizeY : this.THUMB_IMAGE_HEIGHT,
      sizeX: (params.sizeX && params.sizeX < this.MAX_IMAGE_WIDTH) ? params.sizeX : this.MAX_IMAGE_WIDTH,
      sizeY: (params.sizeY && params.sizeY < this.MAX_IMAGE_HEIGHT) ? params.sizeY : this.MAX_IMAGE_HEIGHT
    }

    const reqParams = new URLSearchParams(params as URLSearchParams);
    return this.http.get<ISharepointFiles>(
      `${this.API_FILE}gallery/all?path=${path}${reqParams ? '&' + reqParams.toString() : ''}`
    );
  }

  getSharepointFile(fileUrl: string): Observable<Blob> {
    return this.http.get( fileUrl, {
      responseType: 'blob'
    });
  }
}
