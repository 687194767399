import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NewsViewSubmenuComponent } from '../../news-view/news-view-submenu/news-view-submenu.component';
import { MediaQueryService } from '@my7n/ui';

@Component({
  selector: 'cms-news-view-submenu',
  templateUrl: '../../news-view/news-view-submenu/news-view-submenu.component.html'
})
export class CmsNewsViewSubmenuComponent extends NewsViewSubmenuComponent {

  get queryParams() {
    const isPreviewMode = this.activatedRoute.snapshot.url[3]?.path === 'preview';

    if (isPreviewMode) {
      return {
        newsPreviewMode: true,
        previewNewsId: this.activatedRoute.snapshot.url[2].path
      };
    }

    return null;
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    public mediaQueryService: MediaQueryService 
  ) {
    super(mediaQueryService);
  }
}
