import { FrontendFeature, FrontendFeaturesModule } from '../feature';
import { Feature } from '../interfaces/feature';

export const BUSINESS_UNIT_PREFIX = 'business-unit/';
@FrontendFeaturesModule('BusinessUnit')
export class BusinessUnitFeatures {
  @FrontendFeature({
    summary: 'BU Poland'
  })
  static readonly Poland: Feature = BUSINESS_UNIT_PREFIX + 'poland';

  @FrontendFeature({
    summary: 'BU Denmark'
  })
  static readonly Denmark: Feature = BUSINESS_UNIT_PREFIX + 'denmark';

  @FrontendFeature({
    summary: 'BU India'
  })
  static readonly India: Feature = BUSINESS_UNIT_PREFIX + 'india';

  @FrontendFeature({
    summary: 'BU International'
  })
  static readonly International: Feature =
    BUSINESS_UNIT_PREFIX + 'international';

  @FrontendFeature({
    summary: 'BU HQ'
  })
  static readonly HQ: Feature = BUSINESS_UNIT_PREFIX + 'hq';
}
