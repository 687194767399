<div *rxLet="state$ as localState"
     class="reactions-container flex flex-row flex-start-center gap-3">
  <reactions-list *ngIf="localState.reactions !== null"
                  [reactions]="localState.reactions"
                  [liked]="localState.liked"></reactions-list>
  <reactions-button *ngIf="localState.reactions !== null"
                    #reactionsButton
                    [contentId]="localState.contentId"
                    [contentType]="localState.contentType"
                    [liked]="localState.liked"
                    (reactionSave)="onReactionClick(localState.contentId, localState.contentType, $event)"></reactions-button>
</div>