import { Input, OnInit, ChangeDetectorRef, Injectable } from '@angular/core';
import { take } from 'rxjs';
// Services
import { CvLanguageService } from '../services/cv-language.service';
// Interfaces
import { ICvLanguage } from '../interfaces/cv-language';
import { ICvListItem } from '../interfaces/cv-list-item';

@Injectable()
export class BaseCvElement implements OnInit {
  @Input() cv: ICvListItem;
  cvLanguage: ICvLanguage = {
    LanguageCode: '',
    LanguageName: ''
  };

  constructor(
    private cvLanguageService: CvLanguageService,
    protected cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.cvLanguageService.get(this.cv.Language)
      .pipe(take(1))
      .subscribe((lang: ICvLanguage) => {
        this.cvLanguage = lang;
        this.cdr.markForCheck();
      });
  }

  stopPropagation($event) {
    // Prevent from double router navigate calling on anchor element
    $event.stopPropagation();
    $event.stopImmediatePropagation();
  }
}
