import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ICmsVideoUpdateItem } from '../../interfaces/cms';
import * as selectors from '../../store/reducers/cms/video-update.selectors';
import * as fromCms from '../../store/reducers/cms/index';
import * as VideoUpdateActions from '../../store/actions/cms/video-update.actions';

@Injectable()
export class VideoUpdateFacadeService {
  constructor(private store: Store<fromCms.State>) {}

  get latestVideoUpdate$(): Observable<ICmsVideoUpdateItem> {
    return this.store.select(selectors.selectLatestVideoUpdate);
  }

  get latestVideoUpdateLoading$(): Observable<boolean> {
    return this.store.select(selectors.selectLatestVideoUpdateLoading);
  }

  get latestVideoUpdateRequestTimestamp$(): Observable<number> {
    return this.store.select(selectors.selectLatestVideoUpdateRequestTimestamp);
  }

  queryLatestVideoUpdateByUserBu() {
    this.store.dispatch(VideoUpdateActions.queryLatestVideoUpdateByUserLegalEntityCountryFeature());
  }

  queryVideoUpdatePreviewById(id: string) {
    this.store.dispatch(VideoUpdateActions.queryVideoUpdatePreviewById({ id }));
  }
}
