<div class="feedback-dialog-wrapper">
  <form [formGroup]="feedbackForm" (ngSubmit)="onSubmit()" data-test-id="feedback-modal-form">
    <h1 mat-dialog-title>Send feedback
      <button mat-button 
              type="button" 
              isIconButton="true" 
              (click)="closeDialog()" 
              class="close-btn"
              data-test-id="feedback-modal-close-button">
        <mat-icon svgIcon="close"></mat-icon>
      </button>
    </h1>
    <div mat-dialog-content class="feedback-dialog" aria-label="Feedback dialog">
      <p>Share an idea or report a problem</p>
      <mat-form-field appearance="outline">
        <mat-label>Your message</mat-label>
        <textarea matInput 
                  rows="4" 
                  formControlName="userFeedback" 
                  data-test-id="feedback-modal-form-message-textarea"></textarea>
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button type="submit" 
              mat-flat-button 
              color="primary" 
              class="small-button" 
              [disabled]="feedbackForm.controls.userFeedback.invalid"
              data-test-id="feedback-modal-form-send-button">Send feedback</button>
      <button type="button" 
              mat-button 
              color="primary" 
              class="small-button" 
              (click)="closeDialog()"
              data-test-id="feedback-modal-form-cancel-button">Cancel</button>
    </div>
  </form>
</div>

