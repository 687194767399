import { createSelector } from '@ngrx/store';
import { getCmsModuleState, getDictionariesState } from './index';
import * as fromDictionaries from './dictionaries.reducer';
import {
  CmsDictionaryTypes,
  ICmsDictionaryItem
} from '../../../interfaces/cms';

export const selectDictionariesState = createSelector(
  getCmsModuleState,
  getDictionariesState
);

export const selectDictionaries = createSelector(
  selectDictionariesState,
  fromDictionaries.getDictionaries
);

export const selectDictionariesLoading = createSelector(
  selectDictionariesState,
  fromDictionaries.getDictionariesLoading
);

// https://github.com/ngrx/platform/issues/2980#issuecomment-819551245
export const selectDictionaryByType = (dictionaryType: CmsDictionaryTypes) =>
  createSelector(selectDictionaries, (dictionaries) => {
    return dictionaries?.filter(
      (item: ICmsDictionaryItem) => item.type === dictionaryType
    );
  });

export const selectDictionariesByTypes = (types: Array<CmsDictionaryTypes>) =>
  createSelector(selectDictionaries, (dictionaries) => {
    return dictionaries?.filter((item: ICmsDictionaryItem) =>
      types.includes(item.type)
    );
  });
