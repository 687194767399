import { Action, createReducer, on } from '@ngrx/store';
import * as GlobalCommonActions from '../../actions/global.actions';
import { ITokenClaims } from '../../../interfaces/token-claims';
import { IBlobSasTokens } from '../../../interfaces/blob-sas';

export interface GlobalCommonState {
  appVersion: string;
  leavingPageGuardEnabled: boolean;
  tokenClaims: ITokenClaims;
  blobSasTokens: IBlobSasTokens;
}

export const initialState: GlobalCommonState = {
  appVersion: '0.0.0',
  leavingPageGuardEnabled: false,
  tokenClaims: null,
  blobSasTokens: null
};

const _globalReducer = createReducer(
  initialState,
  on(GlobalCommonActions.updateAppVersion, (state, { appVersion }) => ({
    ...state,
    appVersion: appVersion
  })),
  on(GlobalCommonActions.updateLeavingPageGuardEnabled, (state, { isEnabled }) => ({
    ...state,
    leavingPageGuardEnabled: isEnabled
  })),
  on(GlobalCommonActions.updateTokenClaims, (state, { tokenClaims }) => ({
    ...state,
    tokenClaims
  })),
  on(GlobalCommonActions.queryBlobSasTokensSuccess, (state, { blobSasTokens }) => ({
    ...state,
    blobSasTokens
  }))
);

export function globalReducer(state: GlobalCommonState | undefined, action: Action) {
  return _globalReducer(state, action);
}

export const getAppVersion = (state: GlobalCommonState) => {
  return state.appVersion;
};

export const getLeavingPageGuardEnabled = (state: GlobalCommonState) => {
  return state.leavingPageGuardEnabled;
};

export const getTokenClaims = (state: GlobalCommonState) => {
  return state.tokenClaims;
};

export const getTokenClaimsBusinessUnit = (state: GlobalCommonState) => {
  return state.tokenClaims.BusinessUnit;
};

export const getBlobSasTokens = (state: GlobalCommonState) => {
  return state.blobSasTokens;
};
