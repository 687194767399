import { Injectable, ElementRef } from '@angular/core';

@Injectable()
export class MatMenuCustomService {

    constructor() { }

    matMenuClosed(event: any, trigger: ElementRef) {
        if (event === undefined) { // void is clicking on the backdrop
            window.setTimeout(() => {
                trigger.nativeElement.blur();
            }, 0);
        }
        window.setTimeout(() => {
            trigger.nativeElement.classList.remove('menu-opened');
        }, 0);
    }

    matMenuOpened(trigger: ElementRef) {
        window.setTimeout(() => {
            trigger.nativeElement.classList.add('menu-opened');
        }, 0);
    }

}
