import { Component, Input, OnInit } from '@angular/core';
import { IGalleryConfig } from '../../../interfaces/gallery-config';
import { INewsAttachment } from '../../../interfaces/news-attachment';

@Component({
  selector: 'gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  @Input() items: INewsAttachment[];
  @Input() newsId: number;
  @Input() itemHeight: number;
  @Input() limitToOneRow: boolean;
  @Input() set rowItems(value: number) {
    if (this.config) {
      this.config.rowItems = value;
    }
    this._rowItems = value;
  }

  get rowItems() {
    return this._rowItems;
  }

  selfInstance: GalleryComponent;

  /**
   * Gallery config object.
   */
  config: IGalleryConfig;
  private _rowItems = 3;

  constructor() {
    this.selfInstance = this;
  }

  ngOnInit() {
    this.config = {
      itemHeight: this.itemHeight || 120,
      rowItems: this.rowItems,
      limitToOneRow: this.limitToOneRow || false
    };
  }
}
