import { Component, OnInit, OnDestroy, Input, ElementRef, Output, EventEmitter, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { SafeUrl } from '@angular/platform-browser';
// Services
import { LabeledEditService } from '../../../services/labeled-edit.service';
import { MediaQueryService } from '@my7n/ui';
// Validators
import { EmailValidator } from '../../../validators/email-validator';
import { RequiredTrimmedValidator } from '../../../validators/required-trimmed.validator';
import { IStringMap } from '@my7n/ui';
import { UrlValidator } from '../../../validators/url-validator';
// Services
import { AgentService } from '../../../services/agent.service';
// Interfaces
import { COMMON_BUTTONS_TEXTS } from '../../../interfaces/common-texts';

@Component({
  selector: 'labeled-edit',
  templateUrl: './labeled-edit.component.html',
  styleUrls: ['./labeled-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ AgentService, LabeledEditService ] // AgentService is dependency for LabeledEditService
})
export class LabeledEditComponent implements OnInit, OnDestroy {
  @Input() consultantId: string;
  @Input() fieldValue: any;
  @Input() required: boolean;
  @Input() maxlength: number;
  @Input() validateEmail: boolean;
  @Input() display: string;
  @Input() label: string;
  @Input() type: string;
  @Input() placeholder: string;
  @Input() activateWith: string;
  @Input() updateMethodName: string;
  @Input() readonly = false;
  @Input() tooltipHtml: string;
  @Input() multilineTooltip = false;
  @Input() texts: IStringMap = {};
  @Input() canEdit = true;

  @Output() serverError = new EventEmitter<boolean>();
  @Output() updateSuccess = new EventEmitter<any>();

  COMMON_BUTTONS_TEXTS = COMMON_BUTTONS_TEXTS;

  labeledEditForm: UntypedFormGroup;

  editMode = false;
  loading = false;
  error = false;
  _formCopyValue: any;
  getContactWebsite: () => string | null;
  getSkype: () => SafeUrl | null;
  safeSkype: any;

  constructor(public mediaQueryService: MediaQueryService,
              public element: ElementRef,
              private fb: UntypedFormBuilder,
              private labeledEditService: LabeledEditService,
              public sanitizer: DomSanitizer,
              private changeDetRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    const valueValidatorsArray: any[] = [];

    if (this.required) {
      valueValidatorsArray.push(RequiredTrimmedValidator);
    }

    if (this.type === 'email') {
      valueValidatorsArray.push(EmailValidator);
    }

    if (this.type === 'url') {
      valueValidatorsArray.push(UrlValidator);
    }

    if (this.maxlength) {
      valueValidatorsArray.push(Validators.maxLength(this.maxlength));
    }

    this.labeledEditForm = this.fb.group({
      fieldValue: this.fb.control(this.fieldValue, valueValidatorsArray)
    });

    this.display = this.display || 'text';
    this.type = this.type || 'text';

    if (this.display === 'url') {
      this.getContactWebsite = () => {
        const companyWebsite = this.fieldValue;
        if (companyWebsite) {
          return companyWebsite.indexOf('http') === 0 ? companyWebsite : 'http://' + companyWebsite;
        }
        return null;
      };
    }

    if (this.display === 'skype') {
      this.getSkype = () => {
        const skype = this.fieldValue;
        if (skype) {
          this.safeSkype = this.sanitizer.bypassSecurityTrustUrl(('skype:' + skype + '?call').toString());
          return this.safeSkype;
        }
        return null;
      };
    }
  }

  checkForActivate() {
    return null;
  }

  activate() {
    if (!this.editMode && !this.readonly) {
      this.editMode = true;
      this.error = false;
      this._formCopyValue = this.labeledEditForm.value.fieldValue;
    }
  }

  keyListener($event) {
    switch ($event.which) {
      // Bind ESC key
      case 27:
        this.reset();
        break;
    }
  }

  reset() {
    // may be undefined within tests
    this.labeledEditForm.reset({ fieldValue: this._formCopyValue });
    this.fieldValue = this._formCopyValue;

    this.editMode = false;
    this.loading = false;
  }

  clear($event) {
    this.labeledEditForm.setValue({ fieldValue: null });

    $event.stopPropagation();
  }

  update() {
    if (this.loading || this.readonly) {
      return false;
    }

    if (!this.canEdit) {
      console.debug('[LabeledEditComponent] User doesn\'t have permission to edit');
      return false;
    }

    if (this.labeledEditForm.invalid) {
      console.debug('[LabeledEditComponent] Form is invalid, value:', this.labeledEditForm.controls.fieldValue.value);
      return false;
    }

    this.error = false;
    this.loading = true;

    this.labeledEditForm.controls.fieldValue.setValue(this.getTrimmed(this.labeledEditForm.controls.fieldValue.value));

    this.labeledEditService.labeledEditSave(this.labeledEditForm.controls.fieldValue.value, this.updateMethodName, this.consultantId).then(() => {
      this.error = false;
      this.editMode = false;
      this.loading = false;
      this.fieldValue = this.labeledEditForm.controls.fieldValue.value;
      this.updateSuccess.emit(this.fieldValue);
    }, () => {
      this.reset();
      this.error = true;
      this.serverError.emit(true);
    }).finally(() => {
      this.changeDetRef.markForCheck();
    });
    return true;
  }

  ngOnDestroy() {
    this.checkForActivate();
  }

  private getTrimmed(value: string): string | null {
    return value ? value.trim() : null;
  }
}
