import { Component, OnInit, ChangeDetectionStrategy, Input, ElementRef, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LogVideoModules, videoPlayerDefaultConfig } from '../../../interfaces/video-player';

// rxjs
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Interfaces
import { IGalleryConfig } from '../../../interfaces/gallery-config';
import { ISharepointFiles, SharepointImageSize } from '../../../interfaces/sharepoint';
import { CmsContentTypes } from '../../../interfaces/cms';
import { ICmsNewsList } from '../../../interfaces/news';
import { INewsMediaSize } from '../../../interfaces/news-media-size';
import { ContentTypes } from '../../../interfaces/reactions';

// services
import { CmsCardService } from '../cms-card/cms-card.service';
import { GalleryFacadeService } from '../../../services/facades/gallery-facade.service';

// helpers
import { SharepointHelper } from '../../../utils/helpers/sharepoint.helper';
import { IMediaQueriesBreakpoints, MediaQueryService } from '@my7n/ui';

@Component({
  selector: 'cms-news-card',
  templateUrl: './cms-news-card.component.html',
  styleUrls: ['./cms-news-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CmsNewsCardComponent implements OnInit, OnDestroy {
  @Input() news: ICmsNewsList;
  @Input() titleLimit: number;
  @Input() descriptionLimit: number;

  @ViewChild('newsContainer', { static: true }) newsContainer: ElementRef;

  detailsUrl: string;
  imageSize: INewsMediaSize;
  showVideo: boolean;
  defaultImageUrl = 'assets/images/timeline/news-list-default-bg.png';
  videoPlayerConfig = videoPlayerDefaultConfig;

  contentType = ContentTypes.MY7N_NEWS;

  LogVideoModules = LogVideoModules;

  galleryPhotos$: Observable<ISharepointFiles>;
  galleryItemsInRowNumber: number;
  galleryConfig: IGalleryConfig = {
    itemHeight: 252,
    rowItems: 3,
    limitToOneRow: true
  };

  private _destroySubject$: Subject<void> = new Subject();

  readonly galleryItemsInRow = {
    MOBILE: 3,
    DESKTOP: 5
  };

  get hasImage(): boolean {
    return !!this.news.Image;
  }

  get hasVideo(): boolean {
    return !!this.news.VideoUrl;
  }

  get hasVideoThumbnail(): boolean {
    return !!this.news.VideoThumbnail;
  }

  get hasGallery(): boolean {
    return !!this.news.GalleryUrl;
  }

  get bannerImage() {
    if (this.hasVideo) {
      if (this.hasVideoThumbnail) {
        return this.news.VideoThumbnail;
      } else {
        return this.news.Image || this.defaultImageUrl;
      }
    }

    if (this.hasImage) {
      return this.news.Image;
    }

    return null;
  }

  get isPreview(): boolean {
    return this.activatedRoute.snapshot.queryParams['newsPreviewMode'] === 'true';
  }

  get newsId(): string {
    return this.news.Id as string;
  }

  /**
   * window.resize event listener function.
   */
  windowResizeListener = () => this.resizeContent();

  constructor(
    private cmsCardService: CmsCardService,
    private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private galleryFacade: GalleryFacadeService,
    private mediaQueryService: MediaQueryService
  ) {}

  ngOnInit() {
    // set details url
    this.detailsUrl = this.cmsCardService.getLinkPrefix(CmsContentTypes.News) + this.news.Id + (this.isPreview ? '/preview' : '');

    this.resizeContent();
    window.addEventListener('resize', this.windowResizeListener);

    if (this.hasVideo) {
      this.showVideo = false;

      this.videoPlayerConfig = {
        ...this.videoPlayerConfig,
        containerHeight: 425,
        containerWidth: 755
      }

    } else if (this.hasImage) {
      this.imageSize = {
        width: 755,
        height: 250,
        ratio: 250 / 755
      };
    }

    if (this.news.GalleryUrl) {
      this.initNewsGallery(this.news.GalleryUrl);
    }
  }

  initNewsGallery(galleryUrl: string) {
    const galleryDir = SharepointHelper.extractGalleryDir(galleryUrl);

    if (!galleryDir) {
      console.warn('Did not extract gallery dir for: ', galleryUrl);
      return;
    }
    this.galleryItemsInRowNumber = this.galleryItemsInRow.DESKTOP;

    this.galleryPhotos$ = this.galleryFacade.getGallery$(galleryDir);
    this.galleryFacade.queryGalleryData(galleryDir, {
      thumbSizeX: SharepointImageSize.NewsThumbWidth,
      thumbSizeY: SharepointImageSize.NewsThumbHeight
    });

    this.registerGalleryRowItemsHandler();
  }

  registerGalleryRowItemsHandler() {
    this.mediaQueryService.breakpoints$
      .pipe(takeUntil(this._destroySubject$))
      .subscribe((breakpoints: IMediaQueriesBreakpoints) => {
        const isXsMobile = breakpoints.ltSm;
        this.galleryItemsInRowNumber = isXsMobile ? this.galleryItemsInRow.MOBILE : this.galleryItemsInRow.DESKTOP;
      });
  }

  /**
   * Resize video iframe to match all responsive widths.
   */
  resizeContent() {
    window.setTimeout(() => {
      const width = this.newsContainer.nativeElement.offsetWidth;

      if (this.imageSize) {
        this.imageSize.width = width;
        this.imageSize.height = Math.round(width * this.imageSize.ratio);
        this.cdr.markForCheck();
      }

      if (this.hasVideo) {
        const ratio = 425 / 755;
        this.videoPlayerConfig = {
          ...this.videoPlayerConfig,
          containerHeight: Math.round(width * ratio),
          containerWidth: width
        }
        this.cdr.markForCheck();
      }
    }, 0);
  }

  show() {
    this.showVideo = true;
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.windowResizeListener);
  }
}
