import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';

// Services
import { LocationEditService } from '../../../services/location-edit.service';
import { CheckboxCategoryTreeService } from '../../../services/checkbox-category-tree.service';
import { MediaQueryService } from '@my7n/ui';
import { AgentService } from '../../../services/agent.service';

// Validators
import { CheckboxRequiredValidator } from '../../../validators/checkbox-required.validator';

// Interfaces
import { ILocation } from '../../../interfaces/location';
import { COMMON_BUTTONS_TEXTS } from '../../../interfaces/common-texts';

@Component({
  selector: 'location-edit',
  templateUrl: './location-edit.component.html',
  styleUrls: ['./location-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AgentService, LocationEditService] // AgentService is dependency for LocationEditService
})
export class LocationEditComponent implements OnInit {

  private _locations: Array<ILocation>;

  @Input() set locations(value: Array<ILocation>) {
    this._locations = JSON.parse(JSON.stringify(value));
  };

  get locations(): Array<ILocation> {
    return this._locations;
  }

  @Input() updateMethodName: string;
  @Input() hint: boolean;
  @Input() consultantId: string;
  @Input() readonly = false;
  @Input() insideSidePanel = false;
  @Input() canEdit = true;

  @Output() serverError = new EventEmitter<boolean>();
  @Output() updateSuccess = new EventEmitter<Array<ILocation>>();

  COMMON_BUTTONS_TEXTS = COMMON_BUTTONS_TEXTS;

  loading = false;
  error = false;
  _CopiedLocations = null;

  atLeastOneChecked = false;
  get hintMessage(): boolean {
    return this.hint || false;
  }

  locationEditForm: UntypedFormGroup;

  private _defaultFormState;
  private _editMode$ = new BehaviorSubject<boolean>(false);

  get editMode$(): Observable<boolean> {
    return this._editMode$.asObservable();
  }

  constructor(
    public mediaQueryService: MediaQueryService,
    private fb: UntypedFormBuilder,
    private locationEditService: LocationEditService,
    private changeDetRef: ChangeDetectorRef
    ) { }

  static nameAttributeGenerate(checkbox) {
    return ['location', checkbox.Name, checkbox.Id].map(part => {
      return ((part + '') || '').replace(/[\s|,|\.|-]/g, '').toLowerCase();
    }).join('-');
  }

  ngOnInit() {
    if (this.locations) {
      this.atLeastOneChecked = this.locations.some(CheckboxCategoryTreeService.findChecked);
    }
    this.buildForm();
  }

  private buildForm() {
    const formGroupCheckboxes: any = {};

    formGroupCheckboxes.checkboxes = CheckboxCategoryTreeService.buildFormArray(this.locations || [], CheckboxRequiredValidator(1));

    this.locationEditForm = this.fb.group(formGroupCheckboxes);

    this._defaultFormState = this.locationEditForm.value;
  }

  keyListener($event) {
    switch ($event.which) {
      // Bind ESC key
      case 27:
        this.reset();
        break;
    }
  }

  locationsCheckedLength() {
    if (this.locations) {
      return this.locations.filter(function (location) { return location.Checked === true; }).length;
    }
    return;
  }

  edit() {
    if (!this._editMode$.value && !this.readonly) {
      this._editMode$.next(true);
    }
  }

  reset() {
    if (this.loading) {
      console.warn('[LocationEditComponent] Cannot cancel industry knowledge edit during loading');
      return;
    }

    this.locationEditForm.reset(this._defaultFormState);

    this._editMode$.next(false);
    this.error = false;
    this.loading = false;
  }

  save(locationEditForm: UntypedFormGroup) {
      const locationsIdsArray = [];

      if (this.readonly) {
        console.warn('[LocationEditComponent] Cannot save in readonly mode');
        return;
      }

      if (this.loading) {
        console.warn('[LocationEditComponent] Cannot save during loading');
        return;
      }

      if (!this.canEdit) {
        console.debug('[LocationEditComponent] User doesn\'t have permission to edit');
        return false;
      }

      if (locationEditForm.invalid) {
        console.debug('[LocationEditComponent] form is invalid, value: ', locationEditForm.value);
        return;
      }

      this.loading = true;

      const locations = this.locations;
      const checkboxesValues = locationEditForm.value.checkboxes;

      CheckboxCategoryTreeService.collectValues(checkboxesValues, locations, locationsIdsArray);

      this.locationEditService.locationsEditSave(locationsIdsArray, this.updateMethodName, this.consultantId).then((result) => {
        CheckboxCategoryTreeService.updateModel(locations, locationsIdsArray);
        this._editMode$.next(false);
        this.loading = false;

        this.atLeastOneChecked = locationsIdsArray.length > 0;
        this._defaultFormState = locationEditForm.value;
        this.updateSuccess.emit(this.locations);
        return result;
      }, (error) => {
        console.error('[LocationEditComponent] Failed to update industry ', error);

        this.error = true;
        this.loading = false;
        this.serverError.emit(true);
        return Promise.reject(error);
      }).finally(() => {
        this.changeDetRef.markForCheck();
      });
  }

  switchEditMode() {
    this._editMode$.next(!this._editMode$.value);
  }
}
