import { IEnvironment } from '../interfaces/environment';

export const environment: IEnvironment = {
  production: true,
  enableServiceWorker: true,
  /**
   * Environment dependent variables are defined within index.html
   * and should be replaced by external script
   */
};
