<ng-container *ngIf="(mediaQueryService.breakpoints$ | async) as breakpoints">
  <div class="locations-edit-container" *ngIf="!(editMode$ | async) else editForm" data-test-id="locations-edit-container">
      <div class="locations-edit-content">
        <div class="subtitle" *ngIf="!insideSidePanel">Locations</div>
        <checkboxes-categories-details [checkboxes]="locations" *ngIf="atLeastOneChecked"></checkboxes-categories-details>
        <ng-container *ngIf="!readonly && !insideSidePanel && canEdit">
          <button mat-button
                  class="small-button"
                  *ngIf="locationsCheckedLength() === 0"
                  (click)="edit()"
                  data-test-id="locations-edit-add-button">Add</button>
          <button mat-button
                  class="small-button"
                  *ngIf="atLeastOneChecked"
                  (click)="edit()"
                  data-test-id="locations-edit-edit-button">Edit</button>
        </ng-container>
      </div>
  </div>
  <ng-template #editForm>
    <form class="edit-form"
          [ngClass]="{loading: loading}"
          novalidate
          name="locationEditForm"
          [formGroup]="locationEditForm"
          (ngSubmit)="save(locationEditForm)"
          (keydown)="keyListener($event)"
          (keypress)="keyListener($event)"
          data-test-id="locations-edit-form">
    <h4 class="font-bold">Edit Preferred work location(s)</h4>
    <div class="location-options">
      <div>
        <checkboxes-categories-edit [checkboxes]="locations"
                                  [name]="'locations'"
                                  [parent]="locationEditForm.controls['checkboxes']"></checkboxes-categories-edit>
      </div>
      <div>
          <mat-error [form]="locationEditForm"
                     [field]="locationEditForm.controls['checkboxes']"
                     [messages]="['noRequiredCheckboxCount']"
                     [texts]="{noRequiredCheckboxCount:'Select minimum one location'}"
                     data-test-id="locations-edit-matError"></mat-error>
          <span class="color-danger" *ngIf="error" data-test-id="locations-edit-serverError">Something went wrong - please try again later.</span>
      </div>
    </div>
    <div class="form-actions">
        <button mat-stroked-button
                color="primary"
                class="small-button"
                type="submit"
                [loading]="loading"
                data-test-id="locations-edit-save-button">
          {{ breakpoints.ltMd ? COMMON_BUTTONS_TEXTS.SAVE_CHANGES_MOBILE : COMMON_BUTTONS_TEXTS.SAVE_CHANGES_DESKTOP}}
        </button>
        <button mat-button
                color="primary"
                class="small-button cancel-button"
                (click)="reset()"
                data-test-id="locations-edit-discard-button">
          {{ breakpoints.ltMd ? COMMON_BUTTONS_TEXTS.DISCARD_CHANGES_MOBILE: COMMON_BUTTONS_TEXTS.DISCARD_CHANGES_DESKTOP}}
        </button>
    </div>
  </form>
  </ng-template>
</ng-container>

