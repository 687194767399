import { createSelector } from '@ngrx/store';
import { getCmsModuleState, getVideoUpdateState } from './index';
import * as fromVideoUpdate from './video-update.reducer';

export const selectVideoUpdateState = createSelector(
  getCmsModuleState,
  getVideoUpdateState
);

export const selectLatestVideoUpdate = createSelector(
  selectVideoUpdateState,
  fromVideoUpdate.getLatestVideoUpdate
);

export const selectLatestVideoUpdateLoading = createSelector(
  selectVideoUpdateState,
  fromVideoUpdate.getLatestVideoUpdateLoading
);

export const selectLatestVideoUpdateRequestTimestamp = createSelector(
  selectVideoUpdateState,
  fromVideoUpdate.getLatestVideoUpdateRequestTimestamp
);
