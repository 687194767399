import { createAction, props } from '@ngrx/store';
import { ICmsVideoUpdateItem } from '../../../interfaces/cms';

export const queryLatestVideoUpdateByUserLegalEntityCountryFeature = createAction(
  '[Video Update] query Latest Video Update By User Legal Entity Country feature'
);

export const latestVideoUpdateLoaded = createAction(
'[Video update] Latest Video Update loaded',
  props<{ latestVideoUpdate: ICmsVideoUpdateItem }>()
);

export const latestVideoUpdateLoadedFromCache = createAction(
  '[Video Update] Latest Video Update loaded from cache'
);

export const queryLatestVideoUpdateByUserLegalEntityCountryFeatureError = createAction(
  '[Video Update] query Latest Video Update By User Legal Entity Country feature error'
);

export const queryVideoUpdatePreviewById = createAction(
  '[Video Update] query Video Update Preview By ID',
  props<{ id: string }>()
);

export const querVideoUpdatePreviewByIdError = createAction(
  '[Video Update] query Video Update Preview By ID error'
);
