// vendor modules
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
  HammerModule
} from '@angular/platform-browser';
import * as Hammer from 'hammerjs';
import {
  NgModule,
  APP_INITIALIZER,
  ErrorHandler,
  Injectable
} from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { My7nAppRoutingModule } from './my7n-app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import {
  AbstractSecurityStorage,
  AuthInterceptor,
  AuthModule,
  OidcSecurityService,
  StsConfigLoader,
  StsConfigStaticLoader
} from 'angular-auth-oidc-client';

// Disabled because it was causing troubles in search view #9336 #9335
import { RouteReuseStrategy } from '@angular/router';
import { CacheRouteReuseStrategy } from './classes/cache-route-reuse.strategy';

// app modules
import { My7nMaterialModule } from './modules/my7n-material.module';
import { My7nTimelineModule } from './modules/my7n-timeline.module';
import { My7nCommonModule } from './modules/my7n-common.module';
import { My7nUserProfileModule } from './modules/my7n-user-profile.module';
import { My7nNotificationsModule } from './modules/my7n-notifications.module';
import { My7nSharedModule } from './modules/my7n-shared.module';
import { My7nCmsModule } from './modules/my7n-cms.module';

// components
import { MainComponent } from './components/main/main.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { NavigationMenuComponent } from './components/navigation/navigation-menu/navigation-menu.component';
import { NavigationContentComponent } from './components/navigation/navigation-content/navigation-content.component';
import { NavigationContentItemComponent } from './components/navigation/navigation-content/navigation-content-item/navigation-content-item.component';
import { NavigationSocialLinksComponent } from './components/navigation/navigation-social-links/navigation-social-links.component';
import { WelcomePageComponent } from './components/welcome-page/welcome-page.component';
import { FeedbackModalComponent } from './components/feedback-modal/feedback-modal.component';
import { TermsAgreementComponent } from './components/terms-agreement/terms-agreement.component';
import { LoginRequiredErrorComponent } from './components/info-pages/login-required-error/login-required-error.component';

// directives
import { CustomRouterOutletDirective } from './directives/custom-router-outlet.directive';

// services
import { LogService } from './services/log.service';
import { UserService } from './services/user.service';
import { NavigationService } from './services/navigation.service';
import { AuthorizationService } from './services/authorization.service';
import { AuthenticationService } from './services/authentication.service';
import { EnvironmentService } from './services/environment.service';
import { ErrorHandlerService } from './services/error-handler.service';
import { ApplicationInsightsService } from './services/application-insights.service';
import { My7nOAuthStorageService } from './services/my7n-oauth-storage.service';
import { AppConfigService } from './services/app-config.service';
import { PwaService } from './services/pwa.service';
import { PageTitleService } from './services/page-title.service';

// resolvers
import { UpdateAndReloadResolver } from './resolvers/update-and-reload.resolver';

// interceptors
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { UnauthorizedHttpInterceptor } from './interceptors/unauthorized-http-interceptor.service';
import { CsrfHeaderInterceptorHttpInterceptor } from './interceptors/csrf-header-http-interceptor.service';
import { SasPortraitsHttpInterceptor } from './interceptors/sas-portraits-http-interceptor.service';
import { SharepointHttpInterceptor } from './interceptors/sharepoint-http-interceptor.service';

// guards
import { ModuleEnabledGuard } from './guards/module-enabled.guard';

// init functions
import { initAll } from './my7n-app-init';
import {
  MY7N_ENV_CONFIG,
  my7nEnvConfigFactory
} from './functions/my7n-env-config';
import { environment } from './environments/environment';

// ngrx
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './store/reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, RouterState } from '@ngrx/router-store';
import { RouterEffects } from './store/effects/router.effets';

@Injectable()
export class CustomHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    pinch: { enable: false },
    rotate: { enable: false }
  };

  // This method is crucial to the proper working of gestures (swipe left/right).
  // As for Angular 10/11 it's not clear how HammerJS should be configured, the documentation is poor.
  // Default config doesn't seem to work, and we still need to import 'hammerjs' directly.
  // For now, it is even not mandatory to include this custom config inside providers, and it will still working (because of DI?).
  // The question has been posted on SO: https://stackoverflow.com/questions/65003991
  buildHammer(element: HTMLElement) {
    const mc = new Hammer(element, {
      touchAction: 'pan-y'
    });

    return mc;
  }
}

//
const authFactory = (configService: AppConfigService) => {
  const config = configService.oauthConfig;
  return new StsConfigStaticLoader(config);
};

@NgModule({
  imports: [
    BrowserModule,
    My7nAppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    My7nMaterialModule,
    My7nTimelineModule,
    My7nCommonModule,
    My7nUserProfileModule,
    FormsModule,
    My7nNotificationsModule,
    My7nCmsModule,
    My7nSharedModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([RouterEffects]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Minimal
    }),
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: authFactory,
        deps: [AppConfigService]
      }
    }),
    HammerModule
  ],
  declarations: [
    MainComponent,
    NavigationComponent,
    NavigationMenuComponent,
    WelcomePageComponent,
    FeedbackModalComponent,
    TermsAgreementComponent,
    CustomRouterOutletDirective,
    LoginRequiredErrorComponent,
    NavigationContentComponent,
    NavigationContentItemComponent,
    NavigationSocialLinksComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CsrfHeaderInterceptorHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SasPortraitsHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SharepointHttpInterceptor,
      multi: true
    },
    { provide: MY7N_ENV_CONFIG, useFactory: my7nEnvConfigFactory },
    OidcSecurityService,
    // https://nice-hill-002425310.azurestaticapps.net/docs/documentation/using-access-tokens/#http-interceptor
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    LogService,
    UserService,
    // !!!
    // !!! LogService is set as dependency so that a instance of the LogService gets created even without any injection in other code
    // !!!
    {
      provide: APP_INITIALIZER,
      useFactory: initAll,
      deps: [AuthenticationService, ApplicationInsightsService, LogService],
      multi: true
    },
    {
      provide: AbstractSecurityStorage,
      useClass: My7nOAuthStorageService
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService
    },
    {
      provide: RouteReuseStrategy,
      useClass: CacheRouteReuseStrategy
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: CustomHammerConfig
    },
    // services
    AppConfigService,
    EnvironmentService,
    NavigationService,
    AuthorizationService,
    PageTitleService,
    PwaService,
    UpdateAndReloadResolver,
    // guards
    ModuleEnabledGuard

  ],
  bootstrap: [MainComponent],
  exports: []
})
export class My7nAppModule {}
