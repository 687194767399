<tile [link]="['/cv/', cv.Id]"
      [hideBackgroundImage]="true">
  <div class="container cv-list-element-progress-{{ cv.Progress }} flex flex-col flex-between-stretch"
       id="cv-id-{{ cv.Id }}">
    <div class="body">
      <div class="body-header flex flex-between-center max-md:hidden">
        <div class="labels">
          <span class="pill" color="accent" *ngIf="cv.IsSent">Sent to 7N</span>
          <span class="pill" color="alternative" *ngIf="!cv.IsSent">Draft</span>
          <span class="language pill">{{ cvLanguage.LanguageName }}</span>
        </div>
      </div>
      <h3 class="cv-name">
        <a [routerLink]="['/cv/', cv.Id]" (click)="stopPropagation($event)">
           {{cv.Name}}
        </a>
      </h3>
      <p class="title max-md:hidden">
        {{ cv.Title || "No job title" }}
      </p>
    </div>

    <div class="footer">
      <progress-bar [progress]="cv.Progress" label="CV strength" #progressBar>
        <div class="main-info flex max-md:flex-row-reverse flex-full">
          <h5 class="progress-header font-bold">
            {{ progressBar.label }}:
            <span numberScroll [value]="progressBar.progress">{{ progressBar.progress }}</span>
            %
          </h5>
          <div class="additional-info-title flex-[1_0_auto] md:hidden">
             {{ cv.Title }}
          </div>
        </div>
        <div class="missing-sections max-md:hidden" *ngIf="cv.MissedCvSectionsCount > 0">
          You're missing {{cv.MissedCvSectionsCount}}
          <span [ngPlural]="cv.MissedCvSectionsCount">
            <ng-template ngPluralCase="=1">section</ng-template>
            <ng-template ngPluralCase="other">sections</ng-template>
        </span>
          to own the perfect CV.
        </div>
      </progress-bar>
      <div class="created max-md:hidden">
        <mat-icon svgIcon="calendar_big"></mat-icon>
        <span class="created-text">
          Last modified {{ cv.LastModifiedDate | defaultDate: "day" : true : false }}
        </span>
      </div>
    </div>
  </div>
</tile>
