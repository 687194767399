import { createAction, props } from '@ngrx/store';
import { INotificationItem } from '../../interfaces/notification-item';

export const queryNotifications = createAction('[Notifications] query Notifications items');
export const queryNotificationsError = createAction('[Notifications] query Notifications items error');
export const notificationsLoaded = createAction(
  '[Notifications] loaded Notifications items',
  props<{  notifications: Array<INotificationItem>;
    totalUnseenNotifications: number;
    notificationsRequestTimestamp: Date }>()
);
export const updateNotificationsTimestamp = createAction(
  '[Notifications] update Notification timestamp',
  props<{  timestamp: Date  }>()
);
export const updateNotificationsTimestampSuccess = createAction('[Notifications] Notification timestamp success');
export const updateNotificationsTimestampError = createAction('[Notifications] Notification timestamp error');
export const notificationItemRead = createAction(
  '[Notifications] Notifications item read',
  props<{  notificationItemId: string; }>()
);
export const notificationItemReadSuccess = createAction('[Notifications] Notifications item read success');
export const notificationItemReadError = createAction('[Notifications] Notifications item read error');
export const markAllNotificationsAsRead = createAction('[Notifications] mark all Notifications as read');
export const markAllNotificationsAsReadSuccess = createAction('[Notifications] mark all Notifications as read success');
export const markAllNotificationsAsReadError = createAction('[Notifications] mark all Notifications as read error');
