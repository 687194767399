import { Component, OnInit, Input, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import {GalleryComponent} from '../../gallery.component';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import { INewsAttachment, NewsAttachmentTypes } from '../../../../../interfaces/news-attachment';
import {INewsMediaSize} from '../../../../../interfaces/news-media-size';

@Component({
  selector: 'gallery-lightbox-item',
  templateUrl: './gallery-lightbox-item.component.html',
  styleUrls: ['./gallery-lightbox-item.component.scss']
})
export class GalleryLightboxItemComponent implements OnInit, OnDestroy {
  @Input() lightboxItem: INewsAttachment;

  @ViewChild('videoPlug') videoPlug: ElementRef;
  @ViewChild('videoEl') videoEl: ElementRef;
  @ViewChild('iframeEl') iframeEl: ElementRef;

  showVideo: boolean;

  trustedResourceUrl: SafeUrl;

  videoSize: INewsMediaSize = {
    width: 0,
    height: 0,
    ratio: 0
  };

  isCropped = false;

  get ATTACHMENT_TYPES() {
    return NewsAttachmentTypes;
  }

  /**
   * window.resize event listener function.
   */
  resizeListener = () => this.resizeVideo();

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.showVideo = false;

    switch (this.lightboxItem.Type) {
      case this.ATTACHMENT_TYPES.OTHER:
        this.trustedResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.lightboxItem.Url);
        break;
      case this.ATTACHMENT_TYPES.YT || this.ATTACHMENT_TYPES.VIMEO:
        this.trustedResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.lightboxItem.Url.replace('autoplay=0', 'autoplay=1'));
        break;
      default:
        this.trustedResourceUrl = this.lightboxItem.Url;
    }
  }

  show() {
    this.getVideoSizeFromThumbnail();
    window.addEventListener('resize', this.resizeListener);
    this.showVideo = true;
  }

  getVideoSizeFromThumbnail() {
    this.videoSize.width = this.videoPlug.nativeElement.offsetWidth;
    this.videoSize.height = this.videoPlug.nativeElement.offsetHeight;
    this.videoSize.ratio = this.videoSize.height / this.videoSize.width;
  }

  // Resize iframe for video to match all responsive widths
  resizeVideo() {
    let width;

    if (this.lightboxItem.Type === this.ATTACHMENT_TYPES.OTHER){
      width = this.videoEl.nativeElement.offsetWidth;
    } else {
      width = this.iframeEl.nativeElement.offsetWidth;
    }

    window.setTimeout(() => {
      this.videoSize.height = Math.round(width * this.videoSize.ratio);
    }, 10);
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.resizeListener);
  }
}
