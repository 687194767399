import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
// Components
import { PopoverNotificationsComponent } from '../popover-notifications/popover-notifications.component';
// Interfaces
import { INotificationItem } from '../../../interfaces/notification-item';
// Facades
import { NotificationsFacade } from '../../../services/facades/notifications-facade.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsComponent {
  @Input() notifications: Array<INotificationItem>;
  @Input() popoverInstance: PopoverNotificationsComponent;
  panelClass = 'popover-notifications';
  loading$: Observable<boolean>;

  constructor(private notificationsFacade: NotificationsFacade) {
    this.loading$ = this.notificationsFacade.loadingNotifications$;
  }

  markAllAsRead() {
    this.notificationsFacade.markAllNotificationsAsRead();
  }

  refreshNotifications() {
    this.notificationsFacade.queryNotifications();
  }

}
