import { FrontendFeature, FrontendFeaturesModule } from '../feature';

@FrontendFeaturesModule('Evaluations')
export class EvaluationsFeatures {
  @FrontendFeature({
    summary: 'Main evaluations page'
  })
  static readonly Default = 'evaluations';

  @FrontendFeature({
    summary: 'Preview evaluations report'
  })
  static readonly ItemPreview = 'evaluations/item/preview';

  @FrontendFeature({
    summary: 'Download evaluations report'
  })
  static readonly ItemDownload = 'evaluations/item/download';

  @FrontendFeature({
    summary: 'Download evaluations report'
  })
  static readonly ItemSendToMyEmail = 'evaluations/item/send-to-my-email';

  @FrontendFeature({
    summary: 'Send evaluations report to own inbox'
  })
  static readonly ItemCopyLink = 'evaluations/item/copy-link';

  @FrontendFeature({
    summary: 'Send evaluation form'
  })
  static readonly ItemSend = 'evaluations/item/send';

  @FrontendFeature({
    summary: 'Cancel automatic evaluation'
  })
  static readonly ItemCancel = 'evaluations/item/cancel';

  @FrontendFeature({
    summary: 'Download evaluations report'
  })
  static readonly ItemEditContact = 'evaluations/item/edit-contact';

  @FrontendFeature({
    summary: 'Generate evaluation'
  })
  static readonly Generate = 'evaluations/generate';
}
