export enum COMMON_BUTTONS_TEXTS {
    SAVE_CHANGES_DESKTOP = 'Save changes',
    SAVE_CHANGES_MOBILE =  'Save',
    DISCARD_CHANGES_DESKTOP = 'Discard changes',
    DISCARD_CHANGES_MOBILE =  'Discard',
    CREATE_DRAFT_DESKTOP = 'Create draft',
    CREATE_DRAFT_MOBILE =  'Create',
    DISCARD_DRAFT_DESKTOP = 'Discard draft',
    DISCARD_DRAFT_MOBILE =  'Discard',
    ADD = 'Add'
}
