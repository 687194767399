import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import { SnackBarService, SnackBarTypes } from '@my7n/ui';
import { RxState } from '@rx-angular/state';
import {
  IBusinessDetails,
  MAX_LENGTHS
} from '../../../interfaces/user-profile';
import { UserProfileFacadeService } from '../../../services/facades/user-profile-facade.service';

export interface IBusinessDetailsLocalState {
  businessDetails: IBusinessDetails;
  businessDetailsLoading: boolean;
  canEdit: boolean;
}

export const initialState: IBusinessDetailsLocalState = {
  businessDetails: null,
  businessDetailsLoading: false,
  canEdit: false
};

@Component({
  selector: 'business-details',
  templateUrl: './business-details.component.html',
  styleUrls: ['./business-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState]
})
export class BusinessDetailsComponent implements OnInit {
  MAX_LENGTHS = MAX_LENGTHS;

  @Input() set canEdit(can: boolean) {
    this.state.set({ canEdit: can });
  }

  readonly state$ = this.state.select();

  constructor(
    private state: RxState<IBusinessDetailsLocalState>,
    private userProfileFacade: UserProfileFacadeService,
    private snackBarService: SnackBarService
  ) {
    this.state.set({ ...initialState });
  }

  ngOnInit() {
    this.userProfileFacade.queryBusinessDetails();
    this.initLocalStateConnections();
  }

  initLocalStateConnections() {
    this.state.connect(
      'businessDetails',
      this.userProfileFacade.businessDetails$
    );
    this.state.connect(
      'businessDetailsLoading',
      this.userProfileFacade.businessDetailsLoading$
    );
  }

  businessDetailsCompanyNameUpdated(name: string) {
    this.userProfileFacade.updateBusinessDetailsCompanyName(name);
  }

  businessDetailsCompanyWebsiteUpdated(website: string) {
    this.userProfileFacade.updateBusinessDetailsCompanyWebsite(website);
  }

  onServerError() {
    this.snackBarService.open({
      message: 'A server error occured',
      type: SnackBarTypes.ErrorAlt
    });
  }
}
