<ng-container *rxLet="state$ as state">
  <section
    class="business-details"
    data-test-id="user-profile-business-details-container">
    <h4 class="font-bold">Business details</h4>
    <ng-container *ngIf="!state.businessDetailsLoading; else loading">
      <ng-container *ngIf="state.businessDetails; else nodata">
        <labeled-edit
          label="Business name"
          type="text"
          data-test-id="user-profile-businessNameEdit-container"
          [maxlength]="MAX_LENGTHS.company_name"
          [fieldValue]="state.businessDetails?.CompanyName"
          placeholder="Enter legal name of your company"
          class="company-name"
          updateMethodName="updateCompanyNameProfile"
          [tooltipHtml]="'Legal name of your<br/>business/self-employment.'"
          [multilineTooltip]="true"
          (serverError)="onServerError()"
          (updateSuccess)="businessDetailsCompanyNameUpdated($event)"
          [canEdit]="state.canEdit">
        </labeled-edit>
        <labeled-edit
          label="Professional website"
          placeholder="e.g. http://www.mywebsite.com"
          data-test-id="user-profile-websiteEdit-container"
          display="url"
          type="url"
          [maxlength]="MAX_LENGTHS.website"
          [fieldValue]="state.businessDetails?.CompanyWebsite"
          class="company-website"
          updateMethodName="updateCompanyWebsiteProfile"
          (serverError)="onServerError()"
          (updateSuccess)="businessDetailsCompanyWebsiteUpdated($event)"
          [canEdit]="state.canEdit">
        </labeled-edit>
      </ng-container>
    </ng-container>
  </section>
</ng-container>

<ng-template #nodata>
  <no-data></no-data>
</ng-template>

<ng-template #loading>
  <div class="flex flex-center-start w-full h-full">
    <loader color="primary"></loader>
  </div>
</ng-template>
