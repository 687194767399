import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { SnackBarService, SnackBarTypes, Icons } from '@my7n/ui';
import * as DictionariesActions from '../../actions/cms/dictionaries.actions';
import * as fromCms from '../../reducers/cms/index';
import * as DictionariesSelectors from '../../reducers/cms/dictionaries.selectors';
import { DictionariesContentfulService } from '../../../services/dictionaries-contentful.service';
import {
  CmsDictionaryTypes,
  ICmsDictionaryItem
} from '../../../interfaces/cms';

@Injectable()
export class DictionariesEffects {
  constructor(
    private actions$: Actions,
    private dictionariesContentful: DictionariesContentfulService,
    private snackBarService: SnackBarService,
    private cmsStore$: Store<fromCms.FeatureState>
  ) {}

  queryDictionariesByTypes$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DictionariesActions.queryDictionariesByTypes),
      concatLatestFrom((action) => [
        this.cmsStore$.select(
          DictionariesSelectors.selectDictionariesByTypes(action.types)
        )
      ]),
      mergeMap(
        ([action, currentDictionaryState]: [
          any,
          Array<ICmsDictionaryItem>
        ]) => {
          const missing = action.types.filter(
            (item: CmsDictionaryTypes) =>
              currentDictionaryState.findIndex(
                (currentStateItem) => currentStateItem.type === item
              ) === -1
          );
          if (!action.forceRefresh && missing.length === 0) {
            // already exists, return from the store
            return of(
              DictionariesActions.dictionariesLoadedFromCache({
                types: action.types
              })
            );
          } else {
            return this.dictionariesContentful
              .getDictionaryItems$(action.types)
              .pipe(
                map((res: Array<ICmsDictionaryItem>) => {
                  return DictionariesActions.queryDictionariesByTypesSuccess({
                    dictionary: res,
                    types: action.types
                  });
                }),
                catchError(() => {
                  this.snackBarService.open({
                    message: `An error occurred during loading CMS Dictionary (${action.types?.join(', ')}) data.`,
                    type: SnackBarTypes.ErrorAlt,
                    actionIcon: Icons.CLOSE_TINY
                  });
                  return of(
                    DictionariesActions.queryDictionariesByTypesError({
                      types: action.types
                    })
                  );
                })
              );
          }
        }
      )
    );
  });
}
