import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable()
export class PageTitleService {
  /**
   * Maximum length of title tag content.
   */
  readonly MAX_TITLE_LENGTH = 50;

  /**
   * Title suffix which is slways added to the end of requested title.
   */
  readonly TITLE_SUFFIX = ' - my7N';

  constructor(private titleService: Title) {}

  /**
   * Sets content of page title.
   * If needed, truncates too long description.
   * @param {string} pageTitle
   */
  setTitle(pageTitle: string) {
    let title;

    if (pageTitle) {
      pageTitle = pageTitle.slice(0, this.MAX_TITLE_LENGTH - this.TITLE_SUFFIX.length);
      title = `${pageTitle}${this.TITLE_SUFFIX}`;
    } else {
      title = this.TITLE_SUFFIX.slice(3);
    }

    this.titleService.setTitle(title);
  }
}
