import { createAction, props } from '@ngrx/store';
import { CV_MANDATORY_SECTIONS } from '../../interfaces/cv-mandatory-sections';

export const  updateSendToAgentAttempt = createAction(
  '[CV][Global] update send to agent attempt',
  props<{ sendToAgentAttempt: boolean }>()
);

export const updateCvMandatorySectionFilled = createAction(
  '[CV][Global] update cv mandatory section filled',
  props<{ section: CV_MANDATORY_SECTIONS, filled: boolean }>()
);
