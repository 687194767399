import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ICvBaseResponse} from '../../interfaces/cv-base-response';
import {lastValueFrom, Observable, Subject} from 'rxjs';
import {ServiceNames} from '../../interfaces/my7n-env-config';
import {IProfileStrengthResponse, ProfileStrengthKeys} from '../../interfaces/profile-strength';
import {CvProfileStrengthFacadeService} from '../facades/cv-profile-strength.facade.service';
import { GlobalAppConfigFacadeService } from '../facades/global-app-config-facade.service';
import { AppConfigService } from '../app-config.service';


export enum CvUserContext {
  AgentView = 'AgentView',
  ConsultantView = 'ConsultantView'
};

@Injectable()
export class CvCommonService {
  readonly API_CV: string;
  readonly CV_V1_API: string;

  private _userContext: CvUserContext;

  set userContext(userContext: CvUserContext) {
    this._userContext = userContext;
  }

  get userContext(): CvUserContext {
    return this._userContext;
  }

  private _profileStrengthRefresh = new Subject<void>();
  profileStrengthRefresh$ = this._profileStrengthRefresh.asObservable();

  constructor(private http: HttpClient,
              private appConfigService: AppConfigService,
              private cvProfileStrengthFacadeService: CvProfileStrengthFacadeService,
              private globalAppConfigFacadeService: GlobalAppConfigFacadeService) {
    this.API_CV = appConfigService.serviceUrl(ServiceNames.Core) + 'cvs/';
    this.CV_V1_API = appConfigService.serviceUrl(ServiceNames.Cv, 'v1') + 'cv/';
  }

  private static forwardFailPromiseIntercept(error) {
    return Promise.reject(error);
  }

  // profile strength
  getProfileStrength(cvId: number): Observable<IProfileStrengthResponse> {
    return this.http.get<IProfileStrengthResponse>(this.API_CV + cvId + '/strength');
  }


  updateField(field: string, apiUrlSuffix: string, cvV1Api = false): (cvId: number, value: string | number | Array<number|string|{Id: number, Order: number}>) => Promise<ICvBaseResponse> {
    const self = this;

    const endpointUrlPrefix = cvV1Api ? self.CV_V1_API : self.API_CV;

    return function (cvId: number, value: string | number | Array<number|string|{Id: number, Order: number}>) {
      const dataObject = {
        [field]: value
      };

      return lastValueFrom(self.http.patch<ICvBaseResponse>(endpointUrlPrefix + cvId + '/' + apiUrlSuffix, dataObject))
        .then((result) => {
          self.cvProfileStrengthFacadeService.queryProfileStrength(cvId);
          return result;
        }, (error) => {
          console.debug('[CvService] Failed to update ' + field, error);
          return Promise.reject(error);
        });
    };
  }


  commonPutPostUpdate(section: ProfileStrengthKeys, apiPart: string, cvV1Api = false): (cvId: number, fields: any) => Promise<ICvBaseResponse> {
    const self = this;

    const endpointUrlPrefix = cvV1Api ? self.CV_V1_API : self.API_CV;

    return function (cvId: number, fields): Promise<ICvBaseResponse> {
      const updateId = fields.Id;

      if (!cvId) {
        console.error('[CvService] Update of ' + section + ' failed: Missing cvId');
        return Promise.reject('[CvService] Update of ' + section + ' failed: Missing cvId');
      }

      if (updateId === undefined || updateId === null) {
        return lastValueFrom(self.http.post<ICvBaseResponse>(endpointUrlPrefix + cvId + apiPart, fields))
          .then((result) => {
            if (!result.Id) {
              console.error('[CvService] Response is missing ' + section + ' Id');
            }

            fields.Id = result.Id;
            self.cvProfileStrengthFacadeService.queryProfileStrength(cvId);
            return result;
          }, (error) => {
            console.error('[CvService] Failed to update ' + section, error);
            return Promise.reject(error);
          });
      } else {
        return lastValueFrom(self.http.put<ICvBaseResponse>(endpointUrlPrefix + cvId + apiPart + updateId, fields))
          .then((result) => {
            self.cvProfileStrengthFacadeService.queryProfileStrength(cvId);
            return result;
          }, (error) => {
            console.error('[CvService] Failed to update ' + section + ' entry with Id ' + updateId, error);
            return Promise.reject(error);
          });
      }
    };
  }

  commonRemove(apiPart: string, cvV1Api = false): (cvId: number, removeId: number) => Promise<ICvBaseResponse> {
    const self = this;

    const endpointUrlPrefix = cvV1Api ? self.CV_V1_API : self.API_CV;

    return function (cvId, removeId) {
      return lastValueFrom(self.http.delete<ICvBaseResponse>(endpointUrlPrefix + cvId + apiPart + removeId))
        .then((result) => {
          self.cvProfileStrengthFacadeService.queryProfileStrength(cvId);
          return result;
        });
    };
  }
  
  configReload() {
    this.globalAppConfigFacadeService.updateConfigDirtyState(true);
    this.globalAppConfigFacadeService.queryAppConfig();
  }
}
