import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
// Services
// Classes
import { BaseCvElement } from '../../../../classes/base-cv-element';
import { CvLanguageService } from '../../../../services/cv-language.service';
import { ICvListItem } from '../../../../interfaces/cv-list-item';

@Component({
  selector: 'cv-box-primary-cv',
  templateUrl: './cv-box-primary-cv.component.html',
  styleUrls: ['./cv-box-primary-cv.component.scss', '../../../cv/cv-list-view/cv-list/cv-list-element/cv-list-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CvBoxPrimaryCvComponent extends BaseCvElement {
  @Input() cv: ICvListItem;
  constructor(
    cvLanguageService: CvLanguageService,
    cdr: ChangeDetectorRef
    ) {
    super(cvLanguageService, cdr);
  }
}
