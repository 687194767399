import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RxState } from '@rx-angular/state';
import { TermsPayloadKeys } from '../../../interfaces/terms-payload-keys';
import {
  ITermsAndConditions,
  MAX_LENGTHS
} from '../../../interfaces/user-profile';
import { UserProfileFacadeService } from '../../../services/facades/user-profile-facade.service';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';

export interface ITermsAndConditionsLocalState {
  termsAndConditions: ITermsAndConditions;
  termsAndConditionsLoading: boolean;
  isTermsAndCoditionsFormReady: boolean;
  canEdit: boolean;
}

export const initialState: ITermsAndConditionsLocalState = {
  termsAndConditions: null,
  termsAndConditionsLoading: false,
  isTermsAndCoditionsFormReady: false,
  canEdit: false
};

@Component({
  selector: 'terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState]
})
export class TermsAndConditionsComponent implements OnInit {
  MAX_LENGTHS = MAX_LENGTHS;
  termsForm: UntypedFormGroup;
  termsPayloadKeys = TermsPayloadKeys;

  @Input() set canEdit(can: boolean) {
    this.state.set({ canEdit: can });
  }

  readonly state$ = this.state.select();

  constructor(
    private state: RxState<ITermsAndConditionsLocalState>,
    private userProfileFacade: UserProfileFacadeService
  ) {
    this.state.set({ ...initialState });
  }

  ngOnInit() {
    this.userProfileFacade.queryTermsAndConditions();
    this.initLocalStateConnections();
    this.initLocalStateEffects();
  }

  initLocalStateConnections() {
    this.state.connect(
      'termsAndConditions',
      this.userProfileFacade.termsAndConditions$
    );
    this.state.connect(
      'termsAndConditionsLoading',
      this.userProfileFacade.termsAndConditionsLoading$
    );
  }

  initLocalStateEffects() {
    this.setCreateFormWhenReadyEffects();
    this.setTermsFormEffects();
    this.setSlidersStateEffects();
  }

  setCreateFormWhenReadyEffects() {
    const termsAndConditionsReady$ = this.state
      .select('termsAndConditions')
      .pipe(
        filter((terms: ITermsAndConditions) => terms !== null),
        take(1)
      );
    this.state.hold(termsAndConditionsReady$, (terms: ITermsAndConditions) => {
      this.createForm(terms);
    });
  }

  setTermsFormEffects() {
    // when the terms form is ready - set a value changes effects
    // we have to wait until termsForm is defined
    this.state.hold(
      this.state.select('isTermsAndCoditionsFormReady').pipe(
        filter((isReady: boolean) => isReady),
        take(1)
      ),
      () => {
        // everytime the form control value is changing, update the store
        this.state.hold(
          this.termsForm.controls[this.termsPayloadKeys.EventInvitation]
            .valueChanges,
          (accepted: boolean) => {
            this.userProfileFacade.updateTermsEventInvitationSubscription(
              accepted
            );
          }
        );

        // listen to the terms event invitation subscription state changes and update the form without emitting value
        this.state.hold(
          this.state
            .select('termsAndConditions', 'AcceptMy7nEventInvitation')
            .pipe(
              filter((accepted: boolean) => accepted !== undefined),
              distinctUntilChanged()
            ),
          (accepted: boolean) => {
            this.termsForm.controls[
              this.termsPayloadKeys.EventInvitation
            ].setValue(accepted, { emitEvent: false });
          }
        );

        // everytime the form control value is changing, update the store
        this.state.hold(
          this.termsForm.controls[this.termsPayloadKeys.Newsletter]
            .valueChanges,
          (accepted: boolean) => {
            this.userProfileFacade.updateTermsNewsletterSubscription(accepted);
          }
        );

        // listen to the terms newsletter subscription state changes and update the form without emitting value
        this.state.hold(
          this.state.select('termsAndConditions', 'AcceptMy7nNewsletter').pipe(
            filter((accepted: boolean) => accepted !== undefined),
            distinctUntilChanged()
          ),
          (accepted: boolean) => {
            this.termsForm.controls[this.termsPayloadKeys.Newsletter].setValue(
              accepted,
              { emitEvent: false }
            );
          }
        );
      }
    );
  }

  setSlidersStateEffects() {
    // set sliders state
    const canEditWhenTermsFormIsReady$ = combineLatest([
      this.state.select('isTermsAndCoditionsFormReady'),
      this.state.select('canEdit')
    ]).pipe(
      filter((result: [boolean, boolean]) => result[0]),
      take(1),
      map((result: [boolean, boolean]) => result[1])
    );

    this.state.hold(canEditWhenTermsFormIsReady$, (canEdit: boolean) => {
      this.setTermsSliderState(canEdit);
    });
  }

  createForm(terms: ITermsAndConditions) {
    this.termsForm = new UntypedFormGroup({
      [this.termsPayloadKeys.EventInvitation]: new UntypedFormControl(
        terms.AcceptMy7nEventInvitation
      ),
      [this.termsPayloadKeys.Newsletter]: new UntypedFormControl(
        terms.AcceptMy7nNewsletter
      )
    });

    this.state.set({ isTermsAndCoditionsFormReady: true });
  }

  setTermsSliderState(enable: boolean) {
    if (enable) {
      this.termsForm
        .get(this.termsPayloadKeys.EventInvitation)
        .enable({ emitEvent: false });
      this.termsForm
        .get(this.termsPayloadKeys.Newsletter)
        .enable({ emitEvent: false });
    } else {
      this.termsForm
        .get(this.termsPayloadKeys.EventInvitation)
        .disable({ emitEvent: false });
      this.termsForm
        .get(this.termsPayloadKeys.Newsletter)
        .disable({ emitEvent: false });
    }
  }
}
