import { createAction, props } from '@ngrx/store';
import {
  CmsDictionaryTypes,
  ICmsDictionaryItem
} from '../../../interfaces/cms';

export const queryDictionariesByTypes = createAction(
  '[CMS Dictionaries] query Dictionaries By Types',
  props<{
    types: Array<CmsDictionaryTypes>;
    forceRefresh: boolean;
  }>()
);

export const queryDictionariesByTypesSuccess = createAction(
  '[CMS Dictionaries] query Dictionaries By Types success',
  props<{
    dictionary: Array<ICmsDictionaryItem>;
    types: Array<CmsDictionaryTypes>;
  }>()
);

export const dictionariesLoadedFromCache = createAction(
  '[CMS Dictionaries] dictionaries Loaded From Cache',
  props<{ types: Array<CmsDictionaryTypes> }>()
);

export const queryDictionariesByTypesError = createAction(
  '[CMS Dictionaries] query Dictionaries By Types error',
  props<{ types: Array<CmsDictionaryTypes> }>()
);
