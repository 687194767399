import { FrontendFeature, FrontendFeaturesModule } from '../feature';

@FrontendFeaturesModule('Notifications')
export class NotificationsFeatures {
  @FrontendFeature({
    summary: 'Access to notifications'
  })
  static readonly Default = 'notifications';
}

