import { FrontendFeature, FrontendFeaturesModule } from '../feature';

@FrontendFeaturesModule('Referrals')
export class ReferralsFeatures {
  @FrontendFeature({
    summary: 'Main referrals page'
  })
  static readonly Default = 'referrals';

  @FrontendFeature({
    summary: 'About page access'
  })
  static readonly About = 'referrals/about';

  @FrontendFeature({
    summary: 'Terms and conditions page'
  })
  static readonly Terms = 'referrals/terms';

  @FrontendFeature({
    summary: 'Submit referral'
  })
  static readonly Submit = 'referrals/submit';
}
