import { createAction, props } from '@ngrx/store';
import { INewsBase } from '../../interfaces/news';
import { INewsLoadedInfo } from '../../interfaces/timeline';

export const queryNews = createAction(
  '[Timeline][News] query news',
  props<{ previewMode: boolean }>()
);

export const queryNewsSuccess = createAction(
  '[Timeline][News] query news Success',
  props<{ news: INewsBase[], loadedInfo: INewsLoadedInfo }>()
);

export const queryNewsError = createAction(
  '[Timeline][News] query news Error'
);

export const queryMoreNews = createAction(
  '[Timeline][News] query more news',
  props<{ previewMode: boolean }>()
);

export const queryMoreNewsSuccess = createAction(
  '[Timeline][News] query more news Success',
  props<{ news: INewsBase[], loadedInfo: INewsLoadedInfo }>()
);

export const queryMoreNewsError = createAction(
  '[Timeline][News] query more news Error'
);

export const saveScrollYPosition = createAction(
  '[Timeline][News] save scroll position',
  props<{ scrollY: number }>()
);

export const clearNews = createAction(
  '[Timeline][News] clear news'
);
