import { Injectable, ErrorHandler } from '@angular/core';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {
  handleError(error: any) {
    // TEMPORARELY @TODO
    // if (error && error.unauthorized) {
    //   return;
    // }
    super.handleError(error);
  }
}
