import { Injectable } from '@angular/core';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {ICheckboxCategoryTreeItem} from "../interfaces/checkbox-category-tree-item";
import {CheckboxRequiredValidator} from "../validators/checkbox-required.validator";

@Injectable()
export class CheckboxCategoryTreeService {

  constructor() { }

  /**
   * // @FIXME recurrensive implementation, change for deep trees;
   * @param {Array<ICheckboxCategoryTreeItem>} nodes
   * @param validator Validator
   * @returns {FormArray}
   */
  static buildFormArray(nodes: Array<ICheckboxCategoryTreeItem>, validator?): UntypedFormArray {
    let baseArrayForm = new UntypedFormArray([], {
      validators: validator
    });

    nodes.forEach(node => {
      let innerGroup: any = {
        checkbox: new UntypedFormControl(node.Checked || false)
      };

      if (node.Subcategories && node.Subcategories.length > 0) {
        innerGroup.subcategories = CheckboxCategoryTreeService.buildFormArray(node.Subcategories);
      }

      baseArrayForm.push(new UntypedFormGroup(innerGroup));
    });

    return baseArrayForm;
  }

  static updateValuesWithModel(formArray: UntypedFormArray, data: Array<ICheckboxCategoryTreeItem>) {
    let dataModel = CheckboxCategoryTreeService.buildFormModelFromData(data);

    return formArray.setValue(dataModel);
  }

  private static buildFormModelFromData(data: Array<ICheckboxCategoryTreeItem>) {
    let baseArrayForm = [];

    data.forEach(node => {
      let innerGroup: any = {
        checkbox: node.Checked || false
      };

      if (node.Subcategories && node.Subcategories.length > 0) {
        innerGroup.subcategories = CheckboxCategoryTreeService.buildFormModelFromData(node.Subcategories);
      }

      baseArrayForm.push(innerGroup);
    });

    return baseArrayForm;
  }

  /**
   * @FIXME recurrensive implementation, change for deep trees;
   * @param item
   * @returns {boolean}
   */
  static findChecked(item): boolean {
    if (item.Checked) {
      return true;
    } else {
      if (Array.isArray(item.Subcategories) && item.Subcategories.length > 0) {
        return item.Subcategories.some(CheckboxCategoryTreeService.findChecked);
      } else {
        return false;
      }
    }
  }

  /**
   * @FIXME recurrensive implementation, change for deep trees;
   * @param checkboxes
   * @param data
   * @param returnList must be passed as reference
   * @param valueMapper
   */
  static collectValues(checkboxes: Array<any>, data: Array<ICheckboxCategoryTreeItem>, returnList: Array<number|string|{Id: number, Order: number}>, valueMapper?: (checkbox: ICheckboxCategoryTreeItem, index: number) => {Id: number, Order: number}) {
    const firstLvlLen = checkboxes.length;

    for (let i = 0; i < firstLvlLen; i++) {
      const valueItem = checkboxes[i],
        dataItem: ICheckboxCategoryTreeItem = data[i];

      if (valueItem.checkbox) {
        if (valueMapper) {
          returnList.push(valueMapper(dataItem, returnList.length));
        } else if (dataItem.Id !== null && dataItem.Id !== undefined) {
          returnList.push(dataItem.Id);
        }
      }

      if (valueItem.subcategories && valueItem.subcategories.length > 0) {
        CheckboxCategoryTreeService.collectValues(valueItem.subcategories, dataItem.Subcategories, returnList);
      }
    }
  }

  /**
   * Returns values from the leafs of checkbox tree. If a category has subcategories only the checked values from
   * it's children get returned. If not parent is added.
   *  @FIXME recurrensive implementation, change for deep trees;
   * @param checkboxes {Array<any>}
   * @param data {Array<any>}
   * @param returnList <Array<number|{Id: number, Order: number}>
   * @param valueMapper
   */
  static collectLeafValues(checkboxes: Array<any>, data: Array<ICheckboxCategoryTreeItem>, returnList: Array<number|string|{Id: number, Order: number}>, valueMapper?: (checkbox: ICheckboxCategoryTreeItem, index: number) => {Id: number, Order: number}) {
    const firstLvlLen = checkboxes.length;

    for (let i = 0; i < firstLvlLen; i++) {
      const valueItem = checkboxes[i],
        dataItem: ICheckboxCategoryTreeItem = data[i];

      if (valueItem.subcategories && valueItem.subcategories.length > 0) {
        CheckboxCategoryTreeService.collectLeafValues(valueItem.subcategories, dataItem.Subcategories, returnList);
      } else if (valueItem.checkbox) {
        if (valueMapper) {
          returnList.push(valueMapper(dataItem, returnList.length));
        } else if (dataItem.Id !== null && dataItem.Id !== undefined) {
          returnList.push(dataItem.Id);
        }
      }
    }
  }

  /**
   * @TODO rewrite for more than one level depth in case its needed
   * @param checkboxes
   * @param data
   * @returns {(any)[]}
   */
  static collectValuesWithOrder(checkboxes, data: Array<ICheckboxCategoryTreeItem>) {
    const valuesLen = checkboxes.length,
        returnList = [],
        unorderedList = [];

    for (let i = 0; i < valuesLen; i++) {
      const group = checkboxes[i];

      // Order is supported only for single level
      if (group.checkbox) {
        // Elements without order should be put last in output array, this is why the list is here split into two
        if (data[i].Order !== null && data[i].Order !== undefined) {
          returnList.push({
            Id: data[i].Id,
            Order: data[i].Order
          });
        } else {
          unorderedList.push({
            Id: data[i].Id,
            Order: null
          });
        }
      }
    }

    return [...returnList.sort((a, b) => a.Order - b.Order), ...unorderedList].map((value, index) => {
      value.Order = index;
      return value;
    })
  }

  /**
   * @FIXME recursive implementation, change for deep trees;
   * @param data
   * @param outputArray
   */
  static updateModel(data: Array<ICheckboxCategoryTreeItem>, outputArray) {
    const firstLvlLen = data.length;

    for (let i = 0; i < firstLvlLen; i++) {
      data[i].Checked = outputArray.indexOf(data[i].Id) > -1;

      if (data[i].Subcategories && data[i].Subcategories.length > 0) {
        CheckboxCategoryTreeService.updateModel(data[i].Subcategories, outputArray);
      }
    }
  }

  static updateModelWithOrder(data, outputArray) {
    const lvlLen = data.length;

    for (let i = 0; i < lvlLen; i++) {
      const dataItem: ICheckboxCategoryTreeItem = data[i],
        outputFound = outputArray.find(outputValue => dataItem.Id == outputValue.Id);

      dataItem.Checked = !!outputFound;

      if (outputFound) {
        dataItem.Order = outputFound.Order;
      } else {
        // reset to null while setting back to model unchecked values
        dataItem.Order = null;
      }

      if (dataItem.Subcategories && dataItem.Subcategories.length > 0) {
        CheckboxCategoryTreeService.updateModelWithOrder(dataItem.Subcategories, outputArray);
      }
    }
  }


}
