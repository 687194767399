import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const FileTypeValidator = (acceptedTypes: Array<string>): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (acceptedTypes.indexOf(value) === -1) {
      return {
        required: true
      };
    }

    return null;
  };
};
