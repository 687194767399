<div #newsContainer
     class="news-container"
     [ngClass]="{
       'news-text-only': newsSubtype === newsSubtypes.TEXT_ONLY,
       'news-img-only': newsSubtype === newsSubtypes.IMG_ONLY,
       'news-video-only': newsSubtype === newsSubtypes.VIDEO_ONLY,
       'news-gallery': newsSubtype === newsSubtypes.GALLERY,
       'without-title': !news.Title,
       'without-desc': !news.Description
     }">

  <!-- news photo -->
  <div class="news-photo"
       *ngIf="newsSubtype === newsSubtypes.IMG_ONLY"
       [ngStyle]="{
         'width': imageSize.width + 'px',
         'height': imageSize.height + 'px',
         'background-image': 'url(' + news.Attachments[0].Url + ')'
       }">
  </div>

  <!-- news video -->
  <div class="news-video"
       *ngIf="newsSubtype === newsSubtypes.VIDEO_ONLY"
       [ngClass]="{'iframe-video': news.Attachments[0].Type !== ATTACHMENT_TYPES.OTHER}"
       [ngStyle]="{
         'width': videoPlayerConfig.containerWidth + 'px',
         'height': videoPlayerConfig.containerHeight + 'px'
       }"
  >
    <div class="video-plug"
         (click)="show()"
         *ngIf="!showVideo"
         [ngStyle]="{
           'width': videoPlayerConfig.containerWidth + 'px',
           'height': videoPlayerConfig.containerHeight + 'px',
           'background-image': 'url(' + news.Attachments[0].ThumbnailUrl + ')'
         }">
    </div>
    <my7n-video-player *ngIf="showVideo && news.Attachments[0].Type !== ATTACHMENT_TYPES.OTHER"
                       [videoUrl]="news.Attachments[0].Url"
                       [module]="LogVideoModules.News" 
                       [title]="news.Title"
                       [articleId]="news.Id"
                       [config]="videoPlayerConfig"></my7n-video-player>
    <video [src]="trustedResourceUrl"
           *ngIf="showVideo && news.Attachments[0].Type === ATTACHMENT_TYPES.OTHER"
           [width]="videoPlayerConfig.containerWidth"
           [height]="videvideoPlayerConfigoSize.containerHeight"
           autoplay
           controls
           controlsList="nodownload"></video>    
  </div>

  <!-- gallery -->
  <div class="news-gallery" *ngIf="newsSubtype === newsSubtypes.GALLERY">
    <gallery [items]="news.Attachments" [newsId]="news.Id" limitToOneRow="true" [itemHeight]="252"></gallery>
  </div>

  <div class="news-content flex flex-col">

    <!-- news title -->
    <h3 class="news-title" *ngIf="news.Title">
      <a [routerLink]="detailsUrl">{{news.Title | truncate: titleLimit: true}}</a>
    </h3>

    <!-- news description with more link -->
    <div class="news-description">
      <p class="news-description-text" *ngIf="news.Description" [innerHTML]="news.Description | truncate: descriptionLimit:true"></p>
      <a [routerLink]="detailsUrl" 
         type="button" 
         mat-button 
         color="primary"
         data-test-id="news-description-read-more">Read more</a>
    </div>
    <hr/>
    <!-- news footer -->
    <div class="news-footer flex flex-row flex-between-center"
         data-test-id="news-container-news-footer">
      <div class="footer-author-container flex max-sm:flex-col sm:gap-24 max-sm:flex-start-start sm:max-md:flex-between-center md:flex-start-center">
        <!-- news author info -->
        <div class="news-author-info flex flex-start-center md:gap-12">
          <div class="news-author-info-avatar max-md:hidden">
            <secure-avatar [avatarUrl]="news.AuthorAvatar" [title]="news.AuthorName"></secure-avatar>
          </div>
          <div class="news-author-info-name-posted flex flex-col flex-[1_1_0%]">
            <span class="news-author-info-name">{{news.AuthorName}}</span>
            <span class="news-author-info-job-title">{{news.AuthorJobTitle}}</span>
          </div>
        </div>

        <!-- news dates -->
        <div class="news-dates flex flex-start-center gap-12">
          <mat-icon class="max-md:hidden" svgIcon="calendar_big"></mat-icon>
          <span class="news-author-info-posted">{{news.Published | defaultDate: 'minute': true}}</span>
        </div>
      </div>
      <reactions [contentId]="news.Id" [contentType]="contentType" data-test-id="reactions"></reactions>
    </div>
  </div>
</div>
