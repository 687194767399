import { Component, OnInit } from '@angular/core';
import { MainComponent } from '../main/main.component';
import { UserProfileService } from '../../services/user-profile.service';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'terms-agreement',
  templateUrl: './terms-agreement.component.html',
  styleUrls: ['./terms-agreement.component.scss']
})

export class TermsAgreementComponent implements OnInit {
  loading = false;
  error = false;
  termsForm: UntypedFormGroup;

  constructor(private rootComp: MainComponent,
    private userProfileService: UserProfileService,
    private router: Router,
    private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.termsForm = new UntypedFormGroup({
      AcceptStoringAndSendingDataBy7n: new UntypedFormControl(false, Validators.requiredTrue),
      AcceptMy7nEventInvitation: new UntypedFormControl(false),
      AcceptMy7nNewsletter: new UntypedFormControl(false),
    });
  }

  sendAgreement(form: UntypedFormGroup) {
    this.setServerErrorFlag(false);

    form.markAllAsTouched();

    if (form.invalid) {
      console.debug('[TermsAgreementComponent] You need to agree to the terms of use', form.value.AcceptStoringAndSendingDataBy7n, form.value.AcceptMy7nEventInvitation, form.value.AcceptMy7nNewsletter);
      return false;
    }

    this.loading = true;

    this.userProfileService.updateTermsAgreement(form.value).then(() => {
      this.router.navigateByUrl('').then(() => {
        this.loading = false;
      });
    }).catch((e) => {
      this.setServerErrorFlag(true);
      this.loading = false;
    });
  }

  setServerErrorFlag(flag: boolean): void {
    this.error = flag;
    if (flag) {
      this.termsForm.setErrors({ serverError: true });
    } else {
      this.termsForm.setErrors(null);
    }
  }

}
