import { Component, OnInit, Input, ElementRef, ViewChild, Inject, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { INewsList } from '../../../../../interfaces/news';
import { IStringNumberMap } from '@my7n/ui';
import { NEWS_INJECTION_TOKEN } from '../../../../../common/custom-tokens';
import { TimelineService } from '../../../../../services/timeline.service';
import { ContentTypes } from '../../../../../interfaces/reactions';

@Component({
  selector: 'news-list-event-element',
  templateUrl: './news-list-event-element.component.html',
  styleUrls: ['./news-list-event-element.component.scss', '../news-list-element/news-list-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewsListEventElementComponent implements OnInit, OnDestroy {
  @Input() event: INewsList;
  @ViewChild('newsContainer', { static: true }) newsContainer: ElementRef;

  contentType = ContentTypes.MY7N_EVENT;
  imageSize: IStringNumberMap = {
    width: 755,
    height: 250,
    ratio: 250 / 755
  };

  templateTimeout: number|null = null;

  detailsUrl: string;

  /**
   * Checks if event lasts one day.
   * @returns {boolean}
   */
  isTheSameDay(): boolean {
    if (this.event.EndDate && this.event.StartDate) {
      return this.event.StartDate.slice(0, 10) === this.event.EndDate.slice(0, 10);
    }
  }

  /**
   * window.resize event listener function.
   */
  windowResizeListener = () => this.resizeContent();

  get titleLimit(): number {
    return this.timelineService.titleTruncateLimit;
  }

  get descriptionLimit(): number {
    return this.timelineService.descriptionTruncateLimit;
  }

  constructor(@Inject(NEWS_INJECTION_TOKEN) newsInjected: INewsList,
              private timelineService: TimelineService,
              private cdr: ChangeDetectorRef) {
    this.event = this.event || newsInjected;
  }

  ngOnInit() {
    this.detailsUrl = '/events/' + this.event.Id;
    this.resizeContent();
    window.addEventListener('resize', this.windowResizeListener);
  }

  /**
   * Resize video iframe to match all responsive widths.
   */
  resizeContent() {
    window.setTimeout(() => {
      const width = this.newsContainer.nativeElement.offsetWidth;
      this.imageSize.width = width;
      this.imageSize.height = Math.round(width * this.imageSize.ratio);
      this.cdr.markForCheck();
    }, 0);
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.windowResizeListener);
    if (this.templateTimeout) {
      window.clearTimeout(this.templateTimeout);
    }
  }

}

