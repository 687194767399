<ng-container class="video-player-container" *rxLet="playerState$ as playerState">
    <ng-container *ngIf="playerState.videoUrl; else error">
        <iframe id="{{playerId}}" 
                *ngIf="isPlayerReady(playerState)"
                [src]="(playerState.embeddedHeaderVideoUrl) | trustedUrl"
                [attr.width]="'100%'" 
                [attr.height]="playerState.config?.containerHeight" 
                [attr.frameborder]="0" 
                allow="autoplay" 
                webkitallowfullscreen
                mozallowfullscreen
                allowfullscreen
                (load)="iframeLoaded()"
                [ngClass]="{ hidden: playerState.loading }"
                [attr.data-test-id]="'video-player-iframe-'+ playerId"></iframe>

      <div class="loader-container" [ngClass]="{ hidden: !playerState.loading }">
        <div class="flex flex-center-center self-center w-full h-full"
             [attr.data-test-id]="'video-player-loader-'+playerId">
          <loader color="primary"></loader>
        </div>
      </div>
    </ng-container>
</ng-container>


<ng-template #error>
    <div class="broken-player flex flex-col flex-center-center gap-18 w-full h-full"
         [attr.data-test-id]="'video-player-error-'+playerId">
       <no-data icon="broken_image" message="There was a problem with loading the video!"></no-data>
    </div>
</ng-template>


