import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { NotificationFrequency } from '../../../interfaces/notification-settings';

@Component({
  selector: 'notification-frequency',
  templateUrl: './notification-frequency.component.html',
  styleUrls: ['./notification-frequency.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationFrequencyComponent {
  @Input() frequency: NotificationFrequency;
  @Input() canEdit = true;
  @Input() saving: boolean;

  @Output() frequencyUpdated = new EventEmitter<NotificationFrequency>();

  editMode = false;

  edit() {
    this.editMode = true;
  }

  onEditResult(data: {
    result: 'updated' | 'canceled';
    frequency?: NotificationFrequency;
  }) {
    if (data.result === 'updated') {
      this.saving = true;
      this.frequencyUpdated.emit(data.frequency);
    }
    this.editMode = false;
  }
}
