import { Component, Input, OnInit } from '@angular/core';
import { LayoutContainerModes, SubNavbarService } from '@my7n/ui';
import { ICmsItem } from '../../../interfaces/cms';
import { CmsItemViewService } from './cms-item-view.service';

@Component({
  selector: 'cms-item-view',
  templateUrl: './cms-item-view.component.html',
  styleUrls: ['./cms-item-view.component.scss']
})
export class CmsViewComponent implements OnInit {
  @Input() details: ICmsItem;
  LayoutContainerModes = LayoutContainerModes;

  constructor(
    private subnavbarService: SubNavbarService,
    private cmsItemViewService: CmsItemViewService
  ) {}

  ngOnInit() {
    this.cmsItemViewService.subnavbarComponent$.subscribe((component) => {
      this.subnavbarService.updateSecondLevelComponent(component);
    });
  }

  get hasTags(): boolean {
    return this.details.tags && this.details.tags.length > 0;
  }
}
