import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, lastValueFrom, Observable, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { CommonFeatures, UserProfileFeatures } from '@my7n/features';
// Services
import { AuthorizationService } from './authorization.service';
// Configs
import { AppConfigService } from '../services/app-config.service';
// Interfaces
import { IAddress } from '../interfaces/address';
import { IPhones } from '../interfaces/phones';
import { ITermsPayload } from '../interfaces/terms-payload';
import { ITermsNewsletterPayload } from '../interfaces/terms-newsletter-payload';
import { ITermsEventInvitationPayload } from '../interfaces/terms-event-invitation-payload';
import { ServiceNames } from '../interfaces/my7n-env-config';
import { ICountriesResponse, ICountry } from '../interfaces/country';

import {
  IBusinessDetails,
  IPersonalDetails,
  IPersonalDetailsResponse,
  ITermsAndConditions,
  IUserProfile
} from '../interfaces/user-profile';
import { ILocation } from '../interfaces/location';
import { GlobalAppConfigFacadeService } from './facades/global-app-config-facade.service';


@Injectable()
export class UserProfileService {
  readonly API_USER: string;
  readonly API_CONTACT: string;
  readonly API_LOCATIONS: string;
  readonly API_DICTIONARIES: string;
  readonly API_GDPR: string;

  get userProfileTermsFeature() {
    return UserProfileFeatures.Terms;
  }

  get welcomePageFeature() {
    return CommonFeatures.WelcomePage;
  }

  constructor(
    private authorizationService: AuthorizationService,
    private http: HttpClient,
    private appConfigService: AppConfigService,
    private globalAppConfigFacadeService: GlobalAppConfigFacadeService
  ) {
    this.API_USER = appConfigService.serviceUrl(ServiceNames.User, 'v1') + 'user/';
    this.API_CONTACT =
      appConfigService.serviceUrl(ServiceNames.Core, 'v2') + 'users/contact/';
    this.API_LOCATIONS =
      appConfigService.serviceUrl(ServiceNames.Core, 'v2') + 'users/locations/';
    this.API_DICTIONARIES = appConfigService.serviceUrl(ServiceNames.Consultant, 'v1') + 'consultant/dictionaries/';
    this.API_GDPR = appConfigService.serviceUrl(ServiceNames.Gdpr, 'v1') + 'gdpr/';
  }

  hasAgreedToTerms(): Observable<boolean> {
    return forkJoin([
      this.globalAppConfigFacadeService.user$.pipe(take(1), map((user) => user.AcceptStoringAndSendingDataBy7n)),
      this.authorizationService.can(this.userProfileTermsFeature).pipe(take(1))
    ])
    .pipe(
      map(([termsAccepted, canSeeTerms]) => {
        return !canSeeTerms || termsAccepted;
      }
    ));
  }

  hasSeenWelcomeScreen(): Observable<boolean> {
    return forkJoin([
      this.globalAppConfigFacadeService.user$.pipe(take(1), map((user) => user.ShowWelcomeScreen)),
      this.authorizationService.can(this.welcomePageFeature).pipe(take(1))
    ])
    .pipe(
      map(([showWelcomeScreen, canSeeWelcomePage]) => {
        return !canSeeWelcomePage || !showWelcomeScreen;
      }
    ));
  }

  updateTermsAgreement(agreements: ITermsPayload): Promise<void> {
    return lastValueFrom(this.http
      .patch(this.API_GDPR + 'consent/terms', agreements))
      .then(() => {
        this.globalAppConfigFacadeService.updateUser({
          AcceptStoringAndSendingDataBy7n: agreements.AcceptStoringAndSendingDataBy7n,
          AcceptedMy7nEventInvitation: agreements.AcceptMy7nEventInvitation,
          AcceptedMy7nNewsletter: agreements.AcceptMy7nNewsletter
        });

        return;
      })
      .catch((e) => {
        console.error(
          '[UserProfileService] There was a problem while saving agreements',
          e
        );
        return Promise.reject(e);
      });
  }

  updateWelcomeScreenVisit(visited: boolean): Promise<void> {
    return lastValueFrom(this.http
      .patch(this.API_USER + 'welcome', {
        Visited: visited
      }))
      .then(() => {
        this.globalAppConfigFacadeService.updateUser({
          ShowWelcomeScreen: false
        });

        return;
      })
      .catch((e) => {
        console.error(
          '[UserProfileService] Failed to save welcome visited flag to backend',
          e
        );
        return Promise.reject(e);
      });
  }

  updateTermsEventInvitation(
    agreement: ITermsEventInvitationPayload
  ): Observable<void> {
    return this.http.patch<void>(this.API_GDPR + 'consent/terms', agreement);
  }

  updateTermsNewsletter(agreement: ITermsNewsletterPayload): Observable<void> {
    return this.http.patch<void>(this.API_GDPR + 'consent/terms', agreement);
  }

  getBusinessDetails(): Observable<IBusinessDetails> {
    return this.http.get<IUserProfile>(this.API_CONTACT).pipe(
      map((res: IUserProfile) => {
        const businessDetailsResult: IBusinessDetails = {
          CompanyName: res.CompanyName,
          CompanyWebsite: res.CompanyWebsite
        };
        return businessDetailsResult;
      })
    );
  }

  getPersonalDetails(): Observable<IPersonalDetails> {
    return this.http
      .get<IPersonalDetailsResponse>(this.API_USER + 'personal-details')
      .pipe(
        map((res: IPersonalDetailsResponse) => {
          const personalDetails: IPersonalDetails = {
            FirstName: res.FirstName,
            LastName: res.LastName,
            Email: res.Email,
            Address: res.Address,
            Phones: {
              BusinessPhone: res.BusinessPhone,
              MobilePhone: res.MobilePhone
            }
          };
          return personalDetails;
        })
      );
  }

  // for preferred work locations data, we still have to use the old endpoint which isn't going to be replaced soon
  getPreferredWorkLocations(): Observable<Array<ILocation>> {
    return this.http
      .get<IUserProfile>(this.API_CONTACT)
      .pipe(map((res: IUserProfile) => res.Locations));
  }

  getTermsAndConditions(): Observable<Partial<ITermsAndConditions>> {
    return this.http.get<Partial<ITermsAndConditions>>(this.API_GDPR + 'consent/terms');
  }

  getAllCountries(): Observable<Array<ICountry>> {
    return this.http
      .get<ICountriesResponse>(this.API_DICTIONARIES + 'countries')
      .pipe(
        map((result) => {
          return result.Countries?.sort((a, b) => a.Name.localeCompare(b.Name));
        }),
        catchError((e) => {
          console.error('[UserProfileService] Failed to get countries', e);
          return throwError(() => e);
        })
      );
  }

  getAllLocations(): Promise<Array<ILocation>> {
    return lastValueFrom(this.http
      .get(this.API_LOCATIONS))
      .then((result: Array<ILocation>) => {
        return result;
      })
      .catch((e) => {
        console.error(
          '[UserProfileService] Failed to get location dictionary',
          e
        );

        return Promise.reject(e);
      });
  }

  updateEmail(emailValue: string): Promise<void> {
    return lastValueFrom(this.http
      .patch(this.API_USER + 'personal-details/email', { Email: emailValue }))
      .then(
        (result: any) => {
          return result;
        },
        (error) => {
          console.error(
            '[UserProfileService] Failed to update consultant email',
            error
          );
          return Promise.reject(error);
        }
      );
  }

  updatePhones(phones: IPhones): Promise<void> {
    return lastValueFrom(this.http
      .patch(this.API_USER + 'personal-details/phone', phones))
      .then(
        (result: any) => {
          return result;
        },
        (error) => {
          console.error(
            '[UserProfileService] Failed to update consultant phones',
            error
          );
          return Promise.reject(error);
        }
      );
  }

  updateCompanyName(name: string): Promise<void> {
    return lastValueFrom(this.http
      .patch(this.API_CONTACT + 'companyName', { CompanyName: name }))
      .then(
        (result: any) => {
          return result;
        },
        (error) => {
          console.error(
            '[UserProfileService] Failed to update consultant company name',
            error
          );
          return Promise.reject(error);
        }
      );
  }

  updateCompanyWebsite(website: string): Promise<void> {
    return lastValueFrom(this.http
      .patch(this.API_CONTACT + 'companyWebsite', { CompanyWebsite: website }))
      .then(
        (result: any) => {
          return result;
        },
        (error) => {
          console.error(
            '[UserProfileService] Failed to update consultant company website',
            error
          );
          return Promise.reject(error);
        }
      );
  }

  updateAddress(address: IAddress): Promise<void> {
    return lastValueFrom(this.http
      .patch(this.API_USER + 'personal-details/address', address))
      .then(
        (result: any) => {
          return result;
        },
        (error) => {
          console.error(
            '[UserProfileService] Failed to update consultant address',
            error
          );
          return Promise.reject(error);
        }
      );
  }

  updateLocations(locations: number[]): Promise<void> {
    return lastValueFrom(this.http
      .patch(this.API_CONTACT + 'locations', { Locations: locations }))
      .then(
        (result: any) => {
          return result;
        },
        (error) => {
          console.error(
            '[UserProfileService] Failed to update consultant location',
            error
          );
          return Promise.reject(error);
        }
      );
  }
}
