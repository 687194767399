import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TimelineNewsFacadeService } from '../../../../../services/facades/timeline-news-facade.service';
import { ICmsNewsViewItem, NEWS_DETAIL_URLS } from '../../../../../interfaces/news';
import { CmsItemViewService } from '../../../../cms/cms-item-view/cms-item-view.service';
import { CmsNewsViewSubmenuComponent } from './cms-news-view-submenu/cms-news-view-submenu.component';

@Component({
  selector: 'cms-news-view',
  templateUrl: './cms-news-view.component.html',
  styleUrls: ['./cms-news-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CmsItemViewService]
})
export class CmsNewsViewComponent implements OnInit, OnDestroy {
  itemId: string;
  details: ICmsNewsViewItem;

  constructor(
    private cmsItemService: CmsItemViewService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private timelineNewsFacade: TimelineNewsFacadeService
  ) {}

  ngOnInit(): void {
    this.details = this.activatedRoute.snapshot.data.news;
    this.cmsItemService.updateSubnavbar(CmsNewsViewSubmenuComponent);
  }

  ngOnDestroy(): void {
    const gointToNewsView = NEWS_DETAIL_URLS.some((url) => this.router.url.startsWith(url));
    const gointToTimeline = this.router.url === '' || this.router.url === '/';

    if (!(gointToNewsView || gointToTimeline)) {
      this.timelineNewsFacade.clearNews();
    }
  }
}
