import { HttpErrorResponse } from '@angular/common/http';
import { NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';

export class ResolveDecorator {
  constructor() {}

  public static handleError(error: HttpErrorResponse, router: Router) {
    console.debug('[ResolveDecorator] handleError');
    // Here we are waiting until NavigationEnd occurs to preserve the target url
    // Without that, navigation is canceled and previous url is restored
    router.events.pipe(
        filter((event) => event instanceof NavigationEnd || event instanceof NavigationCancel),
        take(1)
      )
      .subscribe((event) => {
          console.log('[ResolveDecorator] handleError onetime redirect subscribe', event);
          ResolveDecorator.redirectToErrorPage(error, router);
        });

    // @TODO error can be thrown here to trigger cancel navigation
    // it need to contain: 'ngNavigationCancelingError: true' as a property because it's internally cancelling navigation
    // problem is that location url changes to `/` when it's used.
    // @TODO why we need to cancel?! because angular still triggers target component contructor...
    // return throwError({
    //   error: error,
    //   ngNavigationCancelingError: true
    // })
  }

  public static redirectToErrorPage(error: HttpErrorResponse, router: Router, hideErrorPageUrl = true) {
    const errorStatus = error.status;
    const serverError = errorStatus.toString()[0] === '5';
    const clientError = errorStatus.toString()[0] === '4';

    switch (errorStatus) {
      case 400:
        console.debug('[ResolveDecorator] Redirecting to error 400 component, without location change');
        router.navigate([ '/bad-request' ] , {skipLocationChange: hideErrorPageUrl});
        break;
      case 401:
      case 403:
        console.debug('[ResolveDecorator] Redirecting to error 401/403 component, without location change');
        router.navigate([ '/unauthorized' ], {skipLocationChange: hideErrorPageUrl});
        break;
      case 404:
        console.debug('[ResolveDecorator] Redirecting to error 404 component, without location change');
        router.navigate([ '/not-found' ] , {skipLocationChange: hideErrorPageUrl});
        break;
      case 410:
        console.debug('[ResolveDecorator] Redirecting to error 410 component, without location change');
        router.navigateByUrl('/gone', {skipLocationChange: hideErrorPageUrl});
        break;
      case 500:
        console.debug('[ResolveDecorator] Redirecting to error 500 component, without location change');
        router.navigate([ '/server-error' ], {skipLocationChange: hideErrorPageUrl});
        break;
      default:
        if (serverError) {
          console.debug('[ResolveDecorator] Other server error: ' + errorStatus + ', redirecting to server-error component, without location change');
          router.navigate([ '/server-error' ], {skipLocationChange: hideErrorPageUrl});
          break;
        }

        if (clientError) {
          console.debug('[ResolveDecorator] Other client error: ' + errorStatus + ', redirecting to bad-request-error component, without location change');
          router.navigate([ '/bad-request' ], {skipLocationChange: hideErrorPageUrl});
          break;
        }
    }
  }
}
