export function generateInt(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function generateText(wordsList: Array<string>, min: number = 1, max: number = wordsList.length, duplicates: boolean): string {
    const list = [...wordsList],
    generatedList = [];
    let textLength = generateInt(min, max);

    while (textLength--) {
        const choosenIdx = generateInt(0, list.length - 1);
        generatedList.push(list[choosenIdx]);
        if (!duplicates) {
            list.splice(choosenIdx, 1);
        }
    }

    return generatedList.join(' ');
}

export function generateDate(): Date {
    const maxDate = Date.now();
    const timestamp = Math.floor(Math.random() * maxDate);
    return new Date(timestamp);
}

export function generateDateInFuture(days: number): Date {
    let date = new Date();
    date.setDate(date.getDate() + days);
    return date;
}

export function getMonthInFuture(offset: number, nativeMonthFormat = true): number {
  const currentDate = new Date();
  const baseMonth = currentDate.getMonth();
  const offsetMonth = baseMonth + offset;

  const calculatedMonth = offsetMonth > 11 ? (offsetMonth) % 12 : offsetMonth;
  return nativeMonthFormat ? calculatedMonth : calculatedMonth + 1;
}

export function getYearInFuture(monthOffset: number): number {
  const currentDate = new Date();
  const baseMonth = currentDate.getMonth();

  const monthInFuture = baseMonth + monthOffset;
  return monthInFuture > 11 ? currentDate.getFullYear() + Math.floor(monthInFuture / 12) : currentDate.getFullYear();
}

export function generateBanner(generateEmpty?: boolean): string {
    const emptyBanner = generateEmpty ? generateInt(0, 10) < 1  : false;

    if (emptyBanner) {
        return null;
    }

    const bannerId = generateInt(1, 20);
    return `https://my7nstagingneublobmain.blob.core.windows.net/eventbanners/preset/thumbnail/city-${bannerId}.png`;
}

export function chooseFromArray<T>(array: Array<T>): T {
    const list = [...array],
    choosenIdx = generateInt(0, list.length - 1);

    return list[choosenIdx];
}

export function chooseManyFromArray<T>(array: Array<T>, min: number = 1, max: number = 2, duplicates?: boolean): Array<T> {
    const list = [...array],
    choosenElements: Array<T> = [],
    count = generateInt(min, (max > list.length && !duplicates) ? list.length : max);

    while (choosenElements.length < count) {
        const choosenIdx = generateInt(0, list.length - 1);
        choosenElements.push(list[choosenIdx]);
        if (!duplicates) {
            list.splice(choosenIdx, 1);
        }

    }

    return choosenElements;
}

export function generateNumbersList(howMany: number, startFromZero = false): Array<number> {
    const list: Array<number> = [];
    let i = startFromZero ? 0 : 1;

    while (i <= howMany) {
      list.push(i++);
    }

    return list;
}

// generating long strings, useful for testing maxlength
export function generateGivenLengthString(
    length: number,
    char: string = 'W'
  ): string {
    if (length > 0) {
      return new Array(length + 1).join(char);
    } else {
      return '';
    }
  }

