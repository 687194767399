<div [formGroup]="parent">
  <ng-template [ngIf]="showLetterHeaders" [ngIfElse]="withoutLetterHeaders">
    <div *ngFor="let header of headers" class="avoid-column-break" [ngClass]="{ 'group-margin': showLetterHeaders }">
      <div class="letter-header">{{ header.letter }}</div>
      <ng-container *ngFor="let checkbox of getItemsByHeader(header.letter); index as i">
        <checkbox-category [instance]="instance" [branch]="parent.controls[header.firstIndex + i].controls"
          [data]="checkbox" [name]="name" [parentForm]="parent" [index]="header.firstIndex + i" [isFlat]="isFlat">
        </checkbox-category>
      </ng-container>
    </div>
  </ng-template>
  <ng-template #withoutLetterHeaders>
    <checkbox-category *ngFor="let control of parent.controls; index as i" [instance]="instance"
      [branch]="control.controls" [data]="checkboxes[i]" [name]="name" [parentForm]="parent" [index]="i"
      [isFlat]="isFlat" [isColumnBreakBefore]="columns && isColumnBreakBefore(i)">
    </checkbox-category>
  </ng-template>
</div>
