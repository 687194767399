<div *ngIf="config.showHeader"
     class="event-box-header-bar flex flex-between-center">
  <h4 class="event-box-title font-bold"
      data-test-id="event-box-header">{{config.texts.header}}</h4>
  <div class="flex flex-end-center gap-24">
    <a routerLink="/events"
       [queryParams]="{filter: 'upcoming'}"
       type="button"
       mat-button
       class="small-button"
       color="primary"
       data-test-id="event-box-link">
      {{config.texts.allEventsButton}}
    </a>
  </div>
</div>
<event-box-element *ngFor="let e of eventsList"
                   [event]="e"
                   [ngClass]="{
                    'visible-mobile': config.hideSwipeForMobile,
                    'hidden': !config.hideSwipeForMobile && (mediaQueryService.breakpoints$ | async).ltMd
                  }"
                   data-test-id="event-box-element-desktop"></event-box-element>
<div class="no-events-container" *ngIf="!eventsList.length">
  <p>{{config.texts.noEvents}}</p>
</div>

<!-- only for mobile -->
<ng-container *ngIf="eventsList.length > 0 && !config.hideSwipeForMobile">
  <swipe-list class="block md:hidden">
    <event-box-element *ngFor="let e of eventsList"
                       [event]="e"
                       class="swipeEl"
                       data-test-id="event-box-element-mobile"></event-box-element>
  </swipe-list>
</ng-container>
