import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  SimpleChanges,
  OnChanges,
  HostListener, HostBinding, ChangeDetectorRef
} from '@angular/core';


// Interfaces
import { INotificationItem, NotificationItemType } from '../../../../interfaces/notification-item';
// Facades
import { NotificationsFacade } from '../../../../services/facades/notifications-facade.service';

@Component({
  selector: 'notifications-list-element',
  templateUrl: './notifications-list-element.component.html',
  styleUrls: ['./notifications-list-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsListElementComponent implements OnInit, OnChanges {
  @HostBinding('class.notification-read') get isNotificationRead() {
    return this.notification && this.notification.IsVisited;
  }
  @Input() notification: INotificationItem;
  @Output() checkDetailsClicked = new EventEmitter<null>();

  isHovered = false;
  isOtherType: boolean;

  urlBase: string;
  urlParams: {
    [k: string]: any;
  };

  @HostListener('mouseenter') onMouseEnter() {
    this.isHovered = true;
  }
  @HostListener('mouseleave') onMouseLeave() {
    this.isHovered = false;
  }

  constructor(private notificationsFacade: NotificationsFacade) { }

  ngOnInit() {}

  /**
   * Important!
   * Below logic must be execute on changes because this component is used by virtual scroll.
   * Virtual scroll uses cache for list item components and ngOnInit is not always executed.
   */
  ngOnChanges(changes: SimpleChanges) {
    this.isOtherType = this.notification.Type === NotificationItemType.OTHER;
    if (!this.isOtherType) {
      this.setNotificationUrlParts();
    }
  }

  setNotificationUrlParts() {
    const urlParams = {};
    let urlBase;

    switch (this.notification.Type) {
      case NotificationItemType.EVENT:
        urlBase = `/events/${this.notification.ExternalId}`;
        break;
      case NotificationItemType.NEWS:
        urlBase = `/news/${this.notification.ExternalId}`;
        break;
      case NotificationItemType.REFERRAL:
        urlBase = `/referrals/overview`;
        urlParams['id'] = this.notification.ExternalId;
        break;
      default:
        console.error(`[NotificationsListElement] Wrong notification type or not provided at all. Notification Id: ${this.notification.Id}`);
        return null;
    }

    this.urlBase = urlBase;
    this.urlParams = urlParams;
  }

  handleClick($event) {
    if (!this.notification.IsVisited) {
      this.notificationsFacade.notificationItemRead(this.notification.Id);
    }
    // Prevent from double router navigate calling
    $event.stopPropagation();
    $event.stopImmediatePropagation();
    this.checkDetailsClicked.emit();
  }
}
