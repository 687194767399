import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { My7nMaterialModule } from './my7n-material.module';

@NgModule({
  imports: [
    CommonModule,
    My7nMaterialModule
  ],
  exports: [
    My7nMaterialModule
  ],
  declarations: [],
  bootstrap: []
})
export class My7nSharedModule { }
