import { Injectable } from '@angular/core';
import { GlobalAppConfigFacadeService } from './facades/global-app-config-facade.service';

@Injectable()
export class FabAgentService {

  constructor(private globalAppConfigFacadeService: GlobalAppConfigFacadeService) { }

  remindLater() {
    return this.globalAppConfigFacadeService.updateUser({ HasToUpdateContactData: true});
  }
}
