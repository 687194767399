<h1 mat-dialog-title>
  Can't delete primary CV
  <button mat-button 
          isIconButton="true" 
          class="close-btn" 
          (click)="cancelDialog()"
          data-test-id="consultant-cv-primary-dialog-close-button">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</h1>
<div mat-dialog-content class="flex flex-col">
  <p>
    Primary CV cannot be deleted. To delete this CV,
    <span *ngIf="cvsCount > 1">mark another CV as primary.</span>
    <span *ngIf="cvsCount === 1">contact 7N IT support.</span>
  </p>
</div>
<mat-dialog-actions align="start">
  <button type="button" 
          mat-stroked-button color="primary"
          [ngClass]="{ 'small-button': (mediaQueryService.breakpoints$ | async).ltMd }"
          (click)="cancelDialog()"
          data-test-id="consultant-cv-primary-dialog-ok-button">OK</button>
</mat-dialog-actions>
