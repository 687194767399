import { createAction, props } from '@ngrx/store';
import { IBlobSasTokens } from '../../interfaces/blob-sas';
import { ITokenClaims } from '../../interfaces/token-claims';

export const updateAppVersion = createAction(
  '[Global] update app version',
  props<{ appVersion: string }>()
);

export const updateLeavingPageGuardEnabled = createAction(
  '[Global] update leaving page guard enabled',
  props<{ isEnabled: boolean }>()
);

export const updateTokenClaims = createAction(
  '[Global] update token data',
  props<{ tokenClaims: ITokenClaims }>()
);

export const queryBlobSasTokens = createAction(
  '[Global] query blob sas tokens'
);

export const queryBlobSasTokensSuccess = createAction(
  '[Global] query blob sas tokens Success',
  props<{ blobSasTokens: IBlobSasTokens }>()
);

export const queryBlobSasTokensError = createAction(
  '[Global] query blob sas tokens Error'
);
