import { FrontendFeature, FrontendFeaturesModule } from '../feature';

@FrontendFeaturesModule('Events')
export class EventsFeatures {
  @FrontendFeature({
    summary: 'Main events page'
  })
  static readonly Default = 'events';

  @FrontendFeature({
    summary: 'Events upcoming tab'
  })
  static readonly Upcoming = 'events/upcoming';

  @FrontendFeature({
    summary: 'Events past tab'
  })
  static readonly Past = 'events/past';

  @FrontendFeature({
    summary: 'Events drafts tab'
  })
  static readonly Drafts = 'events/drafts';

  @FrontendFeature({
    summary: 'Events add new'
  })
  static readonly Add = 'events/add';

  @FrontendFeature({
    summary: 'Events show detail'
  })
  static readonly ItemView = 'events/item/view';

  @FrontendFeature({
    summary: 'Events publish (change from draft to published)'
  })
  static readonly ItemPublish = 'events/item/publish';

  @FrontendFeature({
    summary: 'Events unpublish (change published to draft)'
  })
  static readonly ItemUnpublish = 'events/item/unpublish';

  @FrontendFeature({
    summary: 'Events add to timeline'
  })
  static readonly ItemAddToTimeline = 'events/item/add-to-timeline';

  @FrontendFeature({
    summary: 'Events edit item'
  })
  static readonly ItemEdit = 'events/item/edit';

  @FrontendFeature({
    summary: 'Events duplicate item'
  })
  static readonly ItemDuplicate = 'events/item/duplicate';

  @FrontendFeature({
    summary: 'Events duplicate item'
  })
  static readonly ItemCheckLink = 'events/item/check-link';

  @FrontendFeature({
    summary: 'Events delete item'
  })
  static readonly ItemDelete = 'events/item/delete';

  @FrontendFeature({
    summary: 'Event type item'
  })
  static readonly EventType = 'events/item/type';
}
