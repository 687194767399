<div #videoContainer>
  <div *rxLet="state$ as localState">
    <div *ngIf="localState.video"
         class="video-update-box flex flex-col gap-12">
      <h4 class="font-bold">{{ localState.video.title }}</h4>
      <my7n-video-player *ngIf="localState.video.videoUrl"
                         [videoUrl]="localState.video.videoUrl"
                         [module]="LogVideoModules.VideoUpdateBox"
                         [title]="localState.video.title"
                         [articleId]="localState.video.id" 
                         [config]="localState.videoPlayerConfig"></my7n-video-player>
    </div>
  </div>
</div>
