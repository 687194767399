import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CmsDictionaryTypes, ICmsDictionaryItem } from '../../interfaces/cms';
import { Store, select } from '@ngrx/store';
import * as DictionariesActions from '../../store/actions/cms/dictionaries.actions';
import * as DictionariesSelectors from '../../store/reducers/cms/dictionaries.selectors';
import * as fromDictionaries from '../../store/reducers/cms/dictionaries.reducer';

@Injectable()
export class DictionariesFacadeService {
  constructor(private store: Store<fromDictionaries.FeatureState>) {}

  get loadingDictionaries$(): Observable<boolean> {
    return this.store.pipe(
      select(DictionariesSelectors.selectDictionariesLoading)
    );
  }

  queryDictionariesByTypes(
    types: Array<CmsDictionaryTypes>,
    forceRefresh = false
  ) {
    this.store.dispatch(
      DictionariesActions.queryDictionariesByTypes({
        types,
        forceRefresh
      })
    );
  }

  getDictionaryByType$(
    type: CmsDictionaryTypes
  ): Observable<Array<ICmsDictionaryItem>> {
    return this.store.pipe(
      select(DictionariesSelectors.selectDictionaryByType(type))
    );
  }
}
