import { FrontendFeature, FrontendFeaturesModule } from '../feature';
import { Feature } from '../interfaces/feature';

export const LEGAL_ENTITY_COUNTRY_FEATURE_PREFIX = 'legal-entity/country/';
export const LEGAL_ENTITY_FEATURE_PREFIX = 'legal-entity/';
@FrontendFeaturesModule('LegalEntity')
export class LegalEntityFeatures {
  @FrontendFeature({
    summary: 'Legal entity Poland'
  })
  static readonly Poland: Feature =
    LEGAL_ENTITY_COUNTRY_FEATURE_PREFIX + 'poland';

  @FrontendFeature({
    summary: 'Legal entity Denmark'
  })
  static readonly Denmark: Feature =
    LEGAL_ENTITY_COUNTRY_FEATURE_PREFIX + 'denmark';

  @FrontendFeature({
    summary: 'Legal entity USA'
  })
  static readonly USA: Feature = LEGAL_ENTITY_COUNTRY_FEATURE_PREFIX + 'usa';

  @FrontendFeature({
    summary: 'Legal entity Switzerland'
  })
  static readonly Switzerland: Feature =
    LEGAL_ENTITY_COUNTRY_FEATURE_PREFIX + 'switzerland';

  @FrontendFeature({
    summary: 'Legal entity Norway'
  })
  static readonly Norway: Feature =
    LEGAL_ENTITY_COUNTRY_FEATURE_PREFIX + 'norway';

  @FrontendFeature({
    summary: 'Legal entity Finland'
  })
  static readonly Finland: Feature =
    LEGAL_ENTITY_COUNTRY_FEATURE_PREFIX + 'finland';

  @FrontendFeature({
    summary: 'Legal entity Sweden'
  })
  static readonly Sweden: Feature =
    LEGAL_ENTITY_COUNTRY_FEATURE_PREFIX + 'sweden';

  @FrontendFeature({
    summary: 'Legal entity India'
  })
  static readonly India: Feature =
    LEGAL_ENTITY_COUNTRY_FEATURE_PREFIX + 'india';

  @FrontendFeature({
    summary: 'Legal entity 7N/AS'
  })
  static readonly _7n_AS: Feature = LEGAL_ENTITY_FEATURE_PREFIX + '7n-as';

  @FrontendFeature({
    summary: 'Legal entity 7N sp. z o.o.'
  })
  static readonly _7n_sp_zoo: Feature =
    LEGAL_ENTITY_FEATURE_PREFIX + '7n-sp-z-o-o';

  @FrontendFeature({
    summary: 'Legal entity 7N CIM sp. z o.o.'
  })
  static readonly _7n_CIM: Feature =
    LEGAL_ENTITY_FEATURE_PREFIX + '7n-cim-sp-z-o-o';

  @FrontendFeature({
    summary: '7N USA Inc.'
  })
  static readonly _7n_USA_Inc: Feature =
    LEGAL_ENTITY_FEATURE_PREFIX + '7n-usa-inc';

  @FrontendFeature({
    summary: 'Legal entity 7n Sweiz AG'
  })
  static readonly _7n_Sweiz_AG: Feature =
    LEGAL_ENTITY_FEATURE_PREFIX + '7n-sweiz-ag';

  @FrontendFeature({
    summary: 'Legal entity 7N Norge AS'
  })
  static readonly _7n_Norge_AS: Feature =
    LEGAL_ENTITY_FEATURE_PREFIX + '7n-norge-as';

  @FrontendFeature({
    summary: 'Legal entity 7N Finland OY'
  })
  static readonly _7n_Finland_OY: Feature =
    LEGAL_ENTITY_FEATURE_PREFIX + '7n-finland-oy';

  @FrontendFeature({
    summary: 'Legal entity 7n IT Consulting AB'
  })
  static readonly _7n_IT_Consulting_AB: Feature =
    LEGAL_ENTITY_FEATURE_PREFIX + '7n-it-consulting-ab';

  @FrontendFeature({
    summary: 'Legal entity 7N Seven N Consulting Pvt. Ltd'
  })
  static readonly _7n_Seven_N_Consulting_Pvt_Ltd: Feature =
    LEGAL_ENTITY_FEATURE_PREFIX + '7n-seven-n-consulting-pvt-ltd';
}
