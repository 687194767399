<ng-container *ngIf="(mediaQueryService.breakpoints$ | async) as breakpoints">
  <div class="phones-edit-wrapper"
       [ngClass]="{ error: error, edit: editMode, 'side-panel': sidePanel }"
       data-test-id="phones-edit-container">
    <div *ngIf="!editMode && !sidePanel">
      <div class="phones-edit-values">
        <span class="phone-label subheading" [ngClass]="{ empty: !phones.MobilePhone }">Mobile phone</span>
      </div>
      <div class="flex flex-between-stretch">
        <span [ngClass]="{ placeholder: !phones.MobilePhone }"
              class="phone-value mobileNumber"
              data-test-id="phones-edit-mobileNumber-value">{{ phones.MobilePhone || "e.g. +48 555 444 333" }}</span>
        <button *ngIf="!readonly && canEdit"
                mat-button
                color="primary"
                class="small-button"
                (click)="activate()"
                data-test-id="phones-edit-mobileNumber-add-edit-button">
          {{ phones.MobilePhone ? 'Edit' : 'Add' }}
        </button>
      </div>
      <div class="business-number-wrapper" *ngIf="phones.BusinessPhone">
        <div class="phones-edit-values">
          <span class="phone-label subheading" [ngClass]="{ empty: !phones.BusinessPhone }">Business phone</span>
        </div>
        <span class="phone-value businessNumber" *ngIf="phones.BusinessPhone" data-test-id="phones-edit-businessNumber-value">{{ phones.BusinessPhone }}</span>
      </div>
    </div>

    <div *ngIf="!editMode && sidePanel" class="flex flex-col flex-start-stretch">
      <!-- Mobile phone -->
      <div class="phone-row row-border first-row flex flex-start-stretch">
        <div class="flex-data-wrapper">
          <div class="phone-label-container">
            <span class="phone-label" [ngClass]="{ empty: !phones.MobilePhone }">Mobile phone</span>
          </div>
          <div class="phone-value-container">
            <a class="phone-value"
               *ngIf="phones.MobilePhone"
               [href]="('tel:' + phones.MobilePhone?.trim()) | trustedUrl"
               data-test-id="phones-edit-sidepanel-mobileNumber-value">{{ phones.MobilePhone }}</a>
            <span *ngIf="!phones.MobilePhone && !readonly" class="phone-value mobileNumber placeholder">e.g. +48 555 444 333</span>
          </div>
        </div>
        <div class="actions">
          <button *ngIf="!readonly  && canEdit"
                  mat-button color="primary"
                  class="small-button"
                  (click)="activate()"
                  data-test-id="phones-edit-sidepanel-mobileNumber-add-edit-button">
            {{ phones.MobilePhone ? 'Edit' : 'Add' }}
          </button>
        </div>
      </div>
      <!-- Business phone -->
      <div class="phone-row last-row flex flex-start-stretch">
        <div class="flex-data-wrapper">
          <div class="phone-label-container">
            <span class="phone-label" [ngClass]="{ empty: !phones.BusinessPhone }">Business phone</span>
          </div>
          <div class="phone-value-container">
            <a class="phone-value"
               [href]="('tel:' + phones.BusinessPhone?.trim()) | trustedUrl"
               data-test-id="phones-edit-sidepanel-businessNumber-value">{{ phones.BusinessPhone }}</a>
          </div>
        </div>
        <div class="actions">
          <button *ngIf="!readonly && canEdit"
                  mat-button color="primary"
                  class="small-button"
                  (click)="activate()"
                  data-test-id="phones-edit-sidepanel-businessNumber-add-edit-button">
            {{ phones.BusinessPhone ? 'Edit' : 'Add' }}
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="editMode" class="phones-edit-form-groups">
      <form class="edit-form"
            [ngClass]="{ loading: loading }"
            name="phonesEditForm"
            [formGroup]="phonesEditForm"
            (ngSubmit)="update()"
            novalidate
            (keydown)="keyListener($event)"
            (keypress)="keyListener($event)"
            data-test-id="phones-edit-form">
        <h4 class="font-bold">Edit phone numbers</h4>
        <div class="content">
          <!-- cell phone -->
          <mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic">
            <mat-label>Mobile phone</mat-label>
            <input matInput
                   formControlName="mobileNumber"
                   type="text"
                   placeholder="Mobile phone"
                   name="mobileNumber"
                   maxlength="100"
                   data-test-id="phones-edit-form-mobileNumber-input"/>
          </mat-form-field>

          <!-- business number -->
          <mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic">
            <mat-label>Business phone (optional)</mat-label>
            <input matInput
                   formControlName="businessNumber"
                   type="text"
                   placeholder="Business phone (optional)"
                   name="businessNumber"
                   maxlength="100"
                   data-test-id="phones-edit-form-businessNumber-input"/>
          </mat-form-field>
        </div>
        <div class="form-actions">
          <button mat-stroked-button
                  color="primary"
                  class="small-button"
                  type="submit"
                  [loading]="loading"
                  data-test-id="phones-edit-form-save-button">
            {{ breakpoints.ltMd ? COMMON_BUTTONS_TEXTS.SAVE_CHANGES_MOBILE : COMMON_BUTTONS_TEXTS.SAVE_CHANGES_DESKTOP}}
          </button>
          <button mat-button
                  color="primary"
                  class="small-button cancel-button"
                  type="button"
                  (click)="reset()"
                  data-test-id="phones-edit-form-discard-button">
            {{ breakpoints.ltMd ? COMMON_BUTTONS_TEXTS.DISCARD_CHANGES_MOBILE: COMMON_BUTTONS_TEXTS.DISCARD_CHANGES_DESKTOP}}
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-container>
