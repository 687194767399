import {
  Component,
  OnInit,
  Inject,
  HostBinding,
  OnDestroy
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { SnackBarService, SnackBarTypes } from '@my7n/ui';
// interfaces
import { IPrintPreferences } from '../../../../interfaces/print-preferences';
// services
import { PrintSettingsService } from '../../../../services/print-settings.service';
import { GlobalAppConfigFacadeService } from '../../../../services/facades/global-app-config-facade.service';

@Component({
  selector: 'print-settings-dialog',
  templateUrl: './print-settings-dialog.component.html',
  styleUrls: ['./print-settings-dialog.component.scss']
})
export class PrintSettingsDialogComponent implements OnInit, OnDestroy {
  printSettings: IPrintPreferences = {};
  presenters: any = {};

  Presenters: any;
  Templates: any;

  @HostBinding('class.loading') loading = false;

  subscriptions = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<PrintSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private printSettingsService: PrintSettingsService,
    private snackBarService: SnackBarService,
    private globalAppConfigFacade: GlobalAppConfigFacadeService
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.globalAppConfigFacade.printPreferences$.subscribe(
        (settings: IPrintPreferences) => {
          this.printSettings = { ...settings };
          this.printSettingsService.preparePresentersAndTemplates(
            this,
            settings
          );
        }
      )
    );
  }

  save() {
    if (!this.loading) {
      this.loading = true;
      this.printSettingsService
        .savePrintSettings(this.printSettings)
        .then((result) => {
          this.snackBarService.open({
            message: 'Settings saved',
            type: SnackBarTypes.SuccessAlt
          });
          this.dialogRef.close(true);
        })
        .catch((error) => {
          this.snackBarService.open({
            message: 'Error while saving',
            type: SnackBarTypes.ErrorAlt
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  cancel() {
    this.dialogRef.close(false);
    console.debug(
      '[PrintSettingsDialogComponent] was closed without confirmation'
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
