import { createAction, props } from '@ngrx/store';
import { IAppConfig } from '../../interfaces/app-config';
import { IPrintPreferences } from '../../interfaces/print-preferences';
import { IConfigApiUrls } from '../reducers/global/global-app-config.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { IUserPreferences } from '../../interfaces/user-preferences';

export const queryAppConfig = createAction('[Global][AppConfig] query app config');

export const queryAppConfigSuccess = createAction(
  '[Global][AppConfig] query app config Success',
  props<{ config: IAppConfig }>()
);

export const queryAppConfigError = createAction(
  '[Global][AppConfig] query app config Error',
  props<{ error: HttpErrorResponse }>()
);

export const updatePrintPreferences = createAction(
  '[Global][AppConfig] update print preferences',
  props<{ settings: IPrintPreferences }>()
);

export const updateConfigDirtyState = createAction(
  '[Global][AppConfig] update config dirty state',
  props<{ dirty: boolean }>()
);

export const updateConfigApiUrls = createAction(
  '[Global][AppConfig] update config api urls',
  props<{ configApiUrls: IConfigApiUrls }>()
);

export const updateUser = createAction(
  '[Global][AppConfig] update user',
  props<{ user: Partial<IUserPreferences> }>()
);
