import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import { SnackBarService, SnackBarTypes } from '@my7n/ui';
import { RxState } from '@rx-angular/state';
import { MAX_LENGTHS } from '../../../interfaces/user-profile';
import { UserProfileFacadeService } from '../../../services/facades/user-profile-facade.service';
import { ILocation } from '../../../interfaces/location';

export interface IPreferredWorkLocationsLocalState {
  preferredWorkLocations: Array<ILocation>;
  preferredWorkLocationsLoading: boolean;
  canEdit: boolean;
}

export const initialState: IPreferredWorkLocationsLocalState = {
  preferredWorkLocations: [],
  preferredWorkLocationsLoading: false,
  canEdit: false
};

@Component({
  selector: 'preferred-work-locations',
  templateUrl: './preferred-work-locations.component.html',
  styleUrls: ['./preferred-work-locations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState]
})
export class PreferredWorkLocationsComponent implements OnInit {
  MAX_LENGTHS = MAX_LENGTHS;

  @Input() set canEdit(can: boolean) {
    this.state.set({ canEdit: can });
  }

  readonly state$ = this.state.select();

  constructor(
    private state: RxState<IPreferredWorkLocationsLocalState>,
    private userProfileFacade: UserProfileFacadeService,
    private snackBarService: SnackBarService
  ) {
    this.state.set({ ...initialState });
  }

  ngOnInit() {
    this.userProfileFacade.queryPreferredWorkLocations();
    this.initLocalStateConnections();
  }

  initLocalStateConnections() {
    this.state.connect(
      'preferredWorkLocations',
      this.userProfileFacade.preferredWorkLocations$
    );
    this.state.connect(
      'preferredWorkLocationsLoading',
      this.userProfileFacade.preferredWorkLocationsLoading$
    );
  }

  preferredWorkLocationsUpdated(locations: Array<ILocation>) {
    this.userProfileFacade.updatePreferredWorkLocations(locations);
  }

  onServerError() {
    this.snackBarService.open({
      message: 'A server error occured',
      type: SnackBarTypes.ErrorAlt
    });
  }
}
