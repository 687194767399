<ng-container *ngIf="(mediaQueryService.breakpoints$ | async) as breakpoints">
  <div class="labeled-edit-container"
       [ngClass]="{'edit': editMode}"
       data-test-id="labeled-edit-container">
    <div class="labeled-edit-label" *ngIf="!editMode">{{ label }}
      <tooltip-popover position="bottom" #tooltip>
        <mat-icon-with-hover *ngIf="tooltipHtml" #tooltipIcon iconName="tooltip_default" iconNameHover="tooltip_hover" iconNameActive="tooltip_active"></mat-icon-with-hover>
        <ng-template cdkPortal #tooltipPopoverContent="cdkPortal" let-tooltipPopover>
          <mat-icon svgIcon="close_tiny" (click)="tooltip.closePopover()" class="close-icon"></mat-icon>
          <div [ngClass]="multilineTooltip ? 'multiline-content' : 'tooltip-content'" [innerHTML]="tooltipHtml"></div>
        </ng-template>
      </tooltip-popover>
    </div>

    <div class="labeled-edit-content flex flex-between-stretch"
         *ngIf="!editMode"
         data-test-id="labeled-edit-content">
        <span class="labeled-edit-value" *ngIf="fieldValue && display === 'text'">{{ fieldValue }}</span>
        <span class="labeled-edit-value placeholder" *ngIf="!fieldValue">{{ placeholder }}</span>

        <a class="labeled-edit-value" [attr.href]="getContactWebsite()" *ngIf="display === 'url'" target="_blank">{{ fieldValue }}</a>
        <a class="labeled-edit-value" [attr.href]="getSkype()" *ngIf="display === 'skype'">{{ fieldValue }}</a>

        <ng-container *ngIf="!readonly && canEdit">
          <button mat-button
                  color="primary"
                  class="small-button"
                  *ngIf="!fieldValue"
                  (click)="activate()"
                  data-test-id="labeled-edit-add-button">Add</button>
          <button mat-button
                  color="primary"
                  class="small-button"
                  *ngIf="fieldValue"
                  (click)="activate()"
                  data-test-id="labeled-edit-edit-button">Edit</button>
        </ng-container>
    </div>

    <form *ngIf="editMode"
          [ngClass]="{loading: loading}"
          (ngSubmit)="update()"
          class="edit-form"
          name="labeledEditForm"
          novalidate
          [formGroup]="labeledEditForm"
          (keydown)="keyListener($event)"
          (keypress)="keyListener($event)"
          data-test-id="labeled-edit-form">
      <h4 class="font-bold">Edit {{label}}</h4>
      <div class="content">
        <mat-form-field appearance="outline"
                        floatLabel="always"
                        subscriptSizing="dynamic">
          <mat-label>{{label}}</mat-label>
          <input #inputEl
                 matInput
                 formControlName="fieldValue"
                 type="{{type}}"
                 placeholder="{{ placeholder }}"
                 name="labeledEditInput"
                 [attr.maxlength]="maxlength"
                 data-test-id="labeled-edit-input" />
        </mat-form-field>
        <validation-messages [field]="labeledEditForm.get('fieldValue')"
                             [messages]="['required', 'urlNotValid', 'emailNotValid', 'maxlength']"
                             [texts]="{'required': 'Field is required!', 'urlNotValid': 'Provided URL is not valid', 'emailNotValid': 'Provided e-mail is not valid'}"
                             data-test-id="labeled-edit-input-validation-message"></validation-messages>
      </div>
      <div class="action-bar flex flex-wrap">
        <button mat-stroked-button
                color="primary"
                class="small-button save-button"
                type="submit"
                [loading]="loading"
                data-test-id="labeled-edit-save-button">
          {{ breakpoints.ltMd ? COMMON_BUTTONS_TEXTS.SAVE_CHANGES_MOBILE : COMMON_BUTTONS_TEXTS.SAVE_CHANGES_DESKTOP}}
        </button>
        <button mat-button
                color="primary"
                class="small-button cancel-button"
                type="button"
                (click)="reset()"
                data-test-id="labeled-edit-discard-button">
          {{ breakpoints.ltMd ? COMMON_BUTTONS_TEXTS.DISCARD_CHANGES_MOBILE: COMMON_BUTTONS_TEXTS.DISCARD_CHANGES_DESKTOP}}
        </button>
      </div>
    </form>
  </div>
</ng-container>
