import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RxState } from '@rx-angular/state';
import {
  INotificationSettings,
  NotificationFrequency
} from '../../../interfaces/notification-settings';
import { MAX_LENGTHS } from '../../../interfaces/user-profile';
import { UserProfileFacadeService } from '../../../services/facades/user-profile-facade.service';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';

export interface INotificationSettingsLocalState {
  notificationSettings: INotificationSettings;
  notificationSettingsLoading: boolean;
  notificationSettingsSaving: boolean;
  canEdit: boolean;
  isNotificationsFormReady: boolean;
}

export const initialState: INotificationSettingsLocalState = {
  notificationSettings: null,
  notificationSettingsLoading: false,
  notificationSettingsSaving: false,
  canEdit: false,
  isNotificationsFormReady: false
};

@Component({
  selector: 'notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState]
})
export class NotificationSettingsComponent implements OnInit {
  MAX_LENGTHS = MAX_LENGTHS;

  notificationsForm: UntypedFormGroup;

  @Input() set canEdit(can: boolean) {
    this.state.set({ canEdit: can });
  }

  readonly state$ = this.state.select();

  constructor(
    private state: RxState<INotificationSettingsLocalState>,
    private userProfileFacade: UserProfileFacadeService
  ) {
    this.state.set({ ...initialState });
  }

  ngOnInit() {
    this.userProfileFacade.queryNotificationSettings();
    this.initLocalStateConnections();
    this.initLocalStateEffects();
  }

  initLocalStateConnections() {
    this.state.connect(
      'notificationSettings',
      this.userProfileFacade.notificationSettings$
    );
    this.state.connect(
      'notificationSettingsLoading',
      this.userProfileFacade.notificationSettingsLoading$
    );
    this.state.connect(
      'notificationSettingsSaving',
      this.userProfileFacade.notificationSettingsSaving$
    );
  }

  initLocalStateEffects() {
    this.setCreateFormWhenReadyEffects();
    this.setNotificationsFormEffects();
    this.setSlidersStateEffects();
  }

  setCreateFormWhenReadyEffects() {
    const notificationSettingsReady$ = this.state
      .select('notificationSettings')
      .pipe(
        filter((settings: INotificationSettings) => settings !== null),
        take(1)
      );
    this.state.hold(notificationSettingsReady$, () => {
      this.createNotificationSection();
    });
  }

  setNotificationsFormEffects() {
    // when the notifications form is ready - set a value changes effect
    // we have to wait until notificationsForm is defined
    this.state.hold(
      this.state.select('isNotificationsFormReady').pipe(
        filter((isReady: boolean) => isReady),
        take(1)
      ),
      () => {
        // everytime the form control value is changing, update the store
        this.state.hold(
          this.notificationsForm.controls['subscription'].valueChanges,
          (wantToReceive: boolean) => {
            this.userProfileFacade.updateNotificationSettingsSubscription(
              wantToReceive
            );
          }
        );

        // listen to the notifications' subscription state changes and update the form without emitting value
        this.state.hold(
          this.state.select('notificationSettings', 'WantToReceive').pipe(
            filter((wantToReceive: boolean) => wantToReceive !== undefined),
            distinctUntilChanged()
          ),
          (wantToReceive: boolean) => {
            this.notificationsForm.controls['subscription'].setValue(
              wantToReceive,
              { emitEvent: false }
            );
          }
        );
      }
    );
  }

  setSlidersStateEffects() {
    // set sliders state
    const canEditWhenNotificationsFormIsReady$ = combineLatest([
      this.state.select('isNotificationsFormReady'),
      this.state.select('canEdit')
    ]).pipe(
      filter((result: [boolean, boolean]) => result[0]),
      take(1),
      map((result: [boolean, boolean]) => result[1])
    );

    this.state.hold(
      canEditWhenNotificationsFormIsReady$,
      (canEdit: boolean) => {
        this.setNotificationsSliderState(canEdit);
      }
    );
  }

  setNotificationsSliderState(enable: boolean) {
    if (enable) {
      this.notificationsForm.get('subscription').enable({ emitEvent: false });
    } else {
      this.notificationsForm.get('subscription').disable({ emitEvent: false });
    }
  }

  createNotificationSection() {
    this.notificationsForm = new UntypedFormGroup({
      subscription: new UntypedFormControl(
        this.state.get('notificationSettings')?.WantToReceive
      )
    });
    this.state.set({ isNotificationsFormReady: true });
  }

  notificationSettingsFrequencyUpdated(frequency: NotificationFrequency) {
    this.userProfileFacade.updateNotificationSettingsFrequency({
      ...this.state.get('notificationSettings'),
      SummaryNotificationFrequency: frequency
    });
  }
}
