import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CmsContentTypes, ICmsVideoUpdateItem } from '../interfaces/cms';
import { VideoUpdateHelper } from '../utils/helpers/video-update.helper';
import { ContentfulGetterService } from './contentful-getter.service';
import { LegalEntityFeaturesCountryNameMapping } from '../interfaces/legal-entity';
import { LegalEntityFeatures } from '@my7n/features';

@Injectable()
export class VideoUpdateContentfulService {
  constructor(private contentfulGetterService: ContentfulGetterService) {}

  getLatestVideoUpdateLegalEntityCountryFeature(legalEntityCountryFeature: LegalEntityFeatures): Observable<ICmsVideoUpdateItem> {
    const query = {
      content_type: CmsContentTypes.VideoUpdate,
      'fields.countries': LegalEntityFeaturesCountryNameMapping[legalEntityCountryFeature as keyof typeof LegalEntityFeaturesCountryNameMapping],
      order: '-fields.videoDate',
      limit: 1
    };

    return this.contentfulGetterService.getEntries<ICmsVideoUpdateItem, ICmsVideoUpdateItem>(
      false,
      CmsContentTypes.VideoUpdate,
      VideoUpdateHelper.getVideoUpdateHandler,
      query
    );
  }

  getVideoUpdatePreviewById(id: string): Observable<ICmsVideoUpdateItem> {
    const query = {
      content_type: CmsContentTypes.VideoUpdate,
      'sys.id': id,
      limit: 1
    };

    return this.contentfulGetterService.getEntries<ICmsVideoUpdateItem, ICmsVideoUpdateItem>(
      false,
      CmsContentTypes.VideoUpdate,
      VideoUpdateHelper.getVideoUpdateHandler,
      query
    );
  }
}
