<image-preloader *ngIf="lightboxItem.Type === ATTACHMENT_TYPES.IMG; else lightboxVideo"
                 [cropped]="isCropped"
                 [imageSrc]="trustedResourceUrl"></image-preloader>
<ng-template #lightboxVideo>
  <div class="lightbox-video"
       [ngClass]="{'iframe-video': lightboxItem.Type !== ATTACHMENT_TYPES.OTHER}">
    <div #videoPlug
         class="video-plug"
         *ngIf="!showVideo">
      <img [src]="lightboxItem.ThumbnailUrl"
           (click)="show()" />
    </div>
    <iframe #iframeEl
            [src]="trustedResourceUrl"
            *ngIf="showVideo && (lightboxItem.Type === ATTACHMENT_TYPES.YT || ATTACHMENT_TYPES.VIMEO)"
            width="{{videoSize.width}}"
            height="{{videoSize.height}}"
            frameborder="0"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen></iframe>
    <video #videoEl
           [src]="trustedResourceUrl"
           *ngIf="showVideo && lightboxItem.Type === ATTACHMENT_TYPES.OTHER"
           width="{{videoSize.width}}"
           height="{{videoSize.height}}"
           autoplay
           controls
           controlsList="nodownload"></video>
  </div>
</ng-template>
