// MODULES
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { My7nCommonModule } from './my7n-common.module';
import { My7nMaterialModule } from './my7n-material.module';
import { ScrollingModule } from '@angular/cdk/scrolling';

// Components
import { PopoverNotificationsComponent } from '../components/common/popover-notifications/popover-notifications.component';
import { NotificationsComponent } from '../components/common/notifications/notifications.component';
import { NotificationsListElementComponent } from '../components/common/notifications/notifications-list-element/notifications-list-element.component';

// NGRX
import { StoreModule } from '@ngrx/store';
import * as fromNotifications from '../store/reducers/notifications/index';
import { EffectsModule } from '@ngrx/effects';
import { NotificationsEffects } from '../store/effects/notifications.effects';

@NgModule({
  imports: [
    CommonModule,
    My7nCommonModule,
    My7nMaterialModule,
    StoreModule.forFeature(fromNotifications.notificationsFeatureKey, fromNotifications.reducers),
    EffectsModule.forFeature([NotificationsEffects]),
    ScrollingModule,
    RouterModule
  ],
  exports: [
    // Components
    PopoverNotificationsComponent,
    NotificationsComponent,
    NotificationsListElementComponent,
  ],
  declarations: [
    // Components
    PopoverNotificationsComponent,
    NotificationsComponent,
    NotificationsListElementComponent,
  ]
})
export class My7nNotificationsModule {}
