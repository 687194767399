import { FrontendFeature, FrontendFeaturesModule } from '../feature';

@FrontendFeaturesModule('Common')
export class CommonFeatures {
  @FrontendFeature({
    summary: 'Show chatbot'
  })
  static readonly Chatbot = 'chatbot';

  @FrontendFeature({
    summary: 'Allow sending feedback'
  })
  static readonly SendFeedback = 'send-feedback';

  @FrontendFeature({
    summary: 'Show welcome page'
  })
  static readonly WelcomePage = 'welcome-page';
}
