
<ng-template #navTile>
    <div class="nav-tile flex flex-col flex-center-stretch sm:flex-start-stretch"
      [ngClass]="{active}">
      <div class="nav-tile-header sm:typography-small-header sm:font-bold">
        {{ navItem?.title }}
      </div>
      <div class="nav-tile-desc typography-paragraph text-12 max-sm:hidden">
        {{ navItem?.description }}
      </div>
    </div>
  </ng-template>

  <a [routerLink]="navItem?.link"
     *ngIf="!navItem?.external" 
     (click)="selected$.emit(navItem)"
     mat-button
     [attr.data-test-id]="navItemId">
     <ng-container *ngTemplateOutlet="navTile"></ng-container>
  </a>
  <a *ngIf="navItem?.external" 
     (click)="selected$.emit(navItem)"
     href="{{navItem?.link}}"
     target="_blank"
     mat-button
     [attr.data-test-id]="navItemId">
     <ng-container *ngTemplateOutlet="navTile"></ng-container>
  </a>

