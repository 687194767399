import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IConsultantSearchedSelectable } from '../../../../interfaces/consultant-searched';
import { getAgentModuleState } from '../index';
import * as fromAgentSearch from './agent-search.reducer';

export const agentSearchStateKey = 'search';

export const getAgentSearchState =
  createFeatureSelector<fromAgentSearch.AgentSearchState>(agentSearchStateKey);

export const selectAgentSearchState = createSelector(
  getAgentModuleState,
  getAgentSearchState
);

export const selectSearch = createSelector(
  selectAgentSearchState,
  fromAgentSearch.getSearch
);

export const selectPageIndex = createSelector(
  selectAgentSearchState,
  fromAgentSearch.getPageIndex
);

export const selectPageSize = createSelector(
  selectAgentSearchState,
  fromAgentSearch.getPageSize
);

export const selectTotal = createSelector(
  selectAgentSearchState,
  fromAgentSearch.getTotal
);

export const selectLoading = createSelector(
  selectAgentSearchState,
  fromAgentSearch.getLoading
);

export const selectError = createSelector(
  selectAgentSearchState,
  fromAgentSearch.getError
);

export const selectExtendedView = createSelector(
  selectAgentSearchState,
  fromAgentSearch.getExtendedView
);

export const selectRestore = createSelector(
  selectAgentSearchState,
  fromAgentSearch.getRestore
);

// selector used by entity adapter
export const selectConsultantsEntities = createSelector(
  selectAgentSearchState,
  fromAgentSearch.selectConsultantsEntities
);
// selector used by entity adapter
export const selectAllConsultants = createSelector(
  selectAgentSearchState,
  fromAgentSearch.selectAllConsultants
);
// selector used by entity adapter
export const selectTotalLoadedConsultantsCount = createSelector(
  selectAgentSearchState,
  fromAgentSearch.selectTotalLoadedConsultantsCount
);

export const selectSelectedConsultants = createSelector(
  selectAllConsultants,
  (consultants: IConsultantSearchedSelectable[]) =>
    consultants.filter((consultant) => consultant.selected)
);

export const selectFavouriteFiltersSelected = createSelector(
  selectAgentSearchState,
  fromAgentSearch.getFavouriteFiltersSelectedFilter
);

export const selectFavouriteFiltersSelectedDraft = createSelector(
  selectAgentSearchState,
  fromAgentSearch.getFavouriteFiltersSelectedFilterDraft
);

export const selectFavouriteFiltersInitialized = createSelector(
  selectAgentSearchState,
  fromAgentSearch.getFavouriteFiltersInitialized
);

export const selectFavouriteFilters = createSelector(
  selectAgentSearchState,
  fromAgentSearch.getFavouriteFilters
);

export const selectFavouriteFiltersStatus = createSelector(
  selectAgentSearchState,
  fromAgentSearch.getFavouriteFiltersStatus
);
