import { Injectable, OnDestroy } from '@angular/core';
import { IPrintPreferences } from '../interfaces/print-preferences';
import { ServiceNames } from '../interfaces/my7n-env-config';
import { SnackBarService, SnackBarTypes } from '@my7n/ui';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DownloadService } from './download.service';
import { takeUntil } from 'rxjs/operators';
import { GlobalAppConfigFacadeService } from './facades/global-app-config-facade.service';
import { AppConfigService } from './app-config.service';

@Injectable()
export class PrintService implements OnDestroy {
  readonly API_PRINT: string;
  readonly API_EVALUATION: string;

  /**
   * Ongoing downloads.
   */
  processedFiles: {[key: string]: boolean} = {};

  private unsubscribe$ = new Subject<void>();
  printSettings: IPrintPreferences;

  constructor(private appConfigService: AppConfigService,
              private http: HttpClient,
              private snackBarService: SnackBarService,
              private downloadService: DownloadService,
              private globalAppConfigFacadeService: GlobalAppConfigFacadeService) {
    this.API_PRINT = appConfigService.serviceUrl(ServiceNames.Core) + 'cvs/';
    this.API_EVALUATION = appConfigService.serviceUrl(ServiceNames.Evaluation, 'v1');

    this.globalAppConfigFacadeService.printPreferences$
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe(
        (settings: IPrintPreferences) => {
          this.printSettings = { ...settings };
        }
      );
  }

  private prepareQueryString(preferences: IPrintPreferences) {
    let preferencesKeys;

    if (typeof (preferences) !== 'object') {
      preferences = {};
    }

    preferencesKeys = Object.keys(preferences);

    if (preferencesKeys.length > 0) {
      preferencesKeys = preferencesKeys.map(function (key) {
        return key + '=' + preferences[key];
      });

      return '?' + preferencesKeys.join('&');
    }

    return '';
  }

  private prepareListQueryString(cvIdList: any) {
    if (!cvIdList || cvIdList.length === 0) {
      console.error('[PrintService] At least one CV Id must be provided to prepare print');
      return '';
    }
    const idList = cvIdList.map(function (item) {
      return 'cvId=' + item;
    });

    return this.prepareQueryString(this.printSettings) + '&' + idList.join('&');
  }

  printWordDoc(cvId: number, preferences?: IPrintPreferences) {
    if (!cvId) {
      console.error('[PrintService] CV Id must be provided to prepare print');
      return;
    }

    preferences = preferences || this.printSettings;

    this.printFile(this.API_PRINT + cvId + '/printout/word/' + this.prepareQueryString(preferences), 'docx', false, `CV_${cvId}`);
  }

  printPdf(cvId: number, preferences?: IPrintPreferences) {
    if (!cvId) {
      console.error('[PrintService] CV Id must be provided to prepare print');
      return;
    }

    preferences = preferences || this.printSettings;

    this.printFile(this.API_PRINT + cvId + '/printout/pdf/' + this.prepareQueryString(preferences), 'pdf', false, `CV_${cvId}`);
  }

  printWordDocs(cvIds: number[]) {
    this.printFile(this.API_PRINT + 'printout/words/' + this.prepareListQueryString(cvIds), 'docx', true, `CVs_${cvIds.join('-')}`);
  }

  printPdfs(cvIds: number[]) {
    this.printFile(this.API_PRINT + 'printout/pdfs/' + this.prepareListQueryString(cvIds), 'pdf', true, `CVs_${cvIds.join('-')}`);
  }

  downloadEvaluationPdf(evaluationId: string) {
    if (!evaluationId) {
      console.error('[PrintService] Evaluation Id must be provided to prepare print');
      return;
    }

    this.downloadService.multiStepBlobFileDownload(this.API_EVALUATION + `evaluations/${evaluationId}/printout/download`, null, `evaluation-report-${evaluationId}.pdf`);
  }

  printFile(fileUrl: string, fileExt: string, multipleFiles = false, fallbackFilename: string = 'CV') {
    if (this.processedFiles.hasOwnProperty(fileUrl)) {
       return;
    }

    this.snackBarService.openPending({
      message: 'Downloading in progress...',
      type: SnackBarTypes.NotificationAlt
    });

    this.processedFiles[fileUrl] = true;
    this.getBinaryFile(fileUrl)
      .subscribe((response: HttpResponse<Blob>) => {
        delete this.processedFiles[fileUrl];
        this.snackBarService.close();

        const blobURL = window.URL.createObjectURL(response.body);

        // file name is retrieved from response header
        const fileName = DownloadService.getFileNameFromContentDisposition(response) || `${ fallbackFilename }.${ multipleFiles ? 'zip' : fileExt}`;

        // create fake link to set file name of downloaded file and trigger downloading
        const downloadLink = DownloadService.createDownloadLink(blobURL, fileName, () => {
          // Timeout needs to be triggered because object gets revoked earlier than it's used in old Edge browser
          // so nothing happends without it
          setTimeout(() => {
            // clean
            window.URL.revokeObjectURL(blobURL);
            // Timeout is quite random, 0 produced non-deterministic behavior (sometimes worked and some other times... not)
          }, 5000);
        });
        // trigger download
        downloadLink.click();

      }, () => {
        delete this.processedFiles[fileUrl];

        this.snackBarService.open({
          message: `Error while downloading ${fileExt} file${multipleFiles ? 's' : ''}`,
          type: SnackBarTypes.ErrorAlt
        });
      });
  }

  getBinaryFile(url): Observable<any> {
    // why blob as json? Check this out https://github.com/angular/angular/issues/18586
    return this.http.get(url, {responseType: 'blob' as 'json', observe: 'response'});
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
  }
}
