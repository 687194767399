import { Action, createReducer, on } from '@ngrx/store';
import * as AgentSearchActions from '../../../actions/agent-search.actions';
import * as AgentBookmarksActions from '../../../actions/agent-bookmarks.actions';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { IConsultantSearchedSelectable } from '../../../../interfaces/consultant-searched';
import * as fromMy7n from '../../index';
import { PAGINATOR_SETTINGS_DEFAULT } from '../../../../interfaces/search';
import { Params } from '@angular/router';
import { IFavouriteFiltersSelected, IFavouriteFiltersStatus, ISearchFavouriteFilter } from '../../../../interfaces/serach-filters-data';

export const adapter = createEntityAdapter<IConsultantSearchedSelectable>({
  // we need to provide custom selectId function because standard one uses "id" instead of "Id" (like in our interface)
  selectId: (instance) => instance.Id
});

export interface FeatureState extends fromMy7n.State {
  search: AgentSearchState;
}

export interface AgentSearchState extends EntityState<IConsultantSearchedSelectable> {
  pageIndex: number;
  pageSize: number;
  loading: boolean;
  error: boolean;
  total: number;
  extendedView: boolean;
  restore: { shouldRestore: boolean, scrollY: number, queryParams: Params };
  favouriteFilters: {
    initialized: boolean,
    selectedFilter: IFavouriteFiltersSelected,
    filters: Array<ISearchFavouriteFilter>;
    status: IFavouriteFiltersStatus;
  };
}

export const initialState: AgentSearchState = adapter.getInitialState({
  pageIndex: 0,
  loading: false,
  error: false,
  pageSize: PAGINATOR_SETTINGS_DEFAULT.SEARCH.PAGE_SIZE,
  total: 0,
  extendedView: false,
  restore: {
    shouldRestore: false,
    scrollY: 0,
    queryParams: null
  },
  favouriteFilters: {
    initialized: false,
    selectedFilter: {
      selected: null,
      draft: null
    },
    filters: [],
    status: {
      isWorking: false,
      loading: true,
      loadingError: false
    }
  }
});


const reducer = createReducer(
  initialState,
  on(AgentSearchActions.queryResults, (state) => ({
    ...state,
    loading: true,
    error: false
  })),
  on(AgentSearchActions.queryResultsSuccess, (state, { consultants, total, queryPageOptions }) => ({
    ...adapter.setMany(consultants, state),
    total,
    loading: false,
    pageIndex: queryPageOptions.pageIndex,
    pageSize: queryPageOptions.pageSize
  })),
  on(AgentSearchActions.queryResultsError, (state) => ({
    ...state,
    loading: false,
    error: true
  })),
  on(AgentSearchActions.clearResults, (state) => ({
    ...adapter.removeAll(state),
    pageIndex: 0
  })),
  on(AgentSearchActions.updatePaginator, (state, { paginatorEvent }) => ({
    ...state,
    pageIndex: paginatorEvent.pageIndex,
    pageSize: paginatorEvent.pageSize
  })),
  on(AgentSearchActions.toggleSelectionConsultant, (state, { consultantId, selected }) => ({
    ...adapter.updateOne({ id: consultantId, changes: { selected } }, state)
  })),
  on(AgentSearchActions.clearSelectedConsultats, (state) => ({
    ...adapter.map((entity) =>  {
      return {...entity, selected: false };
    }, state)
  })),
  on(AgentSearchActions.updateExtendedViewState, (state, { checked }) => ({
    ...state,
    extendedView: checked
  })),
  on(AgentSearchActions.updateLoadingState, (state, { loading }) => ({
    ...state,
    loading
  })),
  on(AgentSearchActions.updatePortraitUrl, (state, { consultantId, portraitUrl }) => ({
    ...adapter.updateOne({ id: consultantId, changes: { Portrait: portraitUrl } }, state)
  })),
  on(AgentSearchActions.setRestore, (state, { shouldRestore, scrollY }) => ({
    ...state,
    restore: {
      ...state.restore,
      shouldRestore,
      scrollY
    }
  })),
  on(AgentSearchActions.setRestoreQueryParams, (state, { queryParams }) => ({
    ...state,
    restore: {
      ...state.restore,
      queryParams
    }
  })),
  on(AgentSearchActions.queryFavouriteFilters, (state) => ({
    ...state,
    favouriteFilters: {
      ...state.favouriteFilters,
      initialized: true,
      status: {
        loadingError: false,
        isWorking: false,
        loading: true
      }
    }
  })),
  on(AgentSearchActions.queryFavouriteFiltersSuccess, (state, { filters }) => ({
    ...state,
    favouriteFilters: {
      ...state.favouriteFilters,
      filters,
      status: {
        loadingError: false,
        isWorking: false,
        loading: false
      }
    }
  })),
  on(AgentSearchActions.queryFavouriteFiltersError, (state) => ({
    ...state,
    favouriteFilters: {
      ...state.favouriteFilters,
      status: {
        loadingError: true,
        isWorking: false,
        loading: false
      }
    }
  })),
  on(AgentSearchActions.saveFavouriteFilters, (state) => ({
    ...state,
    favouriteFilters: {
      ...state.favouriteFilters,
      status: {
        isWorking: true,
        loadingError: false,
        loading: false
      }
    }
  })),
  on(AgentSearchActions.saveFavouriteFiltersSuccess, (state, { updatedFilters }) => ({
    ...state,
    favouriteFilters: {
      ...state.favouriteFilters,
      filters: updatedFilters,
      status: {
        isWorking: false,
        loadingError: false,
        loading: false
      }
    }
  })),
  on(AgentSearchActions.saveFavouriteFiltersError, (state) => ({
    ...state,
    favouriteFilters: {
      ...state.favouriteFilters,
      status: {
        isWorking: false,
        loadingError: false,
        loading: false
      }
    }
  })),
  on(AgentSearchActions.selectFavouriteFilter, (state, { selected }) => ({
    ...state,
    favouriteFilters: {
      ...state.favouriteFilters,
      selectedFilter: {
        ...state.favouriteFilters.selectedFilter,
        selected
      }
    }
  })),
  on(AgentSearchActions.selectFavouriteFilterDraft, (state, { draft }) => ({
    ...state,
    favouriteFilters: {
      ...state.favouriteFilters,
      selectedFilter: {
        ...state.favouriteFilters.selectedFilter,
        draft
      }
    }
  })),
  on(AgentSearchActions.setDraftAsSelectedFavouriteFilter, (state) => ({
    ...state,
    favouriteFilters: {
      ...state.favouriteFilters,
      selectedFilter: {
        ...state.favouriteFilters.selectedFilter,
        selected: state.favouriteFilters.selectedFilter.draft
      }
    }
  })),
  on(AgentSearchActions.setSelectedAsDraftFavouriteFilter, (state) => ({
    ...state,
    favouriteFilters: {
      ...state.favouriteFilters,
      selectedFilter: {
        ...state.favouriteFilters.selectedFilter,
        draft: state.favouriteFilters.selectedFilter.selected
      }
    }
  })),
  // global action
  on(AgentSearchActions.bookmarkSelectedConsultantsSuccess, (state, { consultants }) => ({
    ...adapter.updateMany( consultants.map( consultant => ({ id: consultant.Id, changes: { IsBookmarked: true}})) , state)
  })),
  // single action
  on(AgentSearchActions.bookmarkConsultant, (state, { consultant }) => ({
    ...adapter.updateOne({ id: consultant.Id, changes: { IsBookmarked: true } }, state)
  })),
  on(AgentSearchActions.bookmarkConsultantError, (state, { consultant }) => ({
    ...adapter.updateOne({ id: consultant.Id, changes: { IsBookmarked: false } }, state)
  })),
  on(AgentSearchActions.unbookmarkConsultant, (state, { consultantId }) => ({
    ...adapter.updateOne({ id: consultantId, changes: { IsBookmarked: false } }, state)
  })),
  on(AgentSearchActions.unbookmarkConsultantError, (state, { consultantId }) => ({
    ...adapter.updateOne({ id: consultantId, changes: { IsBookmarked: true } }, state)
  })),
  // updating on bookmark view should also update search results
  on(AgentBookmarksActions.bookmarkConsultantSuccess, (state, { consultant }) => ({
    ...adapter.updateOne({ id: consultant.Id, changes: { IsBookmarked: true } }, state)

  })),
  on(AgentBookmarksActions.unbookmarkConsultantSuccess, (state, { consultantId }) => ({
    ...adapter.updateOne({ id: consultantId, changes: { IsBookmarked: false } }, state)
  }))
);

export function agentSearchReducer(state: AgentSearchState | undefined, action: Action) {
  return reducer(state, action);
}

// configure entity store selectors
export const {
  // select the array of contracts
  selectIds: selectConsultantsId,

  // select the dictionary of contract entities
  selectEntities: selectConsultantsEntities,

  // selectAll
  selectAll: selectAllConsultants,

  // select total consultants count
  selectTotal: selectTotalLoadedConsultantsCount
} = adapter.getSelectors();

export const getSearch = (state: AgentSearchState) => {
  return state;
};

export const getPageIndex = (state: AgentSearchState) => {
  return state.pageIndex;
};

export const getPageSize = (state: AgentSearchState) => {
  return state.pageSize;
};

export const getTotal = (state: AgentSearchState) => {
  return state.total;
};

export const getLoading = (state: AgentSearchState) => {
  return state.loading;
};

export const getError = (state: AgentSearchState) => {
  return state.error;
};

export const getExtendedView = (state: AgentSearchState) => {
  return state.extendedView;
};

export const getRestore = (state: AgentSearchState) => {
  return state.restore;
};

export const getFavouriteFiltersSelectedFilter = (state: AgentSearchState) => {
  return state.favouriteFilters.selectedFilter.selected;
};

export const getFavouriteFiltersSelectedFilterDraft = (state: AgentSearchState) => {
  return state.favouriteFilters.selectedFilter.draft;
};

export const getFavouriteFiltersInitialized = (state: AgentSearchState) => {
  return state.favouriteFilters.initialized;
};

export const getFavouriteFilters = (state: AgentSearchState) => {
  return state.favouriteFilters.filters;
};

export const getFavouriteFiltersStatus = (state: AgentSearchState) => {
  return state.favouriteFilters.status;
};
