import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UserProfileFeatures } from '@my7n/features';
import { RxState } from '@rx-angular/state';
import { AuthorizationService } from '../../services/authorization.service';

export interface IUserProfileLocalState {
  hasAccessToPersonalDetails: boolean;
  hasAccessToBusinessDetails: boolean;
  hasAccessToPreferredWorkLocations: boolean;
  hasAccessToTermsAndConditions: boolean;
  hasAccessToNotificationSettings: boolean;
  canEdit: boolean;
}

export const initialState: IUserProfileLocalState = {
  hasAccessToPersonalDetails: false,
  hasAccessToBusinessDetails: false,
  hasAccessToPreferredWorkLocations: false,
  hasAccessToTermsAndConditions: false,
  hasAccessToNotificationSettings: false,
  canEdit: false
};
@Component({
  selector: 'user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState]
})
export class UserProfileComponent implements OnInit {
  // we can split our local state into pieces to improve performance
  readonly state$ = this.state.select();

  constructor(
    private authorizationService: AuthorizationService,
    private state: RxState<IUserProfileLocalState>
  ) {
    this.state.set({ ...initialState });
  }

  ngOnInit() {
    this.initLocalStateConnections();
  }

  initLocalStateConnections() {
    // user profile ngrx store connections
    // access management
    this.state.connect(
      'hasAccessToPersonalDetails',
      this.authorizationService.can(UserProfileFeatures.PersonalDetails)
    );
    this.state.connect(
      'hasAccessToBusinessDetails',
      this.authorizationService.can(UserProfileFeatures.BusinessDetails)
    );
    this.state.connect(
      'hasAccessToPreferredWorkLocations',
      this.authorizationService.can(UserProfileFeatures.WorkLocations)
    );
    this.state.connect(
      'hasAccessToTermsAndConditions',
      this.authorizationService.can(UserProfileFeatures.Terms)
    );
    this.state.connect(
      'hasAccessToNotificationSettings',
      this.authorizationService.can(UserProfileFeatures.Notifications)
    );
    this.state.connect(
      'canEdit',
      this.authorizationService.can(UserProfileFeatures.Edit)
    );
  }
}
