import { IVideoProviders, VideoProviders } from '../../interfaces/video-player';

const PROVIDERS: IVideoProviders = {
    [VideoProviders.Youtube]: {
        // based on https://gist.github.com/rodrigoborgesdeoliveira/987683cfbfcc8d800192da1e73adc486
        domains: ['youtube', 'youtu.be', 'youtube-nocookie'],
        // order matters!
        idSeparators: ['?v=', 'v/', 'e/', 'embed/', 'be/'],
        getEmbeddedUrl: (id: string) => `https://www.youtube.com/embed/${id}?rel=0`
    },
    [VideoProviders.Vimeo]: {
        domains: ['vimeo', 'player.vimeo'],
        // order matters!
        idSeparators: ['video/', 'com/'],
        getEmbeddedUrl: (id: string) => `https://player.vimeo.com/video/${id}`
    }
};

export class EmbedVideo {
    static getEmbededUrl(url: string): string {
        let videoId: string;
        let provider: VideoProviders;

        if (!url) {
            console.debug('[EmbedVideo] Url is empty');
            return '';
        }

        provider = this.extractVideoProvider(url);

        if (!provider) {
            return '';
        }

        videoId = this.extractVideoId(url, provider);

        if (!videoId) {
            console.debug(`[EmbedVideo] Cannot retrieve video id, check if ${provider} use this format: ${url}`);
            return '';
        }

        let embededUrl: string = PROVIDERS[provider].getEmbeddedUrl(videoId);

        embededUrl.replace('autoplay=1', 'autoplay=0'); // don't autoplay as we use API to play the video

        return embededUrl;
    }

    static extractVideoId(url: string, provider: VideoProviders): string {
        const idBeginSeparators = PROVIDERS[provider].idSeparators;
        const idEndSeparators = ['?', '&'];
        let idBeginsIndex: number;
        let idEndsIndex: number;
        let idString: string;

        for (let i = 0; i < idBeginSeparators.length; i++) {
            if (url.indexOf(idBeginSeparators[i]) !== -1) {
                idBeginsIndex = url.indexOf(idBeginSeparators[i]) + idBeginSeparators[i].length;
                break;
            }
        }

        if (idBeginsIndex === undefined) {
            console.debug(`[EmbedVideoService] Couldn\'t find separator for ${provider}`);
            return null;
        }

        idString = url.slice(idBeginsIndex);

        for (let i = 0; i < idEndSeparators.length; i++) {
            if (idString.indexOf(idEndSeparators[i]) !== -1) {
                idEndsIndex = idString.indexOf(idEndSeparators[i]);
                break;
            }
        }

        return idEndsIndex ? idString.slice(0, idEndsIndex) : idString;
    }

    static extractVideoProvider(url: string): VideoProviders {
        let provider: VideoProviders;
        const providersList = Object.keys(PROVIDERS);

        if (!url) {
            console.debug('[EmbedVideo] Cannot extract provider of empty url');
            return null;
        }

        for (let i = 0; i < providersList.length; i++) {
            if (PROVIDERS[providersList[i]].domains.some(domain => url.includes(domain))) {
                provider = providersList[i] as VideoProviders;
                break;
            }
        }

        if (!provider) {
            console.debug('[EmbedVideo] Unknown video provider');
            return null;
        }

        return provider;
    }
}
