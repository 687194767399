import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CmsContentTypes } from '../interfaces/cms';
import { ContentfulGetterService } from './contentful-getter.service';
import {
  ICmsNewsList,
  ICmsNewsView,
  ICmsNewsViewItem
} from '../interfaces/news';
import { ITimelineCmsNewsResponse } from '../interfaces/news-response';
import { NewsHelper } from '../utils/helpers/news.helper';
import { Options } from '@contentful/rich-text-html-renderer';
import { LegalEntityFeaturesCountryNameMapping } from '../interfaces/legal-entity';
import { LegalEntityFeatures } from '@my7n/features';

@Injectable()
export class NewsContentfulService {
  constructor(private contentfulGetterService: ContentfulGetterService) {}

  renderOptionsNewLine: Options = {
    renderNode: {
      ['paragraph']: (node, next) =>
        `<p>${next(node.content).replace(/\n/g, `</br>`)}</p>`
    }
  };

  getNewsByLegalEntityCountryFeature(
    legalEntityCountryFeature: LegalEntityFeatures,
    offset: number,
    pageSize: number,
    previewMode: boolean
  ): Observable<ITimelineCmsNewsResponse> {
    const query = {
      content_type: CmsContentTypes.News,
      'fields.countries': LegalEntityFeaturesCountryNameMapping[legalEntityCountryFeature as keyof typeof LegalEntityFeaturesCountryNameMapping],
      order: '-fields.newsDate',
      skip: offset,
      limit: pageSize
    };

    return this.contentfulGetterService.getEntries<ICmsNewsList, ITimelineCmsNewsResponse>(
      previewMode,
      CmsContentTypes.News,
      NewsHelper.getNewsListHandler,
      query
    );
  }

  getNewsById$(id: string, previewMode = false): Observable<ICmsNewsViewItem> {
    const query = {
      content_type: CmsContentTypes.News,
      'sys.id': id,
      limit: 1
    };

    return this.contentfulGetterService.getEntries<ICmsNewsView, ICmsNewsViewItem>(
      previewMode,
      CmsContentTypes.News,
      NewsHelper.getNewsViewHandler,
      query,
      this.renderOptionsNewLine
    );
  }
}
