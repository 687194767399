import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { INewsAttachment, NewsAttachmentTypes } from '../../../../../interfaces/news-attachment';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'gallery-lightbox-secure-item',
  templateUrl: './gallery-lightbox-secure-item.component.html',
  styleUrls: ['./gallery-lightbox-secure-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GalleryLightboxSecureItemComponent implements OnInit {
  @Input() lightboxItem: INewsAttachment;

  trustedResourceUrl: string;

  get ATTACHMENT_TYPES() {
    return NewsAttachmentTypes;
  }

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    switch (this.lightboxItem.Type) {
      case this.ATTACHMENT_TYPES.IMG_SECURED:
        this.trustedResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.lightboxItem.Url) as string;
        break;
      default:
        console.warn('Image should be type: IMG_SECURED');
        this.trustedResourceUrl = this.lightboxItem.Url;
    }
  }

}
