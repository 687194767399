import * as fromMy7n from '../index';
import { createReducer, on, Action } from '@ngrx/store';
import * as VideoUpdateActions from '../../actions/cms/video-update.actions';
import { ICmsVideoUpdateItem } from '../../../interfaces/cms';

export interface FeatureState extends fromMy7n.State {
  cmsVideoUpdate: State;
}

export interface State {
  latestVideoUpdate: ICmsVideoUpdateItem;
  latestVideoUpdateLoading: boolean;
  latestVideoUpdateRequestTimestamp: number;
}

export const initialState: State = {
  latestVideoUpdate: null,
  latestVideoUpdateLoading: false,
  latestVideoUpdateRequestTimestamp: null
};

const reducer = createReducer(
  initialState,
  on(VideoUpdateActions.queryLatestVideoUpdateByUserLegalEntityCountryFeature, (state) => ({
    ...state,
    latestVideoUpdateLoading: true
  })),
  on(VideoUpdateActions.queryVideoUpdatePreviewById, (state) => ({
    ...state,
    latestVideoUpdateLoading: true
  })),
  on(
    VideoUpdateActions.latestVideoUpdateLoaded,
    (state, { latestVideoUpdate }) => ({
      ...state,
      latestVideoUpdateLoading: false,
      latestVideoUpdate,
      latestVideoUpdateRequestTimestamp: Date.now()
    })
  ),
  on(VideoUpdateActions.latestVideoUpdateLoadedFromCache, (state) => ({
    ...state,
    latestVideoUpdateLoading: false
  })),
  on(VideoUpdateActions.queryLatestVideoUpdateByUserLegalEntityCountryFeatureError, (state) => ({
    ...state,
    latestVideoUpdateLoading: false
  })),
  on(VideoUpdateActions.querVideoUpdatePreviewByIdError, (state) => ({
    ...state,
    latestVideoUpdateLoading: false
  }))
);

export function videoUpdateReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}

export const getLatestVideoUpdate = (state: State) => {
  return state.latestVideoUpdate;
};

export const getLatestVideoUpdateRequestTimestamp = (state: State) => {
  return state.latestVideoUpdateRequestTimestamp;
};

export const getLatestVideoUpdateLoading = (state: State) => {
  return state.latestVideoUpdateLoading;
};
