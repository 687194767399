import { Injectable, OnDestroy } from '@angular/core';
// services
import { StorageCacheService } from './storage-cache.service';
import { ServiceNames } from '../interfaces/my7n-env-config';
import { IPrivateAddressCountryResponse } from '../interfaces/dictionaries';
import { GlobalAppConfigFacadeService } from './facades/global-app-config-facade.service';
import { Subscription } from 'rxjs';
import { TimeKeysHashMap } from '../interfaces/app-config';
import { AppConfigService } from './app-config.service';

@Injectable()
export class DictionariesService implements OnDestroy{
  /**
   * Dictionaries API prefix.
   * @type {string}
   */
  readonly DICT_API_PREFIX: string;
  
  /**
   * Timekeys object.
   * @type {TimeKeysHashMap}
   */
  private timekeys: TimeKeysHashMap;

  /**
   * Subscription of timekeys.
   * @type {Subscription}
   */
  private timekeysSubscription: Subscription;

  constructor(private storageCacheService: StorageCacheService,
              private globalAppConfigFacadeService: GlobalAppConfigFacadeService,
              private appConfigService: AppConfigService) {
    this.DICT_API_PREFIX = this.appConfigService.serviceUrl(ServiceNames.Cv, 'v1') + 'cv/dictionaries/';
    
    this.timekeysSubscription = this.globalAppConfigFacadeService.timeKeys$.subscribe((timekeys) => {
      this.timekeys = timekeys;
    });
  }

  getAllMainCompetenceAreas() {
    return this.storageCacheService.get<any>(this.DICT_API_PREFIX + 'competenceareas', this.timekeys.CompetenceAreas).then((result) => {
      return result.CompetenceAreas;
    }).catch((error) => {
      console.error('[DictionariesService] Failed to get Competence Areas', error);
      return Promise.reject(error);
    });
  }

  getAllWorkRoles() {
    return this.storageCacheService.get<any>(this.DICT_API_PREFIX + 'workroles', this.timekeys.WorkRoles).then((result) => {
      return result.WorkRoles;
    }).catch((error) => {
      console.error('[DictionariesService] Failed to get WorkRoles', error);
      return Promise.reject(error);
    });
  }

  getAllIndustryFocuses() {
    return this.storageCacheService.get<any>(this.DICT_API_PREFIX + 'industryknowledge', this.timekeys.IndustryKnowledges).then((result) => {
      return result.IndustryFocus;
    }).catch((error) => {
      console.error('[DictionariesService] Failed to get Industry Focuses', error);
      return Promise.reject(error);
    });
  }

  getAllPrivateAddressCountries(): Promise<IPrivateAddressCountryResponse> {
    return this.storageCacheService.get<IPrivateAddressCountryResponse>(this.DICT_API_PREFIX + 'countries', this.timekeys.Countries).then((result) => {
      return result;
    }).catch((error) => {
      console.error('[DictionariesService] Failed to get private countries addresses', error);
      return Promise.reject(error);
    });
  }

  ngOnDestroy(): void {
      this.timekeysSubscription.unsubscribe();
  }
}
