import { Injectable } from '@angular/core';
import { ICommonEditSave } from '../interfaces/common-edit-save';

// services
import { AgentService } from './agent.service';
import { UserProfileService } from './user-profile.service';

@Injectable()
export class LocationEditService {

  servicesMethods: object = {
    // consultant view
    updateLocations: (locations: number[], consultantId: string) => {
      return this.agentService.updateLocations(locations, consultantId);
    },

    // profile view
    updateLocationsProfile: (locations: number[]) => {
      return this.userProfileService.updateLocations(locations);
    },
  };

  constructor(public agentService: AgentService, private userProfileService: UserProfileService) { }

  locationsEditSave(fieldValue: any, methodName: string, consultantId: string) {
    return this.servicesMethods[methodName](fieldValue, consultantId);
  }
}
