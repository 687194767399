export interface IQuerySearchParams {
    keywords: string;
    orderBy: string;
    availability?: string;
    availabilityRangeFrom: string;
    availabilityRangeTo: string;
    checkedRelationshipTypesIds: Array<string>;
    checkedConsultantStatusIds: Array<string>;
    checkedConsultantActiveStatusIds: Array<string>;
    checkedCompetenceAreasIds: Array<string>;
    checkedIndustryFocusIds: Array<string>;
    checkedWorkRolesIds: Array<string>;
    checkedLocationsIds: Array<string>;
    checkedPrivateAddressCountryIds: Array<string>;
    owners: Array<string>;
    skills: Array<string>;
    languages: Array<string>;
    skillsRestricted: boolean;
    languagesRestricted: boolean;
    checkedCvLanguagesIds: Array<string>;
}

interface IPaginatorSettings {
    PAGE_SIZE_OPTIONS: Array<number>;
    PAGE_SIZE: number;
}
export interface IQueryPageOptions {
    pageIndex: number;
    pageSize: number;
}

export const PAGINATOR_SETTINGS_DEFAULT: { SEARCH: IPaginatorSettings, BOOKMARKS: IPaginatorSettings } = {
    SEARCH: {
        PAGE_SIZE: 30,
        PAGE_SIZE_OPTIONS: [30]
    },
    BOOKMARKS: {
        PAGE_SIZE: 10,
        PAGE_SIZE_OPTIONS: [10]
    }
};
