import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { map, catchError, mergeMap, switchMap } from 'rxjs/operators';
import * as NotificationsActions from '../../store/actions/notifications.actions';
import * as fromNotifications from '../reducers/notifications/index';
import { NotificationsService } from '../../services/notifications.service';
import { INotificationsResponse } from '../../interfaces/notifications-response';
import { SnackBarService, SnackBarTypes } from '@my7n/ui';

@Injectable()
export class NotificationsEffects {
  constructor(
    private actions$: Actions,
    private notificationsService: NotificationsService,
    private store$: Store<fromNotifications.FeatureState>,
    private snackBarService: SnackBarService
  ) {}

  queryNotifications$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(NotificationsActions.queryNotifications),
      mergeMap(() => {
        return this.notificationsService.getNotifications().pipe(
          map((res: INotificationsResponse) => {
            return NotificationsActions.notificationsLoaded({
              notifications: res.Notifications,
              totalUnseenNotifications: res.TotalUnseenNotifications,
              notificationsRequestTimestamp: res.RequestTimestamp
            });
          }),
          catchError(err => {
            this.snackBarService.open({
              message: 'Error while retrieving notifications',
              type: SnackBarTypes.ErrorAlt
            });
            return of(NotificationsActions.queryNotificationsError());
          })
        );
      })
    );
  });

  updateNotificationsTimestamp$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(NotificationsActions.updateNotificationsTimestamp),
      switchMap((props) => {
        return this.notificationsService.updateNotificationsTimestamp(props.timestamp).pipe(
          map(res => {
            return NotificationsActions.updateNotificationsTimestampSuccess();
          }),
          catchError(err => {
            return of(NotificationsActions.updateNotificationsTimestampError());
          })
        );
      })
    );
  });

  notificationItemRead$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(NotificationsActions.notificationItemRead),
      switchMap((props) => {
        return this.notificationsService.notificationItemRead(props.notificationItemId).pipe(
          map(res => {
            return NotificationsActions.notificationItemReadSuccess();
          }),
          catchError(err => {
            return of(NotificationsActions.notificationItemReadError());
          })
        );
      })
    );
  });

  markAllNotificationsAsRead$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(NotificationsActions.markAllNotificationsAsRead),
      switchMap(() => {
        return this.notificationsService.markAllNotificationsAsRead().pipe(
          map(res => {
            return NotificationsActions.markAllNotificationsAsReadSuccess();
          }),
          catchError(err => {
            this.snackBarService.open({
              message: 'Error while marking notifications as read',
              type: SnackBarTypes.ErrorAlt
            });
            return of(NotificationsActions.markAllNotificationsAsReadError());
          })
        );
      })
    );
  });
}
