import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, ViewChild
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { SafeUrl } from '@angular/platform-browser';

// Interfaces
import { AvatarComponent, IAvatarImpl } from '@my7n/ui';

// Services
import { SecureImageService } from '../../../services/secure-image.service';

@Component({
  selector: 'secure-avatar',
  templateUrl: './secure-avatar.component.html',
  styleUrls: ['./secure-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecureAvatarComponent implements IAvatarImpl, OnDestroy {
  @ViewChild(AvatarComponent, { static: true }) avatarInstance: AvatarComponent;

  @Input()
  set avatarUrl(url: string) {
    this.src$.next(url);
  }
  @Input() title: string;
  @Input() plugEnabled = true;

  @Output() loaded = new EventEmitter<boolean>();

  imgSrc: string | SafeUrl = null;

  private unsubscribe$: Subject<void> = new Subject<void>();
  private src$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  /**
   * This component requires CORS to be enabled for getting images using XHR
   */
  constructor(private httpClient: HttpClient,
              private cdr: ChangeDetectorRef,
              private secureImageService: SecureImageService) {
    this.src$.pipe(
      takeUntil(this.unsubscribe$),
      filter(url => !!url),
      switchMap((url: string) => this.secureImageService.get(url))
    ).subscribe((objectUrl: string) => {
      this.imgSrc = objectUrl;
      this.cdr.markForCheck();
    });
  }

  loadedPassThrough($event) {
    this.loaded.emit($event);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
  }
}
