import { FrontendFeature, FrontendFeaturesModule } from '../feature';

@FrontendFeaturesModule('Consultants') // another used name: CV Search
export class ConsultantsFeatures {

  // consultants list panel ------------------------------------------------->

  @FrontendFeature({
    summary: 'Main Consultants page'
  })
  static readonly Default = 'consultants';

  @FrontendFeature({
    summary: 'Consultants print settings'
  })
  static readonly PrintSettings = 'consultants/print-settings';

  @FrontendFeature({
    summary: 'Consultants extended view '
  })
  static readonly ExtendedView = 'consultants/extended-view';

  @FrontendFeature({
    summary: 'Bookmarked consultants'
  })
  static readonly Bookmarks = 'consultants/bookmarks';

  // consultants list panel actions ----------------------------------------->

  @FrontendFeature({
    summary: 'Send welcome mail'
  })
  static readonly ItemSendWelcomeMail = 'consultants/item/send-welcome-mail';

  @FrontendFeature({
    summary: 'Send follow up mail'
  })
  static readonly ItemSendFollowUpMail = 'consultants/item/send-follow-up-mail';

  @FrontendFeature({
    summary: 'Create CV on consultant\'s behalf'
  })
  static readonly ItemCreateCv = 'consultants/item/create-cv';

  @FrontendFeature({
    summary: 'Go to consultant\'s CRM'
  })
  static readonly ItemGoToCrm = 'consultants/item/go-to-crm';

  @FrontendFeature({
    summary: 'Block/unblock user access'
  })
  static readonly ItemEditAccess = 'consultants/item/edit-access';

  // consultants item --------------------------------------------------------->

  @FrontendFeature({
    summary: 'Show consultant\'s profile'
  })
  static readonly ItemView = 'consultants/item/view';

  @FrontendFeature({
    summary: 'Edit consultant\'s profile'
  })
  static readonly ItemEdit = 'consultants/item/edit';

  @FrontendFeature({
    summary: 'Create consultant\'s account'
  })
  static readonly ItemAccountCreate = 'consultants/item/account/create';

  @FrontendFeature({
    summary: 'View consultant\'s cv section'
  })
  static readonly ItemCv = 'consultants/item/cv';

  @FrontendFeature({
    summary: 'View consultant\'s CRM files section'
  })
  static readonly ItemCrmFile = 'consultants/item/crm-file';

  @FrontendFeature({
    summary: 'View consultant\'s CRM notes section'
  })
  static readonly ItemCrmNotes = 'consultants/item/crm-notes';

  // cv item actions ---------------------------------------------------------->

  @FrontendFeature({
    summary: 'Print consultant\'s CV'
  })
  static readonly ItemCvPrint = 'consultants/item/cv/print';

  @FrontendFeature({
    summary: 'Download consultant\'s CV'
  })
  static readonly ItemCvDownload = 'consultants/item/cv/download';

  @FrontendFeature({
    summary: 'Send consultant\'s CV to own inbox'
  })
  static readonly ItemCvSendToMyEmail = 'consultants/item/cv/send-to-my-email';

  @FrontendFeature({
    summary: 'Duplicate consultant\'s CV'
  })
  static readonly ItemCvDuplicate = 'consultants/item/cv/duplicate';

  @FrontendFeature({
    summary: 'Remove consultant\'s CV'
  })
  static readonly ItemCvDelete = 'consultants/item/cv/delete';

  @FrontendFeature({
    summary: 'Set as consultant\'s primary CV'
  })
  static readonly ItemCvSetAsPrimary = 'consultants/item/cv/set-as-primary';

  // crm file item actions ---------------------------------------------------------->

  @FrontendFeature({
    summary: 'Download CRM file'
  })
  static readonly ItemCrmFileDownload = 'consultants/item/crm-file/download';

  @FrontendFeature({
    summary: 'Send CRM file to own inbox'
  })
  static readonly ItemCrmFileSendToMyEmail = 'consultants/item/crm-file/send-to-my-email';

  // cv view ------------------------------------------------------------------------>

  @FrontendFeature({
    summary: 'Show consultant\'s CV (view)'
  })
  static readonly ItemCvView = 'consultants/item/cv/view';

}

