import { FrontendFeature, FrontendFeaturesModule } from '../feature';

@FrontendFeaturesModule('Cv')
export class CvFeatures {
  @FrontendFeature({
    summary: 'Main CV page (list)'
  })
  static readonly Default = 'cv';

  @FrontendFeature({
    summary: 'Create new CV'
  })
  static readonly Create = 'cv/create';

  @FrontendFeature({
    summary: 'Parse cv'
  })
  static readonly Parse = 'cv/parse';

  @FrontendFeature({
    summary: 'CV view'
  })
  static readonly ItemView = 'cv/item/view';

  @FrontendFeature({
    summary: 'CV edit'
  })
  static readonly ItemEdit = 'cv/item/edit';

  @FrontendFeature({
    summary: 'Rename CV'
  })
  static readonly ItemRename = 'cv/item/rename';

  @FrontendFeature({
    summary: 'Duplicate CV'
  })
  static readonly ItemDuplicate = 'cv/item/duplicate';

  @FrontendFeature({
    summary: 'Remove CV'
  })
  static readonly ItemDelete = 'cv/item/delete';

  @FrontendFeature({
    summary: 'Show CV preview'
  })
  static readonly ItemShowPreview = 'cv/item/show-preview';

  @FrontendFeature({
    summary: 'CV Send to agent'
  })
  static readonly ItemSendToAgent = 'cv/item/send-to-agent';

  @FrontendFeature({
    summary: 'CV wizard'
  })
  static readonly Wizard = 'cv/wizard';

  @FrontendFeature({
    summary: 'CV go to CRM option'
  })
  static readonly GoToCrm = 'cv/item/go-to-crm';

  @FrontendFeature({
    summary: 'CV show activity log option'
  })
  static readonly ActivityLog = 'cv/item/activity-log';

  @FrontendFeature({
    summary: 'CV print settings section'
  })
  static readonly PrintSettings = 'cv/item/print-settings';

  @FrontendFeature({
    summary: 'CV print this cv option'
  })
  static readonly PrintCv = 'cv/item/print-cv';

  @FrontendFeature({
    summary: 'CV send to my email option'
  })
  static readonly SendToEmail = 'cv/item/send-to-email';

  @FrontendFeature({
    summary: 'CV download'
  })
  static readonly Download = 'cv/item/download';

  @FrontendFeature({
    summary: 'CV reorder items'
  })
  static readonly Reorder = 'cv/item/reorder';
}

