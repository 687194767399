import { createSelector } from '@ngrx/store';
import { getGlobalAppConfigState, getGlobalModuleState } from './index';
import * as fromAppConfig from './global-app-config.reducer';

export const selectGlobalAppConfigState = createSelector(
  getGlobalModuleState,
  getGlobalAppConfigState
);

export const selectAppConfig = createSelector(
  selectGlobalAppConfigState,
  fromAppConfig.getAppConfigConfigState
);

export const selectAppConfigUser = createSelector(
  selectGlobalAppConfigState,
  fromAppConfig.getAppConfigUserState
);

export const selectAppConfigPrintPreferences = createSelector(
  selectGlobalAppConfigState,
  fromAppConfig.getPrintPreferencesState
);

export const selectAppConfigLanguages = createSelector(
  selectGlobalAppConfigState,
  fromAppConfig.getAppConfigLanguagesState
);

export const selectAppConfigTimekeys = createSelector(
  selectGlobalAppConfigState,
  fromAppConfig.getAppConfigTimeKeysState
);

export const selectAppConfigDirtyState = createSelector(
  selectGlobalAppConfigState,
  fromAppConfig.getConfigDirtyState
);

export const selectAppConfigLoadingState = createSelector(
  selectGlobalAppConfigState,
  fromAppConfig.getConfigLoadingState
);

export const selectAppConfigErrorState = createSelector(
  selectGlobalAppConfigState,
  fromAppConfig.getConfigErrorState
);

export const selectAppConfigInitializedState = createSelector(
  selectGlobalAppConfigState,
  fromAppConfig.getConfigInitializedState
);

export const selectAppConfigApiUrlsState = createSelector(
  selectGlobalAppConfigState,
  fromAppConfig.getConfigApiUrlsState
);
