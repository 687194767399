import { Injectable } from '@angular/core';
import { AppConfigService } from '../services/app-config.service';

@Injectable()
export class ImagePreloaderService {
  private readonly imagesPath: string;

  constructor() {
    this.imagesPath = AppConfigService.imagesPath;
  }

  preloadImage(path: string): Promise<HTMLImageElement> {
    const imageSrc = this.getImgSrc(path);

    return new Promise(function (resolve, reject) {
      // Create a new image from JavaScript
      const image = new Image();
      // Bind an event listener on the load to call the `resolve` function
      image.onload = () => resolve(image);
      // If the image fails to be downloaded, we don't want the whole system
      // to collapse so we `resolve` instead of `reject`, even on error
      image.onerror = () => resolve(image);
      // Apply the path as `src` to the image so that the browser fetches it
      image.src = imageSrc;
    });
  }

  private getImgSrc(path: string): string {
    return path?.startsWith('http') ? path : this.imagesPath + '/' + path;
  }
}
