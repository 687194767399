import * as fromApp from '../index';
import * as fromUserProfile from './user-profile.reducer';
import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';

export const userProfileFeatureKey = 'userProfileModule';
export const userProfileStateKey = 'userProfile';

export interface FeatureState extends fromApp.State {
  userProfile: State;
}

export interface State {
  userProfile: fromUserProfile.State;
}

export const reducers: ActionReducerMap<State> = {
  userProfile: fromUserProfile.userProfileReducer
};

// user-profile module selectors
export const getUserProfileModuleState = createFeatureSelector<State>(
  userProfileFeatureKey
);

export const getUserProfileState =
  createFeatureSelector<fromUserProfile.State>(userProfileStateKey);

export const selectUserProfileState = createSelector(
  getUserProfileModuleState,
  getUserProfileState
);

export const selectPersonalDetails = createSelector(
  selectUserProfileState,
  fromUserProfile.getPersonalDetails
);

export const selectPersonalDetailsLoading = createSelector(
  selectUserProfileState,
  fromUserProfile.getPersonalDetailsLoading
);

export const selectPersonalDetailsRequestTimestamp = createSelector(
  selectUserProfileState,
  fromUserProfile.getPersonalDetailsRequestTimestamp
);

export const selectBusinessDetails = createSelector(
  selectUserProfileState,
  fromUserProfile.getBusinessDetails
);

export const selectBusinessDetailsLoading = createSelector(
  selectUserProfileState,
  fromUserProfile.getBusinessDetailsLoading
);

export const selectBusinessDetailsRequestTimestamp = createSelector(
  selectUserProfileState,
  fromUserProfile.getBusinessDetailsRequestTimestamp
);

export const selectPreferredWorkLocations = createSelector(
  selectUserProfileState,
  fromUserProfile.getPreferredWorkLocations
);

export const selectPreferredWorkLocationsLoading = createSelector(
  selectUserProfileState,
  fromUserProfile.getPreferredWorkLocationsLoading
);

export const selectPreferredWorkLocationsRequestTimestamp = createSelector(
  selectUserProfileState,
  fromUserProfile.getPreferredWorkLocationsRequestTimestamp
);

export const selectTermsAndConditions = createSelector(
  selectUserProfileState,
  fromUserProfile.getTermsAndConditions
);

export const selectTermsAndConditionsLoading = createSelector(
  selectUserProfileState,
  fromUserProfile.getTermsAndConditionsLoading
);

export const selectTermsAndConditionsRequestTimestamp = createSelector(
  selectUserProfileState,
  fromUserProfile.getTermsAndConditionsRequestTimestamp
);

export const selectNotificationSettings = createSelector(
  selectUserProfileState,
  fromUserProfile.getNotificationSettings
);

export const selectNotificationSettingsLoading = createSelector(
  selectUserProfileState,
  fromUserProfile.getNotificationSettingsLoading
);

export const selectNotificationSettingsSaving = createSelector(
  selectUserProfileState,
  fromUserProfile.getNotificationSettingsSaving
);

export const selectNotificationSettingsRequestTimestamp = createSelector(
  selectUserProfileState,
  fromUserProfile.getNotificationSettingsRequestTimestamp
);


export const selectConsultantsTenantDetails = createSelector(
  selectUserProfileState,
  fromUserProfile.getConsultantsTenantDetails
);

export const selectConsultantsTenantDetailsRequestTimestamp = createSelector(
  selectUserProfileState,
  fromUserProfile.getConsultantsTenantDetailsRequestTimestamp
);

export const selectConsultantsTenantDetailsLoading = createSelector(
  selectUserProfileState,
  fromUserProfile.getConsultantsTenantDetailsLoading
);
