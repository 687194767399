import { Injectable } from '@angular/core';
import { SnackBarService, SnackBarTypes } from '@my7n/ui';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiceNames } from '../interfaces/my7n-env-config';
import { AppConfigService } from './app-config.service';

@Injectable()
export class DownloadService {

  /**
   * Ongoing downloads.
   */
  processedFiles: {[key: string]: boolean} = {};

  DOWNLOAD_API_BASE: string;

  constructor(appConfigService: AppConfigService,
              private http: HttpClient,
              private snackBarService: SnackBarService) {
    this.DOWNLOAD_API_BASE = appConfigService.serviceUrl(ServiceNames.Core, 'v3');
  }

  /**
   * @param fileUrl Request URL for file binary data.
   * @param [fileName] Name of downloaded file.
   *                   If none is provided response content-disposition headers will be parsed
   * @param [externalUrl=false] Flag indicates if requested file is external
   * @param [fallbackFilename] {string} filename to fallback too if content-disposition fails to return a name
   */
  downloadFile(fileUrl: string, fileName?: string, externalUrl = false, fallbackFilename?: string) {
    let fileExt: string = null;

    // prevents from multiple file downloads
    if (this.processedFiles.hasOwnProperty(fileUrl)) {
      return;
    }

    this.snackBarService.openPending({
      message: 'Downloading in progress...',
      type: SnackBarTypes.NotificationAlt
    });

    if (!externalUrl) {
      fileUrl = this.DOWNLOAD_API_BASE + fileUrl;
    }

    if (fileName) {
      // extracting file extension
      fileExt = fileName.slice(fileName.lastIndexOf('.') + 1);
    } else if (!fallbackFilename) {
      console.error('[DownloadService] fallbackFilename must be provided when fileName is missing');
    }

    this.processedFiles[fileUrl] = true;
    this.getBinaryFile(fileUrl)
      .subscribe((response: HttpResponse<Blob>) => {
        delete this.processedFiles[fileUrl];
        this.snackBarService.close();

        const blobURL = window.URL.createObjectURL(response.body);

        if (!fileName) {
          // file name is retrieved from response header
          fileName = DownloadService.getFileNameFromContentDisposition(response) || `${fallbackFilename}`;
          fileExt = fileName.slice(fileName.lastIndexOf('.') + 1);
        }

        // create fake link to set file name of downloaded file and trigger downloading
        const downloadLink = DownloadService.createDownloadLink(blobURL, fileName, () => {
          // Timeout needs to be triggered because object gets revoked earlier than it's used in old Edge browser
          // so nothing happens without it
          setTimeout(() => {
            // clean
            window.URL.revokeObjectURL(blobURL);
            // Timeout is quite random, 0 produced non-deterministic behavior (sometimes worked and some other times... not)
          }, 5000);
        });

        // trigger download
        downloadLink.click();

      }, () => {
        delete this.processedFiles[fileUrl];

        this.snackBarService.open({
          message: `Error while downloading ${fileExt || fileUrl} file`,
          type: SnackBarTypes.ErrorAlt
        });
      });
  }

  /**
   * Download of CRM file is processed in two steps:
   * - first we need to call API endpoint to get direct link to the file in blob storage.
   * - then we can start standard download process.
   *
   * @param fileUrl
   * @param [fileName]
   * @param [fallbackFileName] filename in case getting through content-disposition header fails
   */
  multiStepBlobFileDownload(apiWithFileUrl: string, fileName?: string, fallbackFileName?: string) {
    this.snackBarService.openPending({
      message: 'Preparing to download...',
      type: SnackBarTypes.NotificationAlt
    });

    // First get the url to blob file from backend
    this.http.get<string>(apiWithFileUrl).subscribe((blobURL: string) => {
      // then download it without auth headers
      this.downloadFile(blobURL, fileName, true, fallbackFileName);
    }, () => {
      this.snackBarService.open({
        message: `Error while downloading file`,
        type: SnackBarTypes.ErrorAlt
      });
    });
  }

  getBinaryFile(url): Observable<any> {
    // why blob as json? Check this out https://github.com/angular/angular/issues/18586
    return this.http.get(url, {responseType: 'blob' as 'json', observe: 'response'});
  }

  static getFileNameFromContentDisposition(response: HttpResponse<Blob>): string {
    const contentDispositionHeader = response.headers.get('content-disposition');
    const fileNameRegExp = contentDispositionHeader && contentDispositionHeader.match(/filename=(.*)/) || [];

    return fileNameRegExp.length > 0 ? decodeURIComponent(fileNameRegExp[1]) : null;
  }

  static createDownloadLink(blobURL: string, fileName: string, onClickCallback: () => void): HTMLAnchorElement {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';

    a.href = blobURL;
    a.download = fileName;
    a.onclick = (e: Event) => {
      document.body.removeChild(e.target as HTMLElement);
      onClickCallback();
    };

    return a;
  }
}
