import 'reflect-metadata';
import { FrontendFeature, FrontendFeaturesModule } from '../feature';
import { Feature } from '../interfaces/feature';

@FrontendFeaturesModule('Academy')
export class AcademyFeatures {
  @FrontendFeature({
    summary: 'Default academy page'
  })
  static readonly Default: Feature = 'learning-and-dev/academy';

  @FrontendFeature({
    summary: 'Library academy page'
  })
  static readonly Library: Feature = 'learning-and-dev/academy/library';

  @FrontendFeature({
    summary: 'Book detail view page'
  })
  static readonly BookItemView: Feature = 'learning-and-dev/academy/books/item/view';

  @FrontendFeature({
    summary: 'Book detail preview page'
  })
  static readonly BookItemPreview: Feature = 'learning-and-dev/academy/books/item/preview';

  @FrontendFeature({
    summary: 'About page'
  })
  static readonly About: Feature = 'learning-and-dev/academy/about';

  @FrontendFeature({
    summary: 'Academy Videos main page'
  })
  static readonly Videos: Feature = 'learning-and-dev/academy/videos';

  @FrontendFeature({
    summary: 'Academy video detail view page'
  })
  static readonly VideosItemView: Feature = 'learning-and-dev/academy/videos/item/view';

  @FrontendFeature({
    summary: 'Academy video detail preview page'
  })
  static readonly VideosItemPreview: Feature = 'learning-and-dev/academy/videos/item/preview';

  @FrontendFeature({
    summary: 'Academy Offers main page'
  })
  static readonly Offers: Feature = 'learning-and-dev/academy/offers';

  @FrontendFeature({
    summary: 'Academy Offers detail view page'
  })
  static readonly OffersItemView: Feature = 'learning-and-dev/academy/offers/item/view';

  @FrontendFeature({
    summary: 'Academy Offers detail preview page'
  })
  static readonly OffersItemPreview: Feature = 'learning-and-dev/academy/offers/item/preview';
}
