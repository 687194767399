import {
  Component,
  OnInit,
  HostBinding,
  OnDestroy,
  NgZone
} from '@angular/core';
import {
  ActivatedRoute,
  ActivationStart,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  Router
} from '@angular/router';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  combineLatest
} from 'rxjs';
import {
  takeUntil,
  delay,
  filter,
  map,
  mergeMap,
  pairwise,
  take,
  startWith
} from 'rxjs/operators';
import {
  IconsService,
  ChatBotService,
  SubNavbarService,
  MatDialogService,
  ConfirmDialogComponentDefaultConfig,
  ThemeColors,
  ConfirmDialogComponent
} from '@my7n/ui';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { SafeHtml } from '@angular/platform-browser';
import { Location } from '@angular/common';

import { NavigationService } from '../../services/navigation.service';
import { AuthorizationService } from '../../services/authorization.service';
import { AppHistoryService } from '../../services/app-history.service';

import { IUserPreferences } from '../../interfaces/user-preferences';
import { PageTitleService } from '../../services/page-title.service';
import { INavigationItem } from '../../interfaces/navigation-item';
import { PwaService, UpdateReadyType } from '../../services/pwa.service';
import { MatDialogRef } from '@angular/material/dialog';
import { GlobalCommonFacadeService } from '../../services/facades/global-common-facade.service';
import { GlobalAppConfigFacadeService } from '../../services/facades/global-app-config-facade.service';
import { concatLatestFrom } from '@ngrx/effects';
import { CommonFeatures } from '@my7n/features';
import { AuthenticationService } from '../../services/authentication.service';
import { VideoPlayerService } from '../../services/video-player.service';
import { My7nOAuthStorageService } from '../../services/my7n-oauth-storage.service';
import { HttpErrorResponse } from '@angular/common/http';

const SUBNAVBAR_VISIBLE_CLASS = 'second-lvl-nav-visible';

const ExtendedNavbarConfig = {
  expandTiming: '.5s ease-in',
  collapseTiming: '.2s ease-out'
};

@Component({
  selector: 'body',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  animations: [
    trigger('routerOutletVisibility', [
      state('shown', style({ opacity: '1' })),
      state('hidden', style({ opacity: '0' })),
      state('void', style({ opacity: '0' })),
      transition('hidden => shown', animate('.5s .2s ease-in')),
      transition(
        'shown => hidden',
        animate(ExtendedNavbarConfig.collapseTiming)
      )
    ])
  ]
})
export class MainComponent implements OnInit, OnDestroy {
  /**
   * CSS class name for selector element (body)
   * @type {string}
   */
  @HostBinding('class') public cssClass = '';

  /**
   * Page loader flag.
   */
  loading = true;

  /**
   * First view flag.
   */
  firstView = true;

  /**
   * Logged user preferences.
   */
  CurrentUser: IUserPreferences;

  /**
   * Subscription for page title updater.
   */
  updateTitleSub: Subscription;

  /**
   * Chatbot message to show
   */
  chatBotMessage: SafeHtml;

  /**
   * Flag if chatbot should show message
   */
  chatBotShowMessage: boolean;

  /**
   * Flag if chatbot should be disabled
   */
  chatBotDisabled: boolean;

  /**
   * Flag if chatbot should be hidden
   */
  chatBotHidden: boolean;

  /**
   * Flag if 2nd lvl nav is visible
   */
  secondLvlNavVisible = false;

  primaryNavigation$: Observable<Array<INavigationItem>>;
  activeLink$: Observable<INavigationItem>;

  user$ = this.globalAppConfigFacadeService.user$;

  get chatbotFeature() {
    return CommonFeatures.Chatbot;
  }

  private _routeCssClass: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );
  private _subnavbarCssClass: BehaviorSubject<string> =
    new BehaviorSubject<string>('');
  private _destroySubject$: Subject<void> = new Subject();

  private routeCssClass$: Observable<string>;
  private subnavbarCssClass$: Observable<string>;

  private animationRequested: boolean;
  private changePageAnimationDelay = 100;

  private reloadDialogRef: MatDialogRef<ConfirmDialogComponent>;

  isAuthenticated$ = this.authenticationService.authenticated$;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private navService: NavigationService,
    public authorizationService: AuthorizationService,
    private authenticationService: AuthenticationService,
    private appHistory: AppHistoryService,
    private location: Location,
    private titleService: PageTitleService,
    private iconsService: IconsService,
    private chatBotService: ChatBotService,
    private subNavBarService: SubNavbarService,
    private pwaService: PwaService,
    private dialog: MatDialogService,
    private zone: NgZone,
    private globalCommonFacadeService: GlobalCommonFacadeService,
    private globalAppConfigFacadeService: GlobalAppConfigFacadeService,
    private videoPlayerService: VideoPlayerService,
    private oauthStorageService: My7nOAuthStorageService
  ) {
    this.iconsService.generateCustomMaterialIcons();

    this.routeCssClass$ = this._routeCssClass.asObservable();
    this.subnavbarCssClass$ = this._subnavbarCssClass.asObservable();

    this.primaryNavigation$ = this.navService.primaryNavigation$;
    this.activeLink$ = this.navService.activeNavigationItem$;
  }

  ngOnInit() {
    this.authenticationService.authenticated$.subscribe((authenticated) => {
      if (authenticated) {
        this.globalAppConfigFacadeService.queryAppConfig();

        combineLatest([
          this.globalAppConfigFacadeService.config$.pipe(startWith(null)),
          this.globalAppConfigFacadeService.error$.pipe(startWith(null))
        ])
          .pipe(
            filter(([config, error]) => !!config || !!error),
            take(1)
          )
          .subscribe(([config, error]) => {
            if (config) {
              console.debug(
                '[MainComponent] AppConfig initialized with store.'
              );
            } else if (error) {
              this.handleAppConfigInitErrors(error);
            }
          });

        this.globalAppConfigFacadeService.user$
          .pipe(take(1))
          .subscribe((user) => {
            this.CurrentUser = user;

            if (this.CurrentUser.Agent) {
              this.chatBotService
                .updateDefaultMessage(`<strong>Hello ${this.CurrentUser.FirstName}!</strong><br/><br/>
                    How can I help you? If you have any questions don't hesitate to contact me.<br/><br/>
                    Email: <a href="mailto:${this.CurrentUser.Agent?.Email}">${this.CurrentUser.Agent?.Email}</a>`);
            } else {
              this.chatBotService.updateDefaultMessage(
                `<strong>Hello ${this.CurrentUser.FirstName}!</strong><br/>`
              );
            }
          });
      }
    });

    // This is one of two parts of code responsible for redirecting from old links
    // the second one is in my7n-app-init file
    this.location.subscribe((event: PopStateEvent) => {
      if (event.type === 'hashchange') {
        const locationHash = window.location.hash;

        // Redirect old urls starting with #!/ 'hashbang' to new pushstate way
        // IMPORTANT: location.replace will fail with exception when script is loaded from different origin than the document
        if (locationHash.indexOf('#!/') === 0) {
          const href = window.location.href;
          window.location.replace(
            href.substring(0, href.indexOf('/#!/')) + locationHash.substring(2)
          );
        }
      }
    });

    // Add previous and current address to history service.
    // this helps with navigation in search
    this.router.events
      .pipe(
        filter((e: any) => {
          return e instanceof NavigationEnd;
        }),
        pairwise(),
        takeUntil(this._destroySubject$)
      )
      .subscribe((value: [NavigationEnd, NavigationEnd]) => {
        const [oldUrlEvent, newUrlEvent] = value;
        this.appHistory.addEntry(
          oldUrlEvent.urlAfterRedirects,
          newUrlEvent.urlAfterRedirects
        );
      });

    // Subscribe to route change to set the view class
    this.router.events
      .pipe(takeUntil(this._destroySubject$))
      .subscribe((event) => {
        switch (event.constructor) {
          case ActivationStart:
            event = event as ActivationStart;
            if (event.snapshot.routeConfig.resolve) {
              this.loader(true);
            }
            break;
          case NavigationEnd:
            let route: ActivatedRoute = this.route;
            while (route.firstChild) {
              route = route.firstChild;
            }
            // retrieve body class (custom for different routes, based on first leaf from routing tree)
            this.setRouteBasedBodyClass(route.snapshot.data['viewClass']);
            this.loader(false);
            this.firstView = false;
            break;

          case NavigationCancel:
          case NavigationError:
            this.setRouteBasedBodyClass('');
            this.loader(false);
            break;
        }
      });

    // page title update
    this.updateTitleSub = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        mergeMap((route) => {
          return route.data;
        }),
        takeUntil(this._destroySubject$)
      )
      .subscribe((routeData) => {
        this.titleService.setTitle(routeData.title);
      });

    this.chatBotService.subject
      .pipe(takeUntil(this._destroySubject$))
      .subscribe((data) => {
        this.chatBotMessage =
          data.messageHTMLString === undefined
            ? this.chatBotMessage
            : data.messageHTMLString;
        this.chatBotShowMessage =
          data.showMessage === undefined
            ? this.chatBotShowMessage
            : data.showMessage;
        this.chatBotDisabled =
          data.disabled === undefined ? this.chatBotDisabled : data.disabled;
        this.chatBotHidden =
          data.hidden === undefined ? this.chatBotHidden : data.hidden;
      });

    this.subNavBarService.isSecondLevelNavVisible$
      .pipe(takeUntil(this._destroySubject$), delay(0))
      .subscribe((visible) => {
        this._subnavbarCssClass.next(visible ? SUBNAVBAR_VISIBLE_CLASS : '');
      });

    // This could be combined into one big pipe together with previous statement, but this looks cleaner
    // Subnavbar class is usually a little bit late because it waits for the component to load.
    // Either of the source observables will emit class or empty string, so joining them is safe.
    combineLatest([this.routeCssClass$, this.subnavbarCssClass$])
      .pipe(takeUntil(this._destroySubject$))
      .subscribe((classes) => {
        this.cssClass = classes.join(' ');
      });

    this.pwaService.standaloneAppParamUpdate();
    this.pwaService.initSwMessages();

    this.pwaService.isUpdateReady$
      .pipe(
        takeUntil(this._destroySubject$),
        concatLatestFrom(() => this.pwaService.reloadDialogDisabled$), // check if should show the dialog
        filter((result: [UpdateReadyType, boolean]) => result[1] === false)
      )
      .subscribe((result: [UpdateReadyType, boolean]) => {
        if (result[0] === UpdateReadyType.ForceUpdate) {
          this.openDialog(true);
        } else {
          this.openDialog(false);
        }
      });

    combineLatest([
      this.isAuthenticated$,
      this.globalAppConfigFacadeService.initialized$
    ])
      .pipe(
        filter(
          ([isAuthenticated, initialized]) => isAuthenticated && initialized
        ),
        take(1)
      )
      .subscribe(() => {
        this.globalCommonFacadeService.queryBlobSasTokens();
        this.videoPlayerService.initYtIframeApi();
      });
  }

  openDialog(force = true) {
    // we need to open it inside zone.run to fix missing content for Angular Material dialog
    // thanks to: https://stackoverflow.com/a/50994573
    this.zone.run(() => {
      this.reloadDialogRef = this.dialog.open(ConfirmDialogComponent, {
        ...ConfirmDialogComponentDefaultConfig,
        closeOnNavigation: force,
        data: {
          dialogHTML: force
            ? 'A newer version of the application is available.<br>Please reload to continue to use the application.'
            : 'A newer version of the application is available.<br>Do you want to update it now by reloading the app?',
          buttonsColor: ThemeColors.PRIMARY,
          confirmButtonText: 'Reload',
          cancelButtonText: `Later`,
          headerText: 'Updates available',
          cancelButtonHidden: force,
          closeButtonHidden: force
        }
      });
      this.reloadDialogRef.afterClosed().subscribe((result) => {
        if (force || result) {
          window.location.reload();
        }
      });
    });
  }

  private loader(value: boolean) {
    if (value) {
      this.animationRequested = true;
      setTimeout(() => {
        // Animation will start only if after 100ms animationRequested flag is still active
        if (this.animationRequested) {
          this.loading = true;
        }
      }, this.changePageAnimationDelay);
    }

    // cancelling animation is done immediately
    this.loading = value;
    this.animationRequested = value;
  }

  private setRouteBasedBodyClass(cssClass: string) {
    this._routeCssClass.next(cssClass);
  }

  private handleAppConfigInitErrors(err: HttpErrorResponse) {
    this.oauthStorageService.clear(); // clear the storage to avoid errors on next login
    if (err.status === 401 || err.status === 403) {
      this.router
        .navigate(['/unauthorized'], {
          skipLocationChange: true,
          queryParams: {
            error: err.status,
            errorMessage: err.error ? window.btoa(err.error.Message) : null
          }
        })
        .then(() =>
          console.debug(
            '[AuthGuard] Redirected to unauthorized (' + err.status + ') page'
          )
        );
    }
    this.router
      .navigate(['/server-error'], {
        skipLocationChange: true,
        queryParams: {
          error: err.status,
          errorMessage: err.error ? window.btoa(err.error.Message) : null
        }
      })
      .then(() =>
        console.debug(
          '[AuthGuard] Redirected to server-error (other than 401 and 403) page'
        )
      );
  }

  ngOnDestroy() {
    this._destroySubject$.next();
  }
}
