import { Pipe, PipeTransform } from '@angular/core';
import {SafeUrl, DomSanitizer} from "@angular/platform-browser";

@Pipe({
  name: 'trustedUrl'
})
export class TrustedUrlPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }

  transform(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
