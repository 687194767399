import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { SharepointService } from '../services/sharepoint.service';

@Injectable()
export class SharepointHttpInterceptor implements HttpInterceptor {
  constructor(private sharepointService: SharepointService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.sharepointService.isSharepointUrl(req.url)) {
      return this.sharepointService.getAccessToken().pipe(
        take(1),
        switchMap((token) => {
          if (token) {
            const header = 'Bearer ' + token.access_token;
            const headers = req.headers.set('Authorization', header);

            req = req.clone({ headers });
          }

          return next.handle(req).pipe(
            catchError((err) => {
              return of(err);
            })
          );
        })
      );
    }

    return next.handle(req);
  }
}
