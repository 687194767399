export const NGRX_CACHE_LIMIT_IN_SECONDS = 60;

export function isInCacheLimit(requestTimestamp: number): boolean {
  if (requestTimestamp === null) {
    return false;
  }
  return Date.now() - requestTimestamp > NGRX_CACHE_LIMIT_IN_SECONDS * 1000
    ? false
    : true;
}
