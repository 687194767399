import { Injectable } from '@angular/core';
import { Icons, SnackBarService, SnackBarTypes } from '@my7n/ui';

import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { catchError, distinctUntilChanged, map, mergeMap } from 'rxjs/operators';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import * as ngrxUtils from '../../utils/ngrx-utils';


import * as GalleriesActions from '../actions/galleries.actions';
import * as fromGalleries from '../reducers/galleries';
import * as GalleriesSelectors from '../reducers/galleries/galleries.selectors';

import { ISharepointFiles } from '../../interfaces/sharepoint';
import { SharepointService } from '../../services/sharepoint.service';

@Injectable()
export class GalleriesEffects {
  constructor(
    private actions$: Actions,
    private snackBarService: SnackBarService,
    private galleriesStore$: Store<fromGalleries.State>,
    private sharepointService: SharepointService
  ) {}

  queryGallery$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(GalleriesActions.queryGalleryData),
      distinctUntilChanged(),
      concatLatestFrom((action) =>
        this.galleriesStore$.select(GalleriesSelectors.selectGallery, { path: action.path })
      ),
      mergeMap(([action, gallery]) => {
        if (
          gallery &&
          !action.forceRefresh &&
          ngrxUtils.isInCacheLimit(gallery.lastRequestTimestamp)
        ) {
          return of(GalleriesActions.galleryLoadedFromCache());
        } else {
          return this.sharepointService.getGalleryFiles(action.path, action.galleryParams).pipe(
            map((res: ISharepointFiles) => {
              return GalleriesActions.updateGalleryData({
                gallery: {
                  FilesData: res.FilesData,
                  Count: res.Count,
                  loading: false,
                  error: false,
                  basePath: action.path
                }
              });
            }),
            catchError((err) => {
              this.snackBarService.open({
                message: 'An error occurred during loading gallery data',
                type: SnackBarTypes.ErrorAlt,
                actionIcon: Icons.CLOSE_TINY
              });

              return of(GalleriesActions.queryGalleryDataError({ path: action.path }));
            })
          );
        }
      })
    );
  });
}
