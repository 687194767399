import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { INotificationsResponse } from '../interfaces/notifications-response';
import { INotificationSettings, NotificationFrequency } from '../interfaces/notification-settings';
import { HttpClient } from '@angular/common/http';
import { ServiceNames } from '../interfaces/my7n-env-config';
import { AppConfigService } from './app-config.service';

@Injectable()
export class NotificationsService {

  readonly API_INSTANT: string;
  constructor(private http: HttpClient,
              private appConfigService: AppConfigService) {
    this.API_INSTANT = this.appConfigService.serviceUrl(ServiceNames.InstantNotification, 'v1') + 'instant-notification/';
  }

  getNotifications(): Observable<INotificationsResponse> {
    return this.http.get<INotificationsResponse>(this.API_INSTANT);
  }

  updateNotificationsTimestamp(timestamp: Date): Observable<{}> {
    return this.http.patch(this.API_INSTANT + 'contact/check-out-notifications', { timestamp: timestamp });
  }

  notificationItemRead(notificationId?: string): Observable<{}> {
    return this.http.patch(this.API_INSTANT + 'contact/visit-notifications', { id: notificationId });
  }

  markAllNotificationsAsRead(): Observable<{}> {
    return this.http.patch(this.API_INSTANT + 'contact/visit-notifications', { });
  }

  getUserNotificationSettings(): Observable<INotificationSettings> {
    return this.http.get<INotificationSettings>(this.API_INSTANT + 'settings');
  }

  subscribeEmailNotifications(): Observable<INotificationSettings> {
    return this.http.patch<INotificationSettings>(this.API_INSTANT + 'settings/subscribe', {});
  }

  unsubscribeEmailNotifications(): Observable<INotificationSettings> {
    return this.http.patch<INotificationSettings>(this.API_INSTANT + 'settings/unsubscribe', {});
  }

  setEmailNotificationsFrequency(frequency: NotificationFrequency): Observable<INotificationSettings> {
    return this.http.patch<INotificationSettings>(this.API_INSTANT + 'settings/frequency', {frequency: frequency});
  }
}
