import { createSelector } from '@ngrx/store';
import { getGlobalCommonState, getGlobalModuleState } from './index';
import * as fromGlobalCommon from './global-common.reducer';

export const selectGlobalCommonState = createSelector(
  getGlobalModuleState,
  getGlobalCommonState
);

export const selectAppVersion = createSelector(
  selectGlobalCommonState,
  fromGlobalCommon.getAppVersion
);

export const selectLeavingPageGuardEnabled = createSelector(
  selectGlobalCommonState,
  fromGlobalCommon.getLeavingPageGuardEnabled
);

export const selectTokenClaims = createSelector(
  selectGlobalCommonState,
  fromGlobalCommon.getTokenClaims
);

export const selectTokenClaimsBusinessUnit = createSelector(
  selectGlobalCommonState,
  fromGlobalCommon.getTokenClaimsBusinessUnit
);

export const selectBlobSasTokens = createSelector(
  selectGlobalCommonState,
  fromGlobalCommon.getBlobSasTokens
);
