import { Inject, Injectable } from '@angular/core';
import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web';
import { PwaService } from './pwa.service';
import { MY7N_ENV_CONFIG } from '../functions/my7n-env-config';
import { IMy7nEnvConfig, SeverityLevel } from '../interfaces/my7n-env-config';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { ITelemetryItem } from '@microsoft/applicationinsights-core-js';
import { ITokenClaims } from '../interfaces/token-claims';

@Injectable({
  providedIn: 'root',
})
export class ApplicationInsightsService {
  private static aiRole = 'my7n-frontend';
  /**
   * List of messages which will be ignored from AI log.
   * Every entry from this list will be used as "start" of exception message
   * (e.g. exceptionMessage.startsWith(ignoredMessages[0]) ... etc)
   * @private
   */
  private static ignoredMessages = [
    // ResizeObserver loop limit can be ignored https://stackoverflow.com/a/50387233
    'ResizeObserver loop limit exceeded',
    // @TODO this should be probably handled by creating a OAuthLogger and limit verbosity over there
    'Error refreshing token'
  ];

  private trackingStarted = false;
  private readonly _rawAIInstance: ApplicationInsights;

  private initialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private wasInitialized$: Observable<boolean>;

  private _claims: ITokenClaims;


  public get rawAIInstance(): ApplicationInsights {
    return this._rawAIInstance;
  }

  public get claims(): ITokenClaims {
    return this._claims;
  }

  constructor(
    @Inject(MY7N_ENV_CONFIG) private envConfig: IMy7nEnvConfig
  ) {
    this._rawAIInstance = new ApplicationInsights({
      config: {
        instrumentationKey: this.envConfig.INSIGHTS_KEY,
        distributedTracingMode: DistributedTracingModes.AI,
        enableDebugExceptions: this.envConfig.INSIGHTS_INTERNAL_VERBOSITY,
        enableDebug: this.envConfig.INSIGHTS_INTERNAL_VERBOSITY,
        // Cookies are send with the requests pointing to the same domain
        // as we are using CORS apis, they won't be send anymore we can just disable them
        isCookieUseDisabled: true,
        // Adds Request-Id and Request-Context for AJAX requests other than GET and POST
        enableCorsCorrelation: true, // should be true
        // list of domains excluded from sending request-id and request-context (when enableCorsCorrelation is enabled)
        correlationHeaderExcludedDomains: ['*.visualstudio.com', 'fs.7n.eu', 'fs3.7n.com']
      }
    });

    this.wasInitialized$ = this.initialized$.asObservable().pipe(
      // emit only when init is true, else wait
      filter((wasInitialized: boolean) => {
        return wasInitialized === true;
      })
    );
  }

  init() {
    this.rawAIInstance.loadAppInsights();

    this.addTelemetryInitializers();

    // We cannot ensure that init will be called before startPageTrack
    // this will cause startPageTrack to wait until init was done
    this.initialized$.next(true);
  }

  setClaimsData(claims: ITokenClaims) {
    this._claims = claims;
  }

  addTelemetryInitializers() {
    const addAdditionalTelemetryData = (envelope: ITelemetryItem) => {
      const appVersion = PwaService.appVersion.toString();
      const envelopeData = envelope.data || {};

      envelope.data = {
        ...envelopeData,
        appVersion: appVersion,
        appIsOnline: window.navigator.onLine.toString(),
        BusinessUnit: this.claims.BusinessUnit || '',
        UserName: this.claims.unique_name || '',
        Upn: this.claims.upn || '',
        CrmContactId: this.claims.ID || ''
      };

      // set also role to clearly separate logs
      envelope.tags['ai.cloud.role'] = ApplicationInsightsService.aiRole;
      envelope.tags['ai.cloud.roleInstance'] = ApplicationInsightsService.aiRole + '-' + appVersion;
    };

    const filterIgnoredErrors = (envelope: ITelemetryItem) => {
      if (envelope.baseType === 'ExceptionData' && envelope.baseData.exceptions && envelope.baseData.exceptions[0]) {
        const message = envelope.baseData.exceptions[0].message;

        if (ApplicationInsightsService.ignoredMessages.some(ignored => message.startsWith(ignored))) {
          return false; // false - means this message will be ignored
        }
      }
    };

    this.rawAIInstance.addTelemetryInitializer(addAdditionalTelemetryData);
    this.rawAIInstance.addTelemetryInitializer(filterIgnoredErrors);
  }

  startPageTrack(url: string): Subscription {
    return this.wasInitialized$.pipe(
      take(1)
    ).subscribe(() => {
      this.rawAIInstance.startTrackPage(url);
      this.trackingStarted = true;
   });
  }

  stopPageTrack(url: string): Subscription | void {
    if (this.trackingStarted) {
      return this.wasInitialized$.pipe(
        take(1)
      ).subscribe(() => {
        // second argument (url) is set to null to use default browser location, same as in previous implementation
        this.rawAIInstance.stopTrackPage(url, url, {
          'standalone-app': PwaService.isStandaloneApp.toString(),
        });
        this.trackingStarted = false;
      });
    }
  }

  // Tiny layer to hide the implementation a little ---------------------------------------------
  /**
   * Manually trigger an immediate send of all telemetry still in the buffer.
   * @param {boolean} [async=true]
   * @memberof Initialization
   */
  public flush(async: boolean = true) {
    this.rawAIInstance.flush(async);
  }

  /**
   * Log a user action or other occurrence.
   * @param   eventName    A eventName to identify this event in the portal.
   * @param   eventProperties  map[string, string] - additional data used to filter events and metrics in the portal. Defaults to empty.
   */
  trackEvent(eventName: string, eventProperties?: { [name: string]: string }) {
    this.rawAIInstance.trackEvent({
      name: eventName,
      properties: eventProperties,
    });
  }

  /**
   * Log a diagnostic message.
   * @param    message A message string
   * @param    customProperties  { [name: string]: string } - additional data used to filter traces in the portal. Defaults to empty.
   * @param    severityLevel  SeverityLevel - additional data used to filter traces in the portal. Defaults to empty.
   */
  trackTrace(message: string, customProperties?: { [name: string]: string }, severityLevel?: SeverityLevel) {
    this.rawAIInstance.trackTrace({
      message,
      severityLevel
    }, customProperties);
  }

  /**
   * Log an exception you have caught.
   * @param   exception   An Error from a catch clause, or the string error message.
   * @param   properties  map[string, string] - additional data used to filter events and metrics in the portal. Defaults to empty.
   * @param   severityLevel   SeverityLevel | AI.SeverityLevel - severity level
   */
  trackException(exception: Error, properties?: { [name: string]: string }, severityLevel?: SeverityLevel) {
    this.rawAIInstance.trackException({
      exception,
      severityLevel,
      properties
    });
  }

}
