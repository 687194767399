
export interface IB2CUser {
  UserId: string;
  CreatedOn: string;
  DisplayName: string;
  Email: string;
  EntityId?: string;
  IsActive: boolean;
  FirstName: string;
  LastName: string;
  AssignedEmails: Array<string>;
  UserPrincipalName?: string;
  Identities?: Array<IB2CUserIdentity>
}

export interface IB2CUserIdentity {
  Issuer: string;
  IssuerAssignedId: string;
  SignInType: string;
}

export interface IB2CUsersResponse {
  Count: number;
  Users: Array<IB2CUser>;
}

export interface IB2CUserStatus {
  status: B2CUserStatus;
  duplicates: Array<IB2CUser>;
  crmContactId: string;
  isActive?: boolean;
}

export enum B2CUserStatus {
  Exists = 'Exists',
  EmailUsedByOtherAccount = 'EmailUsedByOtherAccount',
  NotExists = 'NotExists'
}
