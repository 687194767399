import { InjectionToken } from '@angular/core';
import { IMy7nEnvConfig } from '../interfaces/my7n-env-config';

export const MY7N_ENV_CONFIG = new InjectionToken<IMy7nEnvConfig>('MY7N_ENV_CONFIG');

export function my7nEnvConfigFactory() {
  const config: IMy7nEnvConfig = (window as any).my7nEnvConfig;
  if (!config.APPLICATION_URL.endsWith('/')) {
    console.error('[my7nEnvConfigFactory] APPLICATION_URL must end with `/`');
  }
  return config;
}
