import {Pipe, PipeTransform} from '@angular/core';
import { truncateAfterWord } from '../functions/truncate-after-word';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  transform(text: string, length: number, makePlainText: boolean = false): string {
    let emptyNode;

    if (text && makePlainText) {
      emptyNode = document.createElement('div');
      // Replacing img tag before injecting to innerHTML prevents from preloading those images with browser engine
      // this is not the case for scripts, those are not parsed before inserted to DOM
      text = text.replace(/<img[\s]/g, '<img-replacement ');
      text = text.replace(/<br[\s/]*>/g, ' '); // change every <br> to space to avoid sticky words after cleaning HTML
      // When you hit enter by default WYSIWYG editor wraps line content into <p> and generates new <p> for the new line
      // That is why we need to change </p><p> to space
      text = text.replace(/<\/p>[\s]*<p/g, '</p> <p');

      emptyNode.innerHTML = text;
      // merge multiple spaces into one
      text = emptyNode.textContent.replace(/[\s]{2,}/g, ' ').trim();
    }

    return truncateAfterWord(text, length);
  }
}
