import { ClassType, FrontendFeatureDecorator, FrontendFeaturesModuleDecorator } from './interfaces/feature';

export const FRONTEND_FEATURE_METADATA_KEY = Symbol('frontendFeature');
export const FRONTEND_FEATURES_MODULE_METADATA_KEY = Symbol('featureModule');

export function FrontendFeature(details: FrontendFeatureDecorator): PropertyDecorator {
  return Reflect.metadata(FRONTEND_FEATURE_METADATA_KEY, details);
}

export function FrontendFeaturesModule(moduleName: FrontendFeaturesModuleDecorator): ClassDecorator {
  return Reflect.metadata(FRONTEND_FEATURES_MODULE_METADATA_KEY, moduleName);
}

export function getFrontendFeaturesModuleName(module: ClassType): FrontendFeaturesModuleDecorator {
  return Reflect.getMetadata(FRONTEND_FEATURES_MODULE_METADATA_KEY, module);
}

export function getFrontendFeatureDetails(module: ClassType, propertyName: string): FrontendFeatureDecorator {
  return Reflect.getMetadata(FRONTEND_FEATURE_METADATA_KEY, module, propertyName);
}
