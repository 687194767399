import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromGlobal from '../../store/reducers/global/index';
import * as GlobalSelectors from '../../store/reducers/global/global-common.selectors';
import * as GlobalActions from '../../store/actions/global.actions';
import { BusinessUnit } from '../../interfaces/business-unit';
import { ITokenClaims } from '../../interfaces/token-claims';
import { IBlobSasTokens } from '../../interfaces/blob-sas';

@Injectable()
export class GlobalCommonFacadeService {
  constructor(
    private store: Store<fromGlobal.GlobalModuleState>
  ) {}

  get appVersion$(): Observable<string> {
    return this.store.pipe(select(GlobalSelectors.selectAppVersion));
  }

  get userBusinessUnit$(): Observable<BusinessUnit> {
    return this.store.pipe(select(GlobalSelectors.selectTokenClaimsBusinessUnit));
  }

  get leavingPageGuardEnabled$(): Observable<boolean> {
    return this.store.pipe(select(GlobalSelectors.selectLeavingPageGuardEnabled));
  }

  get tokenClaims$(): Observable<ITokenClaims> {
    return this.store.pipe(select(GlobalSelectors.selectTokenClaims));
  }

  get blobSasTokens$(): Observable<IBlobSasTokens> {
    return this.store.pipe(select(GlobalSelectors.selectBlobSasTokens));
  }

  updateAppVersion(version: string) {
    this.store.dispatch(
      GlobalActions.updateAppVersion({
        appVersion: version
      })
    );
  }

  updateLeavingPageGuardEnabled(isEnabled: boolean) {
    this.store.dispatch(
      GlobalActions.updateLeavingPageGuardEnabled({
        isEnabled
      })
    );
  }

  updateTokenClaims(claims: ITokenClaims) {
    this.store.dispatch(
      GlobalActions.updateTokenClaims({ tokenClaims: claims })
    );
  }

  queryBlobSasTokens() {
    this.store.dispatch(GlobalActions.queryBlobSasTokens());
  }
}
